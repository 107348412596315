///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Used to Initialize Firebase Instance

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
	DatabaseRef_Cargo_Collection
} from 'rfbp_aux/services/database_endpoints/directory/cargo'
import {
	DatabaseRef_Carriers_Collection
} from 'rfbp_aux/services/database_endpoints/directory/carriers'
import {
	DatabaseRef_Leases_Collection
} from 'rfbp_aux/services/database_endpoints/directory/leases'
import {
	DatabaseRef_Offloads_Collection
} from 'rfbp_aux/services/database_endpoints/directory/offloads'
import {
	DatabaseRef_Producers_Collection
} from 'rfbp_aux/services/database_endpoints/directory/producers'
import {
	DatabaseRef_Purchasers_Collection
} from 'rfbp_aux/services/database_endpoints/directory/purchasers'
import {
	DatabaseRef_Regions_Collection
} from 'rfbp_aux/services/database_endpoints/directory/regions'
import {
	DatabaseRef_Routes_Collection
} from 'rfbp_aux/services/database_endpoints/directory/routes'
import {
	DatabaseRef_Terminals_Collection
} from 'rfbp_aux/services/database_endpoints/directory/terminals'
import {
	DatabaseRef_Trailers_Collection
} from 'rfbp_aux/services/database_endpoints/directory/trailers'
import {
	DatabaseRef_TransferSites_Collection
} from 'rfbp_aux/services/database_endpoints/directory/transfer_sites'
import {
	DatabaseRef_Trucks_Collection
} from 'rfbp_aux/services/database_endpoints/directory/trucks'
import {
	DatabaseRef_Users_Collection
} from 'rfbp_aux/services/database_endpoints/directory/users'
import {
	generateDatabaseQuery,
	TsInterface_OrderByArray,
	TsInterface_QueryCursorsObject,
	TsInterface_QueryOperatorsArray
} from 'rfbp_core/services/database_management'
import {
	TsType_Any,
	TsType_Null,
	TsType_Number,
	TsType_String
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_SearchIndexObject {
	key: TsType_String
	type: "client" | "global"
	indexKey: TsType_String
	primaryKey: "id"
	indexedAttributes: {
		id: TsType_String
		[ propKey: TsType_String ]: TsType_String
	}
	filterableAttributes: TsType_String[],
	databaseDataEndpointOrderByProp: TsType_String
	databaseDataEndpoint: TsType_Any
	statusToSetIfNull?: TsType_String
	inactiveBoolToStatus?: TsType_String
}

interface TsInterface_SearchIndexList {
	[ indexKey: TsType_String ]: TsInterface_SearchIndexObject
}

///////////////////////////////
// Variables
///////////////////////////////

export const searchConfig = {
	// host: "https://34.42.131.222",
	host: "https://search.loadcall.com",
	apiKey: "3e1acaa6ba096b7e1aaff61ccf1db04fedf85113085b293dd7d67960c3ab7651" // Search API Key
}

export const searchIndexList: TsInterface_SearchIndexList = {
	client_cargo: {
		type: "client",
		key: "client_cargo",
		indexKey: "cargo",
		primaryKey: "id",
		statusToSetIfNull: "active",
		inactiveBoolToStatus: "deleted",
		indexedAttributes: {
			id: "key",
			name: "name",
			status: "status"
		},
		filterableAttributes: [
			"status",
		],
		databaseDataEndpointOrderByProp: "name",
		databaseDataEndpoint: (
			clientKey: TsType_String,
			startAfter: TsType_String | TsType_Null,
			startAt: TsType_String | TsType_Null,
			endAt: TsType_String | TsType_Null,
			endBefore: TsType_String | TsType_Null,
			limit: TsType_Number
		) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ] // databaseDataEndpointOrderByProp
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if ( startAfter != null){	 	queryCursorsObject["startAfter"] = startAfter }
			if ( startAt != null){ 			queryCursorsObject["startAt"] = startAt }
			if ( endAt != null){ 			queryCursorsObject["endAt"] = endAt }
			if ( endBefore != null){ 		queryCursorsObject["endBefore"] = endBefore }
			return generateDatabaseQuery(
				DatabaseRef_Cargo_Collection( clientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
	},
	client_carriers: {
		type: "client",
		key: "client_carriers",
		indexKey: "carriers",
		primaryKey: "id",
		statusToSetIfNull: "active",
		inactiveBoolToStatus: "deleted",
		indexedAttributes: {
			id: "key",
			name: "name",
			status: "status"
		},
		filterableAttributes: [
			"status",
		],
		databaseDataEndpointOrderByProp: "name",
		databaseDataEndpoint: (
			clientKey: TsType_String,
			startAfter: TsType_String | TsType_Null,
			startAt: TsType_String | TsType_Null,
			endAt: TsType_String | TsType_Null,
			endBefore: TsType_String | TsType_Null,
			limit: TsType_Number
		) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ] // databaseDataEndpointOrderByProp
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if ( startAfter != null){	 	queryCursorsObject["startAfter"] = startAfter }
			if ( startAt != null){ 			queryCursorsObject["startAt"] = startAt }
			if ( endAt != null){ 			queryCursorsObject["endAt"] = endAt }
			if ( endBefore != null){ 		queryCursorsObject["endBefore"] = endBefore }
			return generateDatabaseQuery(
				DatabaseRef_Carriers_Collection( clientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
	},
	client_leases: {
		type: "client",
		key: "client_leases",
		indexKey: "leases",
		primaryKey: "id",
		statusToSetIfNull: "active",
		inactiveBoolToStatus: "deleted",
		indexedAttributes: {
			id: "key",
			name: "name",
			lease_property_number: "lease_property_number",
			associated_producer: "associated_producer",
			associated_producer_name: "associated_producer_name",
			status: "status"
		},
		filterableAttributes: [
			"status",
			"associated_producer"
		],
		databaseDataEndpointOrderByProp: "name",
		databaseDataEndpoint: (
			clientKey: TsType_String,
			startAfter: TsType_String | TsType_Null,
			startAt: TsType_String | TsType_Null,
			endAt: TsType_String | TsType_Null,
			endBefore: TsType_String | TsType_Null,
			limit: TsType_Number
		) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ] // databaseDataEndpointOrderByProp
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if ( startAfter != null){	 	queryCursorsObject["startAfter"] = startAfter }
			if ( startAt != null){ 			queryCursorsObject["startAt"] = startAt }
			if ( endAt != null){ 			queryCursorsObject["endAt"] = endAt }
			if ( endBefore != null){ 		queryCursorsObject["endBefore"] = endBefore }
			return generateDatabaseQuery(
				DatabaseRef_Leases_Collection( clientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
	},
	client_offloads: {
		type: "client",
		key: "client_offloads",
		indexKey: "offloads",
		primaryKey: "id",
		statusToSetIfNull: "active",
		inactiveBoolToStatus: "deleted",
		indexedAttributes: {
			id: "key",
			name: "name",
			status: "status"
		},
		filterableAttributes: [
			"status",
		],
		databaseDataEndpointOrderByProp: "name",
		databaseDataEndpoint: (
			clientKey: TsType_String,
			startAfter: TsType_String | TsType_Null,
			startAt: TsType_String | TsType_Null,
			endAt: TsType_String | TsType_Null,
			endBefore: TsType_String | TsType_Null,
			limit: TsType_Number
		) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ] // databaseDataEndpointOrderByProp
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if ( startAfter != null){	 	queryCursorsObject["startAfter"] = startAfter }
			if ( startAt != null){ 			queryCursorsObject["startAt"] = startAt }
			if ( endAt != null){ 			queryCursorsObject["endAt"] = endAt }
			if ( endBefore != null){ 		queryCursorsObject["endBefore"] = endBefore }
			return generateDatabaseQuery(
				DatabaseRef_Offloads_Collection( clientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
	},
	client_producers: {
		type: "client",
		key: "client_producers",
		indexKey: "producers",
		primaryKey: "id",
		statusToSetIfNull: "active",
		inactiveBoolToStatus: "deleted",
		indexedAttributes: {
			id: "key",
			name: "name",
			status: "status"
		},
		filterableAttributes: [
			"status",
		],
		databaseDataEndpointOrderByProp: "name",
		databaseDataEndpoint: (
			clientKey: TsType_String,
			startAfter: TsType_String | TsType_Null,
			startAt: TsType_String | TsType_Null,
			endAt: TsType_String | TsType_Null,
			endBefore: TsType_String | TsType_Null,
			limit: TsType_Number
		) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ] // databaseDataEndpointOrderByProp
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if ( startAfter != null){	 	queryCursorsObject["startAfter"] = startAfter }
			if ( startAt != null){ 			queryCursorsObject["startAt"] = startAt }
			if ( endAt != null){ 			queryCursorsObject["endAt"] = endAt }
			if ( endBefore != null){ 		queryCursorsObject["endBefore"] = endBefore }
			return generateDatabaseQuery(
				DatabaseRef_Producers_Collection( clientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
	},
	client_purchasers: {
		type: "client",
		key: "client_purchasers",
		indexKey: "purchasers",
		primaryKey: "id",
		statusToSetIfNull: "active",
		inactiveBoolToStatus: "deleted",
		indexedAttributes: {
			id: "key",
			name: "name",
			status: "status"
		},
		filterableAttributes: [
			"status",
		],
		databaseDataEndpointOrderByProp: "name",
		databaseDataEndpoint: (
			clientKey: TsType_String,
			startAfter: TsType_String | TsType_Null,
			startAt: TsType_String | TsType_Null,
			endAt: TsType_String | TsType_Null,
			endBefore: TsType_String | TsType_Null,
			limit: TsType_Number
		) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ] // databaseDataEndpointOrderByProp
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if ( startAfter != null){	 	queryCursorsObject["startAfter"] = startAfter }
			if ( startAt != null){ 			queryCursorsObject["startAt"] = startAt }
			if ( endAt != null){ 			queryCursorsObject["endAt"] = endAt }
			if ( endBefore != null){ 		queryCursorsObject["endBefore"] = endBefore }
			return generateDatabaseQuery(
				DatabaseRef_Purchasers_Collection( clientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
	},
	client_regions: {
		type: "client",
		key: "client_regions",
		indexKey: "regions",
		primaryKey: "id",
		statusToSetIfNull: "active",
		inactiveBoolToStatus: "deleted",
		indexedAttributes: {
			id: "key",
			name: "name",
			status: "status"
		},
		filterableAttributes: [
			"status",
		],
		databaseDataEndpointOrderByProp: "name",
		databaseDataEndpoint: (
			clientKey: TsType_String,
			startAfter: TsType_String | TsType_Null,
			startAt: TsType_String | TsType_Null,
			endAt: TsType_String | TsType_Null,
			endBefore: TsType_String | TsType_Null,
			limit: TsType_Number
		) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ] // databaseDataEndpointOrderByProp
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if ( startAfter != null){	 	queryCursorsObject["startAfter"] = startAfter }
			if ( startAt != null){ 			queryCursorsObject["startAt"] = startAt }
			if ( endAt != null){ 			queryCursorsObject["endAt"] = endAt }
			if ( endBefore != null){ 		queryCursorsObject["endBefore"] = endBefore }
			return generateDatabaseQuery(
				DatabaseRef_Regions_Collection( clientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
	},
	client_routes: {
		type: "client",
		key: "client_routes",
		indexKey: "routes",
		primaryKey: "id",
		statusToSetIfNull: "active",
		inactiveBoolToStatus: "deleted",
		indexedAttributes: {
			id: "key",
			name: "name",
			associated_lease: "associated_lease",
			associated_lease_name: "associated_lease_name",
			associated_offload: "associated_offload",
			associated_offload_name: "associated_offload_name",
			associated_purchaser: "associated_purchaser",
			associated_purchaser_name: "associated_purchaser_name",
			associated_cargo: "associated_cargo",
			associated_cargo_name: "associated_cargo_name",
			status: "status"
		},
		filterableAttributes: [
			"associated_lease",
			"associated_offload",
			"associated_purchaser",
			"associated_cargo",
			"status",
		],
		databaseDataEndpointOrderByProp: "name",
		databaseDataEndpoint: (
			clientKey: TsType_String,
			startAfter: TsType_String | TsType_Null,
			startAt: TsType_String | TsType_Null,
			endAt: TsType_String | TsType_Null,
			endBefore: TsType_String | TsType_Null,
			limit: TsType_Number
		) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ] // databaseDataEndpointOrderByProp
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if ( startAfter != null){	 	queryCursorsObject["startAfter"] = startAfter }
			if ( startAt != null){ 			queryCursorsObject["startAt"] = startAt }
			if ( endAt != null){ 			queryCursorsObject["endAt"] = endAt }
			if ( endBefore != null){ 		queryCursorsObject["endBefore"] = endBefore }
			return generateDatabaseQuery(
				DatabaseRef_Routes_Collection( clientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
	},
	client_terminals: {
		type: "client",
		key: "client_terminals",
		indexKey: "terminals",
		primaryKey: "id",
		statusToSetIfNull: "active",
		inactiveBoolToStatus: "deleted",
		indexedAttributes: {
			id: "key",
			name: "name",
			status: "status"
		},
		filterableAttributes: [
			"status",
		],
		databaseDataEndpointOrderByProp: "name",
		databaseDataEndpoint: (
			clientKey: TsType_String,
			startAfter: TsType_String | TsType_Null,
			startAt: TsType_String | TsType_Null,
			endAt: TsType_String | TsType_Null,
			endBefore: TsType_String | TsType_Null,
			limit: TsType_Number
		) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ] // databaseDataEndpointOrderByProp
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if ( startAfter != null){	 	queryCursorsObject["startAfter"] = startAfter }
			if ( startAt != null){ 			queryCursorsObject["startAt"] = startAt }
			if ( endAt != null){ 			queryCursorsObject["endAt"] = endAt }
			if ( endBefore != null){ 		queryCursorsObject["endBefore"] = endBefore }
			return generateDatabaseQuery(
				DatabaseRef_Terminals_Collection( clientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
	},
	client_trailers: {
		type: "client",
		key: "client_trailers",
		indexKey: "trailers",
		primaryKey: "id",
		statusToSetIfNull: "active",
		inactiveBoolToStatus: "deleted",
		indexedAttributes: {
			id: "key",
			name: "name",
			license_plate: "license_plate",
			vin: "vin",
			associated_carrier: "associated_carrier",
			associated_carrier_name: "associated_carrier_name",
			associated_driver: "associated_driver",
			associated_driver_name: "associated_driver_name",
			associated_owner: "associated_owner",
			associated_owner_name: "associated_owner_name",
			status: "status"
		},
		filterableAttributes: [
			"associated_carrier",
			"associated_owner",
			"status",
		],
		databaseDataEndpointOrderByProp: "name",
		databaseDataEndpoint: (
			clientKey: TsType_String,
			startAfter: TsType_String | TsType_Null,
			startAt: TsType_String | TsType_Null,
			endAt: TsType_String | TsType_Null,
			endBefore: TsType_String | TsType_Null,
			limit: TsType_Number
		) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ] // databaseDataEndpointOrderByProp
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if ( startAfter != null){	 	queryCursorsObject["startAfter"] = startAfter }
			if ( startAt != null){ 			queryCursorsObject["startAt"] = startAt }
			if ( endAt != null){ 			queryCursorsObject["endAt"] = endAt }
			if ( endBefore != null){ 		queryCursorsObject["endBefore"] = endBefore }
			return generateDatabaseQuery(
				DatabaseRef_Trailers_Collection( clientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
	},
	client_transfer_sites: {
		type: "client",
		key: "client_transfer_sites",
		indexKey: "transfer_sites",
		primaryKey: "id",
		statusToSetIfNull: "active",
		inactiveBoolToStatus: "deleted",
		indexedAttributes: {
			id: "key",
			name: "name",
			status: "status"
		},
		filterableAttributes: [
			"status",
		],
		databaseDataEndpointOrderByProp: "name",
		databaseDataEndpoint: (
			clientKey: TsType_String,
			startAfter: TsType_String | TsType_Null,
			startAt: TsType_String | TsType_Null,
			endAt: TsType_String | TsType_Null,
			endBefore: TsType_String | TsType_Null,
			limit: TsType_Number
		) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ] // databaseDataEndpointOrderByProp
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if ( startAfter != null){	 	queryCursorsObject["startAfter"] = startAfter }
			if ( startAt != null){ 			queryCursorsObject["startAt"] = startAt }
			if ( endAt != null){ 			queryCursorsObject["endAt"] = endAt }
			if ( endBefore != null){ 		queryCursorsObject["endBefore"] = endBefore }
			return generateDatabaseQuery(
				DatabaseRef_TransferSites_Collection( clientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
	},
	client_trucks: {
		type: "client",
		key: "client_trucks",
		indexKey: "trucks",
		primaryKey: "id",
		statusToSetIfNull: "active",
		inactiveBoolToStatus: "deleted",
		indexedAttributes: {
			id: "key",
			name: "name",
			license_plate: "license_plate",
			vin: "vin",
			associated_carrier: "associated_carrier",
			associated_carrier_name: "associated_carrier_name",
			associated_driver: "associated_driver",
			associated_driver_name: "associated_driver_name",
			associated_owner: "associated_owner",
			associated_owner_name: "associated_owner_name",
			status: "status"
		},
		filterableAttributes: [
			"associated_carrier",
			"associated_owner",
			"status",
		],
		databaseDataEndpointOrderByProp: "name",
		databaseDataEndpoint: (
			clientKey: TsType_String,
			startAfter: TsType_String | TsType_Null,
			startAt: TsType_String | TsType_Null,
			endAt: TsType_String | TsType_Null,
			endBefore: TsType_String | TsType_Null,
			limit: TsType_Number
		) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ] // databaseDataEndpointOrderByProp
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if ( startAfter != null){	 	queryCursorsObject["startAfter"] = startAfter }
			if ( startAt != null){ 			queryCursorsObject["startAt"] = startAt }
			if ( endAt != null){ 			queryCursorsObject["endAt"] = endAt }
			if ( endBefore != null){ 		queryCursorsObject["endBefore"] = endBefore }
			return generateDatabaseQuery(
				DatabaseRef_Trucks_Collection( clientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
	},
	client_users: {
		type: "client",
		key: "client_users",
		indexKey: "users",
		primaryKey: "id",
		statusToSetIfNull: "active",
		inactiveBoolToStatus: "deleted",
		indexedAttributes: {
			id: "key",
			name: "name",
			email: "email",
			phone: "phone",
			driver: "driver",
			primary_role: "primary_role",
			associated_carrier: "associated_carrier",
			associated_carrier_name: "associated_carrier_name",
			status: "status",
		},
		filterableAttributes: [
			"associated_carrier",
			"primary_role",
			"driver",
			"status",
		],
		databaseDataEndpointOrderByProp: "name",
		databaseDataEndpoint: (
			clientKey: TsType_String,
			startAfter: TsType_String | TsType_Null,
			startAt: TsType_String | TsType_Null,
			endAt: TsType_String | TsType_Null,
			endBefore: TsType_String | TsType_Null,
			limit: TsType_Number
		) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
			let orderByArray: TsInterface_OrderByArray = [ { prop: "name", desc: false } ] // databaseDataEndpointOrderByProp
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if ( startAfter != null){	 	queryCursorsObject["startAfter"] = startAfter }
			if ( startAt != null){ 			queryCursorsObject["startAt"] = startAt }
			if ( endAt != null){ 			queryCursorsObject["endAt"] = endAt }
			if ( endBefore != null){ 		queryCursorsObject["endBefore"] = endBefore }
			return generateDatabaseQuery(
				DatabaseRef_Users_Collection( clientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}
	}
}

///////////////////////////////
// Functions
///////////////////////////////
