//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			User Authentication contains all functions related to managing user authentication and identification

		TODO:
			[ ] Typescript - 1 instance of TsType_Any
			[ ] Typescript - 9 instances of TsType_Unknown

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	ActionCodeSettings,
	getAuth,
	GoogleAuthProvider,
	onAuthStateChanged,
	sendPasswordResetEmail,
	sendSignInLinkToEmail,
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut
} from 'firebase/auth'
import {
	Trans
} from 'react-i18next'
import {
	cloudFunctionManageRequest
} from 'rfbp_aux/services/cloud_functions'
import {
	DatabaseRef_GlobalUser_Document
} from 'rfbp_aux/services/database_endpoints/standard_database_endpoints' // OUTSIDE BOILERPLATE
import {
	TsInterface_RootData_GlobalUser
} from 'rfbp_core/services/context'
import {
	DatabaseGetDocument,
	DatabaseSetMergeDocument
} from 'rfbp_core/services/database_management'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_GenericPromiseReject,
	TsInterface_GenericPromiseResolve,
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_Null,
	TsType_String,
	TsType_Unknown,
	TsType_Void
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_UpdateUserClientKeyProperResult {
		error: {}
		success: TsType_Boolean
	}

	interface TsInterface_GetClientKeyResult {
		clientKey: TsType_String
		error: {}
		success: TsType_Boolean
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_FAILED_TO_CHECK_AUTHENTICATION: TsType_JSX = 				<Trans>Failed to check authentication</Trans>
	const s_FAILED_TO_GET_CLIENT_KEY: TsType_JSX = 						<Trans>Failed to get client key</Trans>
	const s_FAILED_TO_GET_USER_AUTHENTICATION_DETAILS: TsType_JSX = 	<Trans>Failed to get user authentication details</Trans>
	const s_FAILED_TO_LOG_IN: TsType_JSX = 								<Trans>Failed to Log In</Trans>
	const s_FAILED_TO_LOG_OUT: TsType_JSX = 							<Trans>Failed to Log Out</Trans>
	const s_FAILED_TO_SEND_PASSWORD_RESET_EMAIL: TsType_JSX = 			<Trans>Failed to send password reset email</Trans>
	const s_FAILED_TO_SEND_SIGN_IN_LINK_TO_EMAIL: TsType_JSX = 			<Trans>Failed to send sign in link to email</Trans>
	const s_FAILED_TO_UPDATE_USER_CLIENT_KEY: TsType_JSX = 				<Trans>Failed to update user client key</Trans>
	const s_MISSING_REQUIRED_PARAMETERS: TsType_JSX = 					<Trans>Missing required parameters</Trans>
	const s_UNAUTHORIZED_DOMAIN: TsType_JSX = 							<Trans>Unauthorized Domain</Trans>
	// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

	const getDomainFromEmail = (email: TsType_String): TsType_String => {
		// Split the email string at the '@' symbol to separate the local part and domain part
		const parts = email.split('@');
		// If the email format is valid and has two parts (local part and domain part)
		if (parts.length === 2) {
			// The domain part will be the second element in the 'parts' array
			return parts[1];
		} else {
			// Return null or throw an error for invalid email formats
			return "";
		}
	}

///////////////////////////////
// Exports
///////////////////////////////

	// Authentication
	export const checkIfAuthenticated = (): Promise< TsType_Unknown > => {
		return new Promise((resolve, reject) => {
			const auth = getAuth()
			onAuthStateChanged(auth, user => {
			  	if (user) {
					resolve({success: true, authenticated: true}); // User is authenticated
			 	} else {
					resolve({success: true, authenticated: false}); // User is not authenticated
			 	}
			}, error => {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CHECK_AUTHENTICATION,
						details: error.message,
						code: "ER-D-SUA-CIA-01"
					}
				})
			});
		});
	}

	export const authWithPassword = ( email: TsType_String, password: TsType_String ): Promise< TsType_Unknown > => {
		return new Promise( ( resolve, reject ) => {
			const auth = getAuth()
			signInWithEmailAndPassword( auth, email, password ).then(( res_SIWEAP ) => {
				const user = res_SIWEAP.user
				resolve({
					success: true,
					user: user
				})
			}).catch(( rej_SIWEAP ) => {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_LOG_IN,
						details: rej_SIWEAP.message,
						code: "ER-D-SUA-AWP-01"
					}
				})
			})
		})
	}

	export const sendAuthLinkToEmail = ( email: TsType_String, actionCodeSettings: ActionCodeSettings ): Promise< TsType_Unknown > => {
		return new Promise( ( resolve, reject ) => {
			const auth = getAuth()
			sendSignInLinkToEmail( auth, email, actionCodeSettings ).then(( res_SSILTE ) => {
				window.localStorage.setItem('emailForSignIn', email)
				// let email = window.localStorage.getItem('emailForSignIn')
				resolve( { success: true } )
			}).catch(( rej_SSILTE ) => {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_SEND_SIGN_IN_LINK_TO_EMAIL,
						details: rej_SSILTE.message,
						code: "ER-D-SUA-SAILTE-01"
					}
				})
			})
		})
	}

	export const logOut = (): Promise< TsType_Unknown > => {
		return new Promise( ( resolve, reject ) => {
			const auth = getAuth()
			signOut( auth ).then(( res_SO ) => {
				resolve( { success: true } )
			}).catch(( rej_SO ) => {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_LOG_OUT,
						details: rej_SO.message,
						code: "ER-D-SUA-AWP-01"
					}
				})
			})
		})
	}

	export const sendPasswordResetToEmail = ( email: TsType_String ): Promise< TsType_Unknown > => {
		return new Promise( ( resolve, reject ) => {
			const auth = getAuth()
			sendPasswordResetEmail( auth, email ).then(( res_SPRE ) => {
				resolve( { success: true } )
			}).catch(( rej_SPRE ) => {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_SEND_PASSWORD_RESET_EMAIL,
						details: rej_SPRE.message,
						code: "ER-D-SUA-SPRTE-01"
					}
				})
			})
		})
	}

	export const waitForAuthenticationVerification = (): Promise< TsType_Unknown > => {
		return new Promise( ( resolve, reject ) => {
			const auth = getAuth()
			onAuthStateChanged(auth, ( user ) => {
				if ( user ) {
					resolve({ success: true, authenticated: true, userKey: user.uid })
				} else {
					reject({ success: false, authenticated: false, userKey: null })
				}
			})
		})
	}

	// Google Auth
	export const authWithGoogle = (
		restrictedDomains: TsType_Null | TsInterface_UnspecifiedObject,
		restrictedDomainErrorMessage: TsType_Null | TsType_JSX
	) => {
		return new Promise( ( resolve, reject ) => {
			const auth = getAuth()
			const provider = new GoogleAuthProvider();
			signInWithPopup(auth, provider).then((res_SIWP) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				// const credential: TsType_Any = GoogleAuthProvider.credentialFromResult(res_SIWP);
				// const token = credential.accessToken;
				// The signed-in user info.
				const user = res_SIWP.user;
				// IdP data available using getAdditionalUserInfo(res_SIWP)
				let userEmail = getProp( user, "email", null )

				if(
					restrictedDomains == null ||
					(
						userEmail != null &&
						getDomainFromEmail( userEmail ) != null &&
						getDomainFromEmail( userEmail ) !== "" &&
						restrictedDomains[ getDomainFromEmail( userEmail ) ] === true
					)
				){
					resolve({
						success: true,
						user: user
					})
				} else {
					let errorMessage = s_UNAUTHORIZED_DOMAIN
					if( restrictedDomainErrorMessage != null ){
						errorMessage = restrictedDomainErrorMessage
					}
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_LOG_IN,
							details: errorMessage,
							code: "ER-D-SUA-AWG-01"
						}
					})
				}

			}).catch((rej_SIWP) => {
				// Handle Errors here.
				const errorCode = rej_SIWP.code;
				const errorMessage = rej_SIWP.message;
				// The email of the user's account used.
				const email = rej_SIWP.customData.email;
				// The AuthCredential type that was used.
				const credential = GoogleAuthProvider.credentialFromError(rej_SIWP);
				// ...
				console.log(errorCode)
				console.log(errorMessage)
				console.log(email)
				console.log(credential)
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_LOG_IN,
						details: errorMessage,
						code: "ER-D-SUA-AWG-02"
					}
				})
			})
		})
	}

	// Permissions and IDs
	export const getClientKey = ( clientKeyCachedInContext: TsType_String | TsType_Null, callbackToSetContext: ( value: TsType_String ) => TsType_Void ): Promise< TsInterface_GetClientKeyResult > => {
		return new Promise( ( resolve, reject ) => {
			if ( clientKeyCachedInContext != null ){
				resolve({ success: true, clientKey: clientKeyCachedInContext, error: {} })
			} else {
				const auth = getAuth()
				onAuthStateChanged( auth, ( user ) => {
					if ( user ) {
						let databasePromiseArray = []
						let globalUser: TsInterface_RootData_GlobalUser = {
							authorized_clients: {},
							client_key: null,
							key: null,
							super: false,
							user_role: null
						}
						databasePromiseArray.push( DatabaseGetDocument( DatabaseRef_GlobalUser_Document(user.uid) ).then(( res_DGD: TsType_Any ) =>{
							globalUser = res_DGD.data
						}).catch(( rej_DGD ) => {
							console.error(rej_DGD)
						}))
						Promise.all( databasePromiseArray ).finally(() => {
							if ( globalUser.client_key != null && globalUser.client_key !== "" ){
								if ( callbackToSetContext != null && typeof callbackToSetContext === 'function' ){
									callbackToSetContext( globalUser.client_key as TsType_String )
								}
								resolve({ success: true, clientKey: globalUser.client_key, error: {} })
							} else {
								reject({
									success: false,
									clientKey: "",
									error: {
										message: s_FAILED_TO_GET_CLIENT_KEY,
										details: s_FAILED_TO_GET_USER_AUTHENTICATION_DETAILS,
										code: "ER-D-SUA-AWP-01"
									}
								})
							}
						})
					} else {
						reject({
							success: false,
							clientKey: "",
							error: {
								message: s_FAILED_TO_GET_CLIENT_KEY,
								details: s_FAILED_TO_GET_USER_AUTHENTICATION_DETAILS,
								code: "ER-D-SUA-AWP-02"
							}
						})
					}
				})
			}
		})
	}

	export const updateUserClientKey = ( clientKey: TsType_String, rootGlobalUser: TsInterface_RootData_GlobalUser ): Promise< TsType_Unknown > => {
		return new Promise( ( resolve, reject ) => {
			if ( rootGlobalUser.super === true ){
				updateUserClientKeyProper( rootGlobalUser.key as TsType_String, clientKey ).then(( res_UUCKP: TsInterface_GenericPromiseResolve ) => {
					resolve( res_UUCKP )
				}).catch(( rej_UUCKP: TsInterface_GenericPromiseReject ) => {
					reject( rej_UUCKP )
				})
			} else {
				cloudFunctionManageRequest(
					"manageUser",
					{
						function: "updateUserClientKey",
						client_key: clientKey,
					}
				).then(( res_CFMUR: TsType_Unknown ) => {
					resolve( res_CFMUR as TsInterface_GenericPromiseResolve )
				}).catch(( rej_CFMUR: TsType_Unknown ) => {
					reject( rej_CFMUR )
				})
			}
		})
	}

	export const updateUserClientKeyProper = ( userKey: TsType_String, clientKey: TsType_String ): Promise< TsInterface_UpdateUserClientKeyProperResult > => {

		// TODO - verify that the user has the rights to access the specified clientKey

		return new Promise( ( resolve, reject ) => {
			if ( userKey != null && clientKey != null ){
				let updateObject = {
					client_key: clientKey,
				}
				DatabaseSetMergeDocument( DatabaseRef_GlobalUser_Document( userKey ), updateObject, {} ).then(( res_DSMD: TsInterface_GenericPromiseResolve ) => {
					resolve( res_DSMD )
				}).catch(( rej_DSMD: TsInterface_GenericPromiseReject ) => {
					reject( rej_DSMD )
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_UPDATE_USER_CLIENT_KEY,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-S-S-UM-UUCK-01"
					}
				})
			}
		})
	}