///////////////////////////////
// Description
///////////////////////////////

	// See config/standard_database_endpoints for documentation

///////////////////////////////
// Imports
///////////////////////////////

import {
	collection,
	CollectionReference,
	doc,
	DocumentData,
	DocumentReference,
	getFirestore,
	Query
} from 'firebase/firestore'
import {
	generateDatabaseQuery,
	TsInterface_OrderByArray,
	TsInterface_QueryOperatorsArray
} from 'rfbp_core/services/database_management'
import {
	TsType_String,
	TsType_Unknown
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Trailers_Collection =
	( clientKey: TsType_String ): CollectionReference< DocumentData > => {
	return collection( getFirestore(), "clients", clientKey, "directory", "trailers", "main" ) }

export const DatabaseRef_ActiveTrailers_Query =
	( clientKey: TsType_String ): Query< TsType_Unknown > => {
	let queryOperatorsArray: TsInterface_QueryOperatorsArray = [ { prop: "status", comparator: "==", value: "active" } ]
	let orderByArray: TsInterface_OrderByArray = []
	return generateDatabaseQuery( collection( getFirestore(), "clients", clientKey, "directory", "trailers", "main" ), queryOperatorsArray, orderByArray, {}, null ) }

export const DatabaseRef_Trailer_Document =
	( clientKey: TsType_String, itemKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "directory", "trailers", "main", itemKey ) }

export const DatabaseRef_TrailerNameSearch_Query =
	( clientKey: TsType_String, name: TsType_String ): Query< TsType_Unknown > => {
	let queryOperatorsArray: TsInterface_QueryOperatorsArray = [ { prop: "name", comparator: "==", value: name } ]
	let orderByArray: TsInterface_OrderByArray = []
	return generateDatabaseQuery( collection( getFirestore(), "clients", clientKey, "directory", "trailers", "main" ), queryOperatorsArray, orderByArray, {}, null ) }
