//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
	DESCRIPTION / USAGE:
		1) Defines Client Types, Application Pages, and Application Navigation
		2) Provides functions to generate navigation and permissions dynamically from specified variables

		ApplicationNavPages ACCESS LEVELS
			always_yes
			default_yes
			default_no
			always_no
			true
			false

		ADDING A NEW PAGE
			1) Create file in containers folder
			2) Import page into "App.tsx"
			3) Add Route into the Router in the "App.tsx" at the bottom
			4) Add Page into the "ApplicationPages" variable of "applicationStructure.tsx" (this file)
			5) IF the page is a root level page, add it to ApplicationNavPages (and ApplicationNavSections if it is in a new section) in "applicationStructure.tsx" (this file)

	TODO:
		[ ] Typescript - might need to import or export interfaces to other files to avoid duplicates?

*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Trans
} from 'react-i18next'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TsInterface_RootData_ClientPermissions,
	TsInterface_RootData_ClientUser,
	TsInterface_RootData_GlobalUser
} from 'rfbp_core/services/context'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_Null,
	TsType_String
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

type TsType_PageAccess = "always_yes" | "default_yes" | "default_no" | "always_no" | TsType_Boolean | TsType_Null

export type TsType_ClientTypes = 'billing' | 'generic_planned' | 'hub' | 'locked' | 'marketer' | 'producer' | 'secure_delivery' | 'transporter'
export type TsType_UserRoles = 'admin' | 'api_access' | 'compliance_analyst' | 'dispatcher' | 'field_admin' | 'field_supervisor' | 'financial_analyst' | 'sales' | 'locked' | 'driver' | 'carrier_admin' | 'producer_admin' | 'producer_pumper' | 'purchaser_admin' | 'stakeholder' | 'producer_foreman' | 'pumper'

interface TsInterface_PageRoleAccessPermissionsObject {
	access: TsType_PageAccess
	highlighted_nav_section: TsType_String
	highlighted_nav_page: TsType_String
	legacy_permission: TsType_String
}

type TsType_ApplicationPageUrlOneParam = (singleParam?: TsType_String) => TsType_String
type TsType_ApplicationPageUrlTwoParams = (paramOne?: TsType_String, paramTwo?: TsType_String) => TsType_String

interface TsInterface_ApplicationPages {
	[key: TsType_String]: {
		key: TsType_String
		root_nav_page_permission: TsType_String
		// url: TsType_ApplicationPageUrlOneParam | TsType_ApplicationPageUrlTwoParams
		url: TsType_Any
		v1_redirect?: TsType_Boolean
		v1_redirect_url?: TsType_ApplicationPageUrlOneParam | TsType_ApplicationPageUrlTwoParams
	}
}

interface TsInterface_ClientTypes {
	[key: TsType_String]: {
		key: TsType_ClientTypes
		name: TsType_String | TsType_JSX
		user_roles: {
			[key: TsType_String]: {
				key: TsType_UserRoles
				name: TsType_String | TsType_JSX
				icon: TsType_JSX
				home_redirect_page?: TsType_Any
			}
		}
	}
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export interface TsInterface_NavPage {
	name: TsType_JSX
	key: TsType_String
	icon: TsType_String
	url: TsType_String
	page_role_access_permissions: {
		[key: TsType_String]: TsInterface_PageRoleAccessPermissionsObject
	}
	nav_badges: {
		[key: TsType_String]: TsType_Boolean
	}
}

interface TsInterface_ApplicationNavPages {
	[key: TsType_String]: TsInterface_NavPage
}

interface TsInterface_ApplicationNavSections {
	[key: TsType_String]: {
		name: TsType_JSX
		key: TsType_String
		links: TsInterface_ApplicationNavPages
	}
}

interface TsInterface_SideBarNavObject {
	[key: TsType_String]: {
		name: TsType_JSX
		links: {
			[key: TsType_String]: TsInterface_NavPage
		}
	}
}

interface TsInterface_FlatUserPermissions {
	[key: TsType_String]: {
		key: TsType_String
		access: TsType_PageAccess
	}
}

interface TsInterface_AvailableUserTypePermissionsObject {
	[key: TsType_String]: {
		sectionKey: TsType_String
		sectionName: TsType_JSX
		permissions: {
			[key: TsType_String]: {
				access: TsType_PageAccess
				permissionKey: TsType_String
				pageName: TsType_JSX
			}
		}
	}
}

interface TsInterface_AvailableClientTypePermissionsObject {
	[clientTypeKey: TsType_String]: {
		[sectionKey: TsType_String]: {
			sectionKey: TsType_String
			sectionName: TsType_JSX
			permissions: {
				[key: TsType_String]: {
					key: TsType_String
					pageName: TsType_JSX
				}
			}
		}
	}
}

interface TsInterface_PermissionObject {
	[permissionKey: TsType_String]: TsType_Boolean
}

interface TsInterface_GenerateActiveUserApplicationPermissionsResult {
	success: TsType_Boolean
	permissions: TsInterface_PermissionObject
	error: {}
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export interface TsInterface_NavigationObject {
	[sectionKey: TsType_String]: {
		key: TsType_String
		name: TsType_JSX
		links: {
			[linkKey: TsType_String]: {
				icon: TsType_String
				key: TsType_String
				name: TsType_JSX
				page_role_access_permissions: {
					[roleKey: TsType_String]: TsInterface_PageRoleAccessPermissionsObject
				}
				// url(): TsType_String
				url: TsType_String
			}
		}
	}
}


///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ACCOUNTING: TsType_JSX = <Trans>Accounting</Trans>
const s_ACCOUNTING_ANALYSIS: TsType_JSX = <Trans>Accounting Analysis</Trans>
const s_ACCOUNTING_MGMT: TsType_JSX = <Trans>Accounting Mgmt</Trans>
const s_ACTIVE_DRIVER_MAP: TsType_JSX = <Trans>Active Driver Map</Trans>
const s_ADMIN: TsType_JSX = <Trans>Admin</Trans>
const s_ALL_DRIVERS_MAP: TsType_JSX = <Trans>All Drivers Map</Trans>
const s_ANALYTICS: TsType_JSX = <Trans>Analytics</Trans>
const s_API_ACCESS: TsType_JSX = <Trans>API Access</Trans>
const s_ASSIGNMENT_ALGORITHM: TsType_JSX = <Trans>Assignment Algorithm</Trans>
const s_BID_ANALYSIS: TsType_JSX = <Trans>Bid Analysis</Trans>
const s_BILLING: TsType_JSX = <Trans>Billing</Trans>
const s_BULK_UPDATES: TsType_JSX = <Trans>Bulk Updates</Trans>
const s_CALCULATOR: TsType_JSX = <Trans>Calculator</Trans>
const s_CARGO: TsType_JSX = <Trans>Cargo</Trans>
const s_CARRIERS: TsType_JSX = <Trans>Carriers</Trans>
const s_CARRIER_ADMIN: TsType_JSX = <Trans>Carrier Admin</Trans>
const s_COMPLIANCE: TsType_JSX = <Trans>Compliance</Trans>
const s_COMPLIANCE_ANALYST: TsType_JSX = <Trans>Compliance Analyst</Trans>
const s_CONTACT_REQUESTS: TsType_JSX = <Trans>Contact Requests</Trans>
const s_COST_ANALYSIS: TsType_JSX = <Trans>Cost Analysis</Trans>
const s_DASHBOARDS: TsType_JSX = <Trans>Dashboards</Trans>
const s_DATA: TsType_JSX = <Trans>Data</Trans>
const s_DATABASE_MGMT: TsType_JSX = <Trans>Database Mgmt</Trans>
const s_DEMO_DATA: TsType_JSX = <Trans>Demo Data</Trans>
const s_DEV_TEAM: TsType_JSX = <Trans>Dev Team</Trans>
const s_DIRECTORY: TsType_JSX = <Trans>Directory</Trans>
const s_DIRECTORY_MGMT: TsType_JSX = <Trans>Directory Mgmt</Trans>
const s_DISPATCH: TsType_JSX = <Trans>Dispatch</Trans>
const s_DISPATCHER: TsType_JSX = <Trans>Dispatcher</Trans>
const s_DOWNLOADS: TsType_JSX = <Trans>Downloads</Trans>
const s_DRIVER: TsType_JSX = <Trans>Driver</Trans>
const s_DRIVERS: TsType_JSX = <Trans>Drivers</Trans>
const s_DRIVER_ETAS: TsType_JSX = <Trans>Driver ETAs</Trans>
const s_DRIVER_ONBOARDING: TsType_JSX = <Trans>Driver Onboarding</Trans>
const s_DRIVER_RESOURCES: TsType_JSX = <Trans>Driver Resources</Trans>
const s_DROPOFF_LOCATIONS: TsType_JSX = <Trans>Dropoff Locations</Trans>
const s_EMPLOYEES: TsType_JSX = <Trans>Employees</Trans>
const s_EQUIPMENT: TsType_JSX = <Trans>Equipment</Trans>
const s_FAST_UPDATES: TsType_JSX = <Trans>Fast Updates</Trans>
const s_FIELD_ADMIN: TsType_JSX = <Trans>Field Admin</Trans>
const s_FIELD_SUPERVISOR: TsType_JSX = <Trans>Field Supervisor</Trans>
const s_FINANCE: TsType_JSX = <Trans>Finance</Trans>
const s_FINANCIAL_ANALYST: TsType_JSX = <Trans>Financial Analyst</Trans>
const s_GENERIC_PLANNED: TsType_JSX = <Trans>Generic Planned</Trans>
const s_HOME: TsType_JSX = <Trans>Home</Trans>
const s_HOT_SHOT_BETA: TsType_JSX = <Trans>Hot Shot (Beta)</Trans>
const s_HUB: TsType_JSX = <Trans>Hub</Trans>
const s_INVENTORY: TsType_JSX = <Trans>Inventory</Trans>
const s_INVOICES: TsType_JSX = <Trans>Invoices</Trans>
const s_INVOICE_ANALYSIS: TsType_JSX = <Trans>Invoice Analysis</Trans>
const s_JOBS: TsType_JSX = <Trans>Jobs</Trans>
const s_LOADCALL_API: TsType_JSX = <Trans>LoadCAll API</Trans>
const s_LOAD_MONITORING: TsType_JSX = <Trans>Load Monitoring</Trans>
const s_LOAD_REQUESTS: TsType_JSX = <Trans>Load Requests</Trans>
const s_LOCKED: TsType_JSX = <Trans>Locked</Trans>
const s_LOG_BOOKS: TsType_JSX = <Trans>Log Books</Trans>
const s_MAP: TsType_JSX = <Trans>Map</Trans>
const s_MAPS: TsType_JSX = <Trans>Maps</Trans>
const s_MARKETER: TsType_JSX = <Trans>Marketer</Trans>
const s_MESSAGES: TsType_JSX = <Trans>Messages</Trans>
const s_NEWS_FEED: TsType_JSX = <Trans>News Feed</Trans>
const s_NEW_LOAD_REQUEST: TsType_JSX = <Trans>New Load Request</Trans>
const s_NOMINATIONS: TsType_JSX = <Trans>Nominations</Trans>
const s_NOTIFICATIONS: TsType_JSX = <Trans>Notifications</Trans>
const s_ONBOARDING: TsType_JSX = <Trans>Onboarding</Trans>
const s_OPERATIONS: TsType_JSX = <Trans>Operations</Trans>
const s_OPERATIONS_MGMT: TsType_JSX = <Trans>Operations Mgmt</Trans>
const s_ORDERS: TsType_JSX = <Trans>Orders</Trans>
const s_PARTNER_ALLOCATION: TsType_JSX = <Trans>Partner Allocation</Trans>
const s_PAYMENTS: TsType_JSX = <Trans>Payments</Trans>
const s_PICKUP_LOCATIONS: TsType_JSX = <Trans>Pickup Locations</Trans>
const s_PRODUCER: TsType_JSX = <Trans>Producer</Trans>
const s_PRODUCERS: TsType_JSX = <Trans>Producers</Trans>
const s_PRODUCER_ADMIN: TsType_JSX = <Trans>Producer Admin</Trans>
const s_PRODUCER_FOREMAN: TsType_JSX = <Trans>Producer Foreman</Trans>
const s_PRODUCER_PUMPER: TsType_JSX = <Trans>Producer Pumper</Trans>
const s_PUMPER: TsType_JSX = <Trans>Pumper</Trans>
const s_PURCHASERS: TsType_JSX = <Trans>Purchasers</Trans>
const s_PURCHASER_ADMIN: TsType_JSX = <Trans>Purchaser Admin</Trans>
const s_QUICK_JOB_ASSIGN: TsType_JSX = <Trans>Quick Job Assign</Trans>
const s_RATE_TABLES: TsType_JSX = <Trans>Rate Tables</Trans>
const s_RECENTS: TsType_JSX = <Trans>Recents</Trans>
const s_REGIONS: TsType_JSX = <Trans>Regions</Trans>
const s_REPORTS: TsType_JSX = <Trans>Reports</Trans>
const s_REPORT_TEMPLATES: TsType_JSX = <Trans>Report Tempates</Trans>
const s_REQUESTS: TsType_JSX = <Trans>Reqeusts</Trans>
const s_REQUEST_ALLOCATION: TsType_JSX = <Trans>Request Allocation</Trans>
const s_RFPS: TsType_JSX = <Trans>RFPs</Trans>
const s_RFP_BIDS: TsType_JSX = <Trans>RFP Bids</Trans>
const s_ROUTES: TsType_JSX = <Trans>Routes</Trans>
const s_ROUTE_PLANNING: TsType_JSX = <Trans>Route Planning</Trans>
const s_SALES: TsType_JSX = <Trans>Sales</Trans>
const s_SCHEDULED_REQUESTS: TsType_JSX = <Trans>Scheduled Requests</Trans>
const s_SCORECARDS: TsType_JSX = <Trans>Scorecards</Trans>
const s_SECURE_DELIVERY: TsType_JSX = <Trans>Secure Delivery</Trans>
const s_SETTINGS: TsType_JSX = <Trans>Settings</Trans>
const s_SETTLEMENTS: TsType_JSX = <Trans>Settlements</Trans>
const s_SITE_REFERRALS: TsType_JSX = <Trans>Site Referrals</Trans>
const s_STAKEHOLDER: TsType_JSX = <Trans>Stakeholder</Trans>
const s_SUPERVISORS: TsType_JSX = <Trans>Supervisors</Trans>
const s_SUPPORT: TsType_JSX = <Trans>Support</Trans>
const s_TASKS: TsType_JSX = <Trans>Tasks</Trans>
const s_TERMINALS: TsType_JSX = <Trans>Terminals</Trans>
const s_THIRD_PARTY_JOBS: TsType_JSX = <Trans>Third Party Jobs</Trans>
const s_TICKETS: TsType_JSX = <Trans>Tickets</Trans>
const s_TICKET_REVIEW: TsType_JSX = <Trans>Ticket Review</Trans>
const s_TICKET_SPREADSHEET: TsType_JSX = <Trans>Ticket Spreadsheet</Trans>
const s_TIMECARDS: TsType_JSX = <Trans>Timecards</Trans>
const s_TRAILERS: TsType_JSX = <Trans>Trailers</Trans>
const s_TRANSPORTER: TsType_JSX = <Trans>Transporter</Trans>
const s_TRANSPORTER_INVOICES: TsType_JSX = <Trans>Transporter Invoices</Trans>
const s_TRUCKS: TsType_JSX = <Trans>Trucks</Trans>
const s_USERS: TsType_JSX = <Trans>Users</Trans>
const s_USER_SETTINGS: TsType_JSX = <Trans>User Settings</Trans>

const s_VEHICLES: TsType_JSX = <Trans>Vehicles</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////


// Client Types
export const ClientUserRoles: TsInterface_UnspecifiedObject = {
	admin: {
		color: "warning",
		icon: <Icon type="solid" icon="crown" />,
		key: "admin",
		name: s_ADMIN,
	},
	api_access: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "api_access",
		name: s_API_ACCESS,
	},
	compliance_analyst: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "compliance_analyst",
		name: s_COMPLIANCE_ANALYST,
	},
	dispatcher: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "dispatcher",
		name: s_DISPATCHER,
	},
	field_admin: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "field_admin",
		name: s_FIELD_ADMIN,
	},
	field_supervisor: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "field_supervisor",
		name: s_FIELD_SUPERVISOR,
	},
	financial_analyst: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "financial_analyst",
		name: s_FINANCIAL_ANALYST,
	},
	sales: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "sales",
		name: s_SALES,
	},
	locked: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "locked",
		name: s_LOCKED,
	},
	driver: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "driver",
		name: s_DRIVER,
	},
	carrier_admin: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "carrier_admin",
		name: s_CARRIER_ADMIN,
	},
	producer_admin: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "producer_admin",
		name: s_PRODUCER_ADMIN,
	},
	producer_pumper: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "producer_pumper",
		name: s_PRODUCER_PUMPER,
	},
	producer_foreman: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "producer_foreman",
		name: s_PRODUCER_FOREMAN,
	},
	pumper: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "pumper",
		name: s_PUMPER,
	},
	purchaser_admin: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "purchaser_admin",
		name: s_PURCHASER_ADMIN,
	},
	stakeholder: {
		color: "info",
		icon: <Icon type="solid" icon="user" />,
		key: "stakeholder",
		name: s_STAKEHOLDER,
	},
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//

// All Pages URLs

// TODO - v1_redirect_url doesn't support params at all right now

export const ApplicationPages: TsInterface_ApplicationPages = {
	//////////////////////////////////////
	// New / Standard
	//////////////////////////////////////
	UserSettingsPage: { key: "UserSettingsPage", root_nav_page_permission: "UserSettingsPage", url: () => "/user/settings" },
	HomePage: { key: "HomePage", root_nav_page_permission: "HomePage", url: () => "/home" },
	UnauthenticatedLoginPage: { key: "UnauthenticatedLoginPage", root_nav_page_permission: "unauthenticated", url: () => "/login" },
	SearchManagementIndexPage: { key: "SearchManagementIndexPage", root_nav_page_permission: "HomePage", url: () => "/super/search_management" },
	//////////////////////////////////////
	// Home
	//////////////////////////////////////
	homeDeprecated: { v1_redirect: true, key: "homeDeprecated", root_nav_page_permission: "homeDeprecated", url: () => "/home_need_to_upgrade" },
	homeLocked: { v1_redirect: true, key: "homeLocked", root_nav_page_permission: "homeLocked", url: () => "/home_locked" },
	//////////////////////////////////////
	// Support
	//////////////////////////////////////
	supportDocsIndex: { v1_redirect: true, key: "supportDocsIndex", root_nav_page_permission: "supportDocsIndex", url: () => "/support" },
	supportDocsPage: { v1_redirect: true, key: "supportDocsPage", root_nav_page_permission: "supportDocsPage", url: (id: TsType_String) => "/support/docs/" + id },
	supportDocsPagesAll: { v1_redirect: true, key: "supportDocsPagesAll", root_nav_page_permission: "supportDocsPagesAll", url: () => "/support/all_docs" },
	supportDocsEdit: { v1_redirect: true, key: "supportDocsEdit", root_nav_page_permission: "supportDocsEdit", url: (id: TsType_String) => "/support/edit/" + id },
	supportDocsTags: { v1_redirect: true, key: "supportDocsTags", root_nav_page_permission: "supportDocsTags", url: (id: TsType_String) => "/support/tags/" + id },
	supportDocsTagsAll: { v1_redirect: true, key: "supportDocsTagsAll", root_nav_page_permission: "supportDocsTagsAll", url: () => "/support/all_tags" },
	supportDocsFiles: { v1_redirect: true, key: "supportDocsFiles", root_nav_page_permission: "supportDocsFiles", url: () => "/support/files" },
	supportStatesList: { v1_redirect: true, key: "supportStatesList", root_nav_page_permission: "supportStatesList", url: () => "/support/states_list" },
	//////////////////////////////////////
	// Unauthenticated Views
	//////////////////////////////////////
	marketingIndex: { v1_redirect: true, key: "marketingIndex", root_nav_page_permission: "marketingIndex", url: () => "/temp/home" },
	publicDownloads: { v1_redirect: true, key: "publicDownloads", root_nav_page_permission: "publicDownloads", url: (id: TsType_String) => "/report_downloads/" + id },
	unauthenticatedSolutions: { v1_redirect: true, key: "unauthenticatedSolutions", root_nav_page_permission: "unauthenticatedSolutions", url: () => "/solutions" },
	unauthenticatedSupport: { v1_redirect: true, key: "unauthenticatedSupport", root_nav_page_permission: "unauthenticatedSupport", url: () => "/loadcall_support" },
	unauthenticatedAbout: { v1_redirect: true, key: "unauthenticatedAbout", root_nav_page_permission: "unauthenticatedAbout", url: () => "/about" },
	unauthenticatedContact: { v1_redirect: true, key: "unauthenticatedContact", root_nav_page_permission: "unauthenticatedContact", url: () => "/contact" },
	unauthenticatedContactLead: { v1_redirect: true, key: "unauthenticatedContactLead", root_nav_page_permission: "unauthenticatedContactLead", url: (id: TsType_String) => "/contact/" + id },
	unauthenticatedAccountCreation: { v1_redirect: true, key: "unauthenticatedAccountCreation", root_nav_page_permission: "unauthenticatedAccountCreation", url: () => "/create-an-account" },
	unauthenticatedPrivacyPolicy: { v1_redirect: true, key: "unauthenticatedPrivacyPolicy", root_nav_page_permission: "unauthenticatedPrivacyPolicy", url: () => "/privacy-policy" },
	unauthenticatedTermsOfService: { v1_redirect: true, key: "unauthenticatedTermsOfService", root_nav_page_permission: "unauthenticatedTermsOfService", url: () => "/terms-of-services" },
	unauthenticatedGettingStartedTransporter: { v1_redirect: true, key: "unauthenticatedGettingStartedTransporter", root_nav_page_permission: "unauthenticatedGettingStartedTransporter", url: () => "/getting_started/transporter" },
	ticketsUnauthenticatedSignatures: { v1_redirect: true, key: "ticketsUnauthenticatedSignatures", root_nav_page_permission: "ticketsUnauthenticatedSignatures", url: (cid: TsType_String, id: TsType_String, tid: TsType_String, mid: TsType_String) => "/ticket_signatures/" + cid + "/" + id + "/" + tid + "/" + mid },
	secureDeliveryUnauthenticatedForm: { v1_redirect: true, key: "secureDeliveryUnauthenticatedForm", root_nav_page_permission: "secureDeliveryUnauthenticatedForm", url: () => "/electronic_station_log" },
	//////////////////////////////////////
	// Transporter - Basic Accounts
	//////////////////////////////////////
	basicAccountAccounting: { v1_redirect: true, key: "basicAccountAccounting", root_nav_page_permission: "basicAccountAccounting", url: () => "/accounting/overview" },
	basicAccountEmployees: { v1_redirect: true, key: "basicAccountEmployees", root_nav_page_permission: "basicAccountEmployees", url: () => "/employees/overview" },
	//////////////////////////////////////
	// Transporter - Full - Home
	//////////////////////////////////////
	notificationsManagment: { v1_redirect: true, key: "notificationsManagment", root_nav_page_permission: "notificationsManagment", url: () => "/home/notifications" },
	newsFeedIndex: { v1_redirect: true, key: "newsFeedIndex", root_nav_page_permission: "newsFeedIndex", url: () => "/home/news_feed" },
	calculatorIndex: { v1_redirect: true, key: "calculatorIndex", root_nav_page_permission: "calculatorIndex", url: () => "/home/calculator" },
	tasksIndex: { v1_redirect: true, key: "tasksIndex", root_nav_page_permission: "tasksIndex", url: () => "/home/tasks" },
	settingsIndex: { v1_redirect: true, key: "settingsIndex", root_nav_page_permission: "settingsIndex", url: () => "/home/user_settings" },
	whatsNewIndex: { v1_redirect: true, key: "whatsNewIndex", root_nav_page_permission: "whatsNewIndex", url: () => "/home/whats_new" },
	//////////////////////////////////////
	// Transporter - Full - Dispatch
	//////////////////////////////////////
	// Boards
	dispatchIndex: { v1_redirect: true, key: "dispatchIndex", root_nav_page_permission: "dispatchIndex", url: () => "/dispatch/boards/" },
	dispatchBoard: { v1_redirect: true, key: "dispatchBoard", root_nav_page_permission: "dispatchBoard", url: (id: TsType_String) => "/dispatch/board/" + id },
	// Planned Route Dispatch
	dispatchIndexPlannedRoutes: { v1_redirect: true, key: "dispatchIndexPlannedRoutes", root_nav_page_permission: "dispatchIndexPlannedRoutes", url: () => "/dispatch/regions/" },
	dispatchBoardPlannedRoutes: { v1_redirect: true, key: "dispatchBoardPlannedRoutes", root_nav_page_permission: "dispatchBoardPlannedRoutes", url: (id: TsType_String) => "/dispatch/region/" + id },
	// Requests
	loadRequests: { v1_redirect: true, key: "loadRequests", root_nav_page_permission: "loadRequests", url: () => "/dispatch/load_requests" },
	scheduledRequests: { v1_redirect: true, key: "scheduledRequests", root_nav_page_permission: "scheduledRequests", url: () => "/dispatch/scheduled_requests" },
	// Job Create and Assign
	jobCreateAndAssign: { v1_redirect: true, key: "jobCreateAndAssign", root_nav_page_permission: "jobCreateAndAssign", url: () => "/dispatch/fast_job_assignment" },
	// Orders
	ordersIndex: { v1_redirect: true, key: "ordersIndex", root_nav_page_permission: "ordersIndex", url: () => "/dispatch/orders/open" },
	ordersImport: { v1_redirect: true, key: "ordersImport", root_nav_page_permission: "ordersImport", url: () => "/dispatch/orders/imports" },
	// Nominations
	nominationsIndex: { v1_redirect: true, key: "nominationsIndex", root_nav_page_permission: "nominationsIndex", url: () => "/dispatch/nominations" },
	// Maps
	mapIndex: { v1_redirect: true, key: "mapIndex", root_nav_page_permission: "mapIndex", url: () => "/dispatch/map" },
	mapAllRegions: { v1_redirect: true, key: "mapAllRegions", root_nav_page_permission: "mapAllRegions", url: () => "/dispatch/map_all_regions" },
	mapRegionIndex: { v1_redirect: true, key: "mapRegionIndex", root_nav_page_permission: "mapRegionIndex", url: (id: TsType_String) => "/dispatch/map/" + id },
	mapClusterLease: { v1_redirect: true, key: "mapClusterLease", root_nav_page_permission: "mapClusterLease", url: (rid: TsType_String, id: TsType_String) => "/dispatch/map/region/" + rid + "/lease/" + id },
	mapLocations: { v1_redirect: true, key: "mapLocations", root_nav_page_permission: "mapLocations", url: () => "/dispatch/map/location_map" },
	// Messages
	messagesIndex: { v1_redirect: true, key: "messagesIndex", root_nav_page_permission: "messagesIndex", url: () => "/dispatch/messages" },
	// Dispatch Drivers
	dispatchDriversIndex: { v1_redirect: true, key: "dispatchDriversIndex", root_nav_page_permission: "dispatchDriversIndex", url: () => "/dispatch/drivers" },
	// Live Loads
	dispatchLiveLoads: { v1_redirect: true, key: "dispatchLiveLoads", root_nav_page_permission: "dispatchLiveLoads", url: () => "/dispatch/drivers/live_loads" },
	// Assignments
	dispatchAssignments: { v1_redirect: true, key: "dispatchAssignments", root_nav_page_permission: "dispatchAssignments", url: () => "/dispatch/drivers/assignments" },
	// ETAs
	jobETAs: { v1_redirect: true, key: "jobETAs", root_nav_page_permission: "jobETAs", url: () => "/dispatch/drivers/etas" },
	// Schedules
	schedulesIndex: { v1_redirect: true, key: "schedulesIndex", root_nav_page_permission: "schedulesIndex", url: () => "/dispatch/drivers/schedules" },
	// Driver Logs
	driverLogsIndex: { v1_redirect: true, key: "driverLogsIndex", root_nav_page_permission: "driverLogsIndex", url: () => "/dispatch/drivers/event_logs" },
	// Documents
	documentsIndex: { v1_redirect: true, key: "documentsIndex", root_nav_page_permission: "documentsIndex", url: () => "/dispatch/documents" },
	// Cargo Authorization
	cargoAuthorizationIndex: { v1_redirect: true, key: "cargoAuthorizationIndex", root_nav_page_permission: "cargoAuthorizationIndex", url: () => "/dispatch/cargo_authorization" },
	// Inactive Drivers
	inactiveCarriers: { v1_redirect: true, key: "inactiveCarriers", root_nav_page_permission: "inactiveCarriers", url: () => "/dispatch/inactive_carriers" },
	inactiveDrivers: { v1_redirect: true, key: "inactiveDrivers", root_nav_page_permission: "inactiveDrivers", url: () => "/dispatch/inactive_drivers" },
	inactiveTrailers: { v1_redirect: true, key: "inactiveTrailers", root_nav_page_permission: "inactiveTrailers", url: () => "/dispatch/inactive_trailers" },
	inactiveTrucks: { v1_redirect: true, key: "inactiveTrucks", root_nav_page_permission: "inactiveTrucks", url: () => "/dispatch/inactive_trucks" },
	// Route Planning
	routePlanningIndex: { v1_redirect: true, key: "routePlanningIndex", root_nav_page_permission: "routePlanningIndex", url: () => "/dispatch/route_planning" },
	routePlanningView: { v1_redirect: true, key: "routePlanningView", root_nav_page_permission: "routePlanningView", url: (id: TsType_String) => "/dispatch/route_plan/" + id },
	// Inventory
	inventoryTrackingIndex: { v1_redirect: true, key: "inventoryTrackingIndex", root_nav_page_permission: "inventoryTrackingIndex", url: () => "/dispatch/inventory" },
	inventoryTrackingDockFullHistory: { v1_redirect: true, key: "inventoryTrackingDockFullHistory", root_nav_page_permission: "inventoryTrackingDockFullHistory", url: (sid: TsType_String, did: TsType_String) => "/dispatch/inventory/history/" + sid + "/" + did },
	inventoryTrackingThirdPartyAccess: { v1_redirect: true, key: "inventoryTrackingThirdPartyAccess", root_nav_page_permission: "inventoryTrackingThirdPartyAccess", url: () => "/dispatch/inventory/access" },
	// Station Logs
	stationLogAllForm: { v1_redirect: true, key: "stationLogAllForm", root_nav_page_permission: "stationLogAllForm", url: (cid: TsType_String) => "/station_logs/all/" + cid },
	stationLogAllSavedForm: { v1_redirect: true, key: "stationLogAllSavedForm", root_nav_page_permission: "stationLogAllSavedForm", url: (cid: TsType_String, slid: TsType_String) => "/station_logs/all/" + cid + "/" + slid },
	stationLogSiteForm: { v1_redirect: true, key: "stationLogSiteForm", root_nav_page_permission: "stationLogSiteForm", url: (cid: TsType_String, tsid: TsType_String) => "/station_logs/site/" + cid + "/" + tsid },
	stationLogSiteSavedForm: { v1_redirect: true, key: "stationLogSiteSavedForm", root_nav_page_permission: "stationLogSiteSavedForm", url: (cid: TsType_String, tsid: TsType_String, slid: TsType_String) => "/station_logs/site/" + cid + "/" + tsid + "/" + slid },
	// Third Party Jobs
	thirdPartyJobsIndex: { v1_redirect: true, key: "thirdPartyJobsIndex", root_nav_page_permission: "thirdPartyJobsIndex", url: () => "/data/third_party_jobs" },
	operationsManagementIndex: { v1_redirect: true, key: "operationsManagementIndex", root_nav_page_permission: "operationsManagementIndex", url: () => "/operations/list" },
	operationsLocationsHeatmap: { v1_redirect: true, key: "operationsLocationsHeatmap", root_nav_page_permission: "operationsLocationsHeatmap", url: () => "/operations/location_heatmap" },
	operationsDriverCalendar: { v1_redirect: true, key: "operationsDriverCalendar", root_nav_page_permission: "operationsDriverCalendar", url: () => "/operations/driver_calendar" },
	operationsTicketCorrectionsAnalysis: { v1_redirect: true, key: "operationsTicketCorrectionsAnalysis", root_nav_page_permission: "operationsTicketCorrectionsAnalysis", url: () => "/operations/ticket_corrections" },
	//////////////////////////////////////
	// Transporter - Full - Compliance
	//////////////////////////////////////
	// Compliance
	complianceIndex: { v1_redirect: true, key: "complianceIndex", root_nav_page_permission: "complianceIndex", url: () => "/employees/compliance" },
	complianceFileDriver: { v1_redirect: true, key: "complianceFileDriver", root_nav_page_permission: "complianceFileDriver", url: (id: TsType_String, rid: TsType_String, tab: TsType_String) => "/employees/compliance/driver_file/" + id + "/r/" + rid + "/" + tab },
	// Equipment
	complianceEquipmentIndex: { v1_redirect: true, key: "complianceEquipmentIndex", root_nav_page_permission: "complianceEquipmentIndex", url: () => "/employees/equipment" },
	// Logbook
	logbookIndex: { v1_redirect: true, key: "logbookIndex", root_nav_page_permission: "logbookIndex", url: () => "/employees/logbook" },
	logbookMileageLogs: { v1_redirect: true, key: "logbookMileageLogs", root_nav_page_permission: "logbookMileageLogs", url: () => "/employees/logbook/mileage_logs" },
	logbookFieldReports: { v1_redirect: true, key: "logbookFieldReports", root_nav_page_permission: "logbookFieldReports", url: () => "/employees/logbook/field_reports" },
	logbookOutOfServiceLogs: { v1_redirect: true, key: "logbookOutOfServiceLogs", root_nav_page_permission: "logbookOutOfServiceLogs", url: () => "/employees/logbook/out_of_service_logs" },
	// Onboarding
	onboardingIndex: { v1_redirect: true, key: "onboardingIndex", root_nav_page_permission: "onboardingIndex", url: (tab: TsType_String) => "/employees/onboarding/" + tab },
	onboardingFileDriver: { v1_redirect: true, key: "onboardingFileDriver", root_nav_page_permission: "onboardingFileDriver", url: (id: TsType_String, rid: TsType_String, tab: TsType_String) => "/employees/onboarding/driver_file/" + id + "/r/" + rid + "/" + tab },
	// Onboarding V2
	onboardingV2FormEditorRoot: { v1_redirect: true, key: "onboardingV2FormEditorRoot", root_nav_page_permission: "onboardingV2FormEditorRoot", url: () => "/employees/onboarding_forms/editor/" },
	onboardingV2FormEditorSection: { v1_redirect: true, key: "onboardingV2FormEditorSection", root_nav_page_permission: "onboardingV2FormEditorSection", url: (id: TsType_String) => "/employees/onboarding_forms/editor/" + id + "/" },
	// Messages
	onboardingMessagesIndex: { v1_redirect: true, key: "onboardingMessagesIndex", root_nav_page_permission: "onboardingMessagesIndex", url: () => "/employees/onboarding_messages" },
	// Supervisor
	supervisorsIndex: { v1_redirect: true, key: "supervisorsIndex", root_nav_page_permission: "supervisorsIndex", url: () => "/employees/supervisors" },
	supervisorIndex: { v1_redirect: true, key: "supervisorIndex", root_nav_page_permission: "supervisorIndex", url: (id: TsType_String, rid: TsType_String) => "/employees/supervisor/" + id + "/r/" + rid },
	supervisorInspectionIndex: { v1_redirect: true, key: "supervisorInspectionIndex", root_nav_page_permission: "supervisorInspectionIndex", url: (type: TsType_String, id: TsType_String, rid: TsType_String) => "/employees/supervisor/inspection/" + type + "/i/" + id + "/r/" + rid },
	// Referrals
	siteReferrals: { v1_redirect: true, key: "siteReferrals", root_nav_page_permission: "siteReferrals", url: () => "/employees/referrals" },
	// Timecards
	timecardsIndex: { v1_redirect: true, key: "timecardsIndex", root_nav_page_permission: "timecardsIndex", url: () => "/employees/timecards" },
	timecardsUser: { v1_redirect: true, key: "timecardsUser", root_nav_page_permission: "timecardsUser", url: (id: TsType_String) => "/employees/timecards/" + id },
	// Basic Onboarding
	onboardingBasicIndex: { v1_redirect: true, key: "onboardingBasicIndex", root_nav_page_permission: "onboardingBasicIndex", url: () => "/employees/driver_onboarding" },
	onboardingBasicFileDriver: { v1_redirect: true, key: "onboardingBasicFileDriver", root_nav_page_permission: "onboardingBasicFileDriver", url: (id: TsType_String) => "/employees/driver_onboarding/driver_file/" + id },
	onboardingBasicFormEditor: { v1_redirect: true, key: "onboardingBasicFormEditor", root_nav_page_permission: "onboardingBasicFormEditor", url: () => "/employees/driver_onboarding/form_editor/" },
	//////////////////////////////////////
	// Transporter - Full - Accounting
	//////////////////////////////////////
	// Version 2
	accountingManagementIndex: { v1_redirect: true, key: "accountingManagementIndex", root_nav_page_permission: "accountingManagementIndex", url: () => "/accounting/management" },
	accountingManagementCharge: { v1_redirect: true, key: "accountingManagementCharge", root_nav_page_permission: "accountingManagementCharge", url: (id: TsType_String) => "/accounting/management/charge/" + id },
	accountingManagementRateSheet: { v1_redirect: true, key: "accountingManagementRateSheet", root_nav_page_permission: "accountingManagementRateSheet", url: (id: TsType_String) => "/accounting/management/rate_sheet/" + id },
	accountingManagementRouteRates: { v1_redirect: true, key: "accountingManagementRouteRates", root_nav_page_permission: "accountingManagementRouteRates", url: () => "/accounting/management/route_rates" },
	accountingManagementApplicationRules: { v1_redirect: true, key: "accountingManagementApplicationRules", root_nav_page_permission: "accountingManagementApplicationRules", url: () => "/accounting/management/application_rules" },
	accountingManagementSettlementRateSheet: { v1_redirect: true, key: "accountingManagementSettlementRateSheet", root_nav_page_permission: "accountingManagementSettlementRateSheet", url: (id: TsType_String) => "/accounting/management/settlement_rate_sheet/" + id },
	// Invoices
	invoicesIndex: { v1_redirect: true, key: "invoicesIndex", root_nav_page_permission: "invoicesIndex", url: () => "/accounting/invoices" },
	invoicesManage: { v1_redirect: true, key: "invoicesManage", root_nav_page_permission: "invoicesManage", url: (id: TsType_String) => "/accounting/invoices/manage/" + id },
	invoicesList: { v1_redirect: true, key: "invoicesList", root_nav_page_permission: "invoicesList", url: (id: TsType_String) => "/accounting/invoices/invoice_list/" + id },
	invoiceView: { v1_redirect: true, key: "invoiceView", root_nav_page_permission: "invoiceView", url: (id: TsType_String, iid: TsType_String, r: TsType_String) => "/accounting/invoices/invoice_list/" + id + "/invoice/" + iid + "/" + r },
	// Settlements
	settlementsIndex: { v1_redirect: true, key: "settlementsIndex", root_nav_page_permission: "settlementsIndex", url: (id: TsType_String) => "/accounting/settlements/i/" + id },
	settlementView: { v1_redirect: true, key: "settlementView", root_nav_page_permission: "settlementView", url: (id: TsType_String) => "/accounting/settlements/view/" + id },
	settlementCarrierBalance: { v1_redirect: true, key: "settlementCarrierBalance", root_nav_page_permission: "settlementCarrierBalance", url: (id: TsType_String) => "/accounting/settlements/carrier_balance/" + id },
	settlementCarrierAssets: { v1_redirect: true, key: "settlementCarrierAssets", root_nav_page_permission: "settlementCarrierAssets", url: (id: TsType_String) => "/accounting/settlements/carrier_assets/" + id },
	settlementCarrierWithholdings: { v1_redirect: true, key: "settlementCarrierWithholdings", root_nav_page_permission: "settlementCarrierWithholdings", url: (id: TsType_String) => "/accounting/settlements/carrier_withholdings/" + id },
	settlementCarrierFuelCards: { v1_redirect: true, key: "settlementCarrierFuelCards", root_nav_page_permission: "settlementCarrierFuelCards", url: (id: TsType_String) => "/accounting/settlements/carrier_fuel_cards/" + id },
	settlementCarrierReimbursements: { v1_redirect: true, key: "settlementCarrierReimbursements", root_nav_page_permission: "settlementCarrierReimbursements", url: (id: TsType_String) => "/accounting/settlements/carrier_reimbursements/" + id },
	settlementsQuickConfig: { v1_redirect: true, key: "settlementsQuickConfig", root_nav_page_permission: "settlementsQuickConfig", url: () => "/accounting/settlements/quick_config" },
	fuelCardsIndex: { v1_redirect: true, key: "fuelCardsIndex", root_nav_page_permission: "fuelCardsIndex", url: () => "/accounting/settlements/fuel_cards" },
	fuelReceiptsImport: { v1_redirect: true, key: "fuelReceiptsImport", root_nav_page_permission: "fuelReceiptsImport", url: () => "/accounting/settlements/fuel_receipts_import" },
	fuelReceiptsUnlinkedIndex: { v1_redirect: true, key: "fuelReceiptsUnlinkedIndex", root_nav_page_permission: "fuelReceiptsUnlinkedIndex", url: () => "/accounting/settlements/fuel_receipts/unlinked" },
	fuelReceiptsUnsettledIndex: { v1_redirect: true, key: "fuelReceiptsUnsettledIndex", root_nav_page_permission: "fuelReceiptsUnsettledIndex", url: () => "/accounting/settlements/fuel_receipts/unsettled" },
	fuelReportConfig: { v1_redirect: true, key: "fuelReportConfig", root_nav_page_permission: "fuelReportConfig", url: () => "/accounting/settlements/fuel_report_config" },
	// Accounting Analysis
	accountingAnalysisIndex: { v1_redirect: true, key: "accountingAnalysisIndex", root_nav_page_permission: "accountingAnalysisIndex", url: () => "/accounting/analysis" },
	bidAnalysisIndex: { v1_redirect: true, key: "bidAnalysisIndex", root_nav_page_permission: "bidAnalysisIndex", url: () => "/accounting/analysis/bid_packages" },
	bidAnalysisPurchaserIndex: { v1_redirect: true, key: "bidAnalysisPurchaserIndex", root_nav_page_permission: "bidAnalysisPurchaserIndex", url: (id: TsType_String) => "/accounting/analysis/bid_packages/" + id },
	bidAnalysisView: { v1_redirect: true, key: "bidAnalysisView", root_nav_page_permission: "bidAnalysisView", url: (id: TsType_String, pid: TsType_String) => "/accounting/analysis/bid_packages/" + id + "/view/" + pid },
	invoiceAnalysisIndex: { v1_redirect: true, key: "invoiceAnalysisIndex", root_nav_page_permission: "invoiceAnalysisIndex", url: () => "/accounting/analysis/invoices" },
	invoicesSummary: { v1_redirect: true, key: "invoicesSummary", root_nav_page_permission: "invoicesSummary", url: () => "/accounting/analysis/summary" },
	// Rate Tables
	rateTablesIndex: { v1_redirect: true, key: "rateTablesIndex", root_nav_page_permission: "rateTablesIndex", url: (pid: TsType_String) => "/accounting/rate_tables/" + pid },
	rateTablesNew: { v1_redirect: true, key: "rateTablesNew", root_nav_page_permission: "rateTablesNew", url: (id: TsType_String) => "/accounting/invoices/rate_tables/" + id + "/new" },
	rateTablesView: { v1_redirect: true, key: "rateTablesView", root_nav_page_permission: "rateTablesView", url: (id: TsType_String, tid: TsType_String) => "/accounting/invoices/rate_tables/" + id + "/rate/" + tid },
	rateTablesLogicLibrary: { v1_redirect: true, key: "rateTablesLogicLibrary", root_nav_page_permission: "rateTablesLogicLibrary", url: () => "/accounting/rate_logic_library" },
	// Tickets Spreadsheet
	ticketSpreadsheetIndex: { v1_redirect: true, key: "ticketSpreadsheetIndex", root_nav_page_permission: "ticketSpreadsheetIndex", url: () => "/accounting/ticket_spreadsheet" },
	// RFPs
	rfpBidsIndex: { v1_redirect: true, key: "rfpBidsIndex", root_nav_page_permission: "rfpBidsIndex", url: () => "/accounting/rfp_bids" },
	rfpBidsView: { v1_redirect: true, key: "rfpBidsView", root_nav_page_permission: "rfpBidsView", url: (id: TsType_String) => "/accounting/rfp_bids/" + id },
	transporterAllocationIndex: { v1_redirect: true, key: "transporterAllocationIndex", root_nav_page_permission: "transporterAllocationIndex", url: () => "/accounting/allocation" },
	transporterAllocationView: { v1_redirect: true, key: "transporterAllocationView", root_nav_page_permission: "transporterAllocationView", url: (id: TsType_String) => "/accounting/allocation/" + id },
	//////////////////////////////////////
	// Transporter - Full - Data
	//////////////////////////////////////
	// Jobs
	jobsIndex: { v1_redirect: true, key: "jobsIndex", root_nav_page_permission: "jobsIndex", url: () => "/data/jobs" },
	jobsView: { v1_redirect: true, key: "jobsView", root_nav_page_permission: "jobsView", url: (id: TsType_String) => "/data/jobs/" + id },
	jobsSearchView: { v1_redirect: true, key: "jobsSearchView", root_nav_page_permission: "jobsSearchView", url: (id: TsType_String) => "/data/jobs/s/" + id },
	jobsSearchViewAlt: { v1_redirect: true, key: "jobsSearchViewAlt", root_nav_page_permission: "jobsSearchViewAlt", url: (id: TsType_String) => "/data/jobs/search/" + id },
	jobsCloseOut: { v1_redirect: true, key: "jobsCloseOut", root_nav_page_permission: "jobsCloseOut", url: (id: TsType_String) => "/data/jobs/close_out/" + id },
	// Tickets
	ticketsIndex: { v1_redirect: true, key: "ticketsIndex", root_nav_page_permission: "ticketsIndex", url: () => "/data/tickets" },
	ticketsDeletedIndex: { v1_redirect: true, key: "ticketsDeletedIndex", root_nav_page_permission: "ticketsDeletedIndex", url: () => "/data/deleted_tickets" },
	ticketsView: { v1_redirect: true, key: "ticketsView", root_nav_page_permission: "ticketsView", url: (id: TsType_String) => "/data/tickets/" + id },
	ticketGroupView: { v1_redirect: true, key: "ticketGroupView", root_nav_page_permission: "ticketGroupView", url: (id: TsType_String) => "/data/ticket_group/" + id },
	ticketsAdminEdit: { v1_redirect: true, key: "ticketsAdminEdit", root_nav_page_permission: "ticketsAdminEdit", url: (id: TsType_String) => "/data/tickets/edit/" + id },
	ticketsSearchView: { v1_redirect: true, key: "ticketsSearchView", root_nav_page_permission: "ticketsSearchView", url: (id: TsType_String) => "/data/tickets/s/" + id },
	ticketsSearchViewAlt: { v1_redirect: true, key: "ticketsSearchViewAlt", root_nav_page_permission: "ticketsSearchViewAlt", url: (id: TsType_String) => "/data/tickets/search/" + id },
	// Ticket Review
	ticketReviewIndex: { v1_redirect: true, key: "ticketReviewIndex", root_nav_page_permission: "ticketReviewIndex", url: () => "/data/ticket_review" },
	ticketReviewCustomize: { v1_redirect: true, key: "ticketReviewCustomize", root_nav_page_permission: "ticketReviewCustomize", url: () => "/data/ticket_review/customize" },
	// Dashboard Analysis
	dashboardAnalysisIndex: { v1_redirect: true, key: "dashboardAnalysisIndex", root_nav_page_permission: "dashboardAnalysisIndex", url: () => "/data/dashboards" },
	dashboardAnalysisPublicView: { v1_redirect: true, key: "dashboardAnalysisPublicView", root_nav_page_permission: "dashboardAnalysisPublicView", url: (cid: TsType_String, id: TsType_String) => "/public/dashboards/" + cid + "/" + id },
	dashboardAnalysisView: { v1_redirect: true, key: "dashboardAnalysisView", root_nav_page_permission: "dashboardAnalysisView", url: (id: TsType_String) => "/data/dashboard_analysis/" + id },
	// Reports
	reportsIndex: { v1_redirect: true, key: "reportsIndex", root_nav_page_permission: "reportsIndex", url: () => "/data/reports" },
	reportTemplatesIndex: { v1_redirect: true, key: "reportTemplatesIndex", root_nav_page_permission: "reportTemplatesIndex", url: () => "/data/reports/templates" },
	reportEmailsIndex: { v1_redirect: true, key: "reportEmailsIndex", root_nav_page_permission: "reportEmailsIndex", url: () => "/data/reports/emails" },
	reportAccessIndex: { v1_redirect: true, key: "reportAccessIndex", root_nav_page_permission: "reportAccessIndex", url: () => "/data/reports/access" },
	reportsGenericIndex: { v1_redirect: true, key: "reportsGenericIndex", root_nav_page_permission: "reportsGenericIndex", url: () => "/data/reporting" },
	// Analytics
	analyticsIndex: { v1_redirect: true, key: "analyticsIndex", root_nav_page_permission: "analyticsIndex", url: () => "/data/analytics" },
	ticketsChartsIndex: { v1_redirect: true, key: "ticketsChartsIndex", root_nav_page_permission: "ticketsChartsIndex", url: () => "/data/tickets_charts" },
	nominationsBreakdown: { v1_redirect: true, key: "nominationsBreakdown", root_nav_page_permission: "nominationsBreakdown", url: () => "/data/breakdown/" },
	// Recents
	ticketsRecent: { v1_redirect: true, key: "ticketsRecent", root_nav_page_permission: "ticketsRecent", url: () => "/data/recent_tickets" },
	jobsRecent: { v1_redirect: true, key: "jobsRecent", root_nav_page_permission: "jobsRecent", url: () => "/data/recent_jobs" },
	// Other
	ticketsMissingInfo: { v1_redirect: true, key: "ticketsMissingInfo", root_nav_page_permission: "ticketsMissingInfo", url: () => "/data/tickets_missing_info" },
	ticketsBulkUpdates: { v1_redirect: true, key: "ticketsBulkUpdates", root_nav_page_permission: "ticketsBulkUpdates", url: () => "/data/bulk_ticket_updates" },
	// Imports
	ticketsImport: { v1_redirect: false, key: "ticketsImport", root_nav_page_permission: "ticketsIndex", url: () => "/data/tickets_import" },
	ticketsImportView: { v1_redirect: false, key: "ticketsImportView", root_nav_page_permission: "ticketsIndex", url: (id: TsType_String) => "/data/tickets_import/" + id },
	// Station Logs V2
	stationLogV2Tickets: { v1_redirect: true, key: "stationLogV2Tickets", root_nav_page_permission: "stationLogV2Tickets", url: () => "/station_log_tickets" },
	secureDeliveryReconciliationIndex: { v1_redirect: true, key: "secureDeliveryReconciliationIndex", root_nav_page_permission: "secureDeliveryReconciliationIndex", url: () => "/data/station_logs/reconciliation" },
	secureDeliveryReconciliationView: { v1_redirect: true, key: "secureDeliveryReconciliationView", root_nav_page_permission: "secureDeliveryReconciliationView", url: (id: TsType_String) => "/data/station_logs/reconciliation/" + id },
	//////////////////////////////////////
	// Transporter - Full - Directory
	//////////////////////////////////////
	// Cargo
	cargoIndex: { v1_redirect: true, key: "cargoIndex", root_nav_page_permission: "cargoIndex", url: () => "/directory/cargo" },
	cargoNew: { v1_redirect: true, key: "cargoNew", root_nav_page_permission: "cargoNew", url: () => "/directory/cargo/new" },
	cargoEdit: { v1_redirect: true, key: "cargoEdit", root_nav_page_permission: "cargoEdit", url: (id: TsType_String) => "/directory/cargo/edit/" + id },
	cargoEquipmentRestrictions: { v1_redirect: true, key: "cargoEquipmentRestrictions", root_nav_page_permission: "cargoEquipmentRestrictions", url: () => "/directory/cargo/restrictions" },
	// Carriers
	carriersIndex: { v1_redirect: true, key: "carriersIndex", root_nav_page_permission: "carriersIndex", url: () => "/directory/carriers" },
	carriersNew: { v1_redirect: true, key: "carriersNew", root_nav_page_permission: "carriersNew", url: () => "/directory/carriers/new" },
	carriersEdit: { v1_redirect: true, key: "carriersEdit", root_nav_page_permission: "carriersEdit", url: (id: TsType_String) => "/directory/carriers/edit/" + id },
	carriersMerge: { v1_redirect: true, key: "carriersMerge", root_nav_page_permission: "carriersMerge", url: () => "/directory/carriers/merge" },
	carriersCleanup: { v1_redirect: true, key: "carriersCleanup", root_nav_page_permission: "carriersCleanup", url: () => "/directory/carriers/cleanup" },
	carriersImport: { v1_redirect: true, key: "carriersImport", root_nav_page_permission: "carriersImport", url: () => "/directory/carriers/imports" },
	carriersFilesManage: { v1_redirect: true, key: "carriersFilesManage", root_nav_page_permission: "carriersFilesManage", url: (id: TsType_String) => "/directory/carriers/files/" + id },
	// Drivers
	driversIndex: { v1_redirect: true, key: "driversIndex", root_nav_page_permission: "driversIndex", url: () => "/directory/drivers" },
	driversNew: { v1_redirect: true, key: "driversNew", root_nav_page_permission: "driversNew", url: () => "/directory/drivers/new" },
	driversEdit: { v1_redirect: true, key: "driversEdit", root_nav_page_permission: "driversEdit", url: (id: TsType_String) => "/directory/drivers/edit/" + id },
	driversHoursOfService: { v1_redirect: true, key: "driversHoursOfService", root_nav_page_permission: "driversHoursOfService", url: (id: TsType_String) => "/directory/drivers/" + id + "/hours_of_service" },
	driverImports: { v1_redirect: true, key: "driverImports", root_nav_page_permission: "driverImports", url: () => "/directory/drivers/import" },
	// Leases
	leasesIndex: { v1_redirect: true, key: "leasesIndex", root_nav_page_permission: "leasesIndex", url: () => "/directory/pickups" },
	leasesAnalytics: { v1_redirect: true, key: "leasesAnalytics", root_nav_page_permission: "leasesAnalytics", url: () => "/directory/pickups/analytics" },
	leaseBulkDeletion: { v1_redirect: true, key: "leaseBulkDeletion", root_nav_page_permission: "leaseBulkDeletion", url: () => "/directory/pickups/bulk_deletion" },
	leasesNew: { v1_redirect: true, key: "leasesNew", root_nav_page_permission: "leasesNew", url: () => "/directory/pickups/new" },
	leasesEdit: { v1_redirect: true, key: "leasesEdit", root_nav_page_permission: "leasesEdit", url: (id: TsType_String) => "/directory/pickups/edit/" + id },
	leasesMerge: { v1_redirect: true, key: "leasesMerge", root_nav_page_permission: "leasesMerge", url: () => "/directory/pickups/merge" },
	leasesCleanup: { v1_redirect: true, key: "leasesCleanup", root_nav_page_permission: "leasesCleanup", url: () => "/directory/pickups/cleanup" },
	leasesImport: { v1_redirect: true, key: "leasesImport", root_nav_page_permission: "leasesImport", url: () => "/directory/pickups/import" },
	leasesDocksManage: { v1_redirect: true, key: "leasesDocksManage", root_nav_page_permission: "leasesDocksManage", url: (id: TsType_String) => "/directory/pickups/docks/" + id },
	leasesFilesManage: { v1_redirect: true, key: "leasesFilesManage", root_nav_page_permission: "leasesFilesManage", url: (id: TsType_String) => "/directory/pickups/files/" + id },
	leasesGeofence: { v1_redirect: true, key: "leasesGeofence", root_nav_page_permission: "leasesGeofence", url: (id: TsType_String) => "/directory/pickups/geofence/" + id },
	leaseLocationLinking: { v1_redirect: true, key: "leaseLocationLinking", root_nav_page_permission: "leaseLocationLinking", url: () => "/directory/pickups/location_linking" },
	leasesLocationsMap: { v1_redirect: true, key: "leasesLocationsMap", root_nav_page_permission: "leasesLocationsMap", url: () => "/directory/pickups/map" },
	// Pickups
	pickupsIndex: { v1_redirect: true, key: "pickupsIndex", root_nav_page_permission: "pickupsIndex", url: () => "/directory/pickup_locations" },
	pickupsNew: { v1_redirect: true, key: "pickupsNew", root_nav_page_permission: "pickupsNew", url: () => "/directory/pickup_locations/new" },
	pickupsEdit: { v1_redirect: true, key: "pickupsEdit", root_nav_page_permission: "pickupsEdit", url: (id: TsType_String) => "/directory/pickup_locations/edit/" + id },
	pickupsImport: { v1_redirect: true, key: "pickupsImport", root_nav_page_permission: "pickupsImport", url: () => "/directory/pickup_locations/import" },
	// Offloads
	offloadsIndex: { v1_redirect: true, key: "offloadsIndex", root_nav_page_permission: "offloadsIndex", url: () => "/directory/dropoffs" },
	offloadsNew: { v1_redirect: true, key: "offloadsNew", root_nav_page_permission: "offloadsNew", url: () => "/directory/dropoffs/new" },
	offloadsEdit: { v1_redirect: true, key: "offloadsEdit", root_nav_page_permission: "offloadsEdit", url: (id: TsType_String) => "/directory/dropoffs/edit/" + id },
	offloadsMerge: { v1_redirect: true, key: "offloadsMerge", root_nav_page_permission: "offloadsMerge", url: () => "/directory/dropoffs/merge" },
	offloadsCleanup: { v1_redirect: true, key: "offloadsCleanup", root_nav_page_permission: "offloadsCleanup", url: () => "/directory/dropoffs/cleanup" },
	offloadsImport: { v1_redirect: true, key: "offloadsImport", root_nav_page_permission: "offloadsImport", url: () => "/directory/dropoffs/import" },
	offloadsAccountsManage: { v1_redirect: true, key: "offloadsAccountsManage", root_nav_page_permission: "offloadsAccountsManage", url: (id: TsType_String) => "/directory/dropoffs/accounts/" + id },
	offloadsSecureDeliveryManage: { v1_redirect: true, key: "offloadsSecureDeliveryManage", root_nav_page_permission: "offloadsSecureDeliveryManage", url: (id: TsType_String) => "/directory/dropoffs/secure_delivery/" + id },
	offloadsFilesManage: { v1_redirect: true, key: "offloadsFilesManage", root_nav_page_permission: "offloadsFilesManage", url: (id: TsType_String) => "/directory/dropoffs/files/" + id },
	offloadsGeofence: { v1_redirect: true, key: "offloadsGeofence", root_nav_page_permission: "offloadsGeofence", url: (id: TsType_String) => "/directory/dropoffs/geofence/" + id },
	offloadLocationLinking: { v1_redirect: true, key: "offloadLocationLinking", root_nav_page_permission: "offloadLocationLinking", url: () => "/directory/dropoffs/location_linking" },
	offloadsLocationsMap: { v1_redirect: true, key: "offloadsLocationsMap", root_nav_page_permission: "offloadsLocationsMap", url: () => "/directory/dropoffs/map" },
	// Dropoffs
	dropoffsIndex: { v1_redirect: true, key: "dropoffsIndex", root_nav_page_permission: "dropoffsIndex", url: () => "/directory/dropoff_locations" },
	dropoffsNew: { v1_redirect: true, key: "dropoffsNew", root_nav_page_permission: "dropoffsNew", url: () => "/directory/dropoff_locations/new" },
	dropoffsEdit: { v1_redirect: true, key: "dropoffsEdit", root_nav_page_permission: "dropoffsEdit", url: (id: TsType_String) => "/directory/dropoff_locations/edit/" + id },
	dropoffsImport: { v1_redirect: true, key: "dropoffsImport", root_nav_page_permission: "dropoffsImport", url: () => "/directory/dropoff_locations/import" },
	// Producers
	producersIndex: { v1_redirect: true, key: "producersIndex", root_nav_page_permission: "producersIndex", url: () => "/directory/producers" },
	producersNew: { v1_redirect: true, key: "producersNew", root_nav_page_permission: "producersNew", url: () => "/directory/producers/new" },
	producersEdit: { v1_redirect: true, key: "producersEdit", root_nav_page_permission: "producersEdit", url: (id: TsType_String) => "/directory/producers/edit/" + id },
	producersMerge: { v1_redirect: true, key: "producersMerge", root_nav_page_permission: "producersMerge", url: () => "/directory/producers/merge" },
	producersCleanup: { v1_redirect: true, key: "producersCleanup", root_nav_page_permission: "producersCleanup", url: () => "/directory/producers/cleanup" },
	producersFields: { v1_redirect: true, key: "producersFields", root_nav_page_permission: "producersFields", url: (id: TsType_String) => "/directory/producers/" + id + "/manage_fields/" },
	producersImport: { v1_redirect: true, key: "producersImport", root_nav_page_permission: "producersImport", url: () => "/directory/producers/imports" },
	// Purchasers
	purchasersIndex: { v1_redirect: true, key: "purchasersIndex", root_nav_page_permission: "purchasersIndex", url: () => "/directory/purchasers" },
	purchasersNew: { v1_redirect: true, key: "purchasersNew", root_nav_page_permission: "purchasersNew", url: () => "/directory/purchasers/new" },
	purchasersEdit: { v1_redirect: true, key: "purchasersEdit", root_nav_page_permission: "purchasersEdit", url: (id: TsType_String) => "/directory/purchasers/edit/" + id },
	purchasersMerge: { v1_redirect: true, key: "purchasersMerge", root_nav_page_permission: "purchasersMerge", url: () => "/directory/purchasers/merge" },
	purchasersFilesManage: { v1_redirect: true, key: "purchasersFilesManage", root_nav_page_permission: "purchasersFilesManage", url: (id: TsType_String) => "/directory/purchasers/files/" + id },
	purchasersCleanup: { v1_redirect: true, key: "purchasersCleanup", root_nav_page_permission: "purchasersCleanup", url: () => "/directory/purchasers/cleanup" },
	purchasersImport: { v1_redirect: true, key: "purchasersImport", root_nav_page_permission: "purchasersImport", url: () => "/directory/purchasers/imports" },
	purchasersTicketEmail: { v1_redirect: true, key: "purchasersTicketEmail", root_nav_page_permission: "purchasersTicketEmail", url: (id: TsType_String) => "/directory/purchasers/ticket_emails/" + id },
	// Regions
	regionsIndex: { v1_redirect: true, key: "regionsIndex", root_nav_page_permission: "regionsIndex", url: () => "/directory/regions" },
	regionsNew: { v1_redirect: true, key: "regionsNew", root_nav_page_permission: "regionsNew", url: () => "/directory/regions/new" },
	regionsEdit: { v1_redirect: true, key: "regionsEdit", root_nav_page_permission: "regionsEdit", url: (id: TsType_String) => "/directory/regions/edit/" + id },
	regionsCleanup: { v1_redirect: true, key: "regionsCleanup", root_nav_page_permission: "regionsCleanup", url: () => "/directory/regions/cleanup" },
	regionsImport: { v1_redirect: true, key: "regionsImport", root_nav_page_permission: "regionsImport", url: () => "/directory/regions/imports" },
	// Routes
	routesIndex: { v1_redirect: true, key: "routesIndex", root_nav_page_permission: "routesIndex", url: () => "/directory/routes" },
	routesNew: { v1_redirect: true, key: "routesNew", root_nav_page_permission: "routesNew", url: () => "/directory/routes/new" },
	routesEdit: { v1_redirect: true, key: "routesEdit", root_nav_page_permission: "routesEdit", url: (id: TsType_String) => "/directory/routes/edit/" + id },
	routesCleanup: { v1_redirect: true, key: "routesCleanup", root_nav_page_permission: "routesCleanup", url: () => "/directory/routes/cleanup" },
	routesImport: { v1_redirect: true, key: "routesImport", root_nav_page_permission: "routesImport", url: () => "/directory/routes/imports" },
	routesFilesManage: { v1_redirect: true, key: "routesFilesManage", root_nav_page_permission: "routesFilesManage", url: (id: TsType_String) => "/directory/routes/files/" + id },
	routeReview: { v1_redirect: true, key: "routeReview", root_nav_page_permission: "routeReview", url: (id: TsType_String) => "/directory/routes/review/" + id },
	// Terminals
	terminalsIndex: { v1_redirect: true, key: "terminalsIndex", root_nav_page_permission: "terminalsIndex", url: () => "/directory/terminals" },
	terminalsNew: { v1_redirect: true, key: "terminalsNew", root_nav_page_permission: "terminalsNew", url: () => "/directory/terminals/new" },
	terminalsEdit: { v1_redirect: true, key: "terminalsEdit", root_nav_page_permission: "terminalsEdit", url: (id: TsType_String) => "/directory/terminals/edit/" + id },
	terminalsCleanup: { v1_redirect: true, key: "terminalsCleanup", root_nav_page_permission: "terminalsCleanup", url: () => "/directory/terminals/cleanup" },
	terminalsImport: { v1_redirect: true, key: "terminalsImport", root_nav_page_permission: "terminalsImport", url: () => "/directory/terminals/imports" },
	// Trailers
	trailersIndex: { v1_redirect: true, key: "trailersIndex", root_nav_page_permission: "trailersIndex", url: () => "/directory/trailers" },
	trailersNew: { v1_redirect: true, key: "trailersNew", root_nav_page_permission: "trailersNew", url: () => "/directory/trailers/new" },
	trailersEdit: { v1_redirect: true, key: "trailersEdit", root_nav_page_permission: "trailersEdit", url: (id: TsType_String) => "/directory/trailers/edit/" + id },
	trailersCleanup: { v1_redirect: true, key: "trailersCleanup", root_nav_page_permission: "trailersCleanup", url: () => "/directory/trailers/cleanup" },
	trailersImport: { v1_redirect: true, key: "trailersImport", root_nav_page_permission: "trailersImport", url: () => "/directory/trailers/import" },
	trailersFilesManage: { v1_redirect: true, key: "trailersFilesManage", root_nav_page_permission: "trailersFilesManage", url: (id: TsType_String) => "/directory/trailers/files/" + id },
	// Trucks
	trucksIndex: { v1_redirect: true, key: "trucksIndex", root_nav_page_permission: "trucksIndex", url: () => "/directory/trucks" },
	trucksNew: { v1_redirect: true, key: "trucksNew", root_nav_page_permission: "trucksNew", url: () => "/directory/trucks/new" },
	trucksEdit: { v1_redirect: true, key: "trucksEdit", root_nav_page_permission: "trucksEdit", url: (id: TsType_String) => "/directory/trucks/edit/" + id },
	trucksCleanup: { v1_redirect: true, key: "trucksCleanup", root_nav_page_permission: "trucksCleanup", url: () => "/directory/trucks/cleanup" },
	trucksImport: { v1_redirect: true, key: "trucksImport", root_nav_page_permission: "trucksImport", url: () => "/directory/trucks/import" },
	trucksFilesManage: { v1_redirect: true, key: "trucksFilesManage", root_nav_page_permission: "trucksFilesManage", url: (id: TsType_String) => "/directory/trucks/files/" + id },
	// Vehicles
	vehiclesIndex: { v1_redirect: true, key: "vehiclesIndex", root_nav_page_permission: "vehiclesIndex", url: () => "/directory/vehicles" },
	vehiclesNew: { v1_redirect: true, key: "vehiclesNew", root_nav_page_permission: "vehiclesNew", url: () => "/directory/vehicles/new" },
	vehiclesEdit: { v1_redirect: true, key: "vehiclesEdit", root_nav_page_permission: "vehiclesEdit", url: (id: TsType_String) => "/directory/vehicles/edit/" + id },
	vehiclesImport: { v1_redirect: true, key: "vehiclesImport", root_nav_page_permission: "vehiclesImport", url: () => "/directory/vehicles/import" },
	// Fast Updates
	fastUpdatesIndex: { v1_redirect: true, key: "fastUpdatesIndex", root_nav_page_permission: "fastUpdatesIndex", url: () => "/directory/fast_updates" },
	ticketTankGaugeUpdate: { v1_redirect: true, key: "ticketTankGaugeUpdate", root_nav_page_permission: "ticketTankGaugeUpdate", url: (id: TsType_String) => "/data/ticket_volume_update/" + id },
	//////////////////////////////////////
	// Transporter - Full - Settings
	//////////////////////////////////////
	// App Settings
	clientSettingsIndex: { v1_redirect: true, key: "clientSettingsIndex", root_nav_page_permission: "clientSettingsIndex", url: () => "/admin/settings" },
	// Users
	usersIndex: { v1_redirect: true, key: "usersIndex", root_nav_page_permission: "usersIndex", url: () => "/admin/users" },
	usersNew: { v1_redirect: true, key: "usersNew", root_nav_page_permission: "usersNew", url: () => "/admin/users/new" },
	usersEdit: { v1_redirect: true, key: "usersEdit", root_nav_page_permission: "usersEdit", url: (id: TsType_String) => "/admin/users/edit/" + id },
	usersPermissions: { v1_redirect: true, key: "usersPermissions", root_nav_page_permission: "usersPermissions", url: (id: TsType_String) => "/admin/users/permissions/" + id },
	userInfo: { v1_redirect: true, key: "userInfo", root_nav_page_permission: "userInfo", url: () => "/user_info" },
	// Database Management
	databaseManagement: { v1_redirect: true, key: "databaseManagement", root_nav_page_permission: "databaseManagement", url: () => "/admin/database_management" },
	demoDataManagement: { v1_redirect: true, key: "demoDataManagement", root_nav_page_permission: "demoDataManagement", url: () => "/admin/demo_data_management" },
	// API
	apiDocumentation: { v1_redirect: true, key: "apiDocumentation", root_nav_page_permission: "apiDocumentation", url: () => "/admin/api_documentation" },
	// Requests
	adminRequestsIndex: { v1_redirect: true, key: "adminRequestsIndex", root_nav_page_permission: "adminRequestsIndex", url: () => "/admin/requests" },
	// Payments
	clientPaymentsIndex: { v1_redirect: true, key: "clientPaymentsIndex", root_nav_page_permission: "clientPaymentsIndex", url: () => "/admin/payments" },
	//////////////////////////////////////
	// Transporter - Carrier
	//////////////////////////////////////
	carrierMapIndex: { v1_redirect: true, key: "carrierMapIndex", root_nav_page_permission: "carrierMapIndex", url: () => "/carrier/map" },
	carrierJobETAs: { v1_redirect: true, key: "carrierJobETAs", root_nav_page_permission: "carrierJobETAs", url: () => "/carrier/etas" },
	carrierTicketsIndex: { v1_redirect: true, key: "carrierTicketsIndex", root_nav_page_permission: "carrierTicketsIndex", url: () => "/carrier/tickets" },
	carrierDriversIndex: { v1_redirect: true, key: "carrierDriversIndex", root_nav_page_permission: "carrierDriversIndex", url: () => "/carrier/drivers" },
	carrierTrailersIndex: { v1_redirect: true, key: "carrierTrailersIndex", root_nav_page_permission: "carrierTrailersIndex", url: () => "/carrier/trailers" },
	carrierTrucksIndex: { v1_redirect: true, key: "carrierTrucksIndex", root_nav_page_permission: "carrierTrucksIndex", url: () => "/carrier/trucks" },
	carrierDownloadsIndex: { v1_redirect: true, key: "carrierDownloadsIndex", root_nav_page_permission: "carrierDownloadsIndex", url: () => "/carrier/downloads" },
	//////////////////////////////////////
	// Transporter - Driver
	//////////////////////////////////////
	driverOnboardingIndex: { v1_redirect: true, key: "driverOnboardingIndex", root_nav_page_permission: "driverOnboardingIndex", url: () => "/driver_onboarding" },
	//////////////////////////////////////
	// Transporter - Producer
	//////////////////////////////////////
	producerPortalCalculatorIndex: { v1_redirect: true, key: "producerPortalCalculatorIndex", root_nav_page_permission: "producerPortalCalculatorIndex", url: () => "/producer_portal/calculator" },
	producerPortalMapIndex: { v1_redirect: true, key: "producerPortalMapIndex", root_nav_page_permission: "producerPortalMapIndex", url: () => "/producer_portal/driver_locations" },
	producerPortalDownloadsIndex: { v1_redirect: true, key: "producerPortalDownloadsIndex", root_nav_page_permission: "producerPortalDownloadsIndex", url: () => "/producer_portal/downloads" },
	//////////////////////////////////////
	// Transporter - Purchaser
	//////////////////////////////////////
	purchaserNominationsIndex: { v1_redirect: true, key: "purchaserNominationsIndex", root_nav_page_permission: "purchaserNominationsIndex", url: () => "/purchaser/nominations" },
	purchaserCalculatorIndex: { v1_redirect: true, key: "purchaserCalculatorIndex", root_nav_page_permission: "purchaserCalculatorIndex", url: () => "/purchaser/calculator" },
	purchaserMapIndex: { v1_redirect: true, key: "purchaserMapIndex", root_nav_page_permission: "purchaserMapIndex", url: () => "/purchaser/driver_locations" },
	purchaserRequestsIndex: { v1_redirect: true, key: "purchaserRequestsIndex", root_nav_page_permission: "purchaserRequestsIndex", url: () => "/purchaser/requests" },
	purchaserJobETAs: { v1_redirect: true, key: "purchaserJobETAs", root_nav_page_permission: "purchaserJobETAs", url: () => "/purchaser/driver_etas" },
	purchaserTicketsIndex: { v1_redirect: true, key: "purchaserTicketsIndex", root_nav_page_permission: "purchaserTicketsIndex", url: () => "/purchaser/ticket_table" },
	purchaserBidAnalysisIndex: { v1_redirect: true, key: "purchaserBidAnalysisIndex", root_nav_page_permission: "purchaserBidAnalysisIndex", url: () => "/purchaser/bid_analysis" },
	purchaserBidAnalysisView: { v1_redirect: true, key: "purchaserBidAnalysisView", root_nav_page_permission: "purchaserBidAnalysisView", url: (pid: TsType_String) => "/purchaser/bid_analysis/" + pid },
	purchaserInvoiceAnalysisIndex: { v1_redirect: true, key: "purchaserInvoiceAnalysisIndex", root_nav_page_permission: "purchaserInvoiceAnalysisIndex", url: () => "/purchaser/invoice_analysis" },
	purchaserDownloadsIndex: { v1_redirect: true, key: "purchaserDownloadsIndex", root_nav_page_permission: "purchaserDownloadsIndex", url: () => "/purchaser/downloads" },
	//////////////////////////////////////
	// Hub - Full - Home
	//////////////////////////////////////
	hubAssociationManagement: { v1_redirect: true, key: "hubAssociationManagement", root_nav_page_permission: "hubAssociationManagement", url: () => "/hub/association_management" },
	requestAllocationIndex: { v1_redirect: true, key: "requestAllocationIndex", root_nav_page_permission: "requestAllocationIndex", url: () => "/hub/request_allocation" },
	//////////////////////////////////////
	// Marketer - Full
	//////////////////////////////////////
	marketerCalculatorIndex: { v1_redirect: true, key: "marketerCalculatorIndex", root_nav_page_permission: "marketerCalculatorIndex", url: () => "/marketer/calculator" },
	marketerNewRequest: { v1_redirect: true, key: "marketerNewRequest", root_nav_page_permission: "marketerNewRequest", url: () => "/marketer/new_load_request" },
	marketerLoadMonitoring: { v1_redirect: true, key: "marketerLoadMonitoring", root_nav_page_permission: "marketerLoadMonitoring", url: () => "/marketer/load_monitoring" },
	marketerCarrierScorecards: { v1_redirect: true, key: "marketerCarrierScorecards", root_nav_page_permission: "marketerCarrierScorecards", url: () => "/marketer/carrier_scorecards" },
	marketerNominationsIndex: { v1_redirect: true, key: "marketerNominationsIndex", root_nav_page_permission: "marketerNominationsIndex", url: () => "/marketer/nominations" },
	marketerJobETAs: { v1_redirect: true, key: "marketerJobETAs", root_nav_page_permission: "marketerJobETAs", url: () => "/marketer/driver_etas" },
	marketerMapIndex: { v1_redirect: true, key: "marketerMapIndex", root_nav_page_permission: "marketerMapIndex", url: () => "/marketer/driver_locations" },
	marketerTicketsIndex: { v1_redirect: true, key: "marketerTicketsIndex", root_nav_page_permission: "marketerTicketsIndex", url: () => "/marketer/tickets" },
	marketerDownloadsIndex: { v1_redirect: true, key: "marketerDownloadsIndex", root_nav_page_permission: "marketerDownloadsIndex", url: () => "/marketer/downloads" },
	marketerReportTemplatesIndex: { v1_redirect: true, key: "marketerReportTemplatesIndex", root_nav_page_permission: "marketerReportTemplatesIndex", url: () => "/marketer/downloads/templates" },
	marketerBidAnalysisIndex: { v1_redirect: true, key: "marketerBidAnalysisIndex", root_nav_page_permission: "marketerBidAnalysisIndex", url: () => "/marketer/bid_analysis" },
	marketerBidAnalysisView: { v1_redirect: true, key: "marketerBidAnalysisView", root_nav_page_permission: "marketerBidAnalysisView", url: (id: TsType_String) => "/marketer/bid_analysis/" + id },
	marketerInvoiceAnalysisIndex: { v1_redirect: true, key: "marketerInvoiceAnalysisIndex", root_nav_page_permission: "marketerInvoiceAnalysisIndex", url: () => "/marketer/cost_analysis" },
	marketerDirectoryManagement: { v1_redirect: true, key: "marketerDirectoryManagement", root_nav_page_permission: "marketerDirectoryManagement", url: () => "/marketer/directory_management" },
	marketerAssignmentAlgorithmConfigIndex: { v1_redirect: true, key: "marketerAssignmentAlgorithmConfigIndex", root_nav_page_permission: "marketerAssignmentAlgorithmConfigIndex", url: () => "/marketer/load_assignment_algorithm" },
	marketerAssignmentAlgorithmConfigManage: { v1_redirect: true, key: "marketerAssignmentAlgorithmConfigManage", root_nav_page_permission: "marketerAssignmentAlgorithmConfigManage", url: (id: TsType_String) => "/marketer/load_assignment_algorithm/" + id },
	//////////////////////////////////////
	// Producer - Full
	//////////////////////////////////////
	producerCalculatorIndex: { v1_redirect: true, key: "producerCalculatorIndex", root_nav_page_permission: "producerCalculatorIndex", url: () => "/producer/calculator" },
	producerNewRequest: { v1_redirect: true, key: "producerNewRequest", root_nav_page_permission: "producerNewRequest", url: () => "/producer/new_load_request" },
	producerLoadMonitoring: { v1_redirect: true, key: "producerLoadMonitoring", root_nav_page_permission: "producerLoadMonitoring", url: () => "/producer/load_monitoring" },
	producerCarrierScorecards: { v1_redirect: true, key: "producerCarrierScorecards", root_nav_page_permission: "producerCarrierScorecards", url: () => "/producer/carrier_scorecards" },
	producerNominationsIndex: { v1_redirect: true, key: "producerNominationsIndex", root_nav_page_permission: "producerNominationsIndex", url: () => "/producer/nominations" },
	producerJobETAs: { v1_redirect: true, key: "producerJobETAs", root_nav_page_permission: "producerJobETAs", url: () => "/producer/driver_etas" },
	producerJobUpstreamETAs: { v1_redirect: true, key: "producerJobUpstreamETAs", root_nav_page_permission: "producerJobUpstreamETAs", url: () => "/producer/driver_etas_list" },
	producerMapIndex: { v1_redirect: true, key: "producerMapIndex", root_nav_page_permission: "producerMapIndex", url: () => "/producer/driver_locations" },
	producerMapUpstreamIndex: { v1_redirect: true, key: "producerMapUpstreamIndex", root_nav_page_permission: "producerMapUpstreamIndex", url: () => "/producer/driver_map" },
	producerTicketsIndex: { v1_redirect: true, key: "producerTicketsIndex", root_nav_page_permission: "producerTicketsIndex", url: () => "/producer/tickets" },
	producerTicketsUpstreamIndex: { v1_redirect: true, key: "producerTicketsUpstreamIndex", root_nav_page_permission: "producerTicketsUpstreamIndex", url: () => "/producer/ticket_data" },
	producerDownloadsIndex: { v1_redirect: true, key: "producerDownloadsIndex", root_nav_page_permission: "producerDownloadsIndex", url: () => "/producer/downloads" },
	producerReportTemplatesIndex: { v1_redirect: true, key: "producerReportTemplatesIndex", root_nav_page_permission: "producerReportTemplatesIndex", url: () => "/producer/downloads/templates" },
	producerBidAnalysisIndex: { v1_redirect: true, key: "producerBidAnalysisIndex", root_nav_page_permission: "producerBidAnalysisIndex", url: () => "/producer/bid_analysis" },
	producerBidAnalysisView: { v1_redirect: true, key: "producerBidAnalysisView", root_nav_page_permission: "producerBidAnalysisView", url: (id: TsType_String) => "/producer/bid_analysis/" + id },
	producerDirectoryManagement: { v1_redirect: true, key: "producerDirectoryManagement", root_nav_page_permission: "producerDirectoryManagement", url: () => "/producer/directory_management" },
	producerAssignmentAlgorithmConfigIndex: { v1_redirect: true, key: "producerAssignmentAlgorithmConfigIndex", root_nav_page_permission: "producerAssignmentAlgorithmConfigIndex", url: () => "/producer/load_assignment_algorithm" },
	producerAssignmentAlgorithmConfigManage: { v1_redirect: true, key: "producerAssignmentAlgorithmConfigManage", root_nav_page_permission: "producerAssignmentAlgorithmConfigManage", url: (id: TsType_String) => "/producer/load_assignment_algorithm/" + id },
	//////////////////////////////////////
	// Partner - Full
	//////////////////////////////////////
	partnerMapIndexDemo: { v1_redirect: true, key: "partnerMapIndexDemo", root_nav_page_permission: "partnerMapIndexDemo", url: () => "/partner/map" },
	partnerHotShotDemo: { v1_redirect: true, key: "partnerHotShotDemo", root_nav_page_permission: "partnerHotShotDemo", url: () => "/partner/hot_shot" },
	partnerCarrierScorecardsDemo: { v1_redirect: true, key: "partnerCarrierScorecardsDemo", root_nav_page_permission: "partnerCarrierScorecardsDemo", url: () => "/partner/carrier_scorecards" },
	partnerUnauthenticatedJobView: { v1_redirect: true, key: "partnerUnauthenticatedJobView", root_nav_page_permission: "partnerUnauthenticatedJobView", url: (cid: TsType_String, jid: TsType_String) => "/live_job/" + cid + "/" + jid },
	partnerScheduledRequests: { v1_redirect: true, key: "partnerScheduledRequests", root_nav_page_permission: "partnerScheduledRequests", url: () => "/partner/scheduled_requests" },
	partnerInvoicesIndex: { v1_redirect: true, key: "partnerInvoicesIndex", root_nav_page_permission: "partnerInvoicesIndex", url: () => "/partner/invoices" },
	partnerInvoicesView: { v1_redirect: true, key: "partnerInvoicesView", root_nav_page_permission: "partnerInvoicesView", url: (cid: TsType_String, id: TsType_String) => "/partner/invoices/" + cid + "/" + id },
	rfpIndex: { v1_redirect: true, key: "rfpIndex", root_nav_page_permission: "rfpIndex", url: () => "/partner/rfps" },
	rfpView: { v1_redirect: true, key: "rfpView", root_nav_page_permission: "rfpView", url: (id: TsType_String) => "/partner/rfps/" + id },
	rfpTemplateEditorIndex: { v1_redirect: true, key: "rfpTemplateEditorIndex", root_nav_page_permission: "rfpTemplateEditorIndex", url: () => "/partner/rfp_templates" },
	rfpTemplateEditorView: { v1_redirect: true, key: "rfpTemplateEditorView", root_nav_page_permission: "rfpTemplateEditorView", url: (id: TsType_String) => "/partner/rfp_templates/" + id },
	//////////////////////////////////////
	// Dev Team
	//////////////////////////////////////
	developerSupportIndex: { v1_redirect: true, key: "developerSupportIndex", root_nav_page_permission: "developerSupportIndex", url: () => "/super/developer_support" },
	developerAnalyticsIndex: { v1_redirect: true, key: "developerAnalyticsIndex", root_nav_page_permission: "developerAnalyticsIndex", url: () => "/super/developer_analytics" },
	superAnalyticsIndex: { v1_redirect: true, key: "superAnalyticsIndex", root_nav_page_permission: "superAnalyticsIndex", url: () => "/super/analytics" },
	superBillingIndex: { v1_redirect: true, key: "superBillingIndex", root_nav_page_permission: "superBillingIndex", url: () => "/product_billing" },
	superClientsIndex: { v1_redirect: true, key: "superClientsIndex", root_nav_page_permission: "superClientsIndex", url: () => "/super/clients" },
	superClientPermissions: { v1_redirect: true, key: "superClientPermissions", root_nav_page_permission: "superClientPermissions", url: (id: TsType_String) => "/super/clients/permissions/" + id },
	superSetupIndex: { v1_redirect: true, key: "superSetupIndex", root_nav_page_permission: "superSetupIndex", url: (id: TsType_String, tid: TsType_String) => "/super/clients/database_setup/" + id + "/" + tid },
	superLogsIndex: { v1_redirect: true, key: "superLogsIndex", root_nav_page_permission: "superLogsIndex", url: () => "/super/logs" },
	superLogsErrors: { v1_redirect: true, key: "superLogsErrors", root_nav_page_permission: "superLogsErrors", url: () => "/super/errors" },
	superLogsCloudErrors: { v1_redirect: true, key: "superLogsCloudErrors", root_nav_page_permission: "superLogsCloudErrors", url: () => "/super/cloud_errors" },
	superModelsIndex: { v1_redirect: true, key: "superModelsIndex", root_nav_page_permission: "superModelsIndex", url: () => "/super/models" },
	superHealthIndex: { v1_redirect: true, key: "superHealthIndex", root_nav_page_permission: "superHealthIndex", url: () => "/super/health" },
	superRoadMapIndex: { v1_redirect: true, key: "superRoadMapIndex", root_nav_page_permission: "superRoadMapIndex", url: () => "/super/roadmap" },
	superTestLabIndex: { v1_redirect: true, key: "superTestLabIndex", root_nav_page_permission: "superTestLabIndex", url: () => "/super/test_lab" },
	superSpecialTestLab: { v1_redirect: true, key: "superSpecialTestLab", root_nav_page_permission: "superSpecialTestLab", url: () => "/super/special" },
	superUsersIndex: { v1_redirect: true, key: "superUsersIndex", root_nav_page_permission: "superUsersIndex", url: () => "/super/users" },
	superImportsIndex: { v1_redirect: true, key: "superImportsIndex", root_nav_page_permission: "superImportsIndex", url: () => "/super/imports" },
	// SuperClientPermissionsManagementPage: 		{ key: "SuperClientPermissionsManagementPage", 		root_nav_page_permission: "SuperClientPermissionsManagementPage", 	url: () => "/super/client_permissions/" },
	// SuperTestLabPage: 							{ key: "SuperTestLabPage", 							root_nav_page_permission: "SuperTestLabPage", 						url: () => "/super/test_lab/" },
	// // Boilerplate Docs
	// BoilerplateDocsPage: 						{ key: "BoilerplateDocsPage", 						root_nav_page_permission: "BoilerplateDocsPage", 					url: () => "/boilerplate/docs/components" },
}

export const ClientTypes: TsInterface_ClientTypes = {
	billing: {
		key: "billing",
		name: s_BILLING,
		user_roles: {
			admin: {
				key: "admin",
				name: ClientUserRoles["admin"]["name"],
				icon: ClientUserRoles["admin"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
		},
	},
	generic_planned: {
		key: "generic_planned",
		name: s_GENERIC_PLANNED,
		user_roles: {
			admin: {
				key: "admin",
				name: ClientUserRoles["admin"]["name"],
				icon: ClientUserRoles["admin"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			dispatcher: {
				key: "dispatcher",
				name: ClientUserRoles["dispatcher"]["name"],
				icon: ClientUserRoles["dispatcher"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
		},
	},
	hub: {
		key: "hub",
		name: s_HUB,
		user_roles: {
			admin: {
				key: "admin",
				name: ClientUserRoles["admin"]["name"],
				icon: ClientUserRoles["admin"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			dispatcher: {
				key: "dispatcher",
				name: ClientUserRoles["dispatcher"]["name"],
				icon: ClientUserRoles["dispatcher"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
		},
	},
	locked: {
		key: "locked",
		name: s_LOCKED,
		user_roles: {
			admin: {
				key: "admin",
				name: ClientUserRoles["admin"]["name"],
				icon: ClientUserRoles["admin"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			api_access: {
				key: "api_access",
				name: ClientUserRoles["api_access"]["name"],
				icon: ClientUserRoles["api_access"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			compliance_analyst: {
				key: "compliance_analyst",
				name: ClientUserRoles["compliance_analyst"]["name"],
				icon: ClientUserRoles["compliance_analyst"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			dispatcher: {
				key: "dispatcher",
				name: ClientUserRoles["dispatcher"]["name"],
				icon: ClientUserRoles["dispatcher"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			field_admin: {
				key: "field_admin",
				name: ClientUserRoles["field_admin"]["name"],
				icon: ClientUserRoles["field_admin"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			field_supervisor: {
				key: "field_supervisor",
				name: ClientUserRoles["field_supervisor"]["name"],
				icon: ClientUserRoles["field_supervisor"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			financial_analyst: {
				key: "financial_analyst",
				name: ClientUserRoles["financial_analyst"]["name"],
				icon: ClientUserRoles["financial_analyst"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
		},
	},
	marketer: {
		key: "marketer",
		name: s_MARKETER,
		user_roles: {
			admin: {
				key: "admin",
				name: ClientUserRoles["admin"]["name"],
				icon: ClientUserRoles["admin"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
		},
	},
	producer: {
		key: "producer",
		name: s_PRODUCER,
		user_roles: {
			admin: {
				key: "admin",
				name: ClientUserRoles["admin"]["name"],
				icon: ClientUserRoles["admin"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			producer_foreman: {
				key: "producer_foreman",
				name: ClientUserRoles["producer_foreman"]["name"],
				icon: ClientUserRoles["producer_foreman"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			pumper: {
				key: "pumper",
				name: ClientUserRoles["pumper"]["name"],
				icon: ClientUserRoles["pumper"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
		},
	},
	secure_delivery: {
		key: "secure_delivery",
		name: s_SECURE_DELIVERY,
		user_roles: {
			admin: {
				key: "admin",
				name: ClientUserRoles["admin"]["name"],
				icon: ClientUserRoles["admin"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
		},
	},
	transporter: {
		key: "transporter",
		name: s_TRANSPORTER,
		user_roles: {
			admin: {
				key: "admin",
				name: ClientUserRoles["admin"]["name"],
				icon: ClientUserRoles["admin"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			api_access: {
				key: "api_access",
				name: ClientUserRoles["api_access"]["name"],
				icon: ClientUserRoles["api_access"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			compliance_analyst: {
				key: "compliance_analyst",
				name: ClientUserRoles["compliance_analyst"]["name"],
				icon: ClientUserRoles["compliance_analyst"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			dispatcher: {
				key: "dispatcher",
				name: ClientUserRoles["dispatcher"]["name"],
				icon: ClientUserRoles["dispatcher"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			field_admin: {
				key: "field_admin",
				name: ClientUserRoles["field_admin"]["name"],
				icon: ClientUserRoles["field_admin"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			field_supervisor: {
				key: "field_supervisor",
				name: ClientUserRoles["field_supervisor"]["name"],
				icon: ClientUserRoles["field_supervisor"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			financial_analyst: {
				key: "financial_analyst",
				name: ClientUserRoles["financial_analyst"]["name"],
				icon: ClientUserRoles["financial_analyst"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			sales: {
				key: "sales",
				name: ClientUserRoles["sales"]["name"],
				icon: ClientUserRoles["sales"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			locked: {
				key: "locked",
				name: ClientUserRoles["locked"]["name"],
				icon: ClientUserRoles["locked"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			driver: {
				key: "driver",
				name: ClientUserRoles["driver"]["name"],
				icon: ClientUserRoles["driver"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			carrier_admin: {
				key: "carrier_admin",
				name: ClientUserRoles["carrier_admin"]["name"],
				icon: ClientUserRoles["carrier_admin"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			producer_admin: {
				key: "producer_admin",
				name: ClientUserRoles["producer_admin"]["name"],
				icon: ClientUserRoles["producer_admin"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			producer_pumper: {
				key: "producer_pumper",
				name: ClientUserRoles["producer_pumper"]["name"],
				icon: ClientUserRoles["producer_pumper"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			purchaser_admin: {
				key: "purchaser_admin",
				name: ClientUserRoles["purchaser_admin"]["name"],
				icon: ClientUserRoles["purchaser_admin"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
			stakeholder: {
				key: "stakeholder",
				name: ClientUserRoles["stakeholder"]["name"],
				icon: ClientUserRoles["stakeholder"]["icon"],
				home_redirect_page: ApplicationPages.HomePage,
			},
		},
	},
}

export const ApplicationMajorPages: TsInterface_ApplicationPages = {
	HomePage: ApplicationPages["HomePage"],
	UserSettingsPage: ApplicationPages["UserSettingsPage"],
}

// Nav Sections
export const ApplicationNavSections: TsInterface_ApplicationNavSections = {
	// transporter
	home: {
		name: s_HOME,
		key: "home",
		links: {},
	},
	dispatch: { // Has to stay this key for permissions
		name: s_OPERATIONS,
		key: "dispatch",
		links: {},
	},
	operations: { // used for marketer and producer accounts
		name: s_OPERATIONS,
		key: "operations",
		links: {},
	},
	compliance: { // Has to stay this key for permissions
		name: s_EMPLOYEES,
		key: "compliance",
		links: {},
	},
	accounting: {
		name: s_ACCOUNTING,
		key: "accounting",
		links: {},
	},
	finance: { // used for marketer and producer accounts
		name: s_FINANCE,
		key: "finance",
		links: {},
	},
	data: {
		name: s_DATA,
		key: "data",
		links: {},
	},
	directory: {
		name: s_DIRECTORY,
		key: "directory",
		links: {},
	},
	admin: {
		name: s_ADMIN,
		key: "admin",
		links: {},
	},
	dev_team: {
		name: s_DEV_TEAM,
		key: "dev_team",
		links: {},
	},
	// billing
		// not a priority
	// generic planned
		// not used
	// hub
		// not a priority
	// locked
		// not a priority
	// marketer

	// producer

	// secure delivery
		// not a priority

}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//

// Nav Pages
export const ApplicationNavPages: TsInterface_ApplicationNavPages = {

	//////////////////////////////////////
	// HOME
	//////////////////////////////////////

	// Transporter - Full - Home
	HomePage: {
		name: s_HOME,
		key: "HomePage",
		icon: "home-blank",
		url: ApplicationPages.HomePage.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_transporter_full_home_home" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_transporter_full_home_home" },
			"transporter_compliance_analyst": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_transporter_full_home_home" },
			"transporter_dispatcher": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_transporter_full_home_home" },
			"transporter_field_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_transporter_full_home_home" },
			"transporter_field_supervisor": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_transporter_full_home_home" },
			"transporter_financial_analyst": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_transporter_full_home_home" },
			"transporter_sales": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_transporter_full_home_home" },
			"transporter_locked": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_transporter_locked_home_home" },
			"transporter_driver": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_transporter_driver_home_home" },
			"transporter_carrier_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_transporter_carrier_home_home" },
			"transporter_stakeholder": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_transporter_stakeholder_home_home" },
			"billing_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_billing_full_home_home" },
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_generic_planned_full_home_home" },
			"generic_planned_dispatcher": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_generic_planned_full_home_home" },
			"hub_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_hub_full_home_home" },
			"hub_dispatcher": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_hub_full_home_home" },
			"locked_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_locked_full_home_home" },
			"locked_api_access": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_locked_full_home_home" },
			"locked_compliance_analyst": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_locked_full_home_home" },
			"locked_dispatcher": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_locked_full_home_home" },
			"locked_field_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_locked_full_home_home" },
			"locked_field_supervisor": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_locked_full_home_home" },
			"locked_financial_analyst": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_locked_full_home_home" },
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_marketer_full_home_home" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_producer_full_home_home" },
			"producer_producer_foreman": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_producer_full_home_home" },
			"producer_pumper": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_producer_full_home_home" },
			"secure_delivery_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_secure_delivery_full_home_home" },
			"secure_delivery_driver": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "HomePage", legacy_permission: "pv2_secure_delivery_driver_home_home" },
		},
		nav_badges: {}
	},
	notificationsManagment: {
		name: s_NOTIFICATIONS,
		key: "notificationsManagment",
		icon: "bell",
		url: ApplicationPages.notificationsManagment.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_transporter_full_home_notifications" },
			"transporter_api_access": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_transporter_full_home_notifications" },
			"transporter_compliance_analyst": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_transporter_full_home_notifications" },
			"transporter_dispatcher": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_transporter_full_home_notifications" },
			"transporter_field_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_transporter_full_home_notifications" },
			"transporter_field_supervisor": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_transporter_full_home_notifications" },
			"transporter_financial_analyst": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_transporter_full_home_notifications" },
			"transporter_sales": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_transporter_full_home_notifications" },
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_generic_planned_full_home_notifications" },
			"generic_planned_dispatcher": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_generic_planned_full_home_notifications" },
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_marketer_full_home_notifications" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_producer_full_home_notifications" },
			"producer_producer_foreman": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_producer_full_home_notifications" },
			"producer_pumper": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "notificationsManagment", legacy_permission: "pv2_producer_full_home_notifications" },
		},
		nav_badges: {}
	},
	newsFeedIndex: {
		name: s_NEWS_FEED,
		key: "newsFeedIndex",
		icon: "rss",
		url: ApplicationPages.newsFeedIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "newsFeedIndex", legacy_permission: "pv2_transporter_full_home_news_feed" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "newsFeedIndex", legacy_permission: "pv2_transporter_full_home_news_feed" },
			"transporter_compliance_analyst": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "newsFeedIndex", legacy_permission: "pv2_transporter_full_home_news_feed" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "newsFeedIndex", legacy_permission: "pv2_transporter_full_home_news_feed" },
			"transporter_field_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "newsFeedIndex", legacy_permission: "pv2_transporter_full_home_news_feed" },
			"transporter_field_supervisor": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "newsFeedIndex", legacy_permission: "pv2_transporter_full_home_news_feed" },
			"transporter_financial_analyst": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "newsFeedIndex", legacy_permission: "pv2_transporter_full_home_news_feed" },
			"transporter_sales": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "newsFeedIndex", legacy_permission: "pv2_transporter_full_home_news_feed" }
		},
		nav_badges: {}
	},
	calculatorIndex: {
		name: s_CALCULATOR,
		key: "calculatorIndex",
		icon: "calculator",
		url: ApplicationPages.calculatorIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "calculatorIndex", legacy_permission: "pv2_transporter_full_home_calculator" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "calculatorIndex", legacy_permission: "pv2_transporter_full_home_calculator" },
			"transporter_compliance_analyst": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "calculatorIndex", legacy_permission: "pv2_transporter_full_home_calculator" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "calculatorIndex", legacy_permission: "pv2_transporter_full_home_calculator" },
			"transporter_field_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "calculatorIndex", legacy_permission: "pv2_transporter_full_home_calculator" },
			"transporter_field_supervisor": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "calculatorIndex", legacy_permission: "pv2_transporter_full_home_calculator" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "calculatorIndex", legacy_permission: "pv2_transporter_full_home_calculator" },
			"transporter_sales": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "calculatorIndex", legacy_permission: "pv2_transporter_full_home_calculator" }
		},
		nav_badges: {}
	},
	tasksIndex: {
		name: s_TASKS,
		key: "tasksIndex",
		icon: "server",
		url: ApplicationPages.tasksIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "tasksIndex", legacy_permission: "pv2_transporter_full_home_tasks" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "tasksIndex", legacy_permission: "pv2_transporter_full_home_tasks" },
			"transporter_compliance_analyst": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "tasksIndex", legacy_permission: "pv2_transporter_full_home_tasks" },
			"transporter_dispatcher": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "tasksIndex", legacy_permission: "pv2_transporter_full_home_tasks" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "tasksIndex", legacy_permission: "pv2_transporter_full_home_tasks" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "tasksIndex", legacy_permission: "pv2_transporter_full_home_tasks" },
			"transporter_financial_analyst": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "tasksIndex", legacy_permission: "pv2_transporter_full_home_tasks" },
			"transporter_sales": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "tasksIndex", legacy_permission: "pv2_transporter_full_home_tasks" }
		},
		nav_badges: {}
	},
	settingsIndex: {
		name: s_USER_SETTINGS,
		key: "settingsIndex",
		icon: "cog",
		url: ApplicationPages.settingsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "settingsIndex", legacy_permission: "pv2_transporter_full_home_settings" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "settingsIndex", legacy_permission: "pv2_transporter_full_home_settings" },
			"transporter_compliance_analyst": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "settingsIndex", legacy_permission: "pv2_transporter_full_home_settings" },
			"transporter_dispatcher": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "settingsIndex", legacy_permission: "pv2_transporter_full_home_settings" },
			"transporter_field_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "settingsIndex", legacy_permission: "pv2_transporter_full_home_settings" },
			"transporter_field_supervisor": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "settingsIndex", legacy_permission: "pv2_transporter_full_home_settings" },
			"transporter_financial_analyst": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "settingsIndex", legacy_permission: "pv2_transporter_full_home_settings" },
			"transporter_sales": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "settingsIndex", legacy_permission: "pv2_transporter_full_home_settings" },
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "settingsIndex", legacy_permission: "pv2_generic_planned_full_home_settings" },
			"generic_planned_dispatcher": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "settingsIndex", legacy_permission: "pv2_generic_planned_full_home_settings" },
		},
		nav_badges: {}
	},
	supportDocsIndex: {
		name: s_SUPPORT,
		key: "supportDocsIndex",
		icon: "wrench",
		url: ApplicationPages.supportDocsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "supportDocsIndex", legacy_permission: "pv2_transporter_full_home_support" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "supportDocsIndex", legacy_permission: "pv2_transporter_full_home_support" },
			"transporter_compliance_analyst": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "supportDocsIndex", legacy_permission: "pv2_transporter_full_home_support" },
			"transporter_dispatcher": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "supportDocsIndex", legacy_permission: "pv2_transporter_full_home_support" },
			"transporter_field_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "supportDocsIndex", legacy_permission: "pv2_transporter_full_home_support" },
			"transporter_field_supervisor": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "supportDocsIndex", legacy_permission: "pv2_transporter_full_home_support" },
			"transporter_financial_analyst": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "supportDocsIndex", legacy_permission: "pv2_transporter_full_home_support" },
			"transporter_sales": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "supportDocsIndex", legacy_permission: "pv2_transporter_full_home_support" }
		},
		nav_badges: {}
	},
	// Transporter - Driver - Home
	driverOnboardingIndex: {
		name: s_ONBOARDING,
		key: "driverOnboardingIndex",
		icon: "traffic",
		url: ApplicationPages.driverOnboardingIndex.url(),
		page_role_access_permissions: {
			"transporter_driver": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "driverOnboardingIndex", legacy_permission: "pv2_transporter_driver_home_onboarding" }
		},
		nav_badges: {}
	},
	// Transporter - Carrier - Home
	carrierMapIndex: {
		name: s_MAP,
		key: "carrierMapIndex",
		icon: "map",
		url: ApplicationPages.carrierMapIndex.url(),
		page_role_access_permissions: {
			"transporter_carrier_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "carrierMapIndex", legacy_permission: "pv2_transporter_carrier_home_map" }
		},
		nav_badges: {}
	},
	carrierJobETAs: {
		name: s_DRIVER_ETAS,
		key: "carrierJobETAs",
		icon: "clock",
		url: ApplicationPages.carrierJobETAs.url(),
		page_role_access_permissions: {
			"transporter_carrier_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "carrierJobETAs", legacy_permission: "pv2_transporter_carrier_home_driver_etas" }
		},
		nav_badges: {}
	},
	carrierTicketsIndex: {
		name: s_TICKETS,
		key: "carrierTicketsIndex",
		icon: "receipt",
		url: ApplicationPages.carrierTicketsIndex.url(),
		page_role_access_permissions: {
			"transporter_carrier_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "carrierTicketsIndex", legacy_permission: "pv2_transporter_carrier_home_tickets" }
		},
		nav_badges: {}
	},
	carrierDriversIndex: {
		name: s_DRIVERS,
		key: "carrierDriversIndex",
		icon: "driver3",
		url: ApplicationPages.carrierDriversIndex.url(),
		page_role_access_permissions: {
			"transporter_carrier_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "carrierDriversIndex", legacy_permission: "pv2_transporter_carrier_home_drivers" }
		},
		nav_badges: {}
	},
	carrierTrailersIndex: {
		name: s_TRAILERS,
		key: "carrierTrailersIndex",
		icon: "trailer",
		url: ApplicationPages.carrierTrailersIndex.url(),
		page_role_access_permissions: {
			"transporter_carrier_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "carrierTrailersIndex", legacy_permission: "pv2_transporter_carrier_home_trailers" }
		},
		nav_badges: {}
	},
	carrierTrucksIndex: {
		name: s_TRUCKS,
		key: "carrierTrucksIndex",
		icon: "truck",
		url: ApplicationPages.carrierTrucksIndex.url(),
		page_role_access_permissions: {
			"transporter_carrier_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "carrierTrucksIndex", legacy_permission: "pv2_transporter_carrier_home_trucks" }
		},
		nav_badges: {}
	},
	carrierDownloadsIndex: {
		name: s_DOWNLOADS,
		key: "carrierDownloadsIndex",
		icon: "download",
		url: ApplicationPages.carrierDownloadsIndex.url(),
		page_role_access_permissions: {
			"transporter_carrier_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "carrierDownloadsIndex", legacy_permission: "pv2_transporter_carrier_home_downloads" }
		},
		nav_badges: {}
	},
	// Transporter - Producer - Home
	homeDeprecated: {
		name: s_HOME,
		key: "homeDeprecated",
		icon: "home",
		url: ApplicationPages.homeDeprecated.url(),
		page_role_access_permissions: {
			"transporter_producer_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "homeDeprecated", legacy_permission: "pv2_transporter_producer_home_home" },
			"transporter_producer_pumper": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "homeDeprecated", legacy_permission: "pv2_transporter_producer_home_home" },
			"transporter_purchaser_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "homeDeprecated", legacy_permission: "pv2_transporter_purchaser_home_home" }
		},
		nav_badges: {}
	},
	producerPortalMapIndex: {
		name: s_MAP,
		key: "producerPortalMapIndex",
		icon: "map",
		url: ApplicationPages.producerPortalMapIndex.url(),
		page_role_access_permissions: {
			"transporter_producer_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "producerPortalMapIndex", legacy_permission: "pv2_transporter_producer_home_map" },
			"transporter_producer_pumper": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "producerPortalMapIndex", legacy_permission: "pv2_transporter_producer_home_map" }
		},
		nav_badges: {}
	},
	producerPortalCalculatorIndex: {
		name: s_CALCULATOR,
		key: "producerPortalCalculatorIndex",
		icon: "calculator",
		url: ApplicationPages.producerPortalCalculatorIndex.url(),
		page_role_access_permissions: {
			"transporter_producer_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "producerPortalCalculatorIndex", legacy_permission: "pv2_transporter_producer_home_calculator" },
			"transporter_producer_pumper": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "producerPortalCalculatorIndex", legacy_permission: "pv2_transporter_producer_home_calculator" }
		},
		nav_badges: {}
	},
	producerPortalDownloadsIndex: {
		name: s_DOWNLOADS,
		key: "producerPortalDownloadsIndex",
		icon: "download",
		url: ApplicationPages.producerPortalDownloadsIndex.url(),
		page_role_access_permissions: {
			"transporter_producer_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "producerPortalDownloadsIndex", legacy_permission: "pv2_transporter_producer_home_downloads" },
			"transporter_producer_pumper": { access: "default_no", highlighted_nav_section: "home", highlighted_nav_page: "producerPortalDownloadsIndex", legacy_permission: "pv2_transporter_producer_home_downloads" }
		},
		nav_badges: {}
	},
	// Transporter - Purchaser - Home
	purchaserNominationsIndex: {
		name: s_NOMINATIONS,
		key: "purchaserNominationsIndex",
		icon: "tasks",
		url: ApplicationPages.purchaserNominationsIndex.url(),
		page_role_access_permissions: {
			"transporter_purchaser_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "purchaserNominationsIndex", legacy_permission: "pv2_transporter_purchaser_home_nominations" }
		},
		nav_badges: {}
	},
	purchaserRequestsIndex: {
		name: s_REQUESTS,
		key: "purchaserRequestsIndex",
		icon: "paper-plane",
		url: ApplicationPages.purchaserRequestsIndex.url(),
		page_role_access_permissions: {
			"transporter_purchaser_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "purchaserRequestsIndex", legacy_permission: "pv2_transporter_purchaser_home_requests" }
		},
		nav_badges: {}
	},
	purchaserJobETAs: {
		name: s_DRIVER_ETAS,
		key: "purchaserJobETAs",
		icon: "clock",
		url: ApplicationPages.purchaserJobETAs.url(),
		page_role_access_permissions: {
			"transporter_purchaser_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "purchaserJobETAs", legacy_permission: "pv2_transporter_purchaser_home_driver_etas" }
		},
		nav_badges: {}
	},
	purchaserMapIndex: {
		name: s_MAP,
		key: "purchaserMapIndex",
		icon: "map",
		url: ApplicationPages.purchaserMapIndex.url(),
		page_role_access_permissions: {
			"transporter_purchaser_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "purchaserMapIndex", legacy_permission: "pv2_transporter_purchaser_home_map" }
		},
		nav_badges: {}
	},
	purchaserTicketsIndex: {
		name: s_TICKETS,
		key: "purchaserTicketsIndex",
		icon: "receipt",
		url: ApplicationPages.purchaserTicketsIndex.url(),
		page_role_access_permissions: {
			"transporter_purchaser_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "purchaserTicketsIndex", legacy_permission: "pv2_transporter_purchaser_home_tickets" }
		},
		nav_badges: {}
	},
	purchaserBidAnalysisIndex: {
		name: s_BID_ANALYSIS,
		key: "purchaserBidAnalysisIndex",
		icon: "map-signs",
		url: ApplicationPages.purchaserBidAnalysisIndex.url(),
		page_role_access_permissions: {
			"transporter_purchaser_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "purchaserBidAnalysisIndex", legacy_permission: "pv2_transporter_purchaser_home_bid_analysis" }
		},
		nav_badges: {}
	},
	purchaserInvoiceAnalysisIndex: {
		name: s_INVOICE_ANALYSIS,
		key: "purchaserInvoiceAnalysisIndex",
		icon: "chart-bar",
		url: ApplicationPages.purchaserInvoiceAnalysisIndex.url(),
		page_role_access_permissions: {
			"transporter_purchaser_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "purchaserInvoiceAnalysisIndex", legacy_permission: "pv2_transporter_purchaser_home_invoice_analysis" }
		},
		nav_badges: {}
	},
	purchaserCalculatorIndex: {
		name: s_CALCULATOR,
		key: "purchaserCalculatorIndex",
		icon: "calculator",
		url: ApplicationPages.purchaserCalculatorIndex.url(),
		page_role_access_permissions: {
			"transporter_purchaser_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "purchaserCalculatorIndex", legacy_permission: "pv2_transporter_purchaser_home_calculator" }
		},
		nav_badges: {}
	},
	purchaserDownloadsIndex: {
		name: s_DOWNLOADS,
		key: "purchaserDownloadsIndex",
		icon: "download",
		url: ApplicationPages.purchaserDownloadsIndex.url(),
		page_role_access_permissions: {
			"transporter_purchaser_admin": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "purchaserDownloadsIndex", legacy_permission: "pv2_transporter_purchaser_home_downloads" }
		},
		nav_badges: {}
	},
	// Transporter - Stakeholder - Home
	analyticsIndex: {
		name: s_ANALYTICS,
		key: "analyticsIndex",
		icon: "chart-bar",
		url: ApplicationPages.analyticsIndex.url(),
		page_role_access_permissions: {
			"transporter_stakeholder": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "analyticsIndex", legacy_permission: "pv2_transporter_stakeholder_home_analytics" }
		},
		nav_badges: {}
	},
	// Billing - Full - Home
	superBillingIndex: {
		name: s_BILLING,
		key: "superBillingIndex",
		icon: "sack-dollar",
		url: ApplicationPages.superBillingIndex.url(),
		page_role_access_permissions: {
			"billing_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "superBillingIndex", legacy_permission: "pv2_billing_full_home_billing" }
		},
		nav_badges: {}
	},
	// Hub - Full - Home
	requestAllocationIndex: {
		name: s_REQUEST_ALLOCATION,
		key: "requestAllocationIndex",
		icon: "shuffle",
		url: ApplicationPages.requestAllocationIndex.url(),
		page_role_access_permissions: {
			"hub_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "requestAllocationIndex", legacy_permission: "pv2_hub_full_home_request_allocation" },
			"hub_dispatcher": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "requestAllocationIndex", legacy_permission: "pv2_hub_full_home_request_allocation" }
		},
		nav_badges: {}
	},

	// Marketer - Full - Home
	marketerCalculatorIndex: {
		name: s_CALCULATOR,
		key: "marketerCalculatorIndex",
		icon: "calculator",
		url: ApplicationPages.marketerCalculatorIndex.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "marketerCalculatorIndex", legacy_permission: "pv2_marketer_full_home_calculator" }
		},
		nav_badges: {}
	},

	// Producer - Full - Home
	producerCalculatorIndex: {
		name: s_CALCULATOR,
		key: "producerCalculatorIndex",
		icon: "calculator",
		url: ApplicationPages.producerCalculatorIndex.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "producerCalculatorIndex", legacy_permission: "pv2_producer_full_home_calculator" },
			"producer_producer_foreman": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "producerCalculatorIndex", legacy_permission: "pv2_producer_full_home_calculator" },
			"producer_pumper": { access: "default_yes", highlighted_nav_section: "home", highlighted_nav_page: "producerCalculatorIndex", legacy_permission: "pv2_producer_full_home_calculator" }
		},
		nav_badges: {}
	},

	//////////////////////////////////////
	// Dispatch
	//////////////////////////////////////

	// Transporter - Full - Dispatch
	dispatchIndex: {
		name: s_DISPATCH,
		key: "dispatchIndex",
		icon: "th",
		url: ApplicationPages.dispatchIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch" },
			"transporter_dispatcher": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch" }
		},
		nav_badges: {}
	},
	jobCreateAndAssign: {
		name: s_QUICK_JOB_ASSIGN,
		key: "jobCreateAndAssign",
		icon: "paper-plane",
		url: ApplicationPages.jobCreateAndAssign.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "jobCreateAndAssign", legacy_permission: "pv2_transporter_full_dispatch_job_create_and_assign" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "jobCreateAndAssign", legacy_permission: "pv2_transporter_full_dispatch_job_create_and_assign" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "jobCreateAndAssign", legacy_permission: "pv2_transporter_full_dispatch_job_create_and_assign" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "jobCreateAndAssign", legacy_permission: "pv2_transporter_full_dispatch_job_create_and_assign" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "jobCreateAndAssign", legacy_permission: "pv2_transporter_full_dispatch_job_create_and_assign" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "jobCreateAndAssign", legacy_permission: "pv2_transporter_full_dispatch_job_create_and_assign" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "jobCreateAndAssign", legacy_permission: "pv2_transporter_full_dispatch_job_create_and_assign" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "jobCreateAndAssign", legacy_permission: "pv2_transporter_full_dispatch_job_create_and_assign" }
		},
		nav_badges: {}
	},
	loadRequests: {
		name: s_LOAD_REQUESTS,
		key: "loadRequests",
		icon: "mobile-screen-button",
		url: ApplicationPages.loadRequests.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "loadRequests", legacy_permission: "pv2_transporter_full_dispatch_load_requests" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "loadRequests", legacy_permission: "pv2_transporter_full_dispatch_load_requests" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "loadRequests", legacy_permission: "pv2_transporter_full_dispatch_load_requests" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "loadRequests", legacy_permission: "pv2_transporter_full_dispatch_load_requests" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "loadRequests", legacy_permission: "pv2_transporter_full_dispatch_load_requests" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "loadRequests", legacy_permission: "pv2_transporter_full_dispatch_load_requests" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "loadRequests", legacy_permission: "pv2_transporter_full_dispatch_load_requests" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "loadRequests", legacy_permission: "pv2_transporter_full_dispatch_load_requests" }
		},
		nav_badges: {}
	},
	scheduledRequests: {
		name: s_SCHEDULED_REQUESTS,
		key: "scheduledRequests",
		icon: "clock",
		url: ApplicationPages.scheduledRequests.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "scheduledRequests", legacy_permission: "pv2_transporter_full_dispatch_scheduled_requests" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "scheduledRequests", legacy_permission: "pv2_transporter_full_dispatch_scheduled_requests" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "scheduledRequests", legacy_permission: "pv2_transporter_full_dispatch_scheduled_requests" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "scheduledRequests", legacy_permission: "pv2_transporter_full_dispatch_scheduled_requests" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "scheduledRequests", legacy_permission: "pv2_transporter_full_dispatch_scheduled_requests" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "scheduledRequests", legacy_permission: "pv2_transporter_full_dispatch_scheduled_requests" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "scheduledRequests", legacy_permission: "pv2_transporter_full_dispatch_scheduled_requests" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "scheduledRequests", legacy_permission: "pv2_transporter_full_dispatch_scheduled_requests" }
		},
		nav_badges: {}
	},
	ordersIndex: {
		name: s_ORDERS,
		key: "ordersIndex",
		icon: "notes",
		url: ApplicationPages.ordersIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "ordersIndex", legacy_permission: "pv2_transporter_full_dispatch_orders" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "ordersIndex", legacy_permission: "pv2_transporter_full_dispatch_orders" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "ordersIndex", legacy_permission: "pv2_transporter_full_dispatch_orders" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "ordersIndex", legacy_permission: "pv2_transporter_full_dispatch_orders" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "ordersIndex", legacy_permission: "pv2_transporter_full_dispatch_orders" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "ordersIndex", legacy_permission: "pv2_transporter_full_dispatch_orders" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "ordersIndex", legacy_permission: "pv2_transporter_full_dispatch_orders" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "ordersIndex", legacy_permission: "pv2_transporter_full_dispatch_orders" }
		},
		nav_badges: {}
	},
	dispatchDriversIndex: {
		name: s_DRIVER_RESOURCES,
		key: "dispatchDriversIndex",
		icon: "user-helmet-safety",
		url: ApplicationPages.dispatchDriversIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchDriversIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch_drivers" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchDriversIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch_drivers" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchDriversIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch_drivers" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchDriversIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch_drivers" },
			"transporter_field_admin": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchDriversIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch_drivers" },
			"transporter_field_supervisor": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchDriversIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch_drivers" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchDriversIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch_drivers" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchDriversIndex", legacy_permission: "pv2_transporter_full_dispatch_dispatch_drivers" }
		},
		nav_badges: {}
	},
	mapIndex: {
		name: s_MAPS,
		key: "mapIndex",
		icon: "map",
		url: ApplicationPages.mapIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "mapIndex", legacy_permission: "pv2_transporter_full_dispatch_map" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "mapIndex", legacy_permission: "pv2_transporter_full_dispatch_map" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "mapIndex", legacy_permission: "pv2_transporter_full_dispatch_map" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "mapIndex", legacy_permission: "pv2_transporter_full_dispatch_map" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "mapIndex", legacy_permission: "pv2_transporter_full_dispatch_map" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "mapIndex", legacy_permission: "pv2_transporter_full_dispatch_map" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "mapIndex", legacy_permission: "pv2_transporter_full_dispatch_map" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "mapIndex", legacy_permission: "pv2_transporter_full_dispatch_map" },
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "mapIndex", legacy_permission: "pv2_generic_planned_full_dispatch_map" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "mapIndex", legacy_permission: "pv2_generic_planned_full_dispatch_map" }
		},
		nav_badges: {}
	},
	messagesIndex: {
		name: s_MESSAGES,
		key: "messagesIndex",
		icon: "messages",
		url: ApplicationPages.messagesIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "messagesIndex", legacy_permission: "pv2_transporter_full_dispatch_messages" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "messagesIndex", legacy_permission: "pv2_transporter_full_dispatch_messages" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "messagesIndex", legacy_permission: "pv2_transporter_full_dispatch_messages" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "messagesIndex", legacy_permission: "pv2_transporter_full_dispatch_messages" },
			"transporter_field_admin": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "messagesIndex", legacy_permission: "pv2_transporter_full_dispatch_messages" },
			"transporter_field_supervisor": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "messagesIndex", legacy_permission: "pv2_transporter_full_dispatch_messages" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "messagesIndex", legacy_permission: "pv2_transporter_full_dispatch_messages" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "messagesIndex", legacy_permission: "pv2_transporter_full_dispatch_messages" },
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "messagesIndex", legacy_permission: "pv2_generic_planned_full_dispatch_messages" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "messagesIndex", legacy_permission: "pv2_generic_planned_full_dispatch_messages" },
		},
		nav_badges: {}
	},
	nominationsIndex: {
		name: s_NOMINATIONS,
		key: "nominationsIndex",
		icon: "tasks",
		url: ApplicationPages.nominationsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "nominationsIndex", legacy_permission: "pv2_transporter_full_dispatch_nominations" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "nominationsIndex", legacy_permission: "pv2_transporter_full_dispatch_nominations" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "nominationsIndex", legacy_permission: "pv2_transporter_full_dispatch_nominations" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "nominationsIndex", legacy_permission: "pv2_transporter_full_dispatch_nominations" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "nominationsIndex", legacy_permission: "pv2_transporter_full_dispatch_nominations" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "nominationsIndex", legacy_permission: "pv2_transporter_full_dispatch_nominations" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "nominationsIndex", legacy_permission: "pv2_transporter_full_dispatch_nominations" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "nominationsIndex", legacy_permission: "pv2_transporter_full_dispatch_nominations" }
		},
		nav_badges: {}
	},
	inventoryTrackingIndex: {
		name: s_INVENTORY,
		key: "inventoryTrackingIndex",
		icon: "industry-windows",
		url: ApplicationPages.inventoryTrackingIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "inventoryTrackingIndex", legacy_permission: "pv2_transporter_full_dispatch_inventory" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "inventoryTrackingIndex", legacy_permission: "pv2_transporter_full_dispatch_inventory" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "inventoryTrackingIndex", legacy_permission: "pv2_transporter_full_dispatch_inventory" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "inventoryTrackingIndex", legacy_permission: "pv2_transporter_full_dispatch_inventory" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "inventoryTrackingIndex", legacy_permission: "pv2_transporter_full_dispatch_inventory" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "inventoryTrackingIndex", legacy_permission: "pv2_transporter_full_dispatch_inventory" },
			"transporter_financial_analyst": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "inventoryTrackingIndex", legacy_permission: "pv2_transporter_full_dispatch_inventory" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "inventoryTrackingIndex", legacy_permission: "pv2_transporter_full_dispatch_inventory" },
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "inventoryTrackingIndex", legacy_permission: "pv2_marketer_full_operations_inventory" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "inventoryTrackingIndex", legacy_permission: "pv2_producer_full_operations_inventory" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "operations", highlighted_nav_page: "inventoryTrackingIndex", legacy_permission: "pv2_producer_full_operations_inventory" },
			"producer_pumper": { access: "default_no", highlighted_nav_section: "operations", highlighted_nav_page: "inventoryTrackingIndex", legacy_permission: "pv2_producer_full_operations_inventory" },
		},
		nav_badges: {}
	},
	thirdPartyJobsIndex: {
		name: s_THIRD_PARTY_JOBS,
		key: "thirdPartyJobsIndex",
		icon: "fax",
		url: ApplicationPages.thirdPartyJobsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "thirdPartyJobsIndex", legacy_permission: "pv2_transporter_full_dispatch_third_party_jobs" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "thirdPartyJobsIndex", legacy_permission: "pv2_transporter_full_dispatch_third_party_jobs" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "thirdPartyJobsIndex", legacy_permission: "pv2_transporter_full_dispatch_third_party_jobs" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "thirdPartyJobsIndex", legacy_permission: "pv2_transporter_full_dispatch_third_party_jobs" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "thirdPartyJobsIndex", legacy_permission: "pv2_transporter_full_dispatch_third_party_jobs" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "thirdPartyJobsIndex", legacy_permission: "pv2_transporter_full_dispatch_third_party_jobs" },
			"transporter_financial_analyst": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "thirdPartyJobsIndex", legacy_permission: "pv2_transporter_full_dispatch_third_party_jobs" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "thirdPartyJobsIndex", legacy_permission: "pv2_transporter_full_dispatch_third_party_jobs" }
		},
		nav_badges: {}
	},
	operationsManagementIndex: {
		name: s_OPERATIONS_MGMT,
		key: "operationsManagementIndex",
		icon: "gauge",
		url: ApplicationPages.operationsManagementIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "operationsManagementIndex", legacy_permission: "pv2_transporter_full_dispatch_operations_management" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "operationsManagementIndex", legacy_permission: "pv2_transporter_full_dispatch_operations_management" },
			"transporter_compliance_analyst": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "operationsManagementIndex", legacy_permission: "pv2_transporter_full_dispatch_operations_management" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "operationsManagementIndex", legacy_permission: "pv2_transporter_full_dispatch_operations_management" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "operationsManagementIndex", legacy_permission: "pv2_transporter_full_dispatch_operations_management" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "operationsManagementIndex", legacy_permission: "pv2_transporter_full_dispatch_operations_management" },
			"transporter_financial_analyst": { access: "default_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "operationsManagementIndex", legacy_permission: "pv2_transporter_full_dispatch_operations_management" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "dispatch", highlighted_nav_page: "operationsManagementIndex", legacy_permission: "pv2_transporter_full_dispatch_operations_management" }
		},
		nav_badges: {}
	},
	// Generic Planned - Full - Dispatch
	dispatchIndexPlannedRoutes: {
		name: s_DISPATCH,
		key: "dispatchIndexPlannedRoutes",
		icon: "th",
		url: ApplicationPages.dispatchIndexPlannedRoutes.url(),
		page_role_access_permissions: {
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchIndexPlannedRoutes", legacy_permission: "pv2_generic_planned_full_dispatch_dispatch" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "dispatchIndexPlannedRoutes", legacy_permission: "pv2_generic_planned_full_dispatch_dispatch" }
		},
		nav_badges: {}
	},
	routePlanningIndex: {
		name: s_ROUTE_PLANNING,
		key: "routePlanningIndex",
		icon: "route",
		url: ApplicationPages.routePlanningIndex.url(),
		page_role_access_permissions: {
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "routePlanningIndex", legacy_permission: "pv2_generic_planned_full_dispatch_route_planning" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "dispatch", highlighted_nav_page: "routePlanningIndex", legacy_permission: "pv2_generic_planned_full_dispatch_route_planning" }
		},
		nav_badges: {}
	},

	// Marketer - Full - Operations
	marketerNewRequest: {
		name: s_NEW_LOAD_REQUEST,
		key: "marketerNewRequest",
		icon: "paper-plane",
		url: ApplicationPages.marketerNewRequest.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "marketerNewRequest", legacy_permission: "pv2_marketer_full_operations_new_load_request" }
		},
		nav_badges: {}
	},
	partnerScheduledRequests: {
		name: s_SCHEDULED_REQUESTS,
		key: "partnerScheduledRequests",
		icon: "clock",
		url: ApplicationPages.partnerScheduledRequests.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "partnerScheduledRequests", legacy_permission: "pv2_marketer_full_operations_scheduled_requests" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "partnerScheduledRequests", legacy_permission: "pv2_producer_full_operations_scheduled_requests" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "operations", highlighted_nav_page: "partnerScheduledRequests", legacy_permission: "pv2_producer_full_operations_scheduled_requests" },
			"producer_pumper": { access: "default_no", highlighted_nav_section: "operations", highlighted_nav_page: "partnerScheduledRequests", legacy_permission: "pv2_producer_full_operations_scheduled_requests" }
		},
		nav_badges: {}
	},
	partnerMapIndexDemo: {
		name: s_ALL_DRIVERS_MAP,
		key: "partnerMapIndexDemo",
		icon: "map-location-dot",
		url: ApplicationPages.partnerMapIndexDemo.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "partnerMapIndexDemo", legacy_permission: "pv2_marketer_full_operations_driver_map_v2" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "partnerMapIndexDemo", legacy_permission: "pv2_producer_full_operations_driver_map_v2" },
			"producer_producer_foreman": { access: "default_yes", highlighted_nav_section: "operations", highlighted_nav_page: "partnerMapIndexDemo", legacy_permission: "pv2_producer_full_operations_driver_map_v2" },
			"producer_pumper": { access: "default_yes", highlighted_nav_section: "operations", highlighted_nav_page: "partnerMapIndexDemo", legacy_permission: "pv2_producer_full_operations_driver_map_v2" }
		},
		nav_badges: {}
	},
	marketerMapIndex: {
		name: s_ACTIVE_DRIVER_MAP,
		key: "marketerMapIndex",
		icon: "map",
		url: ApplicationPages.marketerMapIndex.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "marketerMapIndex", legacy_permission: "pv2_marketer_full_operations_driver_map" }
		},
		nav_badges: {}
	},
	marketerLoadMonitoring: {
		name: s_LOAD_MONITORING,
		key: "marketerLoadMonitoring",
		icon: "crosshairs-simple",
		url: ApplicationPages.marketerLoadMonitoring.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "marketerLoadMonitoring", legacy_permission: "pv2_marketer_full_operations_load_monitoring" }
		},
		nav_badges: {}
	},
	marketerJobETAs: {
		name: s_DRIVER_ETAS,
		key: "marketerJobETAs",
		icon: "timer",
		url: ApplicationPages.marketerJobETAs.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "marketerJobETAs", legacy_permission: "pv2_marketer_full_operations_driver_etas" }
		},
		nav_badges: {}
	},
	partnerCarrierScorecardsDemo: {
		name: s_SCORECARDS,
		key: "partnerCarrierScorecardsDemo",
		icon: "ranking-star",
		url: ApplicationPages.partnerCarrierScorecardsDemo.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "partnerCarrierScorecardsDemo", legacy_permission: "pv2_marketer_full_operations_carrier_scorecards" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "partnerCarrierScorecardsDemo", legacy_permission: "pv2_producer_full_operations_carrier_scorecards" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "operations", highlighted_nav_page: "partnerCarrierScorecardsDemo", legacy_permission: "pv2_producer_full_operations_carrier_scorecards" },
			"producer_pumper": { access: "default_no", highlighted_nav_section: "operations", highlighted_nav_page: "partnerCarrierScorecardsDemo", legacy_permission: "pv2_producer_full_operations_carrier_scorecards" }
		},
		nav_badges: {}
	},
	marketerNominationsIndex: {
		name: s_NOMINATIONS,
		key: "marketerNominationsIndex",
		icon: "tasks",
		url: ApplicationPages.marketerNominationsIndex.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "marketerNominationsIndex", legacy_permission: "pv2_marketer_full_operations_nominations" }
		},
		nav_badges: {}
	},
	partnerHotShotDemo: {
		name: s_HOT_SHOT_BETA,
		key: "partnerHotShotDemo",
		icon: "bolt",
		url: ApplicationPages.partnerHotShotDemo.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "partnerHotShotDemo", legacy_permission: "pv2_marketer_full_operations_hot_shot" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "partnerHotShotDemo", legacy_permission: "pv2_producer_full_operations_hot_shot" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "operations", highlighted_nav_page: "partnerHotShotDemo", legacy_permission: "pv2_producer_full_operations_hot_shot" },
			"producer_pumper": { access: "default_no", highlighted_nav_section: "operations", highlighted_nav_page: "partnerHotShotDemo", legacy_permission: "pv2_producer_full_operations_hot_shot" }
		},
		nav_badges: {}
	},

	// Producer - Full - Operations
	producerNewRequest: {
		name: s_NEW_LOAD_REQUEST,
		key: "producerNewRequest",
		icon: "paper-plane",
		url: ApplicationPages.producerNewRequest.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerNewRequest", legacy_permission: "pv2_producer_full_operations_new_load_request" },
			"producer_producer_foreman": { access: "default_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerNewRequest", legacy_permission: "pv2_producer_full_operations_new_load_request" },
			"producer_pumper": { access: "default_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerNewRequest", legacy_permission: "pv2_producer_full_operations_new_load_request" }
		},
		nav_badges: {}
	},
	producerMapIndex: {
		name: s_ACTIVE_DRIVER_MAP,
		key: "producerMapIndex",
		icon: "map",
		url: ApplicationPages.producerMapIndex.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerMapIndex", legacy_permission: "pv2_producer_full_operations_driver_map" },
			"producer_producer_foreman": { access: "default_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerMapIndex", legacy_permission: "pv2_producer_full_operations_driver_map" },
			"producer_pumper": { access: "default_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerMapIndex", legacy_permission: "pv2_producer_full_operations_driver_map" }
		},
		nav_badges: {}
	},
	producerMapUpstreamIndex: {
		name: s_ACTIVE_DRIVER_MAP,
		key: "producerMapUpstreamIndex",
		icon: "map",
		url: ApplicationPages.producerMapUpstreamIndex.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerMapUpstreamIndex", legacy_permission: "pv2_producer_full_operations_driver_map_upstream" },
			"producer_producer_foreman": { access: "default_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerMapUpstreamIndex", legacy_permission: "pv2_producer_full_operations_driver_map_upstream" },
			"producer_pumper": { access: "default_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerMapUpstreamIndex", legacy_permission: "pv2_producer_full_operations_driver_map_upstream" }
		},
		nav_badges: {}
	},
	producerLoadMonitoring: {
		name: s_LOAD_MONITORING,
		key: "producerLoadMonitoring",
		icon: "crosshairs-simple",
		url: ApplicationPages.producerLoadMonitoring.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerLoadMonitoring", legacy_permission: "pv2_producer_full_operations_load_monitoring" },
			"producer_producer_foreman": { access: "default_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerLoadMonitoring", legacy_permission: "pv2_producer_full_operations_load_monitoring" },
			"producer_pumper": { access: "default_no", highlighted_nav_section: "operations", highlighted_nav_page: "producerLoadMonitoring", legacy_permission: "pv2_producer_full_operations_load_monitoring" }
		},
		nav_badges: {}
	},
	producerJobETAs: {
		name: s_DRIVER_ETAS,
		key: "producerJobETAs",
		icon: "timer",
		url: ApplicationPages.producerJobETAs.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerJobETAs", legacy_permission: "pv2_producer_full_operations_driver_etas" },
			"producer_producer_foreman": { access: "default_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerJobETAs", legacy_permission: "pv2_producer_full_operations_driver_etas" },
			"producer_pumper": { access: "default_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerJobETAs", legacy_permission: "pv2_producer_full_operations_driver_etas" }
		},
		nav_badges: {}
	},
	producerJobUpstreamETAs: {
		name: s_DRIVER_ETAS,
		key: "producerJobUpstreamETAs",
		icon: "clock",
		url: ApplicationPages.producerJobUpstreamETAs.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerJobUpstreamETAs", legacy_permission: "pv2_producer_full_operations_driver_etas_upstream" },
			"producer_producer_foreman": { access: "default_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerJobUpstreamETAs", legacy_permission: "pv2_producer_full_operations_driver_etas_upstream" },
			"producer_pumper": { access: "default_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerJobUpstreamETAs", legacy_permission: "pv2_producer_full_operations_driver_etas_upstream" }
		},
		nav_badges: {}
	},
	producerNominationsIndex: {
		name: s_NOMINATIONS,
		key: "producerNominationsIndex",
		icon: "tasks",
		url: ApplicationPages.producerNominationsIndex.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "operations", highlighted_nav_page: "producerNominationsIndex", legacy_permission: "pv2_producer_full_operations_nominations" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "operations", highlighted_nav_page: "producerNominationsIndex", legacy_permission: "pv2_producer_full_operations_nominations" },
			"producer_pumper": { access: "default_no", highlighted_nav_section: "operations", highlighted_nav_page: "producerNominationsIndex", legacy_permission: "pv2_producer_full_operations_nominations" }
		},
		nav_badges: {}
	},

	//////////////////////////////////////
	// Compliance
	//////////////////////////////////////

	// Transporter - Full - Compliance
	basicAccountEmployees: {
		name: s_DRIVER_ONBOARDING,
		key: "basicAccountEmployees",
		icon: "traffic-light",
		url: ApplicationPages.basicAccountEmployees.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "basicAccountEmployees", legacy_permission: "pv2_transporter_full_compliance_employees_basic_upgrade" },
			"transporter_api_access": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "basicAccountEmployees", legacy_permission: "pv2_transporter_full_compliance_employees_basic_upgrade" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "basicAccountEmployees", legacy_permission: "pv2_transporter_full_compliance_employees_basic_upgrade" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "basicAccountEmployees", legacy_permission: "pv2_transporter_full_compliance_employees_basic_upgrade" },
			"transporter_field_admin": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "basicAccountEmployees", legacy_permission: "pv2_transporter_full_compliance_employees_basic_upgrade" },
			"transporter_field_supervisor": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "basicAccountEmployees", legacy_permission: "pv2_transporter_full_compliance_employees_basic_upgrade" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "basicAccountEmployees", legacy_permission: "pv2_transporter_full_compliance_employees_basic_upgrade" },
			"transporter_sales": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "basicAccountEmployees", legacy_permission: "pv2_transporter_full_compliance_employees_basic_upgrade" }
		},
		nav_badges: {}
	},
	onboardingIndex: {
		name: s_ONBOARDING,
		key: "onboardingIndex",
		icon: "traffic-light",
		url: ApplicationPages.onboardingIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding" }
		},
		nav_badges: {}
	},
	onboardingBasicIndex: {
		name: s_DRIVER_ONBOARDING,
		key: "onboardingBasicIndex",
		icon: "traffic-light",
		url: ApplicationPages.onboardingBasicIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingBasicIndex", legacy_permission: "pv2_transporter_full_compliance_basic_onboarding" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingBasicIndex", legacy_permission: "pv2_transporter_full_compliance_basic_onboarding" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingBasicIndex", legacy_permission: "pv2_transporter_full_compliance_basic_onboarding" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingBasicIndex", legacy_permission: "pv2_transporter_full_compliance_basic_onboarding" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingBasicIndex", legacy_permission: "pv2_transporter_full_compliance_basic_onboarding" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingBasicIndex", legacy_permission: "pv2_transporter_full_compliance_basic_onboarding" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingBasicIndex", legacy_permission: "pv2_transporter_full_compliance_basic_onboarding" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingBasicIndex", legacy_permission: "pv2_transporter_full_compliance_basic_onboarding" }
		},
		nav_badges: {}
	},
	complianceIndex: {
		name: s_COMPLIANCE,
		key: "complianceIndex",
		icon: "square-check",
		url: ApplicationPages.complianceIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceIndex", legacy_permission: "pv2_transporter_full_compliance_compliance" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceIndex", legacy_permission: "pv2_transporter_full_compliance_compliance" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceIndex", legacy_permission: "pv2_transporter_full_compliance_compliance" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceIndex", legacy_permission: "pv2_transporter_full_compliance_compliance" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceIndex", legacy_permission: "pv2_transporter_full_compliance_compliance" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceIndex", legacy_permission: "pv2_transporter_full_compliance_compliance" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceIndex", legacy_permission: "pv2_transporter_full_compliance_compliance" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceIndex", legacy_permission: "pv2_transporter_full_compliance_compliance" }
		},
		nav_badges: {}
	},
	supervisorsIndex: {
		name: s_SUPERVISORS,
		key: "supervisorsIndex",
		icon: "helmet-safety",
		url: ApplicationPages.supervisorsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "supervisorsIndex", legacy_permission: "pv2_transporter_full_compliance_supervisors" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "supervisorsIndex", legacy_permission: "pv2_transporter_full_compliance_supervisors" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "supervisorsIndex", legacy_permission: "pv2_transporter_full_compliance_supervisors" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "supervisorsIndex", legacy_permission: "pv2_transporter_full_compliance_supervisors" },
			"transporter_field_admin": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "supervisorsIndex", legacy_permission: "pv2_transporter_full_compliance_supervisors" },
			"transporter_field_supervisor": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "supervisorsIndex", legacy_permission: "pv2_transporter_full_compliance_supervisors" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "supervisorsIndex", legacy_permission: "pv2_transporter_full_compliance_supervisors" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "supervisorsIndex", legacy_permission: "pv2_transporter_full_compliance_supervisors" }
		},
		nav_badges: {}
	},
	onboardingMessagesIndex: {
		name: s_MESSAGES,
		key: "onboardingMessagesIndex",
		icon: "messages",
		url: ApplicationPages.onboardingMessagesIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingMessagesIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding_messages" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingMessagesIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding_messages" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingMessagesIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding_messages" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingMessagesIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding_messages" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingMessagesIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding_messages" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingMessagesIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding_messages" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingMessagesIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding_messages" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "onboardingMessagesIndex", legacy_permission: "pv2_transporter_full_compliance_onboarding_messages" }
		},
		nav_badges: {}
	},
	complianceEquipmentIndex: {
		name: s_EQUIPMENT,
		key: "complianceEquipmentIndex",
		icon: "truck",
		url: ApplicationPages.complianceEquipmentIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceEquipmentIndex", legacy_permission: "pv2_transporter_full_compliance_compliance_equipment" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceEquipmentIndex", legacy_permission: "pv2_transporter_full_compliance_compliance_equipment" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceEquipmentIndex", legacy_permission: "pv2_transporter_full_compliance_compliance_equipment" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceEquipmentIndex", legacy_permission: "pv2_transporter_full_compliance_compliance_equipment" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceEquipmentIndex", legacy_permission: "pv2_transporter_full_compliance_compliance_equipment" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceEquipmentIndex", legacy_permission: "pv2_transporter_full_compliance_compliance_equipment" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceEquipmentIndex", legacy_permission: "pv2_transporter_full_compliance_compliance_equipment" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "complianceEquipmentIndex", legacy_permission: "pv2_transporter_full_compliance_compliance_equipment" }
		},
		nav_badges: {}
	},
	logbookMileageLogs: {
		name: s_LOG_BOOKS,
		key: "logbookMileageLogs",
		icon: "book",
		url: ApplicationPages.logbookMileageLogs.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "logbookMileageLogs", legacy_permission: "pv2_transporter_full_compliance_logbook" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "logbookMileageLogs", legacy_permission: "pv2_transporter_full_compliance_logbook" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "logbookMileageLogs", legacy_permission: "pv2_transporter_full_compliance_logbook" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "logbookMileageLogs", legacy_permission: "pv2_transporter_full_compliance_logbook" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "logbookMileageLogs", legacy_permission: "pv2_transporter_full_compliance_logbook" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "logbookMileageLogs", legacy_permission: "pv2_transporter_full_compliance_logbook" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "logbookMileageLogs", legacy_permission: "pv2_transporter_full_compliance_logbook" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "logbookMileageLogs", legacy_permission: "pv2_transporter_full_compliance_logbook" }
		},
		nav_badges: {}
	},
	siteReferrals: {
		name: s_SITE_REFERRALS,
		key: "siteReferrals",
		icon: "users",
		url: ApplicationPages.siteReferrals.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "siteReferrals", legacy_permission: "pv2_transporter_full_compliance_site_referrals" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "siteReferrals", legacy_permission: "pv2_transporter_full_compliance_site_referrals" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "siteReferrals", legacy_permission: "pv2_transporter_full_compliance_site_referrals" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "siteReferrals", legacy_permission: "pv2_transporter_full_compliance_site_referrals" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "siteReferrals", legacy_permission: "pv2_transporter_full_compliance_site_referrals" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "siteReferrals", legacy_permission: "pv2_transporter_full_compliance_site_referrals" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "siteReferrals", legacy_permission: "pv2_transporter_full_compliance_site_referrals" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "compliance", highlighted_nav_page: "siteReferrals", legacy_permission: "pv2_transporter_full_compliance_site_referrals" }
		},
		nav_badges: {}
	},
	timecardsIndex: {
		name: s_TIMECARDS,
		key: "timecardsIndex",
		icon: "clock",
		url: ApplicationPages.timecardsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "timecardsIndex", legacy_permission: "pv2_transporter_full_compliance_timecards" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "timecardsIndex", legacy_permission: "pv2_transporter_full_compliance_timecards" },
			"transporter_compliance_analyst": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "timecardsIndex", legacy_permission: "pv2_transporter_full_compliance_timecards" },
			"transporter_dispatcher": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "timecardsIndex", legacy_permission: "pv2_transporter_full_compliance_timecards" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "timecardsIndex", legacy_permission: "pv2_transporter_full_compliance_timecards" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "timecardsIndex", legacy_permission: "pv2_transporter_full_compliance_timecards" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "compliance", highlighted_nav_page: "timecardsIndex", legacy_permission: "pv2_transporter_full_compliance_timecards" },
			"transporter_sales": { access: "default_no", highlighted_nav_section: "compliance", highlighted_nav_page: "timecardsIndex", legacy_permission: "pv2_transporter_full_compliance_timecards" }
		},
		nav_badges: {}
	},

	//////////////////////////////////////
	// Accounting
	//////////////////////////////////////

	// Transporter - Full - Accounting
	basicAccountAccounting: {
		name: s_ACCOUNTING,
		key: "basicAccountAccounting",
		icon: "briefcase",
		url: ApplicationPages.basicAccountAccounting.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "basicAccountAccounting", legacy_permission: "pv2_transporter_full_accounting_accounting_basic_upgrade" },
			"transporter_api_access": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "basicAccountAccounting", legacy_permission: "pv2_transporter_full_accounting_accounting_basic_upgrade" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "basicAccountAccounting", legacy_permission: "pv2_transporter_full_accounting_accounting_basic_upgrade" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "basicAccountAccounting", legacy_permission: "pv2_transporter_full_accounting_accounting_basic_upgrade" },
			"transporter_field_admin": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "basicAccountAccounting", legacy_permission: "pv2_transporter_full_accounting_accounting_basic_upgrade" },
			"transporter_field_supervisor": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "basicAccountAccounting", legacy_permission: "pv2_transporter_full_accounting_accounting_basic_upgrade" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "basicAccountAccounting", legacy_permission: "pv2_transporter_full_accounting_accounting_basic_upgrade" },
			"transporter_sales": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "basicAccountAccounting", legacy_permission: "pv2_transporter_full_accounting_accounting_basic_upgrade" }
		},
		nav_badges: {}
	},
	accountingManagementIndex: {
		name: s_ACCOUNTING_MGMT,
		key: "accountingManagementIndex",
		icon: "briefcase",
		url: ApplicationPages.accountingManagementIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingManagementIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_management" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingManagementIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_management" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingManagementIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_management" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingManagementIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_management" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingManagementIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_management" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingManagementIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_management" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingManagementIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_management" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingManagementIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_management" }
		},
		nav_badges: {}
	},
	invoicesIndex: {
		name: s_INVOICES,
		key: "invoicesIndex",
		icon: "money-check-dollar",
		url: ApplicationPages.invoicesIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "invoicesIndex", legacy_permission: "pv2_transporter_full_accounting_invoices" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "accounting", highlighted_nav_page: "invoicesIndex", legacy_permission: "pv2_transporter_full_accounting_invoices" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "invoicesIndex", legacy_permission: "pv2_transporter_full_accounting_invoices" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "invoicesIndex", legacy_permission: "pv2_transporter_full_accounting_invoices" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "invoicesIndex", legacy_permission: "pv2_transporter_full_accounting_invoices" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "invoicesIndex", legacy_permission: "pv2_transporter_full_accounting_invoices" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "invoicesIndex", legacy_permission: "pv2_transporter_full_accounting_invoices" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "invoicesIndex", legacy_permission: "pv2_transporter_full_accounting_invoices" }
		},
		nav_badges: {}
	},
	settlementsIndex: {
		name: s_SETTLEMENTS,
		key: "settlementsIndex",
		icon: "credit-card",
		url: ApplicationPages.settlementsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "settlementsIndex", legacy_permission: "pv2_transporter_full_accounting_settlements" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "accounting", highlighted_nav_page: "settlementsIndex", legacy_permission: "pv2_transporter_full_accounting_settlements" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "settlementsIndex", legacy_permission: "pv2_transporter_full_accounting_settlements" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "settlementsIndex", legacy_permission: "pv2_transporter_full_accounting_settlements" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "settlementsIndex", legacy_permission: "pv2_transporter_full_accounting_settlements" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "settlementsIndex", legacy_permission: "pv2_transporter_full_accounting_settlements" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "settlementsIndex", legacy_permission: "pv2_transporter_full_accounting_settlements" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "settlementsIndex", legacy_permission: "pv2_transporter_full_accounting_settlements" }
		},
		nav_badges: {}
	},
	accountingAnalysisIndex: {
		name: s_ACCOUNTING_ANALYSIS,
		key: "accountingAnalysisIndex",
		icon: "arrow-trend-up",
		url: ApplicationPages.accountingAnalysisIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingAnalysisIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_analysis" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingAnalysisIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_analysis" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingAnalysisIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_analysis" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingAnalysisIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_analysis" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingAnalysisIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_analysis" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingAnalysisIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_analysis" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingAnalysisIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_analysis" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "accountingAnalysisIndex", legacy_permission: "pv2_transporter_full_accounting_accounting_analysis" }
		},
		nav_badges: {}
	},
	rateTablesIndex: {
		name: s_RATE_TABLES,
		key: "rateTablesIndex",
		icon: "book",
		url: ApplicationPages.rateTablesIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "rateTablesIndex", legacy_permission: "pv2_transporter_full_accounting_rate_tables" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "accounting", highlighted_nav_page: "rateTablesIndex", legacy_permission: "pv2_transporter_full_accounting_rate_tables" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "rateTablesIndex", legacy_permission: "pv2_transporter_full_accounting_rate_tables" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "rateTablesIndex", legacy_permission: "pv2_transporter_full_accounting_rate_tables" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "rateTablesIndex", legacy_permission: "pv2_transporter_full_accounting_rate_tables" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "rateTablesIndex", legacy_permission: "pv2_transporter_full_accounting_rate_tables" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "rateTablesIndex", legacy_permission: "pv2_transporter_full_accounting_rate_tables" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "rateTablesIndex", legacy_permission: "pv2_transporter_full_accounting_rate_tables" }
		},
		nav_badges: {}
	},
	ticketSpreadsheetIndex: {
		name: s_TICKET_SPREADSHEET,
		key: "ticketSpreadsheetIndex",
		icon: "table",
		url: ApplicationPages.ticketSpreadsheetIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "ticketSpreadsheetIndex", legacy_permission: "pv2_transporter_full_accounting_ticket_spreadsheet" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "accounting", highlighted_nav_page: "ticketSpreadsheetIndex", legacy_permission: "pv2_transporter_full_accounting_ticket_spreadsheet" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "ticketSpreadsheetIndex", legacy_permission: "pv2_transporter_full_accounting_ticket_spreadsheet" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "ticketSpreadsheetIndex", legacy_permission: "pv2_transporter_full_accounting_ticket_spreadsheet" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "ticketSpreadsheetIndex", legacy_permission: "pv2_transporter_full_accounting_ticket_spreadsheet" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "ticketSpreadsheetIndex", legacy_permission: "pv2_transporter_full_accounting_ticket_spreadsheet" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "ticketSpreadsheetIndex", legacy_permission: "pv2_transporter_full_accounting_ticket_spreadsheet" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "ticketSpreadsheetIndex", legacy_permission: "pv2_transporter_full_accounting_ticket_spreadsheet" }
		},
		nav_badges: {}
	},
	rfpBidsIndex: {
		name: s_RFP_BIDS,
		key: "rfpBidsIndex",
		icon: "handshake-simple",
		url: ApplicationPages.rfpBidsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "rfpBidsIndex", legacy_permission: "pv2_transporter_full_accounting_rfp_bids" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "accounting", highlighted_nav_page: "rfpBidsIndex", legacy_permission: "pv2_transporter_full_accounting_rfp_bids" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "rfpBidsIndex", legacy_permission: "pv2_transporter_full_accounting_rfp_bids" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "rfpBidsIndex", legacy_permission: "pv2_transporter_full_accounting_rfp_bids" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "rfpBidsIndex", legacy_permission: "pv2_transporter_full_accounting_rfp_bids" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "rfpBidsIndex", legacy_permission: "pv2_transporter_full_accounting_rfp_bids" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "rfpBidsIndex", legacy_permission: "pv2_transporter_full_accounting_rfp_bids" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "rfpBidsIndex", legacy_permission: "pv2_transporter_full_accounting_rfp_bids" }
		},
		nav_badges: {}
	},
	transporterAllocationIndex: {
		name: s_PARTNER_ALLOCATION,
		key: "transporterAllocationIndex",
		icon: "square-share-nodes",
		url: ApplicationPages.transporterAllocationIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "accounting", highlighted_nav_page: "transporterAllocationIndex", legacy_permission: "pv2_transporter_full_accounting_allocation" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "accounting", highlighted_nav_page: "transporterAllocationIndex", legacy_permission: "pv2_transporter_full_accounting_allocation" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "transporterAllocationIndex", legacy_permission: "pv2_transporter_full_accounting_allocation" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "transporterAllocationIndex", legacy_permission: "pv2_transporter_full_accounting_allocation" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "transporterAllocationIndex", legacy_permission: "pv2_transporter_full_accounting_allocation" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "transporterAllocationIndex", legacy_permission: "pv2_transporter_full_accounting_allocation" },
			"transporter_financial_analyst": { access: "default_no", highlighted_nav_section: "accounting", highlighted_nav_page: "transporterAllocationIndex", legacy_permission: "pv2_transporter_full_accounting_allocation" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "accounting", highlighted_nav_page: "transporterAllocationIndex", legacy_permission: "pv2_transporter_full_accounting_allocation" }
		},
		nav_badges: {}
	},

	// Marketer - Full - Finance
	rfpIndex: {
		name: s_RFPS,
		key: "rfpIndex",
		icon: "messages-dollar",
		url: ApplicationPages.rfpIndex.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "finance", highlighted_nav_page: "rfpIndex", legacy_permission: "pv2_marketer_full_finance_rfps" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "finance", highlighted_nav_page: "rfpIndex", legacy_permission: "pv2_producer_full_finance_rfps" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "finance", highlighted_nav_page: "rfpIndex", legacy_permission: "pv2_producer_full_finance_rfps" },
			"producer_pumper": { access: "always_no", highlighted_nav_section: "finance", highlighted_nav_page: "rfpIndex", legacy_permission: "pv2_producer_full_finance_rfps" }
		},
		nav_badges: {}
	},
	partnerInvoicesIndex: {
		name: s_TRANSPORTER_INVOICES,
		key: "partnerInvoicesIndex",
		icon: "money-check-dollar",
		url: ApplicationPages.partnerInvoicesIndex.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "finance", highlighted_nav_page: "partnerInvoicesIndex", legacy_permission: "pv2_marketer_full_finance_invoices" }
		},
		nav_badges: {}
	},
	marketerBidAnalysisIndex: {
		name: s_BID_ANALYSIS,
		key: "marketerBidAnalysisIndex",
		icon: "map-signs",
		url: ApplicationPages.marketerBidAnalysisIndex.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "finance", highlighted_nav_page: "marketerBidAnalysisIndex", legacy_permission: "pv2_marketer_full_finance_bid_analysis" }
		},
		nav_badges: {}
	},
	marketerInvoiceAnalysisIndex: {
		name: s_COST_ANALYSIS,
		key: "marketerInvoiceAnalysisIndex",
		icon: "chart-bar",
		url: ApplicationPages.marketerInvoiceAnalysisIndex.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "finance", highlighted_nav_page: "marketerInvoiceAnalysisIndex", legacy_permission: "pv2_marketer_full_finance_cost_analysis" }
		},
		nav_badges: {}
	},

	// Producer - Full - Finance
	producerBidAnalysisIndex: {
		name: s_BID_ANALYSIS,
		key: "producerBidAnalysisIndex",
		icon: "map-signs",
		url: ApplicationPages.producerBidAnalysisIndex.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "finance", highlighted_nav_page: "producerBidAnalysisIndex", legacy_permission: "pv2_producer_full_finance_bid_analysis" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "finance", highlighted_nav_page: "producerBidAnalysisIndex", legacy_permission: "pv2_producer_full_finance_bid_analysis" },
			"producer_pumper": { access: "always_no", highlighted_nav_section: "finance", highlighted_nav_page: "producerBidAnalysisIndex", legacy_permission: "pv2_producer_full_finance_bid_analysis" }
		},
		nav_badges: {}
	},

	//////////////////////////////////////
	// Data
	//////////////////////////////////////

	// Transporter - Full - Data
	jobsIndex: {
		name: s_JOBS,
		key: "jobsIndex",
		icon: "paper-plane",
		url: ApplicationPages.jobsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "jobsIndex", legacy_permission: "pv2_transporter_full_data_jobs" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "jobsIndex", legacy_permission: "pv2_transporter_full_data_jobs" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "jobsIndex", legacy_permission: "pv2_transporter_full_data_jobs" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "jobsIndex", legacy_permission: "pv2_transporter_full_data_jobs" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "jobsIndex", legacy_permission: "pv2_transporter_full_data_jobs" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "jobsIndex", legacy_permission: "pv2_transporter_full_data_jobs" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "jobsIndex", legacy_permission: "pv2_transporter_full_data_jobs" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "jobsIndex", legacy_permission: "pv2_transporter_full_data_jobs" },
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "jobsIndex", legacy_permission: "pv2_generic_planned_full_data_jobs" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "jobsIndex", legacy_permission: "pv2_generic_planned_full_data_jobs" },
		},
		nav_badges: {}
	},
	ticketsIndex: {
		name: s_TICKETS,
		key: "ticketsIndex",
		icon: "receipt",
		url: ApplicationPages.ticketsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "ticketsIndex", legacy_permission: "pv2_transporter_full_data_tickets" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsIndex", legacy_permission: "pv2_transporter_full_data_tickets" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsIndex", legacy_permission: "pv2_transporter_full_data_tickets" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "ticketsIndex", legacy_permission: "pv2_transporter_full_data_tickets" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsIndex", legacy_permission: "pv2_transporter_full_data_tickets" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsIndex", legacy_permission: "pv2_transporter_full_data_tickets" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "ticketsIndex", legacy_permission: "pv2_transporter_full_data_tickets" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsIndex", legacy_permission: "pv2_transporter_full_data_tickets" },
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "ticketsIndex", legacy_permission: "pv2_generic_planned_full_data_tickets" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "ticketsIndex", legacy_permission: "pv2_generic_planned_full_data_tickets" },
		},
		nav_badges: {}
	},
	reportsIndex: {
		name: s_REPORTS,
		key: "reportsIndex",
		icon: "file-lines",
		url: ApplicationPages.reportsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "reportsIndex", legacy_permission: "pv2_transporter_full_data_reports" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "reportsIndex", legacy_permission: "pv2_transporter_full_data_reports" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "reportsIndex", legacy_permission: "pv2_transporter_full_data_reports" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "reportsIndex", legacy_permission: "pv2_transporter_full_data_reports" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "reportsIndex", legacy_permission: "pv2_transporter_full_data_reports" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "reportsIndex", legacy_permission: "pv2_transporter_full_data_reports" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "reportsIndex", legacy_permission: "pv2_transporter_full_data_reports" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "reportsIndex", legacy_permission: "pv2_transporter_full_data_reports" }
		},
		nav_badges: {}
	},
	dashboardAnalysisIndex: {
		name: s_DASHBOARDS,
		key: "dashboardAnalysisIndex",
		icon: "gauge",
		url: ApplicationPages.dashboardAnalysisIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "dashboardAnalysisIndex", legacy_permission: "pv2_transporter_full_data_dashboard_analysis" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "dashboardAnalysisIndex", legacy_permission: "pv2_transporter_full_data_dashboard_analysis" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "dashboardAnalysisIndex", legacy_permission: "pv2_transporter_full_data_dashboard_analysis" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "dashboardAnalysisIndex", legacy_permission: "pv2_transporter_full_data_dashboard_analysis" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "dashboardAnalysisIndex", legacy_permission: "pv2_transporter_full_data_dashboard_analysis" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "dashboardAnalysisIndex", legacy_permission: "pv2_transporter_full_data_dashboard_analysis" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "dashboardAnalysisIndex", legacy_permission: "pv2_transporter_full_data_dashboard_analysis" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "dashboardAnalysisIndex", legacy_permission: "pv2_transporter_full_data_dashboard_analysis" }
		},
		nav_badges: {}
	},
	ticketReviewIndex: {
		name: s_TICKET_REVIEW,
		key: "ticketReviewIndex",
		icon: "crosshairs-simple",
		url: ApplicationPages.ticketReviewIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "ticketReviewIndex", legacy_permission: "pv2_transporter_full_data_ticket_review" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketReviewIndex", legacy_permission: "pv2_transporter_full_data_ticket_review" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketReviewIndex", legacy_permission: "pv2_transporter_full_data_ticket_review" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "ticketReviewIndex", legacy_permission: "pv2_transporter_full_data_ticket_review" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketReviewIndex", legacy_permission: "pv2_transporter_full_data_ticket_review" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketReviewIndex", legacy_permission: "pv2_transporter_full_data_ticket_review" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "ticketReviewIndex", legacy_permission: "pv2_transporter_full_data_ticket_review" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketReviewIndex", legacy_permission: "pv2_transporter_full_data_ticket_review" }
		},
		nav_badges: {}
	},
	ticketsRecent: {
		name: s_RECENTS,
		key: "ticketsRecent",
		icon: "clock",
		url: ApplicationPages.ticketsRecent.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "ticketsRecent", legacy_permission: "pv2_transporter_full_data_recent_tickets" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsRecent", legacy_permission: "pv2_transporter_full_data_recent_tickets" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsRecent", legacy_permission: "pv2_transporter_full_data_recent_tickets" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "ticketsRecent", legacy_permission: "pv2_transporter_full_data_recent_tickets" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsRecent", legacy_permission: "pv2_transporter_full_data_recent_tickets" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsRecent", legacy_permission: "pv2_transporter_full_data_recent_tickets" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "ticketsRecent", legacy_permission: "pv2_transporter_full_data_recent_tickets" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsRecent", legacy_permission: "pv2_transporter_full_data_recent_tickets" }
		},
		nav_badges: {}
	},
	ticketsBulkUpdates: {
		name: s_BULK_UPDATES,
		key: "ticketsBulkUpdates",
		icon: "bolt",
		url: ApplicationPages.ticketsBulkUpdates.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "ticketsBulkUpdates", legacy_permission: "pv2_transporter_full_data_bulk_updates" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsBulkUpdates", legacy_permission: "pv2_transporter_full_data_bulk_updates" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsBulkUpdates", legacy_permission: "pv2_transporter_full_data_bulk_updates" },
			"transporter_dispatcher": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsBulkUpdates", legacy_permission: "pv2_transporter_full_data_bulk_updates" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsBulkUpdates", legacy_permission: "pv2_transporter_full_data_bulk_updates" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsBulkUpdates", legacy_permission: "pv2_transporter_full_data_bulk_updates" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "ticketsBulkUpdates", legacy_permission: "pv2_transporter_full_data_bulk_updates" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "ticketsBulkUpdates", legacy_permission: "pv2_transporter_full_data_bulk_updates" }
		},
		nav_badges: {}
	},
	stationLogV2Tickets: {
		name: s_SECURE_DELIVERY,
		key: "stationLogV2Tickets",
		icon: "lock",
		url: ApplicationPages.stationLogV2Tickets.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "stationLogV2Tickets", legacy_permission: "pv2_transporter_full_data_station_log_tickets" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "stationLogV2Tickets", legacy_permission: "pv2_transporter_full_data_station_log_tickets" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "stationLogV2Tickets", legacy_permission: "pv2_transporter_full_data_station_log_tickets" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "stationLogV2Tickets", legacy_permission: "pv2_transporter_full_data_station_log_tickets" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "stationLogV2Tickets", legacy_permission: "pv2_transporter_full_data_station_log_tickets" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "stationLogV2Tickets", legacy_permission: "pv2_transporter_full_data_station_log_tickets" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "stationLogV2Tickets", legacy_permission: "pv2_transporter_full_data_station_log_tickets" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "stationLogV2Tickets", legacy_permission: "pv2_transporter_full_data_station_log_tickets" },
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "stationLogV2Tickets", legacy_permission: "pv2_marketer_full_data_station_log_tickets" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "stationLogV2Tickets", legacy_permission: "pv2_producer_full_data_station_log_tickets" },
		},
		nav_badges: {}
	},

	// Generic Planned - Full - Data
	reportsGenericIndex: {
		name: s_REPORTS,
		key: "reportsGenericIndex",
		icon: "file-lines",
		url: ApplicationPages.reportsGenericIndex.url(),
		page_role_access_permissions: {
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "reportsGenericIndex", legacy_permission: "pv2_generic_planned_full_data_reports" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "data", highlighted_nav_page: "reportsGenericIndex", legacy_permission: "pv2_generic_planned_full_data_reports" }
		},
		nav_badges: {}
	},

	// Marketer - Full - Data
	marketerTicketsIndex: {
		name: s_TICKETS,
		key: "marketerTicketsIndex",
		icon: "receipt",
		url: ApplicationPages.marketerTicketsIndex.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "marketerTicketsIndex", legacy_permission: "pv2_marketer_full_data_tickets" }
		},
		nav_badges: {}
	},
	marketerDownloadsIndex: {
		name: s_DOWNLOADS,
		key: "marketerDownloadsIndex",
		icon: "download",
		url: ApplicationPages.marketerDownloadsIndex.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "marketerDownloadsIndex", legacy_permission: "pv2_marketer_full_data_downloads" }
		},
		nav_badges: {}
	},
	marketerReportTemplatesIndex: {
		name: s_REPORT_TEMPLATES,
		key: "marketerReportTemplatesIndex",
		icon: "file-lines",
		url: ApplicationPages.marketerReportTemplatesIndex.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "marketerReportTemplatesIndex", legacy_permission: "pv2_marketer_full_data_template_builder" }
		},
		nav_badges: {}
	},

	// Producer - Full - Data
	producerTicketsIndex: {
		name: s_TICKETS,
		key: "producerTicketsIndex",
		icon: "receipt",
		url: ApplicationPages.producerTicketsIndex.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "producerTicketsIndex", legacy_permission: "pv2_producer_full_data_tickets" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "producerTicketsIndex", legacy_permission: "pv2_producer_full_data_tickets" },
			"producer_pumper": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "producerTicketsIndex", legacy_permission: "pv2_producer_full_data_tickets" }
		},
		nav_badges: {}
	},
	producerTicketsUpstreamIndex: {
		name: s_TICKETS,
		key: "producerTicketsUpstreamIndex",
		icon: "receipt",
		url: ApplicationPages.producerTicketsUpstreamIndex.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "producerTicketsUpstreamIndex", legacy_permission: "pv2_producer_full_data_tickets_limited" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "producerTicketsUpstreamIndex", legacy_permission: "pv2_producer_full_data_tickets_limited" },
			"producer_pumper": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "producerTicketsUpstreamIndex", legacy_permission: "pv2_producer_full_data_tickets_limited" }
		},
		nav_badges: {}
	},
	producerDownloadsIndex: {
		name: s_DOWNLOADS,
		key: "producerDownloadsIndex",
		icon: "download",
		url: ApplicationPages.producerDownloadsIndex.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "producerDownloadsIndex", legacy_permission: "pv2_producer_full_data_downloads" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "producerDownloadsIndex", legacy_permission: "pv2_producer_full_data_downloads" },
			"producer_pumper": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "producerDownloadsIndex", legacy_permission: "pv2_producer_full_data_downloads" }
		},
		nav_badges: {}
	},
	producerReportTemplatesIndex: {
		name: s_REPORT_TEMPLATES,
		key: "producerReportTemplatesIndex",
		icon: "file-lines",
		url: ApplicationPages.producerReportTemplatesIndex.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "data", highlighted_nav_page: "producerReportTemplatesIndex", legacy_permission: "pv2_producer_full_data_template_builder" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "data", highlighted_nav_page: "producerReportTemplatesIndex", legacy_permission: "pv2_producer_full_data_template_builder" },
			"producer_pumper": { access: "always_no", highlighted_nav_section: "data", highlighted_nav_page: "producerReportTemplatesIndex", legacy_permission: "pv2_producer_full_data_template_builder" }
		},
		nav_badges: {}
	},

	//////////////////////////////////////
	// Directory
	//////////////////////////////////////

	// Transporter - Full - Directory
	cargoIndex: {
		name: s_CARGO,
		key: "cargoIndex",
		icon: "box",
		url: ApplicationPages.cargoIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_transporter_full_directory_cargo" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_transporter_full_directory_cargo" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_transporter_full_directory_cargo" },
			"transporter_dispatcher": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_transporter_full_directory_cargo" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_transporter_full_directory_cargo" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_transporter_full_directory_cargo" },
			"transporter_financial_analyst": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_transporter_full_directory_cargo" },
			"transporter_sales": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_transporter_full_directory_cargo" },
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_generic_planned_full_directory_cargo" },
			"generic_planned_dispatcher": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_generic_planned_full_directory_cargo" },
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_marketer_full_directory_cargo" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_producer_full_directory_cargo" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_producer_full_directory_cargo" },
			"producer_pumper": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "cargoIndex", legacy_permission: "pv2_producer_full_directory_cargo" }
		},
		nav_badges: {}
	},
	carriersIndex: {
		name: s_CARRIERS,
		key: "carriersIndex",
		icon: "truck-front",
		url: ApplicationPages.carriersIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "carriersIndex", legacy_permission: "pv2_transporter_full_directory_carriers" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "carriersIndex", legacy_permission: "pv2_transporter_full_directory_carriers" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "carriersIndex", legacy_permission: "pv2_transporter_full_directory_carriers" },
			"transporter_dispatcher": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "carriersIndex", legacy_permission: "pv2_transporter_full_directory_carriers" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "carriersIndex", legacy_permission: "pv2_transporter_full_directory_carriers" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "carriersIndex", legacy_permission: "pv2_transporter_full_directory_carriers" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "carriersIndex", legacy_permission: "pv2_transporter_full_directory_carriers" },
			"transporter_sales": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "carriersIndex", legacy_permission: "pv2_transporter_full_directory_carriers" },
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "carriersIndex", legacy_permission: "pv2_generic_planned_full_directory_carriers" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "carriersIndex", legacy_permission: "pv2_generic_planned_full_directory_carriers" }
		},
		nav_badges: {}
	},
	driversIndex: {
		name: s_DRIVERS,
		key: "driversIndex",
		icon: "user-helmet-safety",
		url: ApplicationPages.driversIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "driversIndex", legacy_permission: "pv2_transporter_full_directory_drivers" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "driversIndex", legacy_permission: "pv2_transporter_full_directory_drivers" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "driversIndex", legacy_permission: "pv2_transporter_full_directory_drivers" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "driversIndex", legacy_permission: "pv2_transporter_full_directory_drivers" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "driversIndex", legacy_permission: "pv2_transporter_full_directory_drivers" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "driversIndex", legacy_permission: "pv2_transporter_full_directory_drivers" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "driversIndex", legacy_permission: "pv2_transporter_full_directory_drivers" },
			"transporter_sales": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "driversIndex", legacy_permission: "pv2_transporter_full_directory_drivers" },
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "driversIndex", legacy_permission: "pv2_generic_planned_full_directory_drivers" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "driversIndex", legacy_permission: "pv2_generic_planned_full_directory_drivers" }
		},
		nav_badges: {}
	},
	leasesIndex: {
		name: s_PICKUP_LOCATIONS,
		key: "leasesIndex",
		icon: "thumbtack",
		url: ApplicationPages.leasesIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "leasesIndex", legacy_permission: "pv2_transporter_full_directory_leases" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "leasesIndex", legacy_permission: "pv2_transporter_full_directory_leases" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "leasesIndex", legacy_permission: "pv2_transporter_full_directory_leases" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "leasesIndex", legacy_permission: "pv2_transporter_full_directory_leases" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "leasesIndex", legacy_permission: "pv2_transporter_full_directory_leases" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "leasesIndex", legacy_permission: "pv2_transporter_full_directory_leases" },
			"transporter_financial_analyst": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "leasesIndex", legacy_permission: "pv2_transporter_full_directory_leases" },
			"transporter_sales": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "leasesIndex", legacy_permission: "pv2_transporter_full_directory_leases" },
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "leasesIndex", legacy_permission: "pv2_marketer_full_directory_leases" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "leasesIndex", legacy_permission: "pv2_producer_full_directory_leases" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "leasesIndex", legacy_permission: "pv2_producer_full_directory_leases" },
			"producer_pumper": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "leasesIndex", legacy_permission: "pv2_producer_full_directory_leases" }
		},
		nav_badges: {}
	},
	offloadsIndex: {
		name: s_DROPOFF_LOCATIONS,
		key: "offloadsIndex",
		icon: "flag-checkered",
		url: ApplicationPages.offloadsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "offloadsIndex", legacy_permission: "pv2_transporter_full_directory_offloads" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "offloadsIndex", legacy_permission: "pv2_transporter_full_directory_offloads" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "offloadsIndex", legacy_permission: "pv2_transporter_full_directory_offloads" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "offloadsIndex", legacy_permission: "pv2_transporter_full_directory_offloads" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "offloadsIndex", legacy_permission: "pv2_transporter_full_directory_offloads" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "offloadsIndex", legacy_permission: "pv2_transporter_full_directory_offloads" },
			"transporter_financial_analyst": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "offloadsIndex", legacy_permission: "pv2_transporter_full_directory_offloads" },
			"transporter_sales": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "offloadsIndex", legacy_permission: "pv2_transporter_full_directory_offloads" },
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "offloadsIndex", legacy_permission: "pv2_marketer_full_directory_offloads" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "offloadsIndex", legacy_permission: "pv2_producer_full_directory_offloads" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "offloadsIndex", legacy_permission: "pv2_producer_full_directory_offloads" },
			"producer_pumper": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "offloadsIndex", legacy_permission: "pv2_producer_full_directory_offloads" }
		},
		nav_badges: {}
	},
	producersIndex: {
		name: s_PRODUCERS,
		key: "producersIndex",
		icon: "oil-well",
		url: ApplicationPages.producersIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "producersIndex", legacy_permission: "pv2_transporter_full_directory_producers" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "producersIndex", legacy_permission: "pv2_transporter_full_directory_producers" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "producersIndex", legacy_permission: "pv2_transporter_full_directory_producers" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "producersIndex", legacy_permission: "pv2_transporter_full_directory_producers" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "producersIndex", legacy_permission: "pv2_transporter_full_directory_producers" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "producersIndex", legacy_permission: "pv2_transporter_full_directory_producers" },
			"transporter_financial_analyst": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "producersIndex", legacy_permission: "pv2_transporter_full_directory_producers" },
			"transporter_sales": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "producersIndex", legacy_permission: "pv2_transporter_full_directory_producers" },
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "producersIndex", legacy_permission: "pv2_marketer_full_directory_producers" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "producersIndex", legacy_permission: "pv2_producer_full_directory_producers" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "producersIndex", legacy_permission: "pv2_producer_full_directory_producers" },
			"producer_pumper": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "producersIndex", legacy_permission: "pv2_producer_full_directory_producers" }
		},
		nav_badges: {}
	},
	purchasersIndex: {
		name: s_PURCHASERS,
		key: "purchasersIndex",
		icon: "arrow-trend-up",
		url: ApplicationPages.purchasersIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_transporter_full_directory_purchasers" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_transporter_full_directory_purchasers" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_transporter_full_directory_purchasers" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_transporter_full_directory_purchasers" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_transporter_full_directory_purchasers" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_transporter_full_directory_purchasers" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_transporter_full_directory_purchasers" },
			"transporter_sales": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_transporter_full_directory_purchasers" },
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_generic_planned_full_directory_purchasers" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_generic_planned_full_directory_purchasers" },
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_marketer_full_directory_purchasers" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_producer_full_directory_purchasers" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_producer_full_directory_purchasers" },
			"producer_pumper": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "purchasersIndex", legacy_permission: "pv2_producer_full_directory_purchasers" }
		},
		nav_badges: {}
	},
	regionsIndex: {
		name: s_REGIONS,
		key: "regionsIndex",
		icon: "compass",
		url: ApplicationPages.regionsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_transporter_full_directory_regions" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_transporter_full_directory_regions" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_transporter_full_directory_regions" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_transporter_full_directory_regions" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_transporter_full_directory_regions" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_transporter_full_directory_regions" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_transporter_full_directory_regions" },
			"transporter_sales": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_transporter_full_directory_regions" },
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_generic_planned_full_directory_regions" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_generic_planned_full_directory_regions" },
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_marketer_full_directory_regions" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_producer_full_directory_regions" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_producer_full_directory_regions" },
			"producer_pumper": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "regionsIndex", legacy_permission: "pv2_producer_full_directory_regions" }
		},
		nav_badges: {}
	},
	routesIndex: {
		name: s_ROUTES,
		key: "routesIndex",
		icon: "route",
		url: ApplicationPages.routesIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "routesIndex", legacy_permission: "pv2_transporter_full_directory_routes" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "routesIndex", legacy_permission: "pv2_transporter_full_directory_routes" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "routesIndex", legacy_permission: "pv2_transporter_full_directory_routes" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "routesIndex", legacy_permission: "pv2_transporter_full_directory_routes" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "routesIndex", legacy_permission: "pv2_transporter_full_directory_routes" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "routesIndex", legacy_permission: "pv2_transporter_full_directory_routes" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "routesIndex", legacy_permission: "pv2_transporter_full_directory_routes" },
			"transporter_sales": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "routesIndex", legacy_permission: "pv2_transporter_full_directory_routes" }
		},
		nav_badges: {}
	},
	terminalsIndex: {
		name: s_TERMINALS,
		key: "terminalsIndex",
		icon: "garage",
		url: ApplicationPages.terminalsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "terminalsIndex", legacy_permission: "pv2_transporter_full_directory_terminals" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "terminalsIndex", legacy_permission: "pv2_transporter_full_directory_terminals" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "terminalsIndex", legacy_permission: "pv2_transporter_full_directory_terminals" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "terminalsIndex", legacy_permission: "pv2_transporter_full_directory_terminals" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "terminalsIndex", legacy_permission: "pv2_transporter_full_directory_terminals" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "terminalsIndex", legacy_permission: "pv2_transporter_full_directory_terminals" },
			"transporter_financial_analyst": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "terminalsIndex", legacy_permission: "pv2_transporter_full_directory_terminals" },
			"transporter_sales": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "terminalsIndex", legacy_permission: "pv2_transporter_full_directory_terminals" }
		},
		nav_badges: {}
	},
	trailersIndex: {
		name: s_TRAILERS,
		key: "trailersIndex",
		icon: "trailer",
		url: ApplicationPages.trailersIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "trailersIndex", legacy_permission: "pv2_transporter_full_directory_trailers" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "trailersIndex", legacy_permission: "pv2_transporter_full_directory_trailers" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "trailersIndex", legacy_permission: "pv2_transporter_full_directory_trailers" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "trailersIndex", legacy_permission: "pv2_transporter_full_directory_trailers" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "trailersIndex", legacy_permission: "pv2_transporter_full_directory_trailers" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "trailersIndex", legacy_permission: "pv2_transporter_full_directory_trailers" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "trailersIndex", legacy_permission: "pv2_transporter_full_directory_trailers" },
			"transporter_sales": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "trailersIndex", legacy_permission: "pv2_transporter_full_directory_trailers" }
		},
		nav_badges: {}
	},
	trucksIndex: {
		name: s_TRUCKS,
		key: "trucksIndex",
		icon: "truck",
		url: ApplicationPages.trucksIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "trucksIndex", legacy_permission: "pv2_transporter_full_directory_trucks" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "trucksIndex", legacy_permission: "pv2_transporter_full_directory_trucks" },
			"transporter_compliance_analyst": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "trucksIndex", legacy_permission: "pv2_transporter_full_directory_trucks" },
			"transporter_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "trucksIndex", legacy_permission: "pv2_transporter_full_directory_trucks" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "trucksIndex", legacy_permission: "pv2_transporter_full_directory_trucks" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "trucksIndex", legacy_permission: "pv2_transporter_full_directory_trucks" },
			"transporter_financial_analyst": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "trucksIndex", legacy_permission: "pv2_transporter_full_directory_trucks" },
			"transporter_sales": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "trucksIndex", legacy_permission: "pv2_transporter_full_directory_trucks" }
		},
		nav_badges: {}
	},
	fastUpdatesIndex: {
		name: s_FAST_UPDATES,
		key: "fastUpdatesIndex",
		icon: "angle-double-right",
		url: ApplicationPages.fastUpdatesIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "fastUpdatesIndex", legacy_permission: "pv2_transporter_full_directory_fast_updates" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "fastUpdatesIndex", legacy_permission: "pv2_transporter_full_directory_fast_updates" },
			"transporter_compliance_analyst": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "fastUpdatesIndex", legacy_permission: "pv2_transporter_full_directory_fast_updates" },
			"transporter_dispatcher": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "fastUpdatesIndex", legacy_permission: "pv2_transporter_full_directory_fast_updates" },
			"transporter_field_admin": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "fastUpdatesIndex", legacy_permission: "pv2_transporter_full_directory_fast_updates" },
			"transporter_field_supervisor": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "fastUpdatesIndex", legacy_permission: "pv2_transporter_full_directory_fast_updates" },
			"transporter_financial_analyst": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "fastUpdatesIndex", legacy_permission: "pv2_transporter_full_directory_fast_updates" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "fastUpdatesIndex", legacy_permission: "pv2_transporter_full_directory_fast_updates" }
		},
		nav_badges: {}
	},

	// Producer - Full - Directory
	producerDirectoryManagement: {
		name: s_DIRECTORY_MGMT,
		key: "producerDirectoryManagement",
		icon: "database",
		url: ApplicationPages.producerDirectoryManagement.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "producerDirectoryManagement", legacy_permission: "pv2_producer_full_directory_directory_management" },
			"producer_producer_foreman": { access: "default_no", highlighted_nav_section: "directory", highlighted_nav_page: "producerDirectoryManagement", legacy_permission: "pv2_producer_full_directory_directory_management" },
			"producer_pumper": { access: "always_no", highlighted_nav_section: "directory", highlighted_nav_page: "producerDirectoryManagement", legacy_permission: "pv2_producer_full_directory_directory_management" }
		},
		nav_badges: {}
	},

	// Generic Planned - Full - Directory
	pickupsIndex: {
		name: s_PICKUP_LOCATIONS,
		key: "pickupsIndex",
		icon: "thumbtack",
		url: ApplicationPages.pickupsIndex.url(),
		page_role_access_permissions: {
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "pickupsIndex", legacy_permission: "pv2_generic_planned_full_directory_pickups" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "pickupsIndex", legacy_permission: "pv2_generic_planned_full_directory_pickups" }
		},
		nav_badges: {}
	},
	dropoffsIndex: {
		name: s_DROPOFF_LOCATIONS,
		key: "dropoffsIndex",
		icon: "flag-checkered",
		url: ApplicationPages.dropoffsIndex.url(),
		page_role_access_permissions: {
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "dropoffsIndex", legacy_permission: "pv2_generic_planned_full_directory_dropoffs" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "dropoffsIndex", legacy_permission: "pv2_generic_planned_full_directory_dropoffs" }
		},
		nav_badges: {}
	},
	vehiclesIndex: {
		name: s_VEHICLES,
		key: "vehiclesIndex",
		icon: "truck",
		url: ApplicationPages.vehiclesIndex.url(),
		page_role_access_permissions: {
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "vehiclesIndex", legacy_permission: "pv2_generic_planned_full_directory_vehicles" },
			"generic_planned_dispatcher": { access: "default_yes", highlighted_nav_section: "directory", highlighted_nav_page: "vehiclesIndex", legacy_permission: "pv2_generic_planned_full_directory_vehicles" }
		},
		nav_badges: {}
	},

	// Marketer - Full - Directory
	marketerDirectoryManagement: {
		name: s_DIRECTORY_MGMT,
		key: "marketerDirectoryManagement",
		icon: "database",
		url: ApplicationPages.marketerDirectoryManagement.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "directory", highlighted_nav_page: "marketerDirectoryManagement", legacy_permission: "pv2_marketer_full_directory_directory_management" }
		},
		nav_badges: {}
	},


	//////////////////////////////////////
	// Admin
	//////////////////////////////////////

	// Transporter - Full - Admin
	usersIndex: {
		name: s_USERS,
		key: "usersIndex",
		icon: "users",
		url: ApplicationPages.usersIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_transporter_full_admin_users" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_transporter_full_admin_users" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_transporter_full_admin_users" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_transporter_full_admin_users" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_transporter_full_admin_users" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_transporter_full_admin_users" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_transporter_full_admin_users" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_transporter_full_admin_users" },
			"generic_planned_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_generic_planned_full_admin_users" },
			"generic_planned_dispatcher": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_generic_planned_full_admin_users" },
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_marketer_full_admin_users" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_producer_full_admin_users" },
			"producer_producer_foreman": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_producer_full_admin_users" },
			"producer_pumper": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_producer_full_admin_users" },
			"secure_delivery_admin": { access: "always_yes", highlighted_nav_section: "home", highlighted_nav_page: "usersIndex", legacy_permission: "pv2_secure_delivery_full_home_users" }
		},
		nav_badges: {}
	},
	adminRequestsIndex: {
		name: s_CONTACT_REQUESTS,
		key: "adminRequestsIndex",
		icon: "paper-plane",
		url: ApplicationPages.adminRequestsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "adminRequestsIndex", legacy_permission: "pv2_transporter_full_admin_requests" },
			"transporter_api_access": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "adminRequestsIndex", legacy_permission: "pv2_transporter_full_admin_requests" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "adminRequestsIndex", legacy_permission: "pv2_transporter_full_admin_requests" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "adminRequestsIndex", legacy_permission: "pv2_transporter_full_admin_requests" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "adminRequestsIndex", legacy_permission: "pv2_transporter_full_admin_requests" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "adminRequestsIndex", legacy_permission: "pv2_transporter_full_admin_requests" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "adminRequestsIndex", legacy_permission: "pv2_transporter_full_admin_requests" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "adminRequestsIndex", legacy_permission: "pv2_transporter_full_admin_requests" }
		},
		nav_badges: {}
	},
	demoDataManagement: {
		name: s_DEMO_DATA,
		key: "demoDataManagement",
		icon: "box-archive",
		url: ApplicationPages.demoDataManagement.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "demoDataManagement", legacy_permission: "pv2_transporter_full_admin_demo_data" },
			"transporter_api_access": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "demoDataManagement", legacy_permission: "pv2_transporter_full_admin_demo_data" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "demoDataManagement", legacy_permission: "pv2_transporter_full_admin_demo_data" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "demoDataManagement", legacy_permission: "pv2_transporter_full_admin_demo_data" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "demoDataManagement", legacy_permission: "pv2_transporter_full_admin_demo_data" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "demoDataManagement", legacy_permission: "pv2_transporter_full_admin_demo_data" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "demoDataManagement", legacy_permission: "pv2_transporter_full_admin_demo_data" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "demoDataManagement", legacy_permission: "pv2_transporter_full_admin_demo_data" }
		},
		nav_badges: {}
	},
	databaseManagement: {
		name: s_DATABASE_MGMT,
		key: "databaseManagement",
		icon: "database",
		url: ApplicationPages.databaseManagement.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "databaseManagement", legacy_permission: "pv2_transporter_full_admin_database_management" },
			"transporter_api_access": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "databaseManagement", legacy_permission: "pv2_transporter_full_admin_database_management" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "databaseManagement", legacy_permission: "pv2_transporter_full_admin_database_management" },
			"transporter_dispatcher": { access: "default_no", highlighted_nav_section: "admin", highlighted_nav_page: "databaseManagement", legacy_permission: "pv2_transporter_full_admin_database_management" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "databaseManagement", legacy_permission: "pv2_transporter_full_admin_database_management" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "databaseManagement", legacy_permission: "pv2_transporter_full_admin_database_management" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "databaseManagement", legacy_permission: "pv2_transporter_full_admin_database_management" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "databaseManagement", legacy_permission: "pv2_transporter_full_admin_database_management" }
		},
		nav_badges: {}
	},
	clientPaymentsIndex: {
		name: s_PAYMENTS,
		key: "clientPaymentsIndex",
		icon: "credit-card-alt",
		url: ApplicationPages.clientPaymentsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "clientPaymentsIndex", legacy_permission: "pv2_transporter_full_admin_payments" },
			"transporter_api_access": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientPaymentsIndex", legacy_permission: "pv2_transporter_full_admin_payments" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientPaymentsIndex", legacy_permission: "pv2_transporter_full_admin_payments" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientPaymentsIndex", legacy_permission: "pv2_transporter_full_admin_payments" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientPaymentsIndex", legacy_permission: "pv2_transporter_full_admin_payments" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientPaymentsIndex", legacy_permission: "pv2_transporter_full_admin_payments" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientPaymentsIndex", legacy_permission: "pv2_transporter_full_admin_payments" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientPaymentsIndex", legacy_permission: "pv2_transporter_full_admin_payments" }
		},
		nav_badges: {}
	},
	clientSettingsIndex: {
		name: s_SETTINGS,
		key: "clientSettingsIndex",
		icon: "cog",
		url: ApplicationPages.clientSettingsIndex.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "clientSettingsIndex", legacy_permission: "pv2_transporter_full_admin_settings" },
			"transporter_api_access": { access: "default_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientSettingsIndex", legacy_permission: "pv2_transporter_full_admin_settings" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientSettingsIndex", legacy_permission: "pv2_transporter_full_admin_settings" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientSettingsIndex", legacy_permission: "pv2_transporter_full_admin_settings" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientSettingsIndex", legacy_permission: "pv2_transporter_full_admin_settings" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientSettingsIndex", legacy_permission: "pv2_transporter_full_admin_settings" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientSettingsIndex", legacy_permission: "pv2_transporter_full_admin_settings" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientSettingsIndex", legacy_permission: "pv2_transporter_full_admin_settings" },
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "clientSettingsIndex", legacy_permission: "pv2_marketer_full_admin_settings" },
			"producer_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "clientSettingsIndex", legacy_permission: "pv2_producer_full_admin_settings" },
			"producer_producer_foreman": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientSettingsIndex", legacy_permission: "pv2_producer_full_admin_settings" },
			"producer_pumper": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "clientSettingsIndex", legacy_permission: "pv2_producer_full_admin_settings" }
		},
		nav_badges: {}
	},
	apiDocumentation: {
		name: s_LOADCALL_API,
		key: "apiDocumentation",
		icon: "code",
		url: ApplicationPages.apiDocumentation.url(),
		page_role_access_permissions: {
			"transporter_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "apiDocumentation", legacy_permission: "pv2_transporter_full_admin_apiDocumentation" },
			"transporter_api_access": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "apiDocumentation", legacy_permission: "pv2_transporter_full_admin_apiDocumentation" },
			"transporter_compliance_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "apiDocumentation", legacy_permission: "pv2_transporter_full_admin_apiDocumentation" },
			"transporter_dispatcher": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "apiDocumentation", legacy_permission: "pv2_transporter_full_admin_apiDocumentation" },
			"transporter_field_admin": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "apiDocumentation", legacy_permission: "pv2_transporter_full_admin_apiDocumentation" },
			"transporter_field_supervisor": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "apiDocumentation", legacy_permission: "pv2_transporter_full_admin_apiDocumentation" },
			"transporter_financial_analyst": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "apiDocumentation", legacy_permission: "pv2_transporter_full_admin_apiDocumentation" },
			"transporter_sales": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "apiDocumentation", legacy_permission: "pv2_transporter_full_admin_apiDocumentation" }
		},
		nav_badges: {}
	},

	// Marketer - Full - Admin
	marketerAssignmentAlgorithmConfigIndex: {
		name: s_ASSIGNMENT_ALGORITHM,
		key: "marketerAssignmentAlgorithmConfigIndex",
		icon: "microchip",
		url: ApplicationPages.marketerAssignmentAlgorithmConfigIndex.url(),
		page_role_access_permissions: {
			"marketer_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "marketerAssignmentAlgorithmConfigIndex", legacy_permission: "pv2_marketer_full_admin_load_assignment_algorithm" }
		},
		nav_badges: {}
	},

	// Producer - Full - Admin
	producerAssignmentAlgorithmConfigIndex: {
		name: s_ASSIGNMENT_ALGORITHM,
		key: "producerAssignmentAlgorithmConfigIndex",
		icon: "microchip",
		url: ApplicationPages.producerAssignmentAlgorithmConfigIndex.url(),
		page_role_access_permissions: {
			"producer_admin": { access: "always_yes", highlighted_nav_section: "admin", highlighted_nav_page: "producerAssignmentAlgorithmConfigIndex", legacy_permission: "pv2_producer_full_admin_load_assignment_algorithm" },
			"producer_producer_foreman": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "producerAssignmentAlgorithmConfigIndex", legacy_permission: "pv2_producer_full_admin_load_assignment_algorithm" },
			"producer_pumper": { access: "always_no", highlighted_nav_section: "admin", highlighted_nav_page: "producerAssignmentAlgorithmConfigIndex", legacy_permission: "pv2_producer_full_admin_load_assignment_algorithm" }
		},
		nav_badges: {}
	},

}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export const EmptyApplicationNavigationObject: TsInterface_ApplicationNavSections = {}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export const generateApplicationNavigationObject = (
	clientType: TsType_String,
	userRole: TsType_String
): TsInterface_SideBarNavObject => {
	let sideBarNavObject: TsInterface_SideBarNavObject = {}
	for (let rootNavPageKey in ApplicationNavPages) {
		let rootNavPage: TsInterface_NavPage = ApplicationNavPages[rootNavPageKey]
		let userRoleAccess = getProp(rootNavPage["page_role_access_permissions"][clientType + "_" + userRole], "access", false)
		if (userRoleAccess !== false) {
			let userSpecificRootNavPage = rootNavPage["page_role_access_permissions"][clientType + "_" + userRole]
			if (sideBarNavObject[userSpecificRootNavPage.highlighted_nav_section] == null && ApplicationNavSections[userSpecificRootNavPage.highlighted_nav_section] != null) {
				sideBarNavObject[userSpecificRootNavPage.highlighted_nav_section] = ApplicationNavSections[userSpecificRootNavPage.highlighted_nav_section]
			}
			sideBarNavObject[userSpecificRootNavPage.highlighted_nav_section]["links"][userSpecificRootNavPage.highlighted_nav_page] = rootNavPage
		}
	}
	return sideBarNavObject
}

export const generateAvailableClientLevelPermissions = (): TsInterface_AvailableClientTypePermissionsObject => {
	let availableClientTypePermissions: TsInterface_AvailableClientTypePermissionsObject = {}
	// Loop through each client type to
	for (let clientTypeKey in ClientTypes) {
		availableClientTypePermissions[clientTypeKey] = {}
		let clientTypeUserRoles = ClientTypes[clientTypeKey]["user_roles"]
		for (let userRoleKey in clientTypeUserRoles) {
			let emulatedNav = generateApplicationNavigationObject(clientTypeKey, userRoleKey)
			for (let emulatedSectionKey in emulatedNav) {
				let emulatedSection = emulatedNav[emulatedSectionKey]
				if (availableClientTypePermissions[clientTypeKey][emulatedSectionKey] == null) {
					availableClientTypePermissions[clientTypeKey][emulatedSectionKey] = {
						sectionKey: emulatedSectionKey,
						sectionName: emulatedSection.name,
						permissions: {}
					}
				}
				for (let emulatedRootNavLinkKey in emulatedSection["links"]) {
					let emulatedRootNavLink = emulatedSection["links"][emulatedRootNavLinkKey]
					let compositePermissionKey = "desktop_page_access_" + clientTypeKey + "_" + emulatedSectionKey + "_" + emulatedRootNavLinkKey
					let userRoleWithAccess = false
					for (let roleKey in ApplicationNavPages[emulatedRootNavLinkKey]["page_role_access_permissions"]) {
						if (roleKey.substring(0, clientTypeKey.length) === clientTypeKey) {
							if (ApplicationNavPages[emulatedRootNavLinkKey]["page_role_access_permissions"][roleKey] != null && ApplicationNavPages[emulatedRootNavLinkKey]["page_role_access_permissions"][roleKey]["access"] !== false) {
								userRoleWithAccess = true
							}
						}
					}
					if (userRoleWithAccess === true) {
						availableClientTypePermissions[clientTypeKey][emulatedSectionKey]["permissions"][compositePermissionKey] = {
							key: compositePermissionKey,
							pageName: emulatedRootNavLink.name
						}
					}
				}
			}
		}
	}
	return availableClientTypePermissions
}

export const generateAvailableUserLevelPermissions = (
	userRole: TsType_UserRoles,
	clientType: TsType_ClientTypes,
	rootClientPermissions: TsInterface_RootData_ClientPermissions
): TsInterface_AvailableUserTypePermissionsObject => {
	let availableUserTypePermissionsObject: TsInterface_AvailableUserTypePermissionsObject = {}
	let flatUserPermissions: TsInterface_FlatUserPermissions = {}
	if (userRole != null && clientType != null && rootClientPermissions != null) {
		let emulatedNav = generateApplicationNavigationObject(clientType, userRole)
		// Loop through top level nav pages (which permissions are based on)
		for (let navPageKey in ApplicationNavPages) {
			let navPage = ApplicationNavPages[navPageKey]
			// If the nav page has an access permission for the authenticated user's client type and user role
			if (navPage["page_role_access_permissions"] != null && navPage["page_role_access_permissions"][clientType + "_" + userRole]) {
				let pageRoleAccessPermissions = navPage["page_role_access_permissions"][clientType + "_" + userRole]
				// If access is granted at the client level
				let compositePermissionOverrideKey = "desktop_page_access_" + clientType + "_" + pageRoleAccessPermissions["highlighted_nav_section"] + "_" + pageRoleAccessPermissions["highlighted_nav_page"]
				if (pageRoleAccessPermissions["highlighted_nav_section"] != null && pageRoleAccessPermissions["highlighted_nav_page"] != null && rootClientPermissions[compositePermissionOverrideKey]) {
					if (pageRoleAccessPermissions != null && pageRoleAccessPermissions["access"]) {
						// Just show the permissions that the client AND user role have access to
						flatUserPermissions[compositePermissionOverrideKey] = {
							key: compositePermissionOverrideKey,
							access: pageRoleAccessPermissions["access"]
						}
					}
				}
			}
		}
		for (let emulatedSectionKey in emulatedNav) {
			let emulatedSection = emulatedNav[emulatedSectionKey]
			if (availableUserTypePermissionsObject[emulatedSectionKey] == null) {
				availableUserTypePermissionsObject[emulatedSectionKey] = {
					sectionKey: emulatedSectionKey,
					sectionName: emulatedSection.name,
					permissions: {}
				}
			}
			for (let emulatedRootNavLinkKey in emulatedSection["links"]) {
				let emulatedRootNavLink = emulatedSection["links"][emulatedRootNavLinkKey]
				let compositePermissionKey = "desktop_page_access_" + clientType + "_" + emulatedSectionKey + "_" + emulatedRootNavLinkKey
				if (flatUserPermissions[compositePermissionKey] != null) {
					availableUserTypePermissionsObject[emulatedSectionKey]["permissions"][compositePermissionKey] = {
						access: flatUserPermissions[compositePermissionKey]["access"],
						permissionKey: compositePermissionKey,
						pageName: emulatedRootNavLink.name
					}
				}
			}
		}
	}
	return availableUserTypePermissionsObject
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export const generateActiveUserApplicationPermissions = (
	rootClientUser: TsInterface_RootData_ClientUser,
	rootGlobalUser: TsInterface_RootData_GlobalUser,
	rootClientPermissions: TsInterface_RootData_ClientPermissions
): Promise<TsInterface_GenerateActiveUserApplicationPermissionsResult> => {
	// permissions are of the form "desktop_page_access_$clientType_$sectionKey_$pageKey" where $clientType, $sectionKey and $pageKey values are all in camelCase
	return new Promise((resolve, reject) => {
		let permissions: TsInterface_PermissionObject = {
			HomePage: true
		}
		let userRole = getProp(rootClientUser, "user_role", null)
		if (userRole == null) {
			userRole = getProp(rootGlobalUser, "user_role", null)
		}
		if (userRole != null && rootClientPermissions != null && rootClientPermissions["client_type"] != null) {
			let clientType = rootClientPermissions["client_type"]
			// Loop through top level nav pages (which is what permissions are based on)
			for (let navPageKey in ApplicationNavPages) {
				let navPage = ApplicationNavPages[navPageKey]
				// If the nav page has an access permission for the authenticated user's client type and user role
				if (navPage["page_role_access_permissions"] != null && navPage["page_role_access_permissions"][clientType + "_" + userRole]) {
					let pageRoleAccessPermissions = navPage["page_role_access_permissions"][clientType + "_" + userRole]
					// If access is granted at the client level
					// let compositePermissionOverrideKey = "desktop_page_access_" + clientType + "_" + pageRoleAccessPermissions["highlighted_nav_section"] + "_" + pageRoleAccessPermissions["highlighted_nav_page"]
					let compositePermissionOverrideKey = pageRoleAccessPermissions.legacy_permission
					// if ( pageRoleAccessPermissions["highlighted_nav_section"] != null && pageRoleAccessPermissions["highlighted_nav_page"] != null && rootClientPermissions[ compositePermissionOverrideKey ] ){
					if (
						pageRoleAccessPermissions["highlighted_nav_section"] != null &&
						pageRoleAccessPermissions["highlighted_nav_page"] != null &&
						rootClientPermissions.permissions != null &&
						// @ts-expect-error
						rootClientPermissions.permissions[compositePermissionOverrideKey] === true
					) {
						if (pageRoleAccessPermissions != null && pageRoleAccessPermissions["access"]) {
							// Depending on user role and overrides, determine access boolean
							if (pageRoleAccessPermissions["access"] === "always_yes") {
								permissions[navPage.key] = true
							} else if (pageRoleAccessPermissions["access"] === "default_yes") {
								if (rootClientUser != null && rootClientUser["permission_overrides"] != null && rootClientUser["permission_overrides"][compositePermissionOverrideKey] != null) {
									permissions[navPage.key] = rootClientUser["permission_overrides"][compositePermissionOverrideKey]
								} else {
									permissions[navPage.key] = true
								}
							} else if (pageRoleAccessPermissions["access"] === "default_no") {
								if (rootClientUser != null && rootClientUser["permission_overrides"] != null && rootClientUser["permission_overrides"][compositePermissionOverrideKey] != null) {
									permissions[navPage.key] = rootClientUser["permission_overrides"][compositePermissionOverrideKey]
								} else {
									permissions[navPage.key] = false
								}
							} else if (pageRoleAccessPermissions["access"] === "always_no") {
								permissions[navPage.key] = false
							} else {
								permissions[navPage.key] = false
							}
						} else {
							permissions[navPage.key] = false
						}
					} else {
						permissions[navPage.key] = false
					}
				}
			}
			permissions["HomePage"] = true
			permissions["UserSettingsPage"] = true
			resolve({
				success: true,
				permissions: permissions,
				error: {}
			})
		} else {
			resolve({
				success: false,
				permissions: {},
				error: { message: "", details: "", code: "ER-D-SAS-GAUAP-01" }
			})
		}
	})
}