//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Provides easy to use

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Trans
} from 'react-i18next'
import {
	TsInterface_TableAdditionalData,
	TsInterface_TableColumn,
	TsInterface_TableDataRow,
	TsInterface_TableHooks,
	TsType_TableSortBy
} from 'rfbp_core/components/table'
import {
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box
} from '@mui/material'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_MISSING: TsType_JSX =		<Trans>Missing</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

	export const TableCellMissing = ( propKey: TsType_String, propName: TsType_String | TsType_JSX, sortBy: TsType_TableSortBy ): TsInterface_TableColumn => {
		let basicCell = {
			header: {
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					return <>{ propName }</>
				},
				header_sort_by: sortBy
			},
			cell: {
				cell_jsx: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
					let cellJSX = <></>
					if(
						rowData != null &&
						rowData[propKey] != null
					){
						cellJSX = <>{ rowData[propKey] }</>
					} else {
						cellJSX =
						<Box className="tw-italic tw-opacity-50">
							{ s_MISSING }
						</Box>
					}
					return cellJSX
				},
			}
		}
		return basicCell
	}