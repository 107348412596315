//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Components are reused segments of code used to create contend used to create containers (pages)

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import 'react-phone-input-2/lib/material.css'
import {
	Trans
} from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme' // Outside Boilerplate
import {
	returnJSX_InputAdornment,
	TsInterface_DynamicAttributes,
	TsInterface_FormAdditionalData,
	TsInterface_FormData,
	TsInterface_FormHooksObject,
	TsInterface_FormInput,
	TsInterface_FormSettings,
	TsType_InputChangeCallback
} from 'rfbp_core/components/form'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	FormControl
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		formAdditionalData: TsInterface_FormAdditionalData
		formData: TsInterface_FormData
		formHooks: TsInterface_FormHooksObject
		formInput: TsInterface_FormInput
		formSettings: TsInterface_FormSettings
		inputChangeCallback: TsType_InputChangeCallback
	}

	interface TsInterface_PhoneSettings {
		countryCode: TsType_String 	// "us"
		dialCode: TsType_String 	// "1"
		format: TsType_String		// "+. (...)" ...-....
		name: TsType_String			// "United States"
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_ENTER_A_VALID_PHONE_NUMBER: TsType_JSX = 				<Trans>Enter a valid phone number</Trans>
	// { sort-end } - displayed text

	let phoneInputCss = `
		.react-tel-input .special-label { background: ` + themeVariables.background_paper +` !important; }
		.react-tel-input .invalid-number-message { background: ` + themeVariables.background_paper +` !important; }
		.react-tel-input .form-control { background: ` + themeVariables.background_paper +` !important; }
		.react-tel-input .form-control { color: ` + themeVariables.contrast_text +` !important; }
	`

	// themeVariables.background_paper

///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const PhoneNumberUSA = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let pr_formData: TsInterface_FormData = 											getProp( props, "formData", {} )
		let pr_formInput: TsInterface_FormInput = 											getProp( props, "formInput", {} )
		let pr_formSettings: TsInterface_FormSettings = 									getProp( props, "formSettings", {} )
		let pr_inputChangeCallback: TsType_InputChangeCallback = 							props.inputChangeCallback

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		const phoneRegex: RegExp = /^[0-9]{11}/

		// Functions

		// Generate JSX



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			// Dynamic Class Name
			let dynamicClassName = ""
			// Dynamic Attributes
			let dynamicAttributes: TsInterface_DynamicAttributes = {}
			if ( pr_formInput["required"] === true ){
				dynamicAttributes["required"] = true
			}
			if ( pr_formInput["required"] === true && pr_formSettings.highlight_missing === true && ( pr_formData == null || pr_formData[ pr_formInput["key"] ] == null || pr_formData[ pr_formInput["key"] ] === "" )) {
				dynamicAttributes["error"] = true
			}
			if ( pr_formInput["disabled"] === true ){
				dynamicAttributes["disabled"] = true
			}
			// Form Input JSX
			let componentJSX =
			<Box className="tw-mt-3 tw-mb-2">
				<FormControl className={ dynamicClassName } fullWidth>
					<PhoneInput
						country={'us'}
						countryCodeEditable={ false }
						disableDropdown={ true }
						disableSearchIcon={ true }
						// @ts-expect-error
						isValid={(value: TsType_String) => {
							if ( value.match( phoneRegex ) ) {
								return true
							} else {
								return s_ENTER_A_VALID_PHONE_NUMBER
							}
						}}
						color="primary"
						value={ pr_formData[pr_formInput.key] as TsType_String || "" }
						id={ pr_formInput["key"] }
						label={ pr_formInput["label"] }
						margin="normal"
						onChange={ ( value: TsType_String, settings: TsInterface_PhoneSettings ) => {
							if ( value != null ){
								pr_inputChangeCallback( pr_formInput.key, value, true )
							}
						} }
						variant="outlined"
						InputProps={{
							startAdornment: returnJSX_InputAdornment( pr_formInput, "start" ),
							endAdornment: returnJSX_InputAdornment( pr_formInput, "end" ),
						}}
						{ ...dynamicAttributes }
					/>
				</FormControl>
				<style>{ phoneInputCss }</style>
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}