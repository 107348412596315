///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import React, {
	useContext,
	useEffect
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useNavigate
} from 'react-router-dom'
import {
	returnLoadCallDocumentTitle
} from 'rfbp_aux/config/app_version'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	Context_RootData_GlobalUser
} from 'rfbp_core/services/context'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	logOut
} from 'rfbp_core/services/user_authentication'
import {
	TsType_JSX,
	TsType_String,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button,
	Card,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey: TsType_String = ApplicationPages["UserSettingsPage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_EMAIL: TsType_JSX = 				<Trans>Email</Trans>
	const s_LOG_OUT: TsType_JSX = 				<Trans>Log Out</Trans>
	const s_NAME: TsType_JSX = 					<Trans>Name</Trans>
	const s_PROFILE: TsType_JSX = 				<Trans>Profile</Trans>
	const se_SETTINGS: TsType_String = 			"Settings"
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const un_routerNaviation = 							useNavigate()
		const { uc_RootData_GlobalUser } = 					useContext( Context_RootData_GlobalUser )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			document.title = returnLoadCallDocumentTitle( se_SETTINGS )
		}, [])


		// Other Variables
		let displayUserName: TsType_String = getProp( uc_RootData_GlobalUser, "name", null )
		let displayUserEmail: TsType_String = getProp( uc_RootData_GlobalUser, "email", null )

		// Functions
		const signOut = (): TsType_Void => {
			logOut().then(( res_LO ) => {
				un_routerNaviation( ApplicationPages.UnauthenticatedLoginPage.url() )
			}).catch(( rej_LO ) => {
				console.error( rej_LO )
			})
		}

		// Call Functions

		// JSX Generation

		const returnJSX_ProfileTab = (): TsType_JSX => {
			let profileTabJSX =
			<Card variant="outlined">
				<Box className="tw-text-left">
					<Box className="tw-p-4 tw-text-left tw-inline-block">
						<Box className="">
							<Typography variant="subtitle1"><strong>{ s_NAME }:</strong> { displayUserName }</Typography>
							<Typography variant="subtitle1"><strong>{ s_EMAIL }:</strong> { displayUserEmail }</Typography>
						</Box>
						<Button color="error" onClick={ () => { signOut() } } variant="contained">{ s_LOG_OUT }</Button>
					</Box>
				</Box>
			</Card>
			return profileTabJSX
		}

		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			<AuthenticatedContainer pageHeader={ s_PROFILE } pageKey={ pageKey } content={
				<Box>
					{ returnJSX_ProfileTab() }
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{ returnJSX_Page() }</>

	}