//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Used to generate Prompt Dialogs

		TODO:
			[ ] Typescript - 2 instances of TsType_Any - function copied from MUI Docs
			[ ] Typescript - 1 instance of TsType_Unknown
	*/


///////////////////////////////
// Imports
///////////////////////////////

import React from 'react'
import {
	Trans
} from 'react-i18next'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TsInterface_PromptDialogObject,
	TsInterface_UserInterface_PromptDialogInternalState,
	TsType_UserInterface_AllDialogsDismissCallback
} from 'rfbp_core/services/context'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_MuiAppBarColors,
	TsType_Unknown,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	AppBar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	IconButton,
	Slide,
	Stack,
	TextField,
	Toolbar,
	Typography
} from '@mui/material/'
import {
	TransitionProps
} from '@mui/material/transitions'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_CONFIRM: TsType_JSX = 				<Trans>Confirm</Trans>
	const s_DISMISS: TsType_JSX = 				<Trans>Dismiss</Trans>
	const s_PLEASE_RESPOND: TsType_JSX = 		<Trans>Please Respond</Trans>
	const s_PROMPT: TsType_JSX = 				<Trans>Prompt</Trans>
	const s_TEXT: TsType_JSX = 					<Trans>Text</Trans>
	// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

	const Transition = React.forwardRef(function Transition(
		props: TransitionProps & {
			children: React.ReactElement< TsType_Any, TsType_Any >;
		},
		ref: React.Ref< TsType_Unknown >,
	) {
		return <Slide direction="up" ref={ ref } { ...props } />;
	})


///////////////////////////////
// Component
///////////////////////////////

	export const PromptDialog = (
		prompt: TsInterface_PromptDialogObject,
		dismissDialog: TsType_UserInterface_AllDialogsDismissCallback,
		PromptDialogState: TsInterface_UserInterface_PromptDialogInternalState,
		setPromptDialogState: React.Dispatch< React.SetStateAction< TsInterface_UserInterface_PromptDialogInternalState >>
	): TsType_JSX => {

		// Props
		let pr_confirmText: TsInterface_PromptDialogObject["confirm_text"] = 			getProp( prompt, "confirm_text", s_CONFIRM )
		let pr_inputLabel: TsInterface_PromptDialogObject["input_label"] = 				getProp( prompt, "input_label", s_TEXT )
		let pr_inputType: TsInterface_PromptDialogObject["input_type"] = 				getProp( prompt, "input_type", "text" )
		let pr_promptColor: TsInterface_PromptDialogObject["color"] = 					getProp( prompt, "color", "success" )
		let pr_promptHeader: TsInterface_PromptDialogObject["header"] = 				getProp( prompt, "header", s_PROMPT )
		let pr_promptIcon: TsInterface_PromptDialogObject["icon"] = 					getProp( prompt, "icon", <Icon icon="input-text"/> )
		let pr_promptInputValue: TsInterface_PromptDialogObject["default_value"] = 		getProp( prompt, "default_value", null )
		let pr_promptText: TsInterface_PromptDialogObject["text"] = 					getProp( prompt, "text", s_PLEASE_RESPOND )
		let pr_submitCallback: TsInterface_PromptDialogObject["submit_callback"] = 		getProp( prompt, "submit_callback", ( promptInputValue : TsInterface_PromptDialogObject["default_value"] ) => {
			return new Promise( ( resolve, reject ) => {
				resolve( { success: true } )
			})
		} )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		let open: TsType_Boolean = true

		// Functions
		const handleClose = (): TsType_Void => {
			dismissDialog()
		}

		const handleSubmit = (): TsType_Void => {
			setPromptDialogState({ submitting: true })
			pr_submitCallback!( pr_promptInputValue ).then(( res_SC: TsType_Any ) => {
				setPromptDialogState({ submitting: false })
				if( res_SC == null || res_SC.close_dialog !== false ){
					dismissDialog()
				}
			}).catch(( rej_SC: TsType_Any ) => {
				setPromptDialogState({ submitting: false })
				if( rej_SC == null || rej_SC.close_dialog !== false ){
					dismissDialog()
				}
			})
		}

		const formInputChange = ( event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ): TsType_Void => {
			pr_promptInputValue = event.target.value
		}



		// JSX Generation
		const returnJSX_DialogActions = (): TsType_JSX => {
			let dialogActionsJSX
			if ( PromptDialogState != null && PromptDialogState["submitting"] === true ){
				dialogActionsJSX =
				<Stack spacing={ 1 } direction="row">
					<Button color={ pr_promptColor } disabled onClick={ handleClose } variant="outlined">{ s_DISMISS }</Button>
					<Button color={ pr_promptColor } disabled onClick={ handleSubmit } variant="contained" startIcon={ <Icon icon="arrows-rotate" className="bp_spin"/> }>{ pr_confirmText }</Button>
				</Stack>
			} else {
				dialogActionsJSX =
				<Stack spacing={ 1 } direction="row">
					<Button color={ pr_promptColor } onClick={ handleClose } variant="outlined">{ s_DISMISS }</Button>
					<Button color={ pr_promptColor } onClick={ handleSubmit } variant="contained">{ pr_confirmText }</Button>
				</Stack>
			}
			return dialogActionsJSX
		}

		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				<Dialog
					TransitionComponent={ Transition }
					aria-describedby="alert-dialog-description"
					aria-labelledby="alert-dialog-title"
					className="bp_dialog_md_width"
					keepMounted
					onClose={ handleClose }
					open={ open }
				>
					<AppBar position="static" color={ pr_promptColor as TsType_MuiAppBarColors }>
						<Toolbar>
							<IconButton
								aria-label="menu"
								color="inherit"
								disabled
								edge="start"
								size="large"
								sx={{ mr: 2, color: "#fff !important" }}
							>
								{ pr_promptIcon }
							</IconButton>
							<Typography component={ 'span' } variant={ 'h6' } sx={{ flexGrow: 1 }}>{ pr_promptHeader }</Typography>
						</Toolbar>
					</AppBar>
					<DialogContent>
						<Box>
							<Typography variant="subtitle1" display="block">{ pr_promptText }</Typography>
						</Box>
						<Box>
							<FormControl fullWidth>
								<TextField
									color={ pr_promptColor }
									defaultValue={ pr_promptInputValue }
									id="prompt_dialog_input"
									label={ pr_inputLabel }
									margin="normal"
									onChange={ ( event ) => { formInputChange( event ) } }
									type={ pr_inputType }
									variant="outlined"
								/>
							</FormControl>
						</Box>
					</DialogContent>
					<DialogActions>
						{ returnJSX_DialogActions() }
					</DialogActions>
				</Dialog>
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}