//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import PropTypes from 'prop-types'
import {
	useEffect,
	useState
} from 'react'
import {
	useLocation,
	useNavigate
} from 'react-router-dom'
import {
	TsInterface_TabContentUrlArray,
	TsInterface_TabPanelUrl,
	TsInterface_TabsSettingsUrl
} from 'rfbp_core/components/tabs'
import {
	getProp,
	underscoresToSpaces
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Boolean,
	TsType_JSX,
	TsType_Number,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Tab,
	Tabs
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		tabs: TsInterface_TabContentUrlArray
		tabsSettings: TsInterface_TabsSettingsUrl
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////

	// Tabs - Copied from MUI
	const TabPanel = ( props: TsInterface_TabPanelUrl ) => {
		const { children, value, index, settings, ...other } = props
		return (
			<Box
				role="tabpanel"
				hidden={ value !== index }
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{ value === index && (
					<Box component='div' sx={{ p: 0 }}>
						{ children }
					</Box>
				)}
			</Box>
		)
	}

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired,
	}

	const accessibilityProps = ( index: TsType_Number ) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}
	// End Tabs

	// const hasQueryParams = ( url: TsType_String ) => {
		// // Use a regular expression to check for a "?" followed by one or more characters
		// const regex = /\?.+/;
		// return regex.test(url);
	// }

	export const returnCurrentUrlQueryParamsAsObject = (): TsInterface_UnspecifiedObject => {
		// Get existing query params
		const queryString = window.location.search
		const params = queryString.slice(1).split('&'); // Remove the leading "?" and split by "&"
		let paramsObject: TsInterface_UnspecifiedObject = {}
		for (let keyValuePair of params) {
			const [key, value] = keyValuePair.split('=');
			if (key && value) {
				paramsObject[key] = decodeURIComponent(value);
			}
		}
		return paramsObject
	}

	export const addQueryParamToUrl = (
		baseUrl: TsType_String,
		queryParamKey: TsType_String,
		queryParamValue: TsType_String,
		includeCurrentQueryParams: TsType_Boolean
	): TsType_String => {
		// Instantiate Variables
		let newUrl: TsType_String = baseUrl
		let paramsObject: TsInterface_UnspecifiedObject = {}
		// Get existing query params
		if( includeCurrentQueryParams === true ){
			paramsObject = returnCurrentUrlQueryParamsAsObject()
		}
		// Add new query param
		paramsObject[ queryParamKey ] = queryParamValue
		// Return new URL
		for( let loopKey in paramsObject ){
			if( newUrl.indexOf( "?" ) === -1 ){
				newUrl += "?" + loopKey + "=" + paramsObject[ loopKey ]
			} else {
				newUrl += "&" + loopKey + "=" + paramsObject[ loopKey ]
			}
		}
		return newUrl
	}

///////////////////////////////
// Component
///////////////////////////////

	export const TabsUrl = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let pr_tabs: TsInterface_ComponentProps["tabs"] = 							getProp( props, "tabs", [] )
		let pr_tabsSettings: TsInterface_ComponentProps["tabsSettings"] = 			getProp( props, "tabsSettings", {} )
		let pr_tabsColor: TsInterface_ComponentProps["tabsSettings"]["color"] = 	getProp( pr_tabsSettings, "color", "primary" )

		// Hooks - useContext, useState, useReducer, other
		// eslint-disable-next-line react-hooks/exhaustive-deps
		const ul_queryParams = 									new URLSearchParams( useLocation().search )
		// { sort-start } - hooks
		const [ us_showTabContent, us_setShowTabContent ] = 	useState< TsType_Boolean >( false )
		const [ us_tabIndex, us_setTabIndex ] = 				useState< TsType_Number >( 0 )
		const un_routerNaviation = 								useNavigate()
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			// Get current tab index from URL and Settings
			let tabIndex: TsType_Number = 0
			if (
				pr_tabsSettings != null &&
				pr_tabsSettings["tabQueryParam"] != null &&
				ul_queryParams != null &&
				ul_queryParams.get( pr_tabsSettings["tabQueryParam"] ) != null
			){
				let queryParamValue: TsType_String = ul_queryParams.get( pr_tabsSettings["tabQueryParam"] ) as TsType_String
				for( let i = 0; i < pr_tabs.length; i++ ){
					if( pr_tabs[i]["tabUrlKey"] === queryParamValue ){
						tabIndex = i
					}
				}
			}
			if(
				pr_tabsSettings != null &&
				pr_tabsSettings.overridePageTitle === true &&
				pr_tabsSettings.basePageTitle != null
			){
				if(
					pr_tabs != null &&
					pr_tabs[ tabIndex ] != null &&
					pr_tabs[ tabIndex ]["tabUrlKey"] != null
				){
					document.title = pr_tabsSettings.basePageTitle + " - " + underscoresToSpaces( pr_tabs[ tabIndex ]["tabUrlKey"] )
				} else {
					document.title = pr_tabsSettings.basePageTitle
				}
			}
			us_setTabIndex( tabIndex )
			us_setShowTabContent( true )
		}, [ pr_tabs, pr_tabsSettings, ul_queryParams ])

		// Other Variables

		// Functions

		// JSX Generation
		const returnJSX_TabContent = ( tabContent: TsType_JSX ): TsType_JSX => {
			let tabContentJSX = <></>
			if( us_showTabContent === true ){
				tabContentJSX = tabContent
			}
			return tabContentJSX
		}

		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX: TsType_JSX =
			<Box className="tw-w-full">
				<Tabs
					value={ us_tabIndex }
					onChange={ ( event, value ) => {
						if(
							pr_tabsSettings != null &&
							pr_tabsSettings["baseUrl"] != null &&
							pr_tabsSettings["tabQueryParam"] != null &&
							value != null &&
							pr_tabs != null &&
							pr_tabs[ value ] != null &&
							pr_tabs[ value ]["tabUrlKey"] != null
						){
							un_routerNaviation( addQueryParamToUrl( pr_tabsSettings["baseUrl"], pr_tabsSettings["tabQueryParam"], pr_tabs[ value ]["tabUrlKey"], true ) )
							// if( hasQueryParams( pr_tabsSettings["baseUrl"] ) === true ){
							// 	un_routerNaviation( pr_tabsSettings["baseUrl"] + "&" + pr_tabsSettings["tabQueryParam"] + "=" + pr_tabs[ value ]["tabUrlKey"] )
							// } else {
							// 	un_routerNaviation( pr_tabsSettings["baseUrl"] + "?" + pr_tabsSettings["tabQueryParam"] + "=" + pr_tabs[ value ]["tabUrlKey"] )
							// }
						} else {
							us_setTabIndex( value )
						}
						if ( pr_tabs != null && pr_tabs[ value ] != null && pr_tabs[ value ]["tabOnChange"] != null ){
							pr_tabs[ value ]["tabOnChange"]?.()
						}
					} }
					aria-label="tabs"
					variant="scrollable"
					scrollButtons="auto"
					textColor={ pr_tabsColor }
					indicatorColor={ pr_tabsColor }
					sx={{
						'& .MuiTabs-flexContainer': {
							// flexWrap: 'wrap',
							// width: "100%"
						},
					}}
				>
					{pr_tabs.map((tab, index ) => (
						<Tab key={ index } label={ tab.tabHeader } disabled={ tab.tabDisabled } {...accessibilityProps( index )} />
					))}
				</Tabs>
				{pr_tabs.map(( tab, index ) => (
					<TabPanel key={ index } value={ us_tabIndex } index={ index } settings={ pr_tabsSettings }>
						{ returnJSX_TabContent(tab.tabContent) }
					</TabPanel>
				))}
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}