//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Used to generate Form Dialogs

		TODO:
			[ ] Typescript - 2 instances of TsType_Any - function copied from MUI Docs
			[ ] Typescript - 1 instance of TsType_Unknown
	*/


///////////////////////////////
// Imports
///////////////////////////////

import React from 'react'
import {
	Trans
} from 'react-i18next'
import {
	Form,
	TsInterface_FormAdditionalData,
	TsInterface_FormData,
	TsInterface_FormHooksObject,
	TsInterface_FormInputs,
	TsInterface_FormSettings,
	TsInterface_FormSubmittedData
} from 'rfbp_core/components/form'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TsInterface_FormDialogObject,
	TsInterface_UserInterface_FormDialogInternalState,
	TsType_UserInterface_AllDialogsDismissCallback
} from 'rfbp_core/services/context'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_MuiAppBarColors,
	TsType_Unknown,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	AppBar,
	Box,
	Dialog,
	DialogContent,
	IconButton,
	Slide,
	Toolbar,
	Typography
} from '@mui/material/'
import {
	TransitionProps
} from '@mui/material/transitions'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_EDIT: TsType_JSX = 									<Trans>Edit</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////

	const Transition = React.forwardRef(function Transition(
		props: TransitionProps & {
			children: React.ReactElement< TsType_Any, TsType_Any >;
		},
		ref: React.Ref< TsType_Unknown >,
	) {
		return <Slide direction="up" ref={ ref } { ...props } />;
	})


///////////////////////////////
// Component
///////////////////////////////

	export const FormDialog = (
		form: TsInterface_FormDialogObject,
		dismissDialog: TsType_UserInterface_AllDialogsDismissCallback,
		FormDialogState: TsInterface_UserInterface_FormDialogInternalState,
		setFormDialogState: React.Dispatch< React.SetStateAction< TsInterface_UserInterface_FormDialogInternalState >>
	): TsType_JSX => {

		// Props
		let pr_formRootSettings: TsInterface_FormDialogObject["form"] =									getProp( form, "form", {} )
		let pr_dialogSettings: TsInterface_FormDialogObject["dialog"] =									getProp( form, "dialog", {} )
		let pr_formAdditionalData: TsInterface_FormDialogObject["form"]["formAdditionalData"] =			getProp( pr_formRootSettings, "formAdditionalData", {} )
		let pr_formData: TsInterface_FormDialogObject["form"]["formData"] =								getProp( pr_formRootSettings, "formData", {} )
		let pr_formInputs: TsInterface_FormDialogObject["form"]["formInputs"] =							getProp( pr_formRootSettings, "formInputs", {} )
		let pr_formOnChange: TsInterface_FormDialogObject["form"]["formOnChange"] =						getProp( pr_formRootSettings, "formOnChange", ( formAdditionalData: TsInterface_FormAdditionalData, formData: TsInterface_FormData, formInputs: TsInterface_FormInputs, formSettings: TsInterface_FormSettings ) => {} )
		let pr_formSettings: TsInterface_FormDialogObject["form"]["formSettings"] =						getProp( pr_formRootSettings, "formSettings", {} )
		let pr_formSubmission: TsInterface_FormDialogObject["form"]["formSubmission"] =					getProp( pr_formRootSettings, "formSubmission", ( formSubmittedData: TsInterface_FormSubmittedData, formAdditionalData: TsInterface_FormAdditionalData, formHooks: TsInterface_FormHooksObject ) => { return new Promise( ( resolve, reject ) => { resolve( { success: true } ) })})
		let pr_formDialogHeaderColor: TsInterface_FormDialogObject["dialog"]["formDialogHeaderColor"] =	getProp( pr_dialogSettings, "formDialogHeaderColor", "primary" )
		let pr_formDialogHeaderText: TsInterface_FormDialogObject["dialog"]["formDialogHeaderText"] =	getProp( pr_dialogSettings, "formDialogHeaderText", s_EDIT )
		let pr_formDialogIcon: TsInterface_FormDialogObject["dialog"]["formDialogIcon"] =				getProp( pr_dialogSettings, "formDialogIcon",  <Icon icon="pen-to-square" /> )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		let open: TsType_Boolean = true
		// pr_formSettings["submit_button_hide"] = false

		// Functions
		const handleClose = (): TsType_Void => {
			dismissDialog()
		}

		const formSubmissionProper = ( formSubmittedData: TsInterface_FormSubmittedData, formAdditionalData: TsInterface_FormAdditionalData, formHooks: TsInterface_FormHooksObject ) => {
			return new Promise( ( resolve, reject ) => {
				setFormDialogState({ submitting: true })
				pr_formSubmission( formSubmittedData, formAdditionalData, formHooks ).then(( res_FS ) => {
					setFormDialogState({ submitting: false })
					dismissDialog()
					resolve( res_FS )
				}).catch(( rej_FS ) => {
					setFormDialogState({ submitting: false })
					reject( rej_FS )
				})
			})
		}

		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				<Dialog
					open={ open }
					TransitionComponent={ Transition }
					keepMounted
					onClose={ handleClose }
					aria-labelledby="confirm-dialog-title"
					aria-describedby="confirm-dialog-description"
					className="bp_dialog_lg_width"
				>
					<AppBar position="static" color={ pr_formDialogHeaderColor as TsType_MuiAppBarColors }>
						<Toolbar>
							<IconButton
								disabled
								size="large"
								edge="start"
								color="inherit"
								aria-label="menu"
								sx={{ mr: 2, color: "#fff !important" }}
							>
								{ pr_formDialogIcon }
							</IconButton>
							<Typography component={ 'span' } variant={ 'h6' } sx={{ flexGrow: 1 }}>{ pr_formDialogHeaderText }</Typography>
						</Toolbar>
					</AppBar>
					<DialogContent>
						<Box>
							<Form
								// ref={ formRef }
								formAdditionalData={ pr_formAdditionalData }
								formData={ pr_formData }
								formInputs={ pr_formInputs }
								formOnChange={ pr_formOnChange }
								formSettings={ pr_formSettings }
								formSubmission={ formSubmissionProper }
							/>
						</Box>
					</DialogContent>
					{/* <DialogActions>
						{returnJSX_DialogActions()}
					</DialogActions> */}
				</Dialog>
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}