//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	TsInterface_FormInput
} from 'rfbp_core/components/form'
import {
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	InputAdornment
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

	export const returnJSX_InputAdornment = (formInput: TsInterface_FormInput, direction: TsType_String): TsType_JSX => {
		let inputAdornmentJSX = <></>
		if( direction === "start" && formInput["startAdornment"] != null ){
			inputAdornmentJSX =
			<InputAdornment position="start">
				{ formInput["startAdornment"] }
			</InputAdornment>
		}
		if( direction === "end" && formInput["endAdornment"] != null ){
			inputAdornmentJSX =
			<InputAdornment position="end">
				{ formInput["endAdornment"] }
			</InputAdornment>
		}
		return inputAdornmentJSX
	}