//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Used to generate Prompt Dialogs

		TODO:
			[ ] Typescript - 2 instances of TsType_Any - function copied from MUI Docs
			[ ] Typescript - 1 instance of TsType_Unknown
	*/


///////////////////////////////
// Imports
///////////////////////////////

import React from 'react'
import {
	TsInterface_CustomDialogObject,
	TsType_UserInterface_AllDialogsDismissCallback
} from 'rfbp_core/services/context'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_Unknown,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Dialog,
	Slide
} from '@mui/material/'
import {
	TransitionProps
} from '@mui/material/transitions'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

	const Transition = React.forwardRef(function Transition(
		props: TransitionProps & {
			children: React.ReactElement< TsType_Any, TsType_Any >;
		},
		ref: React.Ref< TsType_Unknown >,
	) {
		return <Slide direction="up" ref={ ref } { ...props } />;
	})


///////////////////////////////
// Component
///////////////////////////////

	export const CustomDialog = (
		dialog: TsInterface_CustomDialogObject,
		dismissDialog: TsType_UserInterface_AllDialogsDismissCallback
	): TsType_JSX => {

		// Props
		let pr_maxWidth: TsInterface_CustomDialogObject["settings"]["max_width"] = 			getProp( dialog.settings, "max_width", "md" )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		let open: TsType_Boolean = true

		// Functions
		const handleClose = (): TsType_Void => {
			dismissDialog()
		}



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				<Dialog
					TransitionComponent={ Transition }
					aria-describedby="alert-dialog-description"
					aria-labelledby="alert-dialog-title"
					fullWidth={ true }
					keepMounted
					maxWidth={ pr_maxWidth }
					onClose={ handleClose }
					open={ open }
				>
					{ dialog.dialog_jsx }
				</Dialog>
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}