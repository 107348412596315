//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Used to generate Alert Dialogs

		TODO:
			[ ] Typescript - 2 instances of TsType_Any - function copied from MUI Docs
			[ ] Typescript - 1 instance of TsType_Unknown
	*/


///////////////////////////////
// Imports
///////////////////////////////

import React from 'react'
import {
	TsInterface_SnackbarObject
} from 'rfbp_core/services/context'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsType_Boolean,
	TsType_JSX,
	TsType_Void,
	TsType_VoidFunction
} from 'rfbp_core/typescript/global_types'
import {
	Alert,
	Snackbar
} from '@mui/material/'
import Fade from '@mui/material/Fade'

///////////////////////////////
// Typescript
///////////////////////////////

	type TsType_SnackbarVerticalAlignmentOptions = "top" | "bottom"
	type TsType_SnackbarHorizontalAlignmentOptions = "left" | "center" | "right"
	// type TsType_SnackbarAnimationDirection = "left" | "up" | "right" | "down"

///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	// const s_ALERT: TsType_JSX = 						<Trans>Alert</Trans>
	// const s_AN_ALERT_HAS_OCCURRED: TsType_JSX = 		<Trans>An alert has occurred</Trans>
	// const s_DISMISS: TsType_JSX = 						<Trans>Dismiss</Trans>
	// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const SnackbarDisplay = (
		displaySnackbar: TsType_Boolean,
		snackbar: TsInterface_SnackbarObject,
		onSnackbarClose: TsType_VoidFunction
	): TsType_JSX => {

		// Props
		let pr_alertType: TsInterface_SnackbarObject["alertType"] = 						getProp( snackbar, "alertType", null )
		let pr_autoHideDuration: TsInterface_SnackbarObject["autoHideDuration"] = 			getProp( snackbar, "autoHideDuration", 3000 )
		let pr_horizontalAlignment: TsType_SnackbarHorizontalAlignmentOptions = 			getProp( snackbar, "horizontalAlignment", "right" )
		let pr_message: TsInterface_SnackbarObject["message"] = 							getProp( snackbar, "message", "" )
		let pr_verticalAlignment: TsType_SnackbarVerticalAlignmentOptions = 				getProp( snackbar, "verticalAlignment", "top" )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables

		// Functions
		// const SlideTransition = (props: SlideProps) => {
			// return <Slide {...props} direction={ animationDirection } />;
		// }

		// const GrowTransition = (props: GrowProps) => {
			// return <Grow {...props} />;
		// }


		const handleClose = (): TsType_Void => {
			onSnackbarClose()
		}



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX = <></>
			if ( pr_alertType != null ) {
				componentJSX =
				<Snackbar
					TransitionComponent = { Fade }
					anchorOrigin={{ vertical: pr_verticalAlignment, horizontal: pr_horizontalAlignment }}
					open={ displaySnackbar }
					autoHideDuration={ pr_autoHideDuration }
					onClose={ () => { handleClose() } }
					// action={action}
				>
					<Alert variant="filled" onClose={ () => { handleClose() } } severity={ pr_alertType } sx={{ width: '100%' }}>
						{ pr_message }
					</Alert>
				</Snackbar>
			} else {
				componentJSX =
				<Snackbar
					TransitionComponent = { Fade }
					anchorOrigin={{ vertical: pr_verticalAlignment, horizontal: pr_horizontalAlignment }}
					open={ displaySnackbar }
					autoHideDuration={ pr_autoHideDuration }
					onClose={ () => { handleClose() } }
					message={ pr_message }
					// action={action}
				/>
			}
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}