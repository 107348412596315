//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Used to generate Alert Dialogs

		TODO:
			[ ] Typescript - 2 instances of TsType_Any - function copied from MUI Docs
			[ ] Typescript - 1 instance of TsType_Unknown
	*/


///////////////////////////////
// Imports
///////////////////////////////

import React from 'react'
import {
	Trans
} from 'react-i18next'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TsInterface_AlertDialogObject,
	TsType_UserInterface_AllDialogsDismissCallback
} from 'rfbp_core/services/context'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_MuiAppBarColors,
	TsType_Unknown,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	AppBar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Slide,
	Toolbar,
	Typography
} from '@mui/material/'
import {
	TransitionProps
} from '@mui/material/transitions'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_ALERT: TsType_JSX = 						<Trans>Alert</Trans>
	const s_AN_ALERT_HAS_OCCURRED: TsType_JSX = 		<Trans>An alert has occurred</Trans>
	const s_DISMISS: TsType_JSX = 						<Trans>Dismiss</Trans>
	// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

	const Transition = React.forwardRef(function Transition(
		props: TransitionProps & {
			children: React.ReactElement< TsType_Any, TsType_Any >;
		},
		ref: React.Ref< TsType_Unknown >,
	) {
		return <Slide direction="up" ref={ref} {...props} />;
	})


///////////////////////////////
// Component
///////////////////////////////

	export const AlertDialog = (
		alert: TsInterface_AlertDialogObject,
		dismissDialog: TsType_UserInterface_AllDialogsDismissCallback
	): TsType_JSX => {

		// Props
		let pr_alertColor: TsInterface_AlertDialogObject["color"] = 		getProp( alert, "color", "info" )
		let pr_alertHeader: TsInterface_AlertDialogObject["header"] = 		getProp( alert, "header", s_ALERT )
		let pr_alertIcon: TsInterface_AlertDialogObject["icon"] = 			getProp( alert, "icon", <Icon icon="message-exclamation"/> )
		let pr_alertText: TsInterface_AlertDialogObject["text"] = 			getProp( alert, "text", s_AN_ALERT_HAS_OCCURRED )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		let open: TsType_Boolean = true

		// Functions
		const handleClose = (): TsType_Void => {
			dismissDialog()
		}



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				<Dialog
					TransitionComponent={ Transition }
					aria-describedby="alert-dialog-description"
					aria-labelledby="alert-dialog-title"
					className="bp_dialog_md_width"
					keepMounted
					onClose={ handleClose }
					open={ open }
				>
					<AppBar position="static" color={ pr_alertColor as TsType_MuiAppBarColors }>
						<Toolbar>
							<IconButton
								aria-label="menu"
								color="inherit"
								disabled
								edge="start"
								size="large"
								sx={{ mr: 2, color: "#fff !important" }}
							>
								{ pr_alertIcon }
							</IconButton>
							<Typography component={'span'} variant={ 'h6' } sx={{ flexGrow: 1 }}>{ pr_alertHeader }</Typography>
						</Toolbar>
					</AppBar>
					<DialogContent>
						<Box>
							<Typography variant="subtitle1" display="block">{ pr_alertText }</Typography>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button color={ pr_alertColor } onClick={ handleClose } variant="outlined">{ s_DISMISS }</Button>
					</DialogActions>
				</Dialog>
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}