//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Generic unbranded login page

		TODO:
			[ ] Feature - Get Login email working

	*/


///////////////////////////////
// Imports
///////////////////////////////

// @ts-expect-error
import logoSrc from 'app/images/logos/logo_gradient.png'
import {
	useContext,
	useEffect,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	Form,
	TsInterface_FormInputs,
	TsInterface_FormSettings,
	TsType_FormOnChange,
	TsType_FormSubmission
} from 'rfbp_core/components/form'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	Context_UserInterface_AlertDialog
} from 'rfbp_core/services/context'
import {
	authWithPassword,
	sendPasswordResetToEmail
} from 'rfbp_core/services/user_authentication'
import {
	TsType_JSX
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_BACK_TO_LOGIN: TsType_JSX =											<Trans>Back to Log In</Trans>
	const s_EMAIL: TsType_JSX =													<Trans>Email</Trans>
	const s_EMAIL_SENT_SUCCESSFULLY: TsType_JSX = 								<Trans>Email Sent Successfully</Trans>
	const s_FORGOT_PASSWORD: TsType_JSX =										<Trans>Forgot Password</Trans>
	const s_LOG_IN: TsType_JSX =												<Trans>Log In</Trans>
	const s_PASSWORD: TsType_JSX =												<Trans>Password</Trans>
	const s_SEND_PASSWORD_RESET_EMAIL: TsType_JSX = 							<Trans>Send Password Reset Email</Trans>
	const s_YOUR_PASSWORD_RESET_EMAIL_SHOULD_ARRIVE_MOMENTARILY: TsType_JSX = 	<Trans>Your password reset email should arrive momentarily</Trans>
	// { sort-end } - displayed text

	// Form Properties
	const formInputs_Login: TsInterface_FormInputs = {
		email: {
			data_type: "string",
			input_type: "text_basic",
			key: "email",
			label: s_EMAIL,
			required: true,
			startAdornment: <Icon icon="user" type="light"/>
		},
		password: {
			data_type: "string",
			input_type: "text_password",
			key: "password",
			label: s_PASSWORD,
			required: true,
			startAdornment: <Icon icon="lock-keyhole" type="light"/>
		},
	}

	const formSettings_Login: TsInterface_FormSettings = {
		submit_button_theme: "info",
		submit_button_alignment: "center",
		submit_button_hide: false,
		submit_button_icon: <Icon icon="right-to-bracket"/>,
		submit_button_saving_icon: true,
		submit_button_text: <>{s_LOG_IN}</>,
	}

	const formInputs_ForgotEmail: TsInterface_FormInputs = {
		email: {
			data_type: "string",
			input_type: "text_basic",
			key: "email",
			label: s_EMAIL,
			required: true,
			startAdornment: <Icon icon="user" type="light"/>
		},
	}

	const FormSettings_ForgotEmail: TsInterface_FormSettings = {
		submit_button_theme: "error",
		submit_button_alignment: "center",
		submit_button_hide: false,
		submit_button_icon: <Icon icon="paper-plane" />,
		submit_button_saving_icon: true,
		submit_button_text: <>{s_SEND_PASSWORD_RESET_EMAIL}</>,
	}


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const AuthWithoutRedirect: React.FC = (): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// const un_routerNaviation = 																	useNavigate()
		const [ us_formAdditionalData_ForgotEmail, us_setFormAdditionalData_ForgotEmail ] = 		useState({})
		const [ us_formAdditionalData_Login, us_setFormAdditionalData_Login ] = 					useState({})
		const [ us_formData_ForgotEmail, us_setFormData_ForgotEmail ] = 							useState({})
		const [ us_formData_Login, us_setFormData_Login ] = 										useState({})
		const [ us_pageType, us_setPageType ] = 													useState("log_in")
		const { uc_setUserInterface_AlertDialogDisplay } = 											useContext(Context_UserInterface_AlertDialog)
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			us_setFormData_Login({})
			us_setFormAdditionalData_Login({})
			us_setFormData_ForgotEmail({})
			us_setFormAdditionalData_ForgotEmail({})
			return () => {}
		}, [])

		// Other Variables

		// Functions
		const formOnChange_Login: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
			// Nothing
		}

		const formSubmission_Login: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
			return new Promise((resolve, reject) => {
				authWithPassword(formSubmittedData.email, formSubmittedData.password).then((res_AWP) => {
					// un_routerNaviation( ApplicationPages.HomePage.url() )
					resolve(res_AWP)
				}).catch((rej_AWP) => {
					reject(rej_AWP)
				})
			})
		}

		const formOnChange_ForgotEmail: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
			// Nothing
		}

		const formSubmission_ForgotEmail: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
			return new Promise((resolve, reject) => {
				sendPasswordResetToEmail(formSubmittedData.email).then((res_SPRTE) => {
					uc_setUserInterface_AlertDialogDisplay({
						display: true,
						alert: {
							color: "info",
							header: s_EMAIL_SENT_SUCCESSFULLY,
							icon: <Icon icon="paper-plane"/>,
							text: s_YOUR_PASSWORD_RESET_EMAIL_SHOULD_ARRIVE_MOMENTARILY,
						}
					})
					us_setPageType("log_in")
					resolve(res_SPRTE)
				}).catch((rej_SPRTE) => {
					reject(rej_SPRTE)
				})
			})
		}

		// Call Functions

		// JSX Generation
		const returnJSX_Page = (): TsType_JSX => {
			let loginFormJSX: TsType_JSX = <></>
			if(us_pageType === "log_in"){
				loginFormJSX =
				<Box>
					<Box className="tw-px-4 tw-mx-auto tw-mb-4 tw-mt-2 tw-max-w-lg">
						<Form
							formAdditionalData={ us_formAdditionalData_Login }
							formData={ us_formData_Login }
							formInputs={ formInputs_Login }
							formOnChange={ formOnChange_Login }
							formSettings={ formSettings_Login }
							formSubmission={ formSubmission_Login }
						/>
					</Box>
					<Box className="tw-mb-16 tw-mt-4">
						<Button variant="text" onClick={() => { us_setPageType("forgot_email") }}>{s_FORGOT_PASSWORD}</Button>
					</Box>
				</Box>
			} else if(us_pageType === "forgot_email"){
				loginFormJSX =
				<Box>
					<Box className="tw-px-4 tw-mx-auto tw-mb-4 tw-mt-2 tw-max-w-lg">
						<Form
							formAdditionalData={ us_formAdditionalData_ForgotEmail }
							formData={ us_formData_ForgotEmail }
							formInputs={ formInputs_ForgotEmail }
							formOnChange={ formOnChange_ForgotEmail }
							formSettings={ FormSettings_ForgotEmail }
							formSubmission={ formSubmission_ForgotEmail }
						/>
					</Box>
					<Box className="tw-mb-16 tw-mt-4">
						<Button variant="text" onClick={() => { us_setPageType("log_in") }}>{s_BACK_TO_LOGIN}</Button>
					</Box>
				</Box>
			}
			let pageJSX =
			<Box className="tw-text-center tw-m-auto">
				<Box className="tw-mt-6">
					<img
						src={ logoSrc }
						alt=""
						loading="lazy"
						width={"200px"}
						className="tw-mr-1"
					/>
				</Box>
				{loginFormJSX}
			</Box>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>
	}