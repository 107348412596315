///////////////////////////////
// Description
///////////////////////////////

	// See config/standard_database_endpoints for documentation

///////////////////////////////
// Imports
///////////////////////////////

import {
	collection,
	CollectionReference,
	doc,
	DocumentData,
	DocumentReference,
	getFirestore
} from 'firebase/firestore'
import {
	TsType_String
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

// Main
export const DatabaseRef_TicketImports_Collection =
	( clientKey: TsType_String ): CollectionReference< DocumentData > => {
	return collection( getFirestore(), "clients", clientKey, "jobs", "ticket_imports", "main" ) }

export const DatabaseRef_TicketImports_Document =
	( clientKey: TsType_String, sessionKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "jobs", "ticket_imports", "main", sessionKey ) }

// Raw Data
export const DatabaseRef_TicketImports_RawData_Collection =
	( clientKey: TsType_String, sessionKey: TsType_String ): CollectionReference< DocumentData > => {
	return collection( getFirestore(), "clients", clientKey, "jobs", "ticket_imports", "main", sessionKey, "raw_data" ) }

export const DatabaseRef_TicketImports_RawData_Document =
	( clientKey: TsType_String, sessionKey: TsType_String, rawDataKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "jobs", "ticket_imports", "main", sessionKey, "raw_data", rawDataKey ) }

// Raw Headers
export const DatabaseRef_TicketImports_RawDataHeaders_Document =
	( clientKey: TsType_String, sessionKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "jobs", "ticket_imports", "main", sessionKey, "raw_data_headers", "all" ) }

// Mapped Data
export const DatabaseRef_TicketImports_MappedData_Collection =
	( clientKey: TsType_String, sessionKey: TsType_String ): CollectionReference< DocumentData > => {
	return collection( getFirestore(), "clients", clientKey, "jobs", "ticket_imports", "main", sessionKey, "mapped_data" ) }

export const DatabaseRef_TicketImports_MappedData_Document =
	( clientKey: TsType_String, sessionKey: TsType_String, mappedDataKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "jobs", "ticket_imports", "main", sessionKey, "mapped_data", mappedDataKey ) }

// Associations
export const DatabaseRef_TicketImports_Associations_Collection =
	( clientKey: TsType_String, sessionKey: TsType_String ): CollectionReference< DocumentData > => {
	return collection( getFirestore(), "clients", clientKey, "jobs", "ticket_imports", "main", sessionKey, "associations" ) }

export const DatabaseRef_TicketImports_Associations_Document =
	( clientKey: TsType_String, sessionKey: TsType_String, associationKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "jobs", "ticket_imports", "main", sessionKey, "associations", associationKey ) }


// TODO - associations