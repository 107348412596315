//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			This form input is a diagnostic tool that displays the formAdditionalData prop as JSON

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////


import {
	Trans
} from 'react-i18next'
import {
	Json
} from 'rfbp_core/components/code_display'
import {
	TsInterface_FormAdditionalData,
	TsInterface_FormData,
	TsInterface_FormHooksObject,
	TsInterface_FormInput,
	TsInterface_FormSettings,
	TsType_InputChangeCallback
} from 'rfbp_core/components/form'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsType_JSX
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		formAdditionalData: TsInterface_FormAdditionalData
		formData: TsInterface_FormData
		formHooks: TsInterface_FormHooksObject
		formInput: TsInterface_FormInput
		formSettings: TsInterface_FormSettings
		inputChangeCallback: TsType_InputChangeCallback
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_FORM_ADDITIONAL_DATA = <Trans>Form Additional Data</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const DisplayFormAdditionalDataJson = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let pr_formAdditionalData: TsInterface_FormData = 			getProp( props, "formAdditionalData", {} )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables

		// Functions

		// Generate JSX



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			// Form Input JSX
			let componentJSX =
			<Box>
				<Typography variant="body2" className="tw-text-left">{ s_FORM_ADDITIONAL_DATA }:</Typography>
				<Json alphebetized={ true } data={ pr_formAdditionalData }/>
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}