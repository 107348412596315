//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Components are reused segments of code used to create contend used to create containers (pages)

		TODO:
			[ ] Typescript - 1 instance of TsType_Any

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	TsInterface_DynamicAttributes,
	TsInterface_FormAdditionalData,
	TsInterface_FormData,
	TsInterface_FormHooksObject,
	TsInterface_FormInput,
	TsInterface_FormSettings,
	TsType_InputChangeCallback
} from 'rfbp_core/components/form'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Boolean,
	TsType_JSX,
	TsType_Number,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		formAdditionalData: TsInterface_FormAdditionalData
		formData: TsInterface_FormData
		formHooks: TsInterface_FormHooksObject
		formInput: TsInterface_FormInput
		formSettings: TsInterface_FormSettings
		inputChangeCallback: TsType_InputChangeCallback
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const MultipleSelectChecklist = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let pr_formData: TsInterface_FormData = 											getProp( props, "formData", {} )
		let pr_formInput: TsInterface_FormInput = 											getProp( props, "formInput", {} )
		let pr_formSettings: TsInterface_FormSettings = 									getProp( props, "formSettings", {} )
		let pr_inputChangeCallback: TsType_InputChangeCallback = 							props.inputChangeCallback

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables

		// Functions

		// Generate JSX



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			// Dynamic Class Name
			let dynamicClassName = ""
			// Dynamic Attributes
			let dynamicAttributes: TsInterface_DynamicAttributes = {}
			if ( pr_formInput["required"] === true ){
				dynamicAttributes["required"] = true
			}
			if ( pr_formInput["required"] === true && pr_formSettings.highlight_missing === true && ( pr_formData == null || pr_formData[ pr_formInput["key"] ] == null || pr_formData[ pr_formInput["key"] ] === "" )) {
				dynamicAttributes["error"] = true
			}
			if ( pr_formInput["disabled"] === true ){
				dynamicAttributes["disabled"] = true
			}
			// Options
			let formInputOptions = []
			if( pr_formInput != null && pr_formInput["options"] != null){
				formInputOptions = pr_formInput["options"]
			}

			// Check if Checked
			const checkIfChecked = ( option: TsInterface_UnspecifiedObject ): TsType_Boolean => {
				let checked = false
				if(
					option != null &&
					option["key"] != null &&
					pr_formInput != null &&
					pr_formInput["key"] != null &&
					pr_formData != null &&
					pr_formData[ pr_formInput["key"] ] != null &&
					// @ts-expect-error - not sure why ts linter is not recognizing the null check above
					pr_formData[ pr_formInput["key"] ][ option["key"] ] === true
				){
					checked = true
				}
				return checked
			}

			const changeCheckboxValue = ( option: TsInterface_UnspecifiedObject): TsType_Void => {
				if(
					option != null &&
					option["key"] != null &&
					pr_formInput != null &&
					pr_formInput["key"] != null
				){
					if( pr_formData == null ){ pr_formData = {} }
					if( pr_formData[ pr_formInput["key"] ] == null ){ pr_formData[ pr_formInput["key"] ] = {} }
					// @ts-expect-error - not sure why ts linter is not recognizing the null check above
					if( pr_formData[ pr_formInput["key"] ][ option["key"] ] == null || pr_formData[ pr_formInput["key"] ][ option["key"] ] === false ){
						// @ts-expect-error - not sure why ts linter is not recognizing the null check above
						pr_formData[ pr_formInput["key"] ][ option["key"] ] = true
					} else {
						// @ts-expect-error - not sure why ts linter is not recognizing the null check above
						pr_formData[ pr_formInput["key"] ][ option["key"] ] = false
					}
				}
				pr_inputChangeCallback( pr_formInput.key, pr_formData[ pr_formInput["key"] ], true )
			}

			// Form Input JSX
			let componentJSX =
			<Box>
				<FormControl className={ dynamicClassName } fullWidth>
					<FormLabel component="legend">{ pr_formInput["label"] }</FormLabel>
					<FormGroup className="tw-ml-8">
						{ formInputOptions.map(( option: TsInterface_UnspecifiedObject, index: TsType_Number ) => (
							<FormControlLabel
								disabled={ option.disabled }
								key={ index }
								control={
									<Checkbox
										checked={ checkIfChecked(option) }
										onChange={ () => { changeCheckboxValue( option ) } }
										{ ...dynamicAttributes }
									/>
								}
								label={ option.value }
							/>
						))}
					</FormGroup>
				</FormControl>
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}