//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Used to Initialize Firebase Instance

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////


import {
	initializeApp
} from 'firebase/app'

///////////////////////////////
// Variables
///////////////////////////////

	const firebaseConfig = {
		apiKey: "AIzaSyA4T-8uG_zlBdlcr5amAeORNy0jmbjbevE",
		authDomain: "load-call.firebaseapp.com",
		databaseURL: "https://load-call.firebaseio.com",
		projectId: "load-call",
		storageBucket: "load-call.appspot.com",
		messagingSenderId: "555263684869",
		appId: "1:555263684869:web:785b653ba4e233d01e876c",
		measurementId: "G-CJJLPP2L5Y"
	}


///////////////////////////////
// Functions
///////////////////////////////

	initializeApp(firebaseConfig)


///////////////////////////////
// Exports
///////////////////////////////

	export default function FirebaseInit(){
		// Initialization occurs just from loading file BEFORE app.js file is loaded
	}