//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Components are reused segments of code used to create contend used to create containers (pages)

		TODO:
			[ ] Conditional Require is always 1 render behind...

	*/


///////////////////////////////
// Imports
///////////////////////////////

import React, {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useReducer,
	useRef,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useNavigate
} from 'react-router-dom'
import {
	AssociationAutocompleteComboBox,
	BooleanCheckbox,
	BooleanSwitch,
	CustomFormInputJSX,
	DisplayFormAdditionalDataJson,
	DisplayFormDataJson,
	DisplayFormSettingsJson,
	MultipleChoiceRadio,
	MultipleChoiceSelect,
	MultipleChoiceSelectStateUSA,
	MultipleSelectChecklist,
	MultipleSelectDropdown,
	PhoneNumberUSA,
	TextBasic,
	TextMultiline,
	TextNumber,
	TextPassword,
	TimestampDate,
	TimestampDatetime,
	TimestampTime,
	TsInterface_FormAdditionalData,
	TsInterface_FormData,
	TsInterface_FormDatabaseUpdateObject,
	TsInterface_FormHooksObject,
	TsInterface_FormInputs,
	TsInterface_FormSettings,
	TsInterface_FormSubmittedData,
	TsType_FormOnChange,
	TsType_FormSubmission,
	TsType_InputChangeCallback
} from 'rfbp_core/components/form'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	evaluateConditionLogic
} from 'rfbp_core/components/logic'
import {
	Context_RootData_ClientKey,
	Context_RootData_ClientPermissions,
	Context_RootData_ClientUser,
	Context_RootData_GlobalUser,
	Context_UserInterface_AlertDialog,
	Context_UserInterface_ConfirmDialog,
	Context_UserInterface_CustomDialog,
	Context_UserInterface_ErrorDialog,
	Context_UserInterface_FormDialog,
	Context_UserInterface_LoadingBar,
	Context_UserInterface_PromptDialog
} from 'rfbp_core/services/context'
import {
	getProp,
	returnDateFromUnknownDateFormat
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_GenericPromiseReject,
	TsType_JSX,
	TsType_Number,
	TsType_String,
	TsType_Unknown,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button
} from '@mui/material/'
import {
	MultipleChoiceSelectStateUSAAbbreviated
} from './inputs/multiple_choice_select_state_usa_abbreviated'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		formAdditionalData: TsInterface_FormAdditionalData,
		formData: TsInterface_FormData,
		formInputs: TsInterface_FormInputs,
		formOnChange: TsType_FormOnChange,
		formSettings: TsInterface_FormSettings,
		formSubmission: TsType_FormSubmission,
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_SUBMIT: TsType_JSX = 				<Trans>submit</Trans>
	// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const CleanFormData = ( formInputs: TsInterface_FormInputs, formData: TsInterface_FormData ): TsInterface_FormDatabaseUpdateObject => {
		// Instantiate Variables
		let cleanFormData: TsInterface_FormDatabaseUpdateObject = {}
		// Loop through Inputs
		for ( let formInputKey in formInputs ){
			let formInputValue = formData[ formInputKey ]
			let formInput = formInputs[ formInputKey ]
			if ( formInput.input_type === "file_upload" ) {
				if ( formData[ formInputKey + "_file_upload_key" ] != null ){
					cleanFormData[ formInputKey + "_file_upload_key" ] = formData[ formInputKey + "_file_upload_key" ]
				}
				if ( formData[ formInputKey + "_file_name" ] != null ){
					cleanFormData[ formInputKey + "_file_name" ] = formData[ formInputKey + "_file_name" ]
				}
				if ( formData[ formInputKey + "_file_extension" ] != null ){
					cleanFormData[ formInputKey + "_file_extension" ] = formData[ formInputKey + "_file_extension" ]
				}
			} else if ( formInput.input_type === "association_autocomplete_combo_box"){ // TODO - other associations here
				if ( formData[ formInputKey + "_key"] != null ){
					cleanFormData[ formInputKey + "_key"] = formData[ formInputKey + "_key"]
				}
				if ( formData[ formInputKey + "_name"] != null ){
					cleanFormData[ formInputKey + "_name"] = formData[ formInputKey + "_name"]
				}
			} else if ( formInput.input_type === "association_autocomplete_async_search" ){
				if ( formData[ formInputKey ] != null ){
					cleanFormData[ formInputKey ] = formData[ formInputKey ]
				}
				if ( formData[ formInputKey.substring(0, formInputKey.length - 4) + "_name" ] != null ){
					cleanFormData[ formInputKey.substring(0, formInputKey.length - 4) + "_name" ] = formData[ formInputKey.substring(0, formInputKey.length - 4) + "_name" ]
				}
			} else if ( formData[ formInputKey ] != null && formInputKey !== "key" ){
				if ( formInput.input_type === "timestamp_date" ){
					if (!isNaN( formInputValue as TsType_Number )){
						// Get Date Props
						let date = returnDateFromUnknownDateFormat( formInputValue as TsType_Number )
						let year = date.getFullYear()
						let month = date.getMonth() + 1
						let day = date.getDate()
						// Generate Composite
						let compositeDate = year.toString() + "-"
						if ( month < 10 ){
							compositeDate += "0" + month.toString() + "-"
						} else {
							compositeDate += month.toString() + "-"
						}
						if ( day < 10 ){
							compositeDate += "0" + day.toString()
						} else {
							compositeDate += day.toString()
						}
						// Set to field value
						formInputValue = compositeDate
					}
				} else if ( formInput.input_type === "timestamp_time" ) {
					if (!isNaN( formInputValue as TsType_Number )){
						// Get Date Props
						let date = returnDateFromUnknownDateFormat( formInputValue as TsType_Number )
						let hours = date.getHours() + 1
						let minutes = date.getMinutes()
						// Generate Composite
						if ( hours < 10 ){
							// @ts-expect-error
							hours = "0" + hours.toString()
						}
						if ( minutes < 10 ){
							// @ts-expect-error
							minutes = "0" + minutes.toString()
						} else {
							// @ts-expect-error
							minutes = minutes.toString()
						}
						let compositeDate = hours + ":" + minutes
						// Set to field value
						formInputValue = compositeDate
					}
				} else if ( formInput.input_type === "timestamp_datetime" ) {
					if ( !isNaN( formInputValue as TsType_Number ) ){
						// Get Date Props
						let date = returnDateFromUnknownDateFormat( formInputValue as TsType_Number )
						let year = date.getFullYear()
						let month = date.getMonth() + 1
						let day = date.getDate()
						let hours = date.getHours() + 1
						let minutes = date.getMinutes()
						// Generate Composite
						let compositeDate = year.toString() + "-"
						if ( month < 10 ){
							compositeDate += "0" + month.toString() + "-"
						} else {
							compositeDate += month.toString() + "-"
						}
						if ( day < 10 ){
							compositeDate += "0" + day.toString()
						} else {
							compositeDate += day.toString()
						}
						compositeDate += "T"
						if ( hours < 10 ){
							// @ts-expect-error
							hours = "0" + hours.toString()
						}
						compositeDate += hours
						if ( minutes < 10 ){
							// @ts-expect-error
							minutes = "0" + minutes.toString()
						} else {
							// @ts-expect-error
							minutes = minutes.toString()
						}
						compositeDate += ":"
						compositeDate += minutes
						// Set to field value
						formInputValue = compositeDate
					}
				} else if ( formInput["data_type"] === "number" ){
					formInputValue = parseFloat( formInputValue as TsType_String )
				} else if ( formInput["data_type"] === "boolean" ){
					if ( formInputValue === "true" ){
						formInputValue = true
					} else if ( formInputValue === "false" ) {
						formInputValue = false
					}
				}
				cleanFormData[ formInputKey ] = formInputValue
			}
		}
		// Return
		return cleanFormData
	}

	export const Form = forwardRef( ( props: TsInterface_ComponentProps, ref: React.ForwardedRef<TsType_Unknown> ): TsType_JSX => {

		// Props
		let pr_formAdditionalData: TsInterface_FormAdditionalData = 								getProp( props, "formAdditionalData", {} )
		let pr_formInitialData: TsInterface_FormData = 												getProp( props, "formData", {} )
		let pr_formInputs: TsInterface_FormInputs = 												getProp( props, "formInputs", {} )
		let pr_formSettings: TsInterface_FormSettings = 											getProp( props, "formSettings", {} )
		let pr_formOnChange: TsType_FormOnChange = 													getProp( props, "formOnChange", ( formAdditionalData: TsInterface_FormAdditionalData, formData: TsInterface_FormData, formInputs: TsInterface_FormInputs, formSettings: TsInterface_FormSettings ) => {} )
		let pr_formSubmission: TsType_FormSubmission = 												getProp( props, "formSubmission", ( formSubmittedData: TsInterface_FormSubmittedData, formAdditionalData: TsInterface_FormAdditionalData ) => { return new Promise( ( resolve, reject ) => { resolve( { success: true } ) }) } )
		let pr_submitButtonAlignmentStyle: TsInterface_FormSettings["submit_button_alignment"] = 	getProp( pr_formSettings, "submit_button_alignment", "right" )
		let pr_submitButtonHide: TsInterface_FormSettings["submit_button_hide"] = 					getProp( pr_formSettings, "submit_button_hide", false )
		let pr_submitButtonIcon: TsInterface_FormSettings["submit_button_icon"] = 					getProp( pr_formSettings, "submit_button_icon", null )
		let pr_submitButtonSavingIcon: TsInterface_FormSettings["submit_button_saving_icon"] = 		getProp( pr_formSettings, "submit_button_saving_icon", true )
		let pr_submitButtonText: TsInterface_FormSettings["submit_button_text"] = 					getProp( pr_formSettings, "submit_button_text", s_SUBMIT )
		let pr_submitButtonTheme: TsInterface_FormSettings["submit_button_theme"] = 				getProp( pr_formSettings, "submit_button_theme", "success" )
		let pr_updateFormEachKeystroke: TsInterface_FormSettings["update_form_each_keystroke"] = 	getProp( pr_formSettings, "update_form_each_keystroke", false )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_formBeingSubmitted, us_setFormBeingSubmitted ] = 	useState( false )
		const [ us_formData, us_setFormData ] = 						useState( pr_formInitialData )
		const [ us_formDisabled, us_setFormDisabled ] = 				useState( true )
		const [ us_inputDataOverrides, us_setInputDataOverrides ] = 	useState( {} )
		const [ us_inputRefsState, us_setInputRefsState ] = 			useState( {} )
		const un_routerNaviation = 										useNavigate()
		const ur_forceRerender = 										useReducer( () => ( {} ), {} )[1] as () => TsType_Void
		const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 	useContext( Context_RootData_ClientKey )
		const { uc_RootData_ClientPermissions } = 						useContext( Context_RootData_ClientPermissions )
		const { uc_RootData_ClientUser } = 								useContext( Context_RootData_ClientUser )
		const { uc_RootData_GlobalUser } = 								useContext( Context_RootData_GlobalUser )
		const { uc_setUserInterface_AlertDialogDisplay } = 				useContext( Context_UserInterface_AlertDialog )
		const { uc_setUserInterface_ConfirmDialogDisplay } = 			useContext( Context_UserInterface_ConfirmDialog )
		const { uc_setUserInterface_CustomDialogDisplay } = 			useContext( Context_UserInterface_CustomDialog )
		const { uc_setUserInterface_ErrorDialogDisplay } = 				useContext( Context_UserInterface_ErrorDialog )
		const { uc_setUserInterface_FormDialogDisplay } = 				useContext( Context_UserInterface_FormDialog )
		const { uc_setUserInterface_LoadingBarDisplay } = 				useContext( Context_UserInterface_LoadingBar )
		const { uc_setUserInterface_PromptDialogDisplay } = 			useContext( Context_UserInterface_PromptDialog )
		// { sort-end } - hooks

		useImperativeHandle( ref, () => ({
			updateFormData( newFormData: TsInterface_FormData ) {
				us_setFormData( newFormData )
				ur_forceRerender()
			}
		}))

		// Hooks - useEffect
		useEffect( () => {
			// Runs just on component load and enables submit button if form is valid
			setTimeout( () => { formDisableCheck( pr_formInputs, us_formData ) }, 1)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [ ] )

		// Other Variables
		const phoneRegex: RegExp = /^[0-9]{11}/
		const formHooks: TsInterface_FormHooksObject = {
			uc_RootData_ClientKey: uc_RootData_ClientKey,
			uc_RootData_ClientPermissions: uc_RootData_ClientPermissions,
			uc_RootData_ClientUser: uc_RootData_ClientUser,
			uc_RootData_GlobalUser: uc_RootData_GlobalUser,
			ur_forceRerender: ur_forceRerender,
			formBeingSubmitted: us_formBeingSubmitted,
			formDisabled: us_formDisabled,
			inputDataOverrides: us_inputDataOverrides,
			inputRefsState: us_inputRefsState,
			un_routerNaviation: un_routerNaviation,
			setFormBeingSubmitted: us_setFormBeingSubmitted,
			setFormDisabled: us_setFormDisabled,
			setInputDataOverrides: us_setInputDataOverrides,
			setInputRefsState: us_setInputRefsState,
			uc_setRootData_ClientKey: uc_setRootData_ClientKey,
			uc_setUserInterface_AlertDialogDisplay: uc_setUserInterface_AlertDialogDisplay,
			uc_setUserInterface_ConfirmDialogDisplay: uc_setUserInterface_ConfirmDialogDisplay,
			uc_setUserInterface_CustomDialogDisplay: uc_setUserInterface_CustomDialogDisplay,
			uc_setUserInterface_ErrorDialogDisplay: uc_setUserInterface_ErrorDialogDisplay,
			uc_setUserInterface_FormDialogDisplay: uc_setUserInterface_FormDialogDisplay,
			uc_setUserInterface_LoadingBarDisplay: uc_setUserInterface_LoadingBarDisplay,
			uc_setUserInterface_PromptDialogDisplay: uc_setUserInterface_PromptDialogDisplay,
			ur_useRef: useRef,
		}

		// Functions
		const formDisableCheck = ( formInputs: TsInterface_FormInputs, formData: TsInterface_FormData ): TsType_Void => {
			let disabledForm = false
			for ( let formInputKey in formInputs ){
				let formInput = formInputs[ formInputKey ]
				if (formInput["required"] === true && formInput["input_type"] === "phone_number_usa"){
					if ( formData == null || formData[ formInputKey ] == null ){
						disabledForm = true
					} else if ( formData != null && formData[ formInputKey ] != null ) {
						let phoneInputValue: TsType_String = formData[ formInputKey ]!.toString()
						if ( !phoneInputValue.match( phoneRegex ) ){
							disabledForm = true
						}
					}
				} else if (formInput["required"] === true && formInput["input_type"] === "association_autocomplete_combo_box"){ // TODO - other associations here
					if( formData == null || formData[ formInputKey + "_key" ] == null || formData[ formInputKey + "_key" ] === "" ){
						disabledForm = true
					}
					if( formData == null || formData[ formInputKey + "_name" ] == null || formData[ formInputKey + "_name" ] === "" ){
						disabledForm = true
					}
				} else if ( formInput["required"] === true) {
					if (
						formData == null ||
						formData[ formInputKey ] == null ||
						formData[ formInputKey ] === ""
					){
						disabledForm = true
					}
				}
			}
			us_setFormDisabled( disabledForm )
		}

		const inputChangeCallback: TsType_InputChangeCallback = ( formInputKey, value, triggerFormChange ): TsType_Void => {
			// Set form data
			if ( formInputKey != null ){
				us_formData[ formInputKey ] = value
				// setFormData(formData)
			}
			// Check Conditional Disable
			setTimeout( () => { formDisableCheck( pr_formInputs, us_formData ) }, 1)
			// Trigger external on change callback if declared
			if (
				( pr_formOnChange != null && triggerFormChange === true ) ||
				pr_updateFormEachKeystroke === true
			){
				pr_formOnChange( pr_formAdditionalData, us_formData, pr_formInputs, pr_formSettings )
			}
			if(
				pr_formInputs != null &&
				pr_formInputs[ formInputKey ] != null &&
				pr_formInputs[ formInputKey ]["submit_on_change"] === true
			){
				submitForm()
			}
			// Rerender
			ur_forceRerender()
		}

		const submitForm = (): TsType_Void => {
			us_setFormBeingSubmitted( true )
			uc_setUserInterface_LoadingBarDisplay( true )
			let dataUpdateObject = CleanFormData( pr_formInputs, us_formData )
			pr_formSubmission( dataUpdateObject, pr_formAdditionalData, formHooks ).then(( res_FS ) => {
				us_setFormBeingSubmitted( false )
				uc_setUserInterface_LoadingBarDisplay( false )
			}).catch(( rej_FS: TsInterface_GenericPromiseReject ) => {
				uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_FS.error })
				us_setFormBeingSubmitted( false )
				uc_setUserInterface_LoadingBarDisplay( false )
			})
		}



		// JSX Generation
		const returnJSX_SubmitButton = (): TsType_JSX => {
			let submitButtonJSX = <></>
			if ( pr_submitButtonHide !== true ){
				if ( us_formBeingSubmitted === true ){
					let savingIcon: TsType_JSX | null = <Icon icon="spinner" className="bp_spin"/>
					if ( pr_submitButtonSavingIcon === false ){ savingIcon = null }
					submitButtonJSX =
					<Button
						className="button_with_loading_icon"
						color={ pr_submitButtonTheme }
						disableElevation
						disabled
						onClick={ submitForm }
						startIcon={ savingIcon }
						type="submit"
						variant="contained"
					>
						{ pr_submitButtonText }
					</Button>
				} else if ( us_formDisabled === true ){
					submitButtonJSX =
					<Button
						color={ pr_submitButtonTheme }
						disableElevation
						disabled
						onClick={ submitForm }
						startIcon={ pr_submitButtonIcon }
						type="submit"
						variant="contained"
					>
						{ pr_submitButtonText }
					</Button>
				} else {
					submitButtonJSX =
					<Button
						color={ pr_submitButtonTheme }
						disableElevation
						onClick={ submitForm }
						startIcon={ pr_submitButtonIcon }
						type="submit"
						variant="contained"
					>
						{ pr_submitButtonText }
					</Button>
				}
			}
			return submitButtonJSX
		}

		const returnJSX_Component = (): TsType_JSX => {
			// Instantiate Variables
			let formInputsArray: TsType_JSX[] = []
			// Loop through inputs
			for ( let formInputKey in pr_formInputs ){
				let formInput = pr_formInputs[ formInputKey ]
				// Handle Conditional Display, Disable, and Require
				if (formInput["conditional_display"] != null && formInput["conditional_display"]["active"] === true && formInput["conditional_display"]["logic"] != null){
					let conditionalDisplay = evaluateConditionLogic(formInput["conditional_display"].logic, {formData: us_formData, formSettings: pr_formSettings, formAdditionalData: pr_formAdditionalData} )
					if (conditionalDisplay != null){ formInput["displayed"] = conditionalDisplay }
				}
				if (formInput["conditional_disable"] != null && formInput["conditional_disable"]["active"] === true && formInput["conditional_disable"]["logic"] != null){
					let conditionalDisable = evaluateConditionLogic(formInput["conditional_disable"].logic, {formData: us_formData, formSettings: pr_formSettings, formAdditionalData: pr_formAdditionalData} )
					if (conditionalDisable != null){ formInput["disabled"] = conditionalDisable }
				}
				if (formInput["conditional_require"] != null && formInput["conditional_require"]["active"] === true && formInput["conditional_require"]["logic"] != null){
					let conditionalRequire = evaluateConditionLogic(formInput["conditional_require"].logic, {formData: us_formData, formSettings: pr_formSettings, formAdditionalData: pr_formAdditionalData} )
					if (conditionalRequire != null){ formInput["required"] = conditionalRequire }
				}
				// If input is displayed
				if ( formInput["displayed"] !== false ){
					switch( formInput["input_type"] ){
						// case "association_autocomplete_async_search":			formInputsArray.push( <TextBasic inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "association_autocomplete_combo_box":				formInputsArray.push( <AssociationAutocompleteComboBox 			inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						// case "association_multiple_choice_radio":				formInputsArray.push( <TextBasic inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						// case "association_multiple_choice_select":				formInputsArray.push( <TextBasic inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						// case "autocomplete_combo_box":							formInputsArray.push( <TextBasic inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "boolean_checkbox":								formInputsArray.push( <BooleanCheckbox 							inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "boolean_switch":									formInputsArray.push( <BooleanSwitch 							inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "custom_form_input_jsx":							formInputsArray.push( <CustomFormInputJSX						inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } formInputs={ pr_formInputs } /> ); 	break
						case "display_form_additional_data_json":				formInputsArray.push( <DisplayFormAdditionalDataJson 			inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "display_form_data_json":							formInputsArray.push( <DisplayFormDataJson 						inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "display_form_settings_json":						formInputsArray.push( <DisplayFormSettingsJson 					inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						// case "file_upload":										formInputsArray.push( <TextBasic inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						// case "google_address_autocomplete":						formInputsArray.push( <GoogleAddressAutocomplete 		inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "multiple_choice_radio":							formInputsArray.push( <MultipleChoiceRadio 						inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "multiple_choice_select":							formInputsArray.push( <MultipleChoiceSelect 					inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "multiple_choice_select_state_usa":				formInputsArray.push( <MultipleChoiceSelectStateUSA 			inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "multiple_choice_select_state_usa_abbreviated":	formInputsArray.push( <MultipleChoiceSelectStateUSAAbbreviated 	inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "multiple_select_checklist":						formInputsArray.push( <MultipleSelectChecklist 					inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "multiple_select_dropdown":						formInputsArray.push( <MultipleSelectDropdown					inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						// case "multiple_select_dropdown_chips":					formInputsArray.push( <TextBasic inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						// case "number_rating":									formInputsArray.push( <TextBasic inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						// case "number_slider":									formInputsArray.push( <TextBasic inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "phone_number_usa":								formInputsArray.push( <PhoneNumberUSA 							inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						// case "signature":										formInputsArray.push( <TextBasic inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "text_basic": 										formInputsArray.push( <TextBasic 								inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "text_multiline":									formInputsArray.push( <TextMultiline 							inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "text_number":										formInputsArray.push( <TextNumber 								inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "text_password":									formInputsArray.push( <TextPassword 							inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "timestamp_date":									formInputsArray.push( <TimestampDate 							inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "timestamp_datetime":								formInputsArray.push( <TimestampDatetime						inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						case "timestamp_time":									formInputsArray.push( <TimestampTime 							inputChangeCallback={ inputChangeCallback } formAdditionalData={ pr_formAdditionalData } formData={ us_formData } formInput={ formInput } formSettings={ pr_formSettings } formHooks={ formHooks } /> ); 	break
						default: // Nothing
					}
				}
			}
			// JSX
			let componentJSX =
			<Box>
				<form name="form" onSubmit={ () => { submitForm() } }>
					{formInputsArray.map(( formInput, formInputIndex ) => (
						<Box key={ formInputIndex }>
							{ formInput }
						</Box>
					))}
				</form>
				<Box style={{ textAlign: pr_submitButtonAlignmentStyle }}>
					{ returnJSX_SubmitButton() }
				</Box>
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	} )