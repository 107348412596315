//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Renders a card that shows formatted JSON

		TODO:

	*/

	// ESLINT OVERRIDES:
		/*eslint no-useless-concat: "off"*/
		/*eslint no-useless-escape: "off"*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	themeVariables
} from 'rfbp_aux/config/app_theme' // OUTSIDE BOILERPLATE
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_String,
	TsType_Undefined
} from 'rfbp_core/typescript/global_types'
import {
	Card
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		alphebetized?: TsType_Boolean
		data: TsType_String | TsInterface_UnspecifiedObject
		include_functions?: TsType_Boolean
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text

	// Other
	let jsonCss = `
		pre.json_view {
			background: ` + themeVariables.background_json + ` !important;
			color: ` + themeVariables.white + `;
			text-align: left;
			padding: 6px;
			font-size: 14px;
			margin: 0px;
		}

		pre.json_view .key { color: ` + themeVariables.primary_light + ` !important; }
		pre.json_view .number { color: ` + themeVariables.warning_light + ` !important; }
		pre.json_view .string { color: ` + themeVariables.success_light + ` !important; }
		pre.json_view .null { color: ` + themeVariables.error_light + ` !important; }
		pre.json_view .boolean { color: ` + themeVariables.info_light + ` !important; }
		pre.json_view .timestamp_tooltip { color: ` + themeVariables.gray_800 + ` !important; }
	`


///////////////////////////////
// Functions
///////////////////////////////

	const syntaxHighlight = (
		object: TsType_String | TsInterface_UnspecifiedObject,
		alphebetized: TsType_Boolean | TsType_Undefined,
		includeFunctions: TsType_Boolean | TsType_Undefined
	): TsType_String => {
		if (typeof object === 'string' || object instanceof String) {
			// If the input is just a string, turn it into an object for display purposes
			return "<span class='" + "string" + " dt_" + object + "' >" + object + "</span>"
		} else {
			// Custom Json stringify function
			let STRIP_COMMENTS = /(\/\/.*$)|(\/\*[\s\S]*?\*\/)|(\s*=[^,\)]*(('(?:\\'|[^'\r\n])*')|("(?:\\"|[^"\r\n])*"))|(\s*=[^,\)]*))/mg
			const ARGUMENT_NAMES = /([^\s,]+)/g
			const getParamNames = (func: TsType_Any) => {
				let fnStr = func.toString().replace(STRIP_COMMENTS, '')
				let result = fnStr.slice(fnStr.indexOf('(')+1, fnStr.indexOf(')')).match(ARGUMENT_NAMES)
				if ( result === null )
					result = []
					return result
				}
			const customStringifyReplacer = (key: TsType_Any, value: TsType_Any) => {
				let functionName = ""
				if ( typeof value === 'function' ){
					let stringifiedParams = getParamNames( value ).join(', ')
					functionName = "(" + stringifiedParams + ") => { }"
				}
				return ( typeof value === 'function' ) ? functionName : value
			}
			// End Custom Json stringify function

			let json
			if ( includeFunctions === false ){
				if ( alphebetized === true ){
					json = JSON.stringify(cleanAlphebetizedJSON( object ), null, 4)
				} else {
					json = JSON.stringify(cleanJSON( object ), null, 4)
				}
			} else {
				if ( alphebetized === true ){
					json = JSON.stringify(cleanAlphebetizedJSON( object ), customStringifyReplacer, 4)
				} else {
					json = JSON.stringify(cleanJSON( object ), customStringifyReplacer, 4)
				}
			}
			json = json.replace(/&/g, "&amp").replace(/</g, "&lt").replace(/>/g, "&gt")
			return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, (match) => {
				let cleanMatch = match.replace(/['"]+/g, '')
				let cls = "number"
				if (/^"/.test(match)) {
					if (/:$/.test(match)) {
						cls = "key"
					} else {
						cls = "string"
					}
				} else if (/true|false/.test(match)) {
					cls = "boolean"
				} else if (/null/.test(match)) {
					cls = "null"
				}
				if ( cleanMatch === "string" || cleanMatch === "number" || cleanMatch === "boolean" ){
					return "<span class='" + cls + " dt_" + cleanMatch + "' >" + match + "</span>"
				} else if ( cleanMatch.indexOf("SUBCOLLECTION_") !== -1 ){
					return "<span class='" + cls + " dt_subcollection' >" + 'SUBCOLLECTION_' + "</span>" + "<span class='" + cls + "' >" + '"' + match.split("SUBCOLLECTION_")[1] + "</span>"
				} else if ( cleanMatch.indexOf("$random") !== -1 ){
					return "<span class='" + cls + " dt_subcollection' >" + match + "</span>"
				} else {
					return "<span class='" + cls + "' >" + match + "</span>"
				}
			})
		}
	}

	const cleanJSON = ( obj: TsInterface_UnspecifiedObject ): TsInterface_UnspecifiedObject => {
		let obj2: TsInterface_UnspecifiedObject = {}
		for ( let i in obj ){
			// if (i.substring(0, 1) !== "$") {
				obj2[ i ] = obj[ i ]
			// }
		}
		return obj2
	}

	const cleanAlphebetizedJSON = ( obj: TsInterface_UnspecifiedObject ): TsInterface_UnspecifiedObject => {
		let cleanObj: TsInterface_UnspecifiedObject = {};
		// Sort Cleaned JSON
		let keys = []
		for ( let k in obj ) {
			// if (k.substring(0, 1) !== "$" && obj.hasOwnProperty(k)) {
				keys.push( k )
			// }
		}
		keys.sort();
		for ( let i = 0; i < keys.length; i++ ) {
			let k = keys[ i ];
			cleanObj[ k ] = sortObjectByPropKey( obj[ k ] )
		}
		return cleanObj
	}

	const sortObjectByPropKey = ( obj: TsInterface_UnspecifiedObject | TsType_String ): TsInterface_UnspecifiedObject | TsType_String => {
		if ( typeof obj === 'string' || obj instanceof String ) {
			return obj
		} else if ( obj == null ){
			return obj
		} else if ( Array.isArray( obj ) ){
			// return obj
			let arrayObj = []
			for ( let i = 0; i < obj.length; i++ ) {
				arrayObj.push( sortObjectByPropKey( obj[ i ]) )
			}
			return arrayObj
		} else if ( typeof obj === 'object' ){
			let sortedObj: TsInterface_UnspecifiedObject = {}
			let keys = []
			for ( let k in obj ) {
				if ( obj.hasOwnProperty( k ) ) {
					keys.push( k )
				}
			}
			keys.sort();
			for ( let i = 0; i < keys.length; i++ ) {
				let k = keys[ i ]
				sortedObj[ k ] = sortObjectByPropKey( obj[ k ] )
			}
			return sortedObj
		} else {
			return obj
		}
	}


///////////////////////////////
// Component
///////////////////////////////

	export const Json = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let pr_alphebetized: TsInterface_ComponentProps["alphebetized"] = 				getProp( props, "alphebetized", false )
		let pr_data: TsInterface_ComponentProps["data"] = 								props.data
		let pr_includeFunctions: TsInterface_ComponentProps["include_functions"] = 		getProp( props, "include_functions", false )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		let html: TsType_String = syntaxHighlight( pr_data, pr_alphebetized, pr_includeFunctions )

		// Functions



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<div>
				<Card variant="outlined" style={{ "fontFamily": "monospace !important" }}>
					<pre className="json_view">
						<div dangerouslySetInnerHTML={{ __html: html}}></div>
					</pre>
				</Card>
				<style>{ jsonCss }</style>
			</div>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}