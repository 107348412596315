///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	useContext,
	useEffect,
	useReducer
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useNavigate
} from 'react-router-dom'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	DatabaseRef_TicketImports_Collection
} from 'rfbp_aux/services/database_endpoints/jobs/ticket_imports'
import {
	TsInterface_FormAdditionalData,
	TsInterface_FormData,
	TsInterface_FormHooksObject,
	TsInterface_FormInputs,
	TsInterface_FormSettings,
	TsInterface_FormSubmittedData
} from 'rfbp_core/components/form'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TableCellBasic,
	TableCellManage,
	TableCellTimestamp,
	TableDatabase,
	TsInterface_TableAdditionalData,
	TsInterface_TableColumns,
	TsInterface_TableDatabaseEndpointQueryObject,
	TsInterface_TableDatabaseSettings,
	TsInterface_TableDataRow,
	TsInterface_TableHooks
} from 'rfbp_core/components/table'
import {
	TableCellMissing
} from 'rfbp_core/components/table/cells/table_cell_missing'
import {
	TabsUrl
} from 'rfbp_core/components/tabs'
import {
	Context_RootData_ClientKey,
	Context_RootData_ClientUser,
	Context_UserInterface_ErrorDialog,
	Context_UserInterface_FormDialog
} from 'rfbp_core/services/context'
import {
	DatabaseAddDocument,
	generateDatabaseQuery,
	TsInterface_OrderByArray,
	TsInterface_QueryCursorsObject,
	TsInterface_QueryOperatorsArray
} from 'rfbp_core/services/database_management'
import {
	getProp,
	objectToArray
} from 'rfbp_core/services/helper_functions'
import {
	getClientKey
} from 'rfbp_core/services/user_authentication'
import {
	TsInterface_UnspecifiedObject,
	TsType_JSX,
	TsType_String,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button,
	Card,
	Chip
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey: TsType_String = ApplicationPages["ticketsImport"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_ACTIVE_SESSIONS: TsType_JSX = 					<Trans>Active Sessions</Trans>
	const s_ARCHIVED_SESSIONS: TsType_JSX = 				<Trans>Archived Sessions</Trans>
	const s_ASSOCIATIONS_BUILT: TsType_JSX = 				<Trans>Associations Built</Trans>
	const s_BACK_TO_TICKETS: TsType_JSX = 					<Trans>Back to tickets</Trans>
	const s_CANCELLED: TsType_JSX = 						<Trans>Cancelled</Trans>
	const s_COLUMNS_MAPPED: TsType_JSX = 					<Trans>Columns Mapped</Trans>
	const s_CREATED_BY: TsType_JSX = 						<Trans>Created By</Trans>
	const s_CREATE_IMPORT_SESSION: TsType_JSX = 			<Trans>Create Import Session</Trans>
	const s_CREATE_NEW_CRUDE_RUN_TICKETS: TsType_JSX = 		<Trans>Create new crude run tickets</Trans>
	const s_FILE_SELECTED: TsType_JSX = 					<Trans>File Selected</Trans>
	const s_IMPORTED: TsType_JSX = 							<Trans>Imported</Trans>
	const s_IMPORT_SESSION_TYPE: TsType_JSX = 				<Trans>Import Session Type</Trans>
	const s_IMPORT_TYPE: TsType_JSX = 						<Trans>Import Type</Trans>
	const s_NEW: TsType_JSX = 								<Trans>New</Trans>
	const s_NEW_IMPORT_SESSION: TsType_JSX = 				<Trans>New Import Session</Trans>
	const s_NEW_TICKETS: TsType_JSX = 						<Trans>New Tickets</Trans>
	const s_SESSION_START_TIME: TsType_JSX = 				<Trans>Session Start Time</Trans>
	const s_STATUS: TsType_JSX = 							<Trans>Status</Trans>
	const s_TICKET_COUNT: TsType_JSX = 						<Trans>Ticket Count</Trans>
	const s_TICKET_IMPORTS: TsType_JSX = 					<Trans>Ticket Imports</Trans>
	const s_UPDATE_EXISTING_CRUDE_RUN_TICKETS: TsType_JSX = <Trans>Update existing crude run tickets</Trans>
	const s_UPDATE_TICKETS: TsType_JSX = 					<Trans>Update Tickets</Trans>
	const s_VIEW: TsType_JSX = 								<Trans>View</Trans>
	const se_TICKET_IMPORTS: TsType_String =				"Ticket Imports"
	// { sort-end } - displayed text

	// Forms
	const ticketImportSessionTypes: TsInterface_UnspecifiedObject = {
		new_crude_run_tickets: {
			key: "new_crude_run_tickets",
			value: s_CREATE_NEW_CRUDE_RUN_TICKETS,
			short_name: s_NEW_TICKETS,
			icon: <Icon icon="sparkles" className="tw-pl-1"/>,
			color: "warning"
		},
		update_crude_run_tickets: {
			key: "update_crude_run_tickets",
			value: s_UPDATE_EXISTING_CRUDE_RUN_TICKETS,
			short_name: s_UPDATE_TICKETS,
			icon: <Icon icon="file-pen" className="tw-pl-1"/>,
			color: "warning"
		}
	}

	const formInputs_TicketImportSession: TsInterface_FormInputs = {
		import_session_type: {
			data_type: "string",
			input_type: "multiple_choice_radio",
			key: "import_session_type",
			label: s_IMPORT_TYPE,
			options: objectToArray( ticketImportSessionTypes ),
			required: true,
		},
	}

	// Table
	const tableColumns_TicketImportSessions: TsInterface_TableColumns = {
		timestamp_created: TableCellTimestamp( "timestamp_created", s_SESSION_START_TIME, "timestamp_created", "YYYY-MM-DD HH:MM", false ),
		import_session_type: {
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_IMPORT_SESSION_TYPE },
				header_sort_by: null
			},
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX = <></>
					if(
						rowData != null &&
						rowData.import_session_type != null &&
						ticketImportSessionTypes[ rowData.import_session_type as TsType_String ] != null
					){
						cellJSX =
						<Chip
							variant='filled'
							color={ ticketImportSessionTypes[ rowData.import_session_type as TsType_String ].color }
							label={ ticketImportSessionTypes[ rowData.import_session_type as TsType_String ].value }
							icon={ ticketImportSessionTypes[ rowData.import_session_type as TsType_String ].icon }
						/>
					}
					return cellJSX
				},
			},
		},
		status: {
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_STATUS },
				header_sort_by: null
			},
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX = <></>
					switch( rowData.substatus ){
						case "new":
							cellJSX =
							<Chip
								variant='filled'
								color="success"
								label={ s_NEW }
								icon={ <Icon icon="play" className="tw-pl-1"/> }
							/>
							break
						case "file_selected":
							cellJSX =
							<Chip
								variant='filled'
								color="success"
								label={ s_FILE_SELECTED }
								icon={ <Icon icon="file-arrow-up" className="tw-pl-1"/> }
							/>
							break
						case "columns_mapped":
							cellJSX =
							<Chip
								variant='filled'
								color="success"
								label={ s_COLUMNS_MAPPED }
								icon={ <Icon icon="map" className="tw-pl-1"/> }
							/>
							break
						case "associations_built":
							cellJSX =
							<Chip
								variant='filled'
								color="success"
								label={ s_ASSOCIATIONS_BUILT }
								icon={ <Icon icon="link" className="tw-pl-1"/> }
							/>
							break
						case "imported":
							cellJSX =
							<Chip
								variant='filled'
								color="info"
								label={ s_IMPORTED }
								icon={ <Icon icon="badge-check" className="tw-pl-1"/> }
							/>
							break
						case "cancelled":
							cellJSX =
							<Chip
								variant='filled'
								color="error"
								label={ s_CANCELLED }
								icon={ <Icon icon="trash-can-xmark" className="tw-pl-1"/> }
							/>
							break
						default:
					}
					return cellJSX
				},
			},
		},
		associated_creator_name: TableCellBasic( "associated_creator_name", s_CREATED_BY, "associated_creator_name" ),
		raw_data_count: TableCellMissing( "raw_data_count", s_TICKET_COUNT, "raw_data_count" ),
		manage: TableCellManage({
			view: {
				icon: <Icon type="solid" icon="magnifying-glass" />,
				label: <>{ s_VIEW }</>,
				onClick: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
					if ( rowData.key != null ){
						tableHooks.un_routerNaviation( ApplicationPages.ticketsImportView.url( rowData.key as TsType_String ))
					}
				}
			},
		})
	}

	const tableSettings_TicketImportSessions: TsInterface_TableDatabaseSettings = {
		rows_per_page: 25,
		show_header: true,
		size: "small",
		sort_direction: "desc",
		sort_property: "timestamp_created",
		use_live_data: true,
	}


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		// const params = useParams()
		// const itemKey: TsType_String = params.id as TsType_String

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const un_routerNaviation = 											useNavigate()
		const ur_forceRerender = 											useReducer(() => ({}), {})[1] as () => TsType_Void
		const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )
		const { uc_RootData_ClientUser } = 									useContext( Context_RootData_ClientUser )
		const { uc_setUserInterface_ErrorDialogDisplay } = 					useContext( Context_UserInterface_ErrorDialog )
		const { uc_setUserInterface_FormDialogDisplay } = 					useContext( Context_UserInterface_FormDialog )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			document.title = se_TICKET_IMPORTS
		}, [ ])

		useEffect(() => {
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey )
			return () => { }
		}, [ uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender ])

		// Other Variables

		// Functions
		const tableDatabaseEndpoint_ActiveTicketImportSessions = ( queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject, tableAdditionalData: TsInterface_TableAdditionalData ) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = [ { prop: "status", comparator: "==", value: "active" } ]
			let orderByArray: TsInterface_OrderByArray = [ { prop: "timestamp_created", desc: false } ]
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if (queryGenerationData["startAfter"] != null){	 	queryCursorsObject["startAfter"] = queryGenerationData.startAfter }
			if (queryGenerationData["startAt"] != null){ 		queryCursorsObject["startAt"] = queryGenerationData.startAt }
			if (queryGenerationData["endAt"] != null){ 			queryCursorsObject["endAt"] = queryGenerationData.endAt }
			if (queryGenerationData["endBefore"] != null){ 		queryCursorsObject["endBefore"] = queryGenerationData.endBefore }
			let limit = getProp( queryGenerationData, "limit", 20 )
			return generateDatabaseQuery(
				DatabaseRef_TicketImports_Collection( uc_RootData_ClientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}

		const tableDatabaseEndpoint_ArchivedTicketImportSessions = ( queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject, tableAdditionalData: TsInterface_TableAdditionalData ) => {
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = [ { prop: "status", comparator: "==", value: "archived" } ]
			let orderByArray: TsInterface_OrderByArray = [ { prop: "timestamp_created", desc: false } ]
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if (queryGenerationData["startAfter"] != null){	 	queryCursorsObject["startAfter"] = queryGenerationData.startAfter }
			if (queryGenerationData["startAt"] != null){ 		queryCursorsObject["startAt"] = queryGenerationData.startAt }
			if (queryGenerationData["endAt"] != null){ 			queryCursorsObject["endAt"] = queryGenerationData.endAt }
			if (queryGenerationData["endBefore"] != null){ 		queryCursorsObject["endBefore"] = queryGenerationData.endBefore }
			let limit = getProp( queryGenerationData, "limit", 20 )
			return generateDatabaseQuery(
				DatabaseRef_TicketImports_Collection( uc_RootData_ClientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}

		const createImportSession = (): TsType_Void => {
			uc_setUserInterface_FormDialogDisplay({
				display: true,
				form: {
					form: {
						formAdditionalData: {},
						formData: {},
						formInputs: formInputs_TicketImportSession,
						formOnChange: ( formAdditionalData: TsInterface_FormAdditionalData, formData: TsInterface_FormData, formInputs: TsInterface_FormInputs, formSettings: TsInterface_FormSettings ) => {},
						formSettings: {},
						formSubmission: ( formSubmittedData: TsInterface_FormSubmittedData, formAdditionalData: TsInterface_FormAdditionalData, formHooks: TsInterface_FormHooksObject ) => {
							return new Promise( ( resolve, reject ) => {
								let importSessionUpdateObject: TsInterface_UnspecifiedObject = {
									timestamp_created: new Date(),
									status: "active",
									substatus: "new",
									associated_creator_name: getProp(uc_RootData_ClientUser, "name", null),
									associated_creator_key: getProp(uc_RootData_ClientUser, "key", null),
									import_session_type: formSubmittedData.import_session_type
								}
								getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
									DatabaseAddDocument( DatabaseRef_TicketImports_Collection(res_GCK.clientKey), importSessionUpdateObject, true, {} ).then((res_DSMD) => {
										resolve(res_DSMD)
										un_routerNaviation( ApplicationPages.ticketsImportView.url( res_DSMD.key ) )
									}).catch((rej_DSMD) => {
										uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
									})
								}).catch(( rej_GCK ) => {
									uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
								})
							})
						},
					},
					dialog: {
						formDialogHeaderColor: "success",
						formDialogHeaderText: <>{ s_CREATE_IMPORT_SESSION }</>,
						formDialogIcon: <Icon type="solid" icon="pen-to-square"/>
					}
				}
			})
		}

		// Call Functions

		// JSX Generation
		const returnJSX_BackButton = (): TsType_JSX => {
			let buttonJSX =
			<Button
				color="inherit"
				variant="outlined"
				onClick={ () => { un_routerNaviation( ApplicationPages.ticketsIndex.url() ) } }
				disableElevation
				startIcon={ <Icon icon="chevron-left" /> }
				className="tw-mr-2"
			>
				{s_BACK_TO_TICKETS}
			</Button>
			return buttonJSX
		}

		const returnJSX_NewImportSessionButton = (): TsType_JSX => {
			let buttonJSX = <></>
			buttonJSX =
			<Button
				color="success"
				variant="contained"
				onClick={ () => {
					createImportSession()
				}}
				disableElevation
				startIcon={
					<Icon icon="circle-plus" type="solid" />
				}
			>
				{ s_NEW_IMPORT_SESSION }
			</Button>

			return buttonJSX
		}

		const returnJSX_ActiveImportSessionTable = (): TsType_JSX => {
			let tableJSX = <></>
			if( uc_RootData_ClientKey != null){
				tableJSX =
				<Box>
					<Card className="">
						<TableDatabase
							tableAdditionalData={ { visible_active_tasks: {} } }
							tableColumns={ tableColumns_TicketImportSessions }
							tableDatabaseEndpoint={ tableDatabaseEndpoint_ActiveTicketImportSessions }
							tableSettings={ tableSettings_TicketImportSessions }
						/>
					</Card>
				</Box>
			} else {
				tableJSX = <></>
			}
			return tableJSX
		}

		const returnJSX_ArchivedImportSessionTable = (): TsType_JSX => {
			let tableJSX = <></>
			if( uc_RootData_ClientKey != null){
				tableJSX =
				<Box>
					<Card className="">
						<TableDatabase
							tableAdditionalData={ { visible_active_tasks: {} } }
							tableColumns={ tableColumns_TicketImportSessions }
							tableDatabaseEndpoint={ tableDatabaseEndpoint_ArchivedTicketImportSessions }
							tableSettings={ tableSettings_TicketImportSessions }
						/>
					</Card>
				</Box>
			} else {
				tableJSX = <></>
			}
			return tableJSX
		}

		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			<AuthenticatedContainer pageHeader={s_TICKET_IMPORTS} pageKey={pageKey} content={
				<Box>
					<Box>
						{ returnJSX_BackButton() }
						{ returnJSX_NewImportSessionButton() }
					</Box>
					<Box className="tw-mt-2">
						<TabsUrl
							tabsSettings={ {
								baseUrl: ApplicationPages.ticketsImport.url(),
								tabQueryParam: "tab",
								overridePageTitle: true,
								basePageTitle: se_TICKET_IMPORTS
							} }
							tabs={[
								{
									tabUrlKey: "Active",
									tabHeader: s_ACTIVE_SESSIONS,
									tabContent: returnJSX_ActiveImportSessionTable()
								},
								{
									tabUrlKey: "Archived",
									tabHeader: s_ARCHIVED_SESSIONS,
									tabContent: returnJSX_ArchivedImportSessionTable()
								},

							]}
						/>
					</Box>
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>

	}