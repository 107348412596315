//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Components are reused segments of code used to create contend used to create containers (pages)

		TODO:
			[ ] Typescript - 1 instance of TsType_Any

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	TsInterface_DynamicAttributes,
	TsInterface_FormAdditionalData,
	TsInterface_FormData,
	TsInterface_FormHooksObject,
	TsInterface_FormInput,
	TsInterface_FormSettings,
	TsType_InputChangeCallback
} from 'rfbp_core/components/form'
import {
	arrayToObject,
	cloneObjectWithoutReference,
	dynamicSort,
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_Number,
	TsType_Unknown,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		formAdditionalData: TsInterface_FormAdditionalData
		formData: TsInterface_FormData
		formHooks: TsInterface_FormHooksObject
		formInput: TsInterface_FormInput
		formSettings: TsInterface_FormSettings
		inputChangeCallback: TsType_InputChangeCallback
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const MultipleSelectDropdown = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let pr_formData: TsInterface_FormData = 											getProp( props, "formData", {} )
		let pr_formInput: TsInterface_FormInput = 											getProp( props, "formInput", {} )
		let pr_formSettings: TsInterface_FormSettings = 									getProp( props, "formSettings", {} )
		let pr_inputChangeCallback: TsType_InputChangeCallback = 							props.inputChangeCallback

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables

		// Functions
		const returnLabelText = (): TsType_JSX => {
			let labelJSX = <></>
			if( pr_formInput["required"] === true ){
				labelJSX = <>{ pr_formInput["label"] }{" *"}</>
			} else {
				labelJSX = <>{ pr_formInput["label"] }</>
			}
			return labelJSX
		}

		// Generate JSX



		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			// Dynamic Class Name
			let dynamicClassName = ""
			// Dynamic Attributes
			let dynamicAttributes: TsInterface_DynamicAttributes = {}
			if ( pr_formInput["required"] === true ){
				dynamicAttributes["required"] = true
			}
			if ( pr_formInput["required"] === true && pr_formSettings.highlight_missing === true && ( pr_formData == null || pr_formData[ pr_formInput["key"] ] == null || pr_formData[ pr_formInput["key"] ] === "" )) {
				dynamicAttributes["error"] = true
			}
			if ( pr_formInput["disabled"] === true ){
				dynamicAttributes["disabled"] = true
			}
			// Options
			let formInputOptions = []
			if( pr_formInput != null && pr_formInput["options"] != null){
				formInputOptions = pr_formInput["options"]
			}

			// Check if Checked
			const checkIfChecked = ( option: TsInterface_UnspecifiedObject ): TsType_Boolean => {
				let checked = false
				if(
					option != null &&
					option["key"] != null &&
					pr_formInput != null &&
					pr_formInput["key"] != null &&
					pr_formData != null &&
					pr_formData[ pr_formInput["key"] ] != null &&
					// @ts-expect-error - not sure why ts linter is not recognizing the null check above
					pr_formData[ pr_formInput["key"] ][ option["key"] ] === true
				){
					checked = true
				}
				return checked
			}

			const changeCheckboxValue = ( option: TsInterface_UnspecifiedObject): TsType_Void => {
				if(
					option != null &&
					option.target != null &&
					option.target.value != null &&
					option.target.value[0] != null
				){
					let optionKey = option.target.value[0]
					if( pr_formData == null ){ pr_formData = {} }
					if( pr_formData[ pr_formInput["key"] ] == null ){ pr_formData[ pr_formInput["key"] ] = {} }
					// @ts-expect-error - not sure why ts linter is not recognizing the null check above
					if( pr_formData[ pr_formInput["key"] ][ optionKey ] == null || pr_formData[ pr_formInput["key"] ][ optionKey ] === false ){
						// @ts-expect-error - not sure why ts linter is not recognizing the null check above
						pr_formData[ pr_formInput["key"] ][ optionKey ] = true
					} else {
						// @ts-expect-error - not sure why ts linter is not recognizing the null check above
						pr_formData[ pr_formInput["key"] ][ optionKey ] = false
						// delete formData[ formInput["key"] ][ optionKey ]
					}
				}
				pr_inputChangeCallback( pr_formInput.key, pr_formData[ pr_formInput["key"] ], true )
			}

			const returnInputDataAsArray = (): TsType_Any[] => {
				let formattedInputData: TsType_Any[] = []
				// Causes problems to send this over in value but an empty array works with custom render that always displays even when empty
				return formattedInputData
			}

			// Form Input JSX
			let componentJSX =
			<Box>
				<Box style={{width: "100%", height: "16px"}}></Box>
				<FormControl className={ dynamicClassName } fullWidth>
					<InputLabel shrink={ true } id={ pr_formInput["key"] }>{ returnLabelText() }</InputLabel>
					<Select
						color="primary"
						value={ returnInputDataAsArray() }
						id={ pr_formInput["key"] }
						label={ returnLabelText() }
						multiple
						onChange={ ( option ) => {
							changeCheckboxValue( option )
						} }
						displayEmpty={ true }
						renderValue={
							(selected: TsType_Any) => {
								let displayString = ""
								if(
									pr_formInput != null &&
									pr_formInput["key"] != null &&
									pr_formData != null &&
									pr_formData[ pr_formInput["key"] ] != null &&
									pr_formInput["options"] != null
								){
									let optionsObject = arrayToObject( pr_formInput["options"] )
									let loopOptions: TsInterface_UnspecifiedObject = cloneObjectWithoutReference( pr_formData[ pr_formInput["key"] ] as TsType_Unknown as TsInterface_UnspecifiedObject )
									for( let loopOptionKey in loopOptions ){
										if( loopOptions[ loopOptionKey ] === true ){
											if(
												optionsObject != null &&
												optionsObject[ loopOptionKey ] != null &&
												optionsObject[ loopOptionKey ]["value"] != null
											){
												if( displayString.length > 0 ){
													displayString += ", "
												}
												displayString += optionsObject[ loopOptionKey ]["value"]
											}
										}
									}
								}
								return displayString
							}
						}
						variant="outlined"
						notched={ true }
					>
						{ formInputOptions.sort( dynamicSort("value", "asc") ).map(( option: TsInterface_UnspecifiedObject, index: TsType_Number ) => (
							<MenuItem
								disabled={ option.disabled }
								key={ index }
								value={option.key}
							>
								<Checkbox checked={ checkIfChecked(option) } />
								<ListItemText primary={option.value} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Box style={{width: "100%", height: "16px"}}></Box>
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}