//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Main home page for authenticated users

		TODO:
			[ ] Auto navigate to proper page based on user role

	*/


///////////////////////////////
// Imports
///////////////////////////////

// @ts-expect-error
import logoSrc from 'app/images/logos/logo_gradient.png'
import React, {
	useEffect
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	returnLoadCallDocumentTitle
} from 'rfbp_aux/config/app_version'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey: TsType_String = ApplicationPages["HomePage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_HOME: TsType_JSX = 				<Trans>Home</Trans>
	const s_VERSION_2: TsType_JSX = 		<Trans>Version 2</Trans>
	const se_HOME: TsType_String = 			"Home"
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		// const params = useParams()

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// let routerNaviation = 												useNavigate()
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			document.title = returnLoadCallDocumentTitle( se_HOME )
		}, [])

		// Other Variables

		// Functions

		// Call Functions

		// JSX Generation
		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX: TsType_JSX =
			<AuthenticatedContainer pageHeader={s_HOME} pageKey={pageKey} content={
				<Box className="tw-m-auto" sx={{ maxWidth: "600px" }}>
					<Box className="tw-mt-6 tw-text-center">
						<img
							src={ logoSrc }
							alt=""
							loading="lazy"
							width={"200px"}
							className="tw-mr-1"
						/>
						<Typography
							variant="h5"
							className="tw-font-bold"
							sx={{ marginTop: "-8px", color: "#ec127a" }}
						>
							{ s_VERSION_2 }
						</Typography>
					</Box>
					<Box className="tw-mt-6">
						<Typography
							variant="h6"
							className="tw-font-bold"
							sx={{ marginTop: "8px" }}
						>
							What is LoadCall Version 2?
						</Typography>
						<Typography
							variant="body1"
							className="tw-opacity-90"
						>
							We're working on rebuilding LoadCall from the ground up to be faster, easier to use, and more powerful. This is a massive undertaking but we want LoadCall to be the best product possible for our users so investing in this rebuild is a no brainer.
						</Typography>
					</Box>
					<Box className="tw-mt-6">
						<Typography
							variant="h6"
							className="tw-font-bold"
							sx={{ marginTop: "8px" }}
						>
							How long is that going to take?
						</Typography>
						<Typography
							variant="body1"
							className="tw-opacity-90"
						>
							It's impossible to know how long this is going to take but it's likely a multi year project. Rather than waiting until it's done to release, we're going to be adding new features or updated pates as soon as they're ready to go. This allows us the flexibility to update parts of the software that need it the most or to pivot to work on new features as needed.
						</Typography>
					</Box>
					<Box className="tw-mt-6">
						<Typography
							variant="h6"
							className="tw-font-bold"
							sx={{ marginTop: "8px" }}
						>
							How do I jump from V1 to V2?
						</Typography>
						<Typography
							variant="body1"
							className="tw-opacity-90"
						>
							When navigating to a page that is available in V2, LoadCall will automatically redirect you to the new version of the page. If you navigate to a page that hasn't been updated LoadCall will automatically send you back to V1 as well.
						</Typography>
					</Box>
					<Box className="tw-mt-6">
						<Typography
							variant="h6"
							className="tw-font-bold"
							sx={{ marginTop: "8px" }}
						>
							How else does this affect me?
						</Typography>
						<Typography
							variant="body1"
							className="tw-opacity-90"
						>
							The main things you may notice is that V2 pages look a little different than V1 pages since we are updating to a more modern UI library. Also since we're hosting V2 on a different url, you will sometimes need to log in when being redirected to a V2 page. Also in our testing the back button can occasionally act a little weird when navigating between V1 and V2 pages but besides that jumping between versions is fairly simple.
						</Typography>
					</Box>
					<Box className="tw-mt-6">
						<Typography
							variant="h6"
							className="tw-font-bold"
							sx={{ marginTop: "8px" }}
						>
							What if I have some ideas for V2?
						</Typography>
						<Typography
							variant="body1"
							className="tw-opacity-90"
						>
							If you have an idea for a new feature or a suggestion for how to improve LoadCall, please let us know! We're always looking for ways to improve and we love hearing from our users.
						</Typography>
					</Box>
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>
	}