//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Wrapper that includes navigation and page header for authenticated parts of the software

		TODO:
			[ ] Typescript - 5 instances of @ts-expect-error - on functions / components copied from mui examples

	*/


///////////////////////////////
// Imports
///////////////////////////////

// Components
// @ts-expect-error
import logoSrc from 'app/images/logos/logo_white.png'
// React
import {
	useContext,
	useEffect,
	useState
} from 'react'
import {
	Link,
	Navigate,
	useLocation,
	useNavigate
} from 'react-router-dom'
// Config
import {
	ApplicationMajorPages,
	ApplicationNavPages,
	ApplicationNavSections,
	ApplicationPages,
	EmptyApplicationNavigationObject,
	generateActiveUserApplicationPermissions,
	generateApplicationNavigationObject,
	TsInterface_NavigationObject,
	TsInterface_NavPage
} from 'rfbp_aux/data/application_structure' // OUTSIDE BOILERPLATE
import {
	Icon
} from 'rfbp_core/components/icons'
// Context
import {
	Context_RootData_AuthenticatedUser,
	Context_RootData_ClientKey,
	Context_RootData_ClientPermissions,
	Context_RootData_ClientUser,
	Context_RootData_GlobalUser,
	Context_RootData_UserPermissions,
	Context_UserInterface_LoadingBar,
	Context_UserInterface_NavBar,
	Hook_AppData_AddToUrlHistory
} from 'rfbp_core/services/context'
import {
	cloneObjectWithoutReference,
	getProp,
	objectToArray
} from 'rfbp_core/services/helper_functions'
import {
	getClientKey
} from 'rfbp_core/services/user_authentication'
// Typescript
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_Null,
	TsType_Number,
	TsType_String,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
// MUI Components
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	CircularProgress,
	Divider,
	Drawer,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	styled,
	Typography
} from '@mui/material/'
import MuiAppBar from '@mui/material/AppBar'
import {
	themeVariables
} from '../config/app_theme'
import {
	AuthWithoutRedirect
} from './auth_without_redirect'

		// Third Party


///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		content: TsType_JSX
		pageHeader: TsType_JSX
		pageKey: TsType_String
	}

	interface TsInterface_ItemCategoryStyle {
		boxShadow: TsType_String
		py: TsType_Number
		px: TsType_Number
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings

	// Other
	const drawerWidth: TsType_Number = 250
	const itemCategory: TsInterface_ItemCategoryStyle = {
		boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
		py: 1.5,
		px: 3,
	}

	// Colors
	const unselectedNavColor = themeVariables.white
	const selectedNavColor = themeVariables.background_json
	const appBarBackgroundColor = themeVariables.background_default
	const logoPrimaryDarkColor = themeVariables.primary_dark

///////////////////////////////
// Functions
///////////////////////////////

	const DrawerHeader = styled( 'div' )(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing( 0, 1 ),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	}))

	const getMainWidth = ( open: TsType_Boolean ): TsType_String => {
		let width = "100%"
		if ( open ){
			width = `calc(100% - ${drawerWidth + 16}px)`
		}
		return width
	}

	const getMainLeftMargin = ( open: TsType_Boolean ): TsType_String => {
		let width = `-${drawerWidth}px`
		if ( open ){
			width = `-${drawerWidth + 16}px`
		}
		return width
	}

	// @ts-expect-error
	const Main = styled('main', { shouldForwardProp: ( prop: TsType_String ) => prop !== 'open' })(({ theme, open }) => ({
		flexGrow: 1,
		width: getMainWidth( open ),
		// padding: theme.spacing( 3 ),
		transition: theme.transitions.create( 'margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: getMainLeftMargin( open ),
		...( open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		}),
	}))

	const AppBar = styled( MuiAppBar, {
		shouldForwardProp: ( prop: TsType_String) => prop !== 'open',
	// @ts-expect-error
	})(({ theme, open }) => ({
		transition: theme.transitions.create( ['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...( open && {
			width: `calc(100% - ${drawerWidth + 0}px)`,
			marginLeft: `${drawerWidth + 0}px`,
			transition: theme.transitions.create( ['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	}))

	const  NavOpenKeyListener = ( props: TsType_Any ) => {

		// Props
		const openNav: TsType_Any = 			getProp( props, "openNav", () => {} )
		const closeNav: TsType_Any = 			getProp( props, "closeNav", () => {} )
		const navOpen: TsType_Any = 			getProp( props, "navOpen", null )

		// Hooks - useEffect
		useEffect(() => {
			const handleKeyDown = (event: TsType_Any ) => {
				if (event.metaKey && event.keyCode === 66) {
					setTimeout( () => {
						if( navOpen === true ){
							closeNav()
						}
						if( navOpen === false ){
							openNav( true )
						}
					}, 1)
				}
			}
			document.addEventListener("keydown", handleKeyDown)
			return () => {
				document.removeEventListener("keydown", handleKeyDown)
			}
		}, [ openNav, closeNav, navOpen]);

		return null;
	}

	// function getUrlPathAfterHost( url: TsType_String ) {
		// try {
		// 	const parsedUrl = new URL(url);
		// 	return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
		// } catch (error) {
		// 	// Handle invalid URLs or other parsing errors
		// 	console.error('Error parsing URL:', error);
		// 	return null;
		// }
	// }

	function getUrlHostWithProtocolAndPort( url: TsType_String ) {
		try {
		  const parsedUrl = new URL(url);
		  const protocol = parsedUrl.protocol;
		  const hostname = parsedUrl.hostname;
		  const port = parsedUrl.port ? `:${parsedUrl.port}` : '';
		  return protocol + '//' + hostname + port;
		} catch (error) {
		  console.error("Invalid URL:", error);
		  return null;
		}
	  }
///////////////////////////////
// Component
///////////////////////////////

	export const AuthenticatedContainer = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		const pr_pageContent: TsInterface_ComponentProps["content"] = 			getProp( props, "content", <></> )
		const pr_pageHeader: TsInterface_ComponentProps["pageHeader"] = 		getProp( props, "pageHeader", <></> )
		const pr_pageKey: TsInterface_ComponentProps["pageKey"] = 				getProp( props, "pageKey", "" )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// const [ us_previousPageUrl, us_setPreviousPageUrl ] = 									useState< TsType_String | TsType_Null >( null )
		// const { uc_AppData_UrlHistory, uc_setAppData_UrlHistory } = 							useContext( Context_AppData_UrlHistory )
		const [ us_addedToUrlHistory, us_setAddedToUrlHistory ] = 								useState< TsType_Boolean >( false )
		const [ us_attemptedUserLoad, us_setAttemptedUserLoad ] = 								useState< TsType_Boolean >( false )
		const [ us_authorizedToViewPage, us_setAuthorizedToViewPage ] = 						useState< TsType_Boolean | TsType_Null >( null )
		const [ us_clientType, us_setClientType ] = 											useState< TsType_String | TsType_Null >( null )
		const [ us_expandedNavSection, us_setExpandedNavSection ] = 							useState< TsType_String | TsType_Null >( null )
		const [ us_loadingBarJSX, us_setLoadingBarJSX ] = 										useState< TsType_JSX >( <Box className="top_loading_bar_placeholder"></Box> )
		const [ us_pageRoleAccessPermissions, us_setPageRoleAccessPermissions ] = 				useState< TsInterface_NavPage["page_role_access_permissions"] >( {} )
		const [ us_sideBarNavObject, us_setSideBarNavObject ] = 								useState< TsInterface_NavigationObject >( {} )
		const [ us_userRole, us_setUserRole ] = 												useState< TsType_String | TsType_Null >( null )
		const ul_useLocation = 																	useLocation()
		const un_routerNaviation = 																useNavigate()
		const { uc_AppData_UrlHistory, hook_addUrlToHistory } = 								Hook_AppData_AddToUrlHistory()
		const { uc_RootData_AuthenticatedUser } = 												useContext( Context_RootData_AuthenticatedUser )
		const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 							useContext( Context_RootData_ClientKey )
		const { uc_RootData_ClientPermissions } = 												useContext( Context_RootData_ClientPermissions )
		const { uc_RootData_ClientUser } = 														useContext( Context_RootData_ClientUser )
		const { uc_RootData_GlobalUser } = 														useContext( Context_RootData_GlobalUser )
		const { uc_RootData_UserPermissions, uc_setRootData_UserPermissions } = 				useContext( Context_RootData_UserPermissions )
		const { uc_UserInterface_LoadingBarDisplay } = 											useContext( Context_UserInterface_LoadingBar )
		const { uc_UserInterface_NavBarDisplay, uc_setUserInterface_NavBarDisplay } = 			useContext( Context_UserInterface_NavBar )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			setTimeout( () => {
				us_setAttemptedUserLoad( true )
			}, 1000)
		}, [ ])

		useEffect(() => {
			let clientType = null
			let userRole = null
			if ( uc_RootData_ClientPermissions != null && uc_RootData_ClientPermissions.client_type != null ){
				clientType = uc_RootData_ClientPermissions.client_type
			}
			if ( uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role != null ){
				userRole = uc_RootData_ClientUser.user_role
			} else if ( uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.user_role != null ){
				userRole = uc_RootData_GlobalUser.user_role
			}
			us_setClientType( clientType )
			us_setUserRole( userRole )
		}, [ uc_RootData_ClientPermissions, uc_RootData_ClientUser, uc_RootData_GlobalUser ])

		useEffect(() => {
			if( us_clientType != null && us_userRole != null ){
				let initialApplicationNavigationObject = generateApplicationNavigationObject( us_clientType, us_userRole )
				let permittedApplicationNavigationObject: TsInterface_NavigationObject = {}
				for ( let loopSectionKey in initialApplicationNavigationObject ){
					let loopSection = initialApplicationNavigationObject[ loopSectionKey ]
					for ( let loopLinkKey in loopSection["links"] ){
						let loopLink = loopSection["links"][ loopLinkKey ]
						if ( uc_RootData_UserPermissions != null && uc_RootData_UserPermissions[ loopLinkKey ] === true && loopLink != null ){
							if ( permittedApplicationNavigationObject[ loopSectionKey ] == null ){
								permittedApplicationNavigationObject[ loopSectionKey ] = cloneObjectWithoutReference( loopSection )
								permittedApplicationNavigationObject[ loopSectionKey ]["links"] = {}
							}
							permittedApplicationNavigationObject[ loopSectionKey ]["links"][ loopLinkKey ] = loopLink
						}
					}
				}
				if ( permittedApplicationNavigationObject == null || objectToArray( permittedApplicationNavigationObject ).length === 0 ){
					permittedApplicationNavigationObject = EmptyApplicationNavigationObject
				}
				us_setSideBarNavObject( permittedApplicationNavigationObject )
			}
		}, [ us_clientType, us_userRole, uc_RootData_UserPermissions ])

		useEffect(() => {

			// TODO - doesn't work for subpages like ticket imports - may need to add another pararm

			let matchedSectionKey = null
			if( pr_pageKey != null && objectToArray( us_sideBarNavObject ).length > 0 ){
				for( let loopSectionKey in us_sideBarNavObject ){
					let loopSection = us_sideBarNavObject[ loopSectionKey ]
					if( loopSection != null && loopSection["links"] != null ){
						for( let loopLinkKey in loopSection["links"] ){
							if( pr_pageKey === loopLinkKey ){
								matchedSectionKey = loopSectionKey
							}
						}
					}
				}
				if( matchedSectionKey != null ){
					us_setExpandedNavSection( matchedSectionKey )
				}
			}
			if(
				matchedSectionKey == null &&
				ApplicationPages != null &&
				pr_pageKey != null &&
				ApplicationPages[ pr_pageKey ] != null &&
				ApplicationPages[ pr_pageKey ]["root_nav_page_permission"] != null &&
				objectToArray( us_sideBarNavObject ).length > 0
			){
				let matchedSectionKey = null
				for( let loopSectionKey in us_sideBarNavObject ){
					let loopSection = us_sideBarNavObject[ loopSectionKey ]
					if( loopSection != null && loopSection["links"] != null ){
						for( let loopLinkKey in loopSection["links"] ){
							if( ApplicationPages[ pr_pageKey ]["root_nav_page_permission"] === loopLinkKey ){
								matchedSectionKey = loopSectionKey
							}
						}
					}
				}
				if( matchedSectionKey != null ){
					us_setExpandedNavSection( matchedSectionKey )
				}
			}
		}, [ pr_pageKey, us_sideBarNavObject ])

		useEffect(() => {
			let topLoadingBarJSX: TsType_JSX = <></>
			if ( uc_UserInterface_LoadingBarDisplay === true ){
				topLoadingBarJSX = <LinearProgress color="secondary" />
			} else {
				topLoadingBarJSX = <Box className="top_loading_bar_placeholder"></Box>
			}
			us_setLoadingBarJSX( topLoadingBarJSX )
		}, [ uc_UserInterface_LoadingBarDisplay ])

		useEffect(() => {
			let pageRootNavPermission: TsType_String = getProp( ApplicationPages[ pr_pageKey ], "root_nav_page_permission", "" )
			let pageRoleAccessPermissions: TsInterface_NavPage["page_role_access_permissions"] = getProp( ApplicationNavPages[ pageRootNavPermission ], "page_role_access_permissions", {} )
			us_setPageRoleAccessPermissions( pageRoleAccessPermissions )
			let authorizedToViewPage: TsType_Boolean = false
			if ( pr_pageKey === "HomePage" ){
				authorizedToViewPage = true
			} else if ( us_clientType == null || us_userRole == null ) {
				authorizedToViewPage = true
			} else {
				if ( uc_RootData_UserPermissions == null || objectToArray( uc_RootData_UserPermissions ).length === 0 || uc_RootData_UserPermissions[pageRootNavPermission] === true ){
					authorizedToViewPage = true
				}
			}
			us_setAuthorizedToViewPage( authorizedToViewPage )
		}, [ pr_pageKey, uc_RootData_UserPermissions, us_clientType, us_userRole])

		useEffect(() => {
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( getResult ) => {
				// Nothing
			}).catch(( getReject) => {
				// un_routerNaviation( ApplicationPages.UnauthenticatedLoginPage.url() )
			})

		}, [ un_routerNaviation, uc_RootData_ClientKey, uc_setRootData_ClientKey ])

		useEffect(() => {
			generateActiveUserApplicationPermissions( uc_RootData_ClientUser, uc_RootData_GlobalUser, uc_RootData_ClientPermissions ).then(( permissionResult ) => {
				if ( permissionResult.success ){
					uc_setRootData_UserPermissions( permissionResult.permissions )
				}
			})
			return () => { }
		}, [ uc_RootData_ClientUser, uc_RootData_GlobalUser, uc_RootData_ClientPermissions, uc_setRootData_UserPermissions ])

		// useBeforeUnload(
			// // Before the page is unloaded, save the previous state url to local storage
			// useCallback(() => {
			// 	let previousUrl = ApplicationPages.HomePage.url()
			// 	if( uc_AppData_UrlHistory != null && uc_AppData_UrlHistory.length > 1 ){
			// 		previousUrl = uc_AppData_UrlHistory[ uc_AppData_UrlHistory.length - 1 ]
			// 	}
			//   	localStorage.previous_state_url = previousUrl
			// }, [ uc_AppData_UrlHistory ])
		// )

		useEffect(() => {
			// Save the current url to url history context via custom hook
			if(
				ul_useLocation != null &&
				ul_useLocation.pathname != null &&
				us_addedToUrlHistory === false
			){
				hook_addUrlToHistory( ul_useLocation.pathname )
				us_setAddedToUrlHistory( true )
			}
		// Don't want to rerun this when us_setAddedToUrlHistory fires
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [ hook_addUrlToHistory, ul_useLocation ])


		useEffect(() => {
			// HANDLE LOADCALL V1 REDIRECTS

			// Instantiate Variables
			let otherVersionBaseUrl = "https://loadcall.com/#"
			let currentPageBaseUrl = getUrlHostWithProtocolAndPort( window.location.href )
			let previousPagePathname = "/home"
			let previousPageHostType = ""
			// Previous Page URL
			if( uc_AppData_UrlHistory != null && uc_AppData_UrlHistory.length > 1 ){
				previousPagePathname = uc_AppData_UrlHistory[ uc_AppData_UrlHistory.length - 1 ]
			}
			// If Previous Page Host
			if( uc_AppData_UrlHistory != null && uc_AppData_UrlHistory.length === 0 ){
				// Navigation to this route came page load or from outside V1
				previousPageHostType = "not_sure"
			} else {
				// Navigation to this route came from within V2
				previousPageHostType = "this_site"
			}
			// Current Page URL
			let currentPageURL = window.location.href
			let currentPagePathname = new URL( currentPageURL ).pathname;

			setTimeout( () => {
				if(
					pr_pageKey != null &&
					ApplicationPages != null &&
					ApplicationPages[ pr_pageKey ] != null &&
					ApplicationPages[ pr_pageKey ]["v1_redirect"] === true
				){
					// Instantiate Variables
					let currentTimestamp = new Date().getTime()
					let previousSessionBackUrl = localStorage["version_redirect_" + currentPagePathname + "_previous_page_url"]
					let previousSessionTimestamp = localStorage["version_redirect_" + currentPagePathname + "_timestamp"]
					// If the previous page was on this host
					if( previousPageHostType === "this_site" ){
						// Save Previous URL Data to local storage
						localStorage["version_redirect_" + currentPagePathname + "_previous_page_url"] = previousPagePathname
						localStorage["version_redirect_" + currentPagePathname + "_timestamp"] = new Date().getTime().toString()
						// Redirect to Other Version of LoadCall
						let redirectURL = otherVersionBaseUrl + currentPagePathname
						window.location.href = redirectURL
					// If we don't know anything about the previous page
					} else {
						// If we don't have a timestamp OR the timestamp is older than 8 hours
						if(
							previousSessionTimestamp != null &&
							!isNaN( parseInt( previousSessionTimestamp ) ) &&
							( currentTimestamp - parseInt( previousSessionTimestamp ) ) > ( 3600000 * 8 )
						){
							// Save Previous URL Data to local storage
							localStorage["version_redirect_" + currentPagePathname + "_previous_page_url"] = previousPagePathname
							localStorage["version_redirect_" + currentPagePathname + "_timestamp"] = new Date().getTime().toString()
							// Redirect to Other Version of LoadCall
							let redirectURL = otherVersionBaseUrl + currentPagePathname
							window.location.href = redirectURL
						} else {
							let cachedPreviousPagePathname = "/home"
							if( previousSessionBackUrl != null ){
								cachedPreviousPagePathname = previousSessionBackUrl
							}
							// Wipe Previous URL Data from local storage
							localStorage["version_redirect_" + currentPagePathname + "_previous_page_url"] = null
							localStorage["version_redirect_" + currentPagePathname + "_timestamp"] = null
							// Redirect to cached previous page at this host
							let redirectURL = currentPageBaseUrl + cachedPreviousPagePathname
							window.location.href = redirectURL
						}
					}
				}
			}, 100) // TODO - check this timeout
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [ pr_pageKey ])

		// Other Variables

		// Functions
		const openNav = (): TsType_Void => {
			uc_setUserInterface_NavBarDisplay( true )
		}

		const closeNav = (): TsType_Void => {
			uc_setUserInterface_NavBarDisplay( false )
		}

		const checkIfNavSectionIsActive = ( sectionKey: TsType_String, linkKey: TsType_String ): TsType_Boolean => {
			let active = false
			if ( sectionKey === "home" && linkKey === "home" && pr_pageKey === "HomePage" ){
				active = true
			} else if ( us_clientType != null && us_userRole != null && us_pageRoleAccessPermissions != null && us_pageRoleAccessPermissions[ us_clientType + "_" + us_userRole ] != null ){
				let compositeKey: TsType_String = us_clientType + "_" + us_userRole
				if ( us_pageRoleAccessPermissions[ compositeKey ]["highlighted_nav_section"] === sectionKey && us_pageRoleAccessPermissions[ compositeKey ]["highlighted_nav_page"] === linkKey ){
					active = true
				}
			}
			return active
		}

		const returnNavLinkStyle = ( selected: TsType_Boolean ): TsInterface_UnspecifiedObject => {
			let style
			if ( selected === true ){
				style = {
					py: '2px',
					pl: "8px",
					// pr: "16px",
					color: selectedNavColor,
					fontWeight: 700,
					// color: 'rgba(255, 255, 255, 0.7)',
					'&:hover, &:focus': {
						bgcolor: 'rgba(255, 255, 255, 0.08)',
					},
					// "borderLeft": "6px solid " + selectedNavColor
					backgroundColor: "rgba(255, 255, 255, 0.36)"
				}
			} else {
				style = {
					py: '2px',
					pl: "8px",
					// pr: "16px",
					color: 'rgba(255, 255, 255, 0.7)',
					fontWeight: 700,
					'&:hover, &:focus': {
						bgcolor: 'rgba(255, 255, 255, 0.08)',
					},
					// "borderLeft": "6px solid rgba(0,0,0,0)"
				}
			}
			return style
		}

		const returnNavIconStyle = ( selected: TsType_Boolean ): TsInterface_UnspecifiedObject => {
			let navIconSX = {
				color: unselectedNavColor,
				minWidth: "40px",
				margin: "auto",
				textAlign: "center"
			}
			if( selected === true ){
				navIconSX["color"] = selectedNavColor
			}
			return navIconSX
		}

		const getMainSectionPadding = ( open: TsType_Boolean ): TsType_String => {
			let cssClassName = ""
			if ( open === true ){
				// cssClassName = "tw-pl-2"
			}
			return cssClassName
		}

		const getHeaderTextMaxWidth = ( open: TsType_Boolean ): TsType_String => {
			// TODO - add 40 for each nav icon visible - i.e. notifications
			let otherHeaderContentWidth = 130
			let maxWidth = `calc(100% - ${otherHeaderContentWidth}px)`
			return maxWidth
		}

		const returnJSX_NavButton = ( open: TsType_Boolean ): TsType_JSX => {
			let navButtonJSX = <></>
			if ( open === true ){
				navButtonJSX =
				<IconButton sx={{ height: "40px", width: "40px", marginTop: "2px", ...( uc_UserInterface_NavBarDisplay && { display: 'none' } ) }} className="tw-inline-block" onClick={ () => { closeNav() } }>
					<Icon icon="chevron-left" />
				</IconButton>
			} else {
				navButtonJSX =
				<IconButton sx={{ height: "40px", width: "40px", marginTop: "2px", ...( !uc_UserInterface_NavBarDisplay && { display: 'none' } ) }} className="tw-inline-block" onClick={ () => { openNav() } }>
					<Icon icon="bars" />
				</IconButton>
			}
			return navButtonJSX
		}

		// Call Functions
		// determinePageAuthorization()

		// JSX Generation
		const returnJSX_Component = (): TsType_JSX => {
			let authContentJSX = <Box></Box>
			if(
				pr_pageKey != null &&
				ApplicationPages != null &&
				ApplicationPages[ pr_pageKey ] != null &&
				ApplicationPages[ pr_pageKey ]["v1_redirect"] === true
			){
				authContentJSX =
				<Box className="tw-text-center tw-p-4">
					<CircularProgress />
				</Box>
			} else if (
				us_attemptedUserLoad === false &&
				( uc_RootData_AuthenticatedUser == null || uc_RootData_AuthenticatedUser.loggedIn == null )
			){
				authContentJSX =
				<Box className="tw-text-center tw-p-4">
					<CircularProgress />
				</Box>
			} else if (
				us_attemptedUserLoad === true &&
				( uc_RootData_AuthenticatedUser == null || uc_RootData_AuthenticatedUser.loggedIn == null )
			){
				authContentJSX = <AuthWithoutRedirect />
			} else if ( uc_RootData_AuthenticatedUser != null && uc_RootData_AuthenticatedUser.loggedIn === false ){
				authContentJSX = <AuthWithoutRedirect />
			} else if ( us_authorizedToViewPage === false ){
				authContentJSX = <Navigate to={ApplicationPages.HomePage.url()} replace />
			} else {
				authContentJSX =
				<Box component='div' className="tw-flex">
					{/* @ts-expect-error */}
					<AppBar position="fixed" open={ uc_UserInterface_NavBarDisplay } sx={ { backgroundColor: appBarBackgroundColor, boxShadow: "none" } } className="tw-pl-0" >
						{ us_loadingBarJSX }
						<Box>
							<Box className="tw-inline-block tw-float-left">
								{ returnJSX_NavButton( uc_UserInterface_NavBarDisplay ) }
							</Box>
							<Typography
								className="tw-inline-block tw-ml-1 tw-mt-2"
								variant="h5"
								noWrap
								component="span"
								sx={{
									"color": logoPrimaryDarkColor,
									"fontWeight": 700,
									"marginTop": "0px",
									"width": "100%",
									"maxWidth": getHeaderTextMaxWidth( uc_UserInterface_NavBarDisplay )
								}}
							>
								{ pr_pageHeader }
							</Typography>
							<Box className="tw-inline-block tw-float-right">
								{/* <IconButton sx={{ height: "40px", width: "40px", marginTop: "4px" }} className="tw-inline-block" onClick={ () => { console.log("TEST") } }>
									<NotificationsActiveIcon/>
								</IconButton> */}
								<Link to={ ApplicationMajorPages.UserSettingsPage.url() } >
									<IconButton sx={{ height: "40px", width: "40px", marginTop: "4px" }} className="tw-inline-block" onClick={ () => {  } }>
										<Icon icon="circle-user" />
									</IconButton>
								</Link>
							</Box>
						</Box>
						<Divider />
					</AppBar>
					<Box>
						<Drawer
							sx={{
								width: drawerWidth,
								flexShrink: 0,
								'& .MuiDrawer-paper': {
									width: drawerWidth,
									boxSizing: 'border-box',
								},
							}}
							variant="persistent"
							anchor="left"
							open={ uc_UserInterface_NavBarDisplay }
						>
							<Box className="tw-px-2" sx={{ background: 'linear-gradient(to bottom, #f9c727 0%, #f26b21 50%, #ec127a 100%)', height: "calc(100vh - 0px)", overflow: "scroll" }}>
								<DrawerHeader sx={ itemCategory }>
									<Box style={{width:'100%', textAlign: "left", height: "60px"}}>
										<img
											src={ logoSrc }
											alt=""
											loading="lazy"
											width={"100px"}
											className="tw-mr-1"
										/>
									</Box>
								</DrawerHeader>
								<List disablePadding>
									{Object.keys( us_sideBarNavObject ).map(( sectionKey, sectionIndex ) => (
										<Box key={ sectionKey }>
											<Accordion
												expanded={ us_expandedNavSection === sectionKey }
												onChange={ () => {
													if( us_expandedNavSection === sectionKey ){
														us_setExpandedNavSection( null )
													} else {
														us_setExpandedNavSection( sectionKey )
													}
												}}
												sx={{
													boxShadow: "none",
													background: us_expandedNavSection === sectionKey ? "rgba(255,255,255,0.2)": "rgba(255,255,255,0)"
												}}
											>
												<AccordionSummary
													expandIcon={<Icon icon="angle-down" sx={{ color: themeVariables.white }} />}
													sx={{
														minHeight: "40px",
														'.Mui-expanded': {
															minHeight: "0px",
														}
													}}
												>
													<Box className="tw-uppercase tw-font-bold tw-m-0" sx={{ color: themeVariables.white }}>
														{ ApplicationNavSections[ sectionKey ].name }
													</Box>
												</AccordionSummary>
												<AccordionDetails className="tw-p-0">
													{Object.keys( us_sideBarNavObject[ sectionKey ]["links"] ).map(( navLinkKey, navLinkName ) => (
														<Link to={ us_sideBarNavObject[ sectionKey ]["links"][ navLinkKey ].url } key={ us_sideBarNavObject[ sectionKey ]["links"][ navLinkKey ].key }>
															<ListItem disablePadding >
																<ListItemButton sx={ returnNavLinkStyle( checkIfNavSectionIsActive( sectionKey, navLinkKey ) ) }>
																	<ListItemIcon sx={ returnNavIconStyle( checkIfNavSectionIsActive( sectionKey, navLinkKey ) ) }>
																		<Icon size="lg" icon={ us_sideBarNavObject[ sectionKey ]["links"][ navLinkKey ].icon  } className="tw-m-auto" />
																	</ListItemIcon>
																	<ListItemText>{ us_sideBarNavObject[ sectionKey ]["links"][ navLinkKey ].name }</ListItemText>
																</ListItemButton>
															</ListItem>
														</Link>
													))}
													<Box className="tw-p-1"></Box>
												</AccordionDetails>
											</Accordion>
											<Divider className="tw-my-2" sx={{ borderColor: "rgba(255, 255, 255, 0.3)" }} />
										</Box>
									))}
								</List>
							</Box>
						</Drawer>
					</Box>
					{/* @ts-expect-error */}
					<Main className={ getMainSectionPadding(uc_UserInterface_NavBarDisplay) } open={ uc_UserInterface_NavBarDisplay }>
						<Box sx={{ height: "50px" }}/>
						<Box className="tw-px-2 tw-py-2">
							{ pr_pageContent }
						</Box>
						<NavOpenKeyListener openNav={ openNav } closeNav={ closeNav } navOpen={ uc_UserInterface_NavBarDisplay } />
					</Main>
				</Box>
			}
			return authContentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}