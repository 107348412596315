///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Used to define translations for strings throughout the application that are wrapped in a <Trans> tag and defined below
			Strings that are not defined for the selected language will default to whatever is stated in the tags

		TODO:
			[ ] Architecture - Probably should silo files with their respective components (https://react.i18next.com/guides/multiple-translation-files)

	*/


///////////////////////////////
// Imports
///////////////////////////////


import i18n from 'i18next'
import {
	initReactI18next
} from 'react-i18next'

///////////////////////////////
// Variables
///////////////////////////////

	const resources = {
		en: {
			translation: {
				"home": "home",
				"Home": "Home",
			}
		},
		es: {
			translation: {
				"home": "casa",
				"Home": "Casa",
			}
		}
	}


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

	i18n.use(initReactI18next).init({
		resources,
		lng: "en",
		// language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	})

	export default i18n