///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Trans
} from 'react-i18next'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_PlaceholderPage {
	pageKey: TsType_String
}

///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	// const pageKey: TsType_String = ApplicationPages["HomePage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_PAGE_NAME: TsType_JSX = 		<Trans>Page Name</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC<TsInterface_PlaceholderPage> = ( { pageKey } ): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => TsType_Void
		// { sort-end } - hooks

		// Hooks - useEffect
		// useEffect(() => { }, [])

		// Other Variables

		// Functions

		// Call Functions

		// JSX Generation
		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			<AuthenticatedContainer pageHeader={s_PAGE_NAME} pageKey={pageKey} content={
				<Box>
					{/* <h1>JSX goes here</h1> */}
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>

	}