///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			Potential Additional Features (nice to haves for a future release)
				- Automatic Subassociation Building in attemptAssociationAutoMapping
				- Link to jobs

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	useContext,
	useEffect,
	useReducer,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useCSVReader
} from 'react-papaparse'
import {
	useNavigate,
	useParams
} from 'react-router-dom'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	DatabaseRef_CargoNameSearch_Query
} from 'rfbp_aux/services/database_endpoints/directory/cargo'
import {
	DatabaseRef_LeaseNameSearch_Query,
	DatabaseRef_Leases_Docks_Collection
} from 'rfbp_aux/services/database_endpoints/directory/leases'
import {
	DatabaseRef_Offload_Accounts_Collection,
	DatabaseRef_OffloadNameSearch_Query
} from 'rfbp_aux/services/database_endpoints/directory/offloads'
import {
	DatabaseRef_ProducerNameSearch_Query
} from 'rfbp_aux/services/database_endpoints/directory/producers'
import {
	DatabaseRef_PurchaserNameSearch_Query,
	DatabaseRef_Purchasers_Regions_Collection
} from 'rfbp_aux/services/database_endpoints/directory/purchasers'
import {
	DatabaseRef_RegionNameSearch_Query
} from 'rfbp_aux/services/database_endpoints/directory/regions'
import {
	DatabaseRef_TrailerNameSearch_Query
} from 'rfbp_aux/services/database_endpoints/directory/trailers'
import {
	DatabaseRef_TruckNameSearch_Query
} from 'rfbp_aux/services/database_endpoints/directory/trucks'
import {
	DatabaseRef_UserNameSearch_Query
} from 'rfbp_aux/services/database_endpoints/directory/users'
import {
	DatabaseRef_TicketImports_Associations_Collection,
	DatabaseRef_TicketImports_Associations_Document,
	DatabaseRef_TicketImports_Document,
	DatabaseRef_TicketImports_MappedData_Collection,
	DatabaseRef_TicketImports_MappedData_Document,
	DatabaseRef_TicketImports_RawData_Collection,
	DatabaseRef_TicketImports_RawData_Document,
	DatabaseRef_TicketImports_RawDataHeaders_Document
} from 'rfbp_aux/services/database_endpoints/jobs/ticket_imports'
import {
	DatabaseRef_Ticket_Document,
	DatabaseRef_TicketIDNumberSearch_Query,
	DatabaseRef_TicketLog_Document,
	DatabaseRef_TicketMini_Document
} from 'rfbp_aux/services/database_endpoints/jobs/tickets'
import {
	TsInterface_InputHooksObject
} from 'rfbp_core/components/form/form_types'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	returnJSX_HighlightedSearchString,
	SearchInput
} from 'rfbp_core/components/search'
import {
	TableBasic,
	TableCellBasic,
	TableCellTimestamp,
	TsInterface_TableColumns,
	TsInterface_TableSettings
} from 'rfbp_core/components/table'
import {
	TabsBasic,
	TsInterface_TabContentArray
} from 'rfbp_core/components/tabs'
import {
	Context_RootData_ClientKey,
	Context_RootData_ClientUser,
	Context_UserInterface_CustomDialog,
	Context_UserInterface_ErrorDialog,
	Context_UserInterface_Snackbar,
	UserInterface_Default_CustomDialogDisplayState
} from 'rfbp_core/services/context'
import {
	DatabaseBatchUpdate,
	DatabaseFieldDelete,
	DatabaseGetCollection,
	DatabaseGetLiveCollection,
	DatabaseGetLiveDocument,
	DatabaseSetMergeDocument,
	TsInterface_DatabaseBatchUpdatesArray
} from 'rfbp_core/services/database_management'
import {
	cloneObjectWithoutReference,
	downloadCSV,
	dynamicSort,
	getProp,
	objectToArray,
	returnFormattedDate,
	returnTimezoneAbbreviation
} from 'rfbp_core/services/helper_functions'
import {
	getClientKey
} from 'rfbp_core/services/user_authentication'
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_Null,
	TsType_Number,
	TsType_String,
	TsType_UnknownPromise,
	TsType_Void,
	TsType_VoidFunction
} from 'rfbp_core/typescript/global_types'
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineOppositeContent,
	TimelineSeparator
} from '@mui/lab'
import {
	AppBar,
	Box,
	Button,
	Card,
	Dialog,
	DialogContent,
	Divider,
	FormControl,
	IconButton,
	MenuItem,
	Select,
	Stack,
	Step,
	StepLabel,
	Stepper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Toolbar,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_SubassociationCustomDialog {
	associationObject: TsInterface_UnspecifiedObject
	associationItem: TsInterface_UnspecifiedObject
	importSessionKey: TsType_String
}

///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey: TsType_String = ApplicationPages["ticketsImportView"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_ASSOCIATIONS_BUILT: TsType_JSX = 										<Trans>Associations built</Trans>
	const s_ATTEMPT_AUTO_ASSOCIATION_BUILD: TsType_JSX = 							<Trans>Attempt Auto Associations built</Trans>
	const s_BACK_TO_TICKET_IMPORTS: TsType_JSX = 									<Trans>Back to Ticket Imports</Trans>
	const s_BOOLEAN: TsType_JSX = 													<Trans>Boolean</Trans>
	const s_BUILD_ASSOCIATIONS: TsType_JSX = 										<Trans>Build Associations</Trans>
	const s_COLUMNS_MAPPED: TsType_JSX = 											<Trans>Columns mapped</Trans>
	const s_CONFIRM_ASSOCIATIONS: TsType_JSX = 										<Trans>Confirm Associations</Trans>
	const s_CONFIRM_FIELD_MAPPING: TsType_JSX = 									<Trans>Confirm Field Mapping</Trans>
	const s_CONFIRM_IMPORT: TsType_JSX = 											<Trans>Confirm Import</Trans>
	const s_CSV_FILE_UPLOADED: TsType_JSX = 										<Trans>CSV file uploaded</Trans>
	const s_DATE: TsType_JSX = 														<Trans>Date</Trans>
	const s_DATETIME: TsType_JSX = 													<Trans>Datetime</Trans>
	const s_DOWNLOAD_EXAMPLE: TsType_JSX = 											<Trans>Download Example</Trans>
	const s_DUPLICATE_MAPPED_FIELDS: TsType_JSX = 									<Trans>Duplicate Mapped Fields</Trans>
	const s_DUPLICATE_MAPPING: TsType_JSX = 										<Trans>Duplicate Mapping</Trans>
	const s_FAILED_TO_CONFIRM_IMPORT: TsType_JSX = 									<Trans>Failed to confirm import</Trans>
	const s_FAILED_TO_DETERMINE_REPLACEMENT_TARGETS_FOR: TsType_JSX = 				<Trans>Failed to determine replacement targets for</Trans>
	const s_FAILED_TO_IMPORT_CSV_FILE: TsType_JSX = 								<Trans>Select a CSV File</Trans>
	const s_FAILED_TO_SAVE_MAPPED_DATA: TsType_JSX = 								<Trans>Failed to save mapped data</Trans>
	const s_IMPORTED_TICKETS: TsType_JSX = 											<Trans>Imported Tickets</Trans>
	const s_IMPORT_COMPLETED: TsType_JSX = 											<Trans>Import completed</Trans>
	const s_IMPORT_SESSION_CREATED: TsType_JSX = 									<Trans>Import session created</Trans>
	const s_IMPORT_SUMMARY: TsType_JSX = 											<Trans>Import Summary</Trans>
	const s_IMPORT_VALUE: TsType_JSX = 												<Trans>Import Value</Trans>
	const s_IMPORT_WARNING: TsType_JSX = 											<Trans>Import Warning</Trans>
	const s_IMPORT_WARNINGS: TsType_JSX = 											<Trans>Import Warnings</Trans>
	const s_INVALID_IMPORT_SESSION_TYPE: TsType_JSX = 								<Trans>Invalid Import Session Type</Trans>
	const s_ITEMS: TsType_JSX = 													<Trans>items</Trans>
	const s_LINKED: TsType_JSX = 													<Trans>Linked</Trans>
	const s_MAPPED: TsType_JSX = 													<Trans>Mapped</Trans>
	const s_MAPPED_AS: TsType_JSX = 												<Trans>Mapped as</Trans>
	const s_MAPPING: TsType_JSX = 													<Trans>Mapping</Trans>
	const s_MAP_COLUMNS: TsType_JSX = 												<Trans>Map Columns</Trans>
	const s_MISSING_REQUIRED_CONFIGURATION_KEYS: TsType_JSX = 						<Trans>Missing Required Configuration Keys</Trans>
	const s_MISSING_REQUIRED_FIELDS: TsType_JSX = 									<Trans>Missing Required Fields</Trans>
	const s_NOT_LINKED: TsType_JSX = 												<Trans>Not Linked</Trans>
	const s_NOT_MAPPED: TsType_JSX = 												<Trans>Not Mapped</Trans>
	const s_NOT_SELECTED: TsType_JSX = 												<Trans>Not Selected</Trans>
	const s_NUMBER: TsType_JSX = 													<Trans>Number</Trans>
	const s_PROCEED_ANYWAY: TsType_JSX = 											<Trans>Proceed Anyway</Trans>
	const s_REMOVE_MAPPING: TsType_JSX = 											<Trans>Remove Mapping</Trans>
	const s_REVIEW_AND_IMPORT: TsType_JSX = 										<Trans>Review and Import</Trans>
	const s_STRING: TsType_JSX = 													<Trans>String</Trans>
	const s_TICKET_IMPORT_SESSION: TsType_JSX = 									<Trans>Ticket Import Session</Trans>
	const s_TIME: TsType_JSX = 														<Trans>Time</Trans>
	const s_TIMEZONE_TO_BE_USED: TsType_JSX = 										<Trans>Timezone to be used</Trans>
	const s_UNLINK: TsType_JSX = 													<Trans>Unlink</Trans>
	const s_UPLOAD_A_CSV_FILE: TsType_JSX = 										<Trans>Upload a CSV File</Trans>
	const s_UPLOAD_CSV: TsType_JSX = 												<Trans>Upload CSV</Trans>
	const s_VALUES: TsType_JSX = 													<Trans>Values</Trans>
	const s_YOU_CAN_ONLY_IMPORT_A_MAXIMUM_OF_500_TICKETS_AT_A_TIME: TsType_JSX = 	<Trans>You can only import a maximum of 500 tickets at a time</Trans>
	const se_TICKET_IMPORT_SESSION: TsType_String =									"Ticket Import Session"
	// { sort-end } - displayed text

	// Import Variables
	const requiredImportMapProperties: TsInterface_UnspecifiedObject = {
		new_crude_run_tickets: {
			associated_lease_name: true,
			associated_offload_name: true,
			associated_dock_name: true,
			bol: true,
			id_number: true,
			associated_producer_name: true,
			associated_purchaser_name: true,
			associated_purchaser_region_name: true,
			associated_driver_name: true,
			associated_truck_name: true,
			associated_trailer_name: true,
			associated_cargo_name: true,
			pickup_date_depart: true,
			pickup_time_depart: true,
			dropoff_date_depart: true,
			dropoff_time_depart: true,
			completed_date: true,
			completed_time: true,
		},
		update_crude_run_tickets: {
			id_number: true,
		},
	}

	const importMapProperties: TsInterface_UnspecifiedObject = {
		new_crude_run_tickets: {
			associated_account_name: 				{ key: "associated_account_name",				header: "Account", 							prop: "associated_account_name", 				data_type: "string", 		association: "associated_account"},
			associated_cargo_name: 					{ key: "associated_cargo_name",					header: "Commodity", 						prop: "associated_cargo_name", 					data_type: "string", 		association: "associated_cargo"},
			associated_dock_name: 					{ key: "associated_dock_name",					header: "Dock", 							prop: "associated_dock_name", 					data_type: "string", 		association: "associated_dock"},
			associated_driver_name: 				{ key: "associated_driver_name",				header: "Driver", 							prop: "associated_driver_name", 				data_type: "string", 		association: "associated_driver"},
			associated_lease_name: 					{ key: "associated_lease_name",					header: "Lease", 							prop: "associated_lease_name", 					data_type: "string", 		association: "associated_lease"},
			associated_lease_property_number: 		{ key: "associated_lease_property_number",		header: "Property Lease Number", 			prop: "associated_lease_property_number", 		data_type: "string", },
			associated_offload_name: 				{ key: "associated_offload_name",				header: "Offload", 							prop: "associated_offload_name", 				data_type: "string", 		association: "associated_offload"},
			associated_producer_name: 				{ key: "associated_producer_name",				header: "Producer", 						prop: "associated_producer_name", 				data_type: "string", 		association: "associated_producer"},
			associated_purchaser_name: 				{ key: "associated_purchaser_name",				header: "Purchaser", 						prop: "associated_purchaser_name", 				data_type: "string", 		association: "associated_purchaser"},
			associated_purchaser_region_name: 		{ key: "associated_purchaser_region_name",		header: "Purchaser Region", 				prop: "associated_purchaser_region_name", 		data_type: "string", 		association: "associated_purchaser_region"},
			associated_region_name: 				{ key: "associated_region_name",				header: "Region", 							prop: "associated_region_name", 				data_type: "string", 		association: "associated_region"},
			associated_trailer_name: 				{ key: "associated_trailer_name",				header: "Trailer", 							prop: "associated_trailer_name", 				data_type: "string", 		association: "associated_trailer"},
			associated_truck_name: 					{ key: "associated_truck_name",					header: "Truck", 							prop: "associated_truck_name", 					data_type: "string", 		association: "associated_truck"},
			bol: 									{ key: "bol",									header: "BOL", 								prop: "bol", 									data_type: "number", },
			completed_date: 						{ key: "completed_date",						header: "Completed Date (MM/dd/yy)", 		prop: "timestamp_completed", 					data_type: "date", 			format: "MM/dd/yy", 	linked_time_prop: "completed_time" },
			completed_time: 						{ key: "completed_time",						header: "Completed Time (HH:mm)", 			prop: "timestamp_completed", 					data_type: "time", 			format: "HH:mm", 		linked_date_prop: "completed_date" },
			dropoff_date_arrive: 					{ key: "dropoff_date_arrive",					header: "Unload Arrive Date (MM/dd/yy)", 	prop: "timestamp_receiver_arrived", 			data_type: "date", 			format: "MM/dd/yy", 	linked_time_prop: "dropoff_time_arrive" },
			dropoff_date_depart: 					{ key: "dropoff_date_depart",					header: "Unload Depart Date (MM/dd/yy)", 	prop: "timestamp_receiver_departed", 			data_type: "date", 			format: "MM/dd/yy", 	linked_time_prop: "dropoff_time_depart" },
			dropoff_time_arrive: 					{ key: "dropoff_time_arrive",					header: "Unload Arrive Time (HH:mm)", 		prop: "timestamp_receiver_arrived", 			data_type: "time", 			format: "HH:mm", 		linked_date_prop: "dropoff_date_arrive" },
			dropoff_time_depart: 					{ key: "dropoff_time_depart",					header: "Unload Depart Time (HH:mm)", 		prop: "timestamp_receiver_departed", 			data_type: "time", 			format: "HH:mm", 		linked_date_prop: "dropoff_date_depart" },
			external_confirmation_number: 			{ key: "external_confirmation_number",			header: "LoadCall Confirmation Number", 	prop: "external_confirmation_number", 			data_type: "number", },
			id_number: 								{ key: "id_number",								header: "Ticket Number", 					prop: "id_number", 								data_type: "number", },
			measurement_average_line_temp: 			{ key: "measurement_average_line_temp", 		header: "Average Line Temp", 				prop: "measurement_average_line_temp", 			data_type: "number", },
			measurement_b_gauge: 					{ key: "measurement_b_gauge", 					header: "Ending Gauge", 					prop: "measurement_b_gauge", 					data_type: "number", },
			measurement_b_temp: 					{ key: "measurement_b_temp", 					header: "Ending Temp", 						prop: "measurement_b_temp", 					data_type: "number", },
			measurement_bsw: 						{ key: "measurement_bsw", 						header: "BS&W", 							prop: "measurement_bsw", 						data_type: "number", },
			measurement_courtesy_number_lease: 		{ key: "measurement_courtesy_number_lease", 	header: "Courtesy Number", 					prop: "measurement_courtesy_number_lease", 		data_type: "string", },
			measurement_courtesy_number_offload: 	{ key: "measurement_courtesy_number_offload", 	header: "Receiver Courtesy", 				prop: "measurement_courtesy_number_offload", 	data_type: "string", },
			measurement_delivered_bbls: 			{ key: "measurement_delivered_bbls", 			header: "Delivered BBLs", 					prop: "measurement_delivered_bbls", 			data_type: "number", },
			measurement_delivered_gallons: 			{ key: "measurement_delivered_gallons", 		header: "Delivered Net Gallons", 			prop: "measurement_delivered_gallons", 			data_type: "number", },
			measurement_driver_notes: 				{ key: "measurement_driver_notes", 				header: "Notes", 							prop: "measurement_driver_notes", 				data_type: "string", },
			measurement_end_mass: 					{ key: "measurement_end_mass", 					header: "Ending Mass", 						prop: "measurement_end_mass", 					data_type: "number", },
			measurement_loaded_bbls: 				{ key: "measurement_loaded_bbls", 				header: "BBLs", 							prop: "measurement_loaded_bbls", 				data_type: "number", },
			measurement_loaded_bbls_dock_net: 		{ key: "measurement_loaded_bbls_dock_net", 		header: "Net BBLs", 						prop: "measurement_loaded_bbls_dock_net", 		data_type: "number", },
			measurement_loaded_gallons_net: 		{ key: "measurement_loaded_gallons_net", 		header: "Net Gallons", 						prop: "measurement_loaded_gallons_net", 		data_type: "number", },
			measurement_meter_factor: 				{ key: "measurement_meter_factor", 				header: "Meter Factor", 					prop: "measurement_meter_factor", 				data_type: "number", },
			measurement_obs_grav: 					{ key: "measurement_obs_grav", 					header: "Observed Grav", 					prop: "measurement_obs_grav", 					data_type: "number", },
			measurement_obs_temp: 					{ key: "measurement_obs_temp", 					header: "Observed Temp", 					prop: "measurement_obs_temp", 					data_type: "number", },
			measurement_receiver_wait_time: 		{ key: "measurement_receiver_wait_time", 		header: "Receiver Wait Time", 				prop: "measurement_receiver_wait_time", 		data_type: "string", },
			measurement_seal_off: 					{ key: "measurement_seal_off", 					header: "Seal Off", 						prop: "measurement_seal_off", 					data_type: "number", },
			measurement_seal_on: 					{ key: "measurement_seal_on", 					header: "Seal On", 							prop: "measurement_seal_on", 					data_type: "number", },
			measurement_shipper_wait_time: 			{ key: "measurement_shipper_wait_time", 		header: "Shipper Wait Time", 				prop: "measurement_shipper_wait_time", 			data_type: "string", },
			measurement_start_mass: 				{ key: "measurement_start_mass", 				header: "Starting Mass", 					prop: "measurement_start_mass", 				data_type: "number", },
			measurement_start_meter: 				{ key: "measurement_start_meter", 				header: "Start Meter (Pickup)", 			prop: "measurement_start_meter", 				data_type: "number", },
			measurement_start_meter_offload: 		{ key: "measurement_start_meter_offload", 		header: "Start Meter (Dropoff)",			prop: "measurement_start_meter_offload", 		data_type: "number", },
			measurement_stop_meter: 				{ key: "measurement_stop_meter", 				header: "Stop Meter (Pickup)", 				prop: "measurement_stop_meter", 				data_type: "number", },
			measurement_stop_meter_offload: 		{ key: "measurement_stop_meter_offload", 		header: "Stop Meter (Dropoff)", 			prop: "measurement_stop_meter_offload", 		data_type: "number", },
			measurement_t_gauge: 					{ key: "measurement_t_gauge", 					header: "Starting Gauge", 					prop: "measurement_t_gauge", 					data_type: "number", },
			measurement_t_temp: 					{ key: "measurement_t_temp", 					header: "Starting Temp", 					prop: "measurement_t_temp", 					data_type: "number", },
			mileage_loaded: 						{ key: "mileage_loaded",						header: "Loaded Miles", 					prop: "mileage_loaded", 						data_type: "number", },
			mileage_rerouted: 						{ key: "mileage_rerouted",						header: "Re-routed Miles", 					prop: "mileage_rerouted", 						data_type: "number", },
			mileage_standard: 						{ key: "mileage_standard",						header: "Standard Distance", 				prop: "mileage_standard", 						data_type: "number", },
			pickup_date_arrive: 					{ key: "pickup_date_arrive",					header: "Pickup Arrive Date (MM/dd/yy)", 	prop: "timestamp_shipper_arrived", 				data_type: "date", 			format: "MM/dd/yy",		linked_time_prop: "pickup_time_arrive" },
			pickup_date_depart: 					{ key: "pickup_date_depart",					header: "Pickup Depart Date (MM/dd/yy)", 	prop: "timestamp_shipper_departed", 			data_type: "date", 			format: "MM/dd/yy",		linked_time_prop: "pickup_time_depart" },
			pickup_time_arrive: 					{ key: "pickup_time_arrive",					header: "Pickup Arrive Time (HH:mm)", 		prop: "timestamp_shipper_arrived", 				data_type: "time", 			format: "HH:mm",		linked_date_prop: "pickup_date_arrive" },
			pickup_time_depart: 					{ key: "pickup_time_depart",					header: "Pickup Depart Time (HH:mm)", 		prop: "timestamp_shipper_departed", 			data_type: "time", 			format: "HH:mm",		linked_date_prop: "pickup_date_depart" },
			po_number: 								{ key: "po_number",								header: "PO Number", 						prop: "po_number", 								data_type: "string", },
			reject_status: 							{ key: "reject_status",							header: "Rejected", 						prop: "reject_status", 							data_type: "string", },
			status: 								{ key: "status",								header: "Status", 							prop: "status", 								data_type: "string", },
		},
		update_crude_run_tickets: {
			associated_dock_name: 					{ key: "associated_dock_name", 					header: "Dock (Name Only NOT Association)", prop: "associated_dock_name", 					data_type: "string", 		replace: "both" },
			external_confirmation_number: 			{ key: "external_confirmation_number", 			header: "LoadCall Confirmation Number", 	prop: "external_confirmation_number", 			data_type: "number",		replace: "main" },
			id_number: 								{ key: "id_number", 							header: "Ticket Number", 					prop: "id_number", 								data_type: "number", },
			measurement_average_line_temp: 			{ key: "measurement_average_line_temp", 		header: "Average Line Temp", 				prop: "measurement_average_line_temp", 			data_type: "number", 		replace: "main" },
			measurement_b_gauge: 					{ key: "measurement_b_gauge", 					header: "Ending Gauge", 					prop: "measurement_b_gauge", 					data_type: "number", 		replace: "main" },
			measurement_b_temp: 					{ key: "measurement_b_temp", 					header: "Ending Temp", 						prop: "measurement_b_temp", 					data_type: "number", 		replace: "main" },
			measurement_bsw: 						{ key: "measurement_bsw", 						header: "BS&W", 							prop: "measurement_bsw", 						data_type: "number", 		replace: "main" },
			measurement_courtesy_number_lease: 		{ key: "measurement_courtesy_number_lease", 	header: "Courtesy Number", 					prop: "measurement_courtesy_number_lease", 		data_type: "string", 		replace: "main" },
			measurement_delivered_bbls: 			{ key: "measurement_delivered_bbls", 			header: "Delivered BBLs", 					prop: "measurement_delivered_bbls", 			data_type: "number", 		replace: "main" },
			measurement_delivered_gallons: 			{ key: "measurement_delivered_gallons", 		header: "Delivered Net Gallons", 			prop: "measurement_delivered_gallons", 			data_type: "number", 		replace: "main" },
			measurement_end_mass: 					{ key: "measurement_end_mass", 					header: "Ending Mass", 						prop: "measurement_end_mass", 					data_type: "number", 		replace: "main" },
			measurement_loaded_bbls: 				{ key: "measurement_loaded_bbls", 				header: "BBLs", 							prop: "measurement_loaded_bbls", 				data_type: "number", 		replace: "both" },
			measurement_loaded_bbls_dock_net: 		{ key: "measurement_loaded_bbls_dock_net", 		header: "Net BBLs", 						prop: "measurement_loaded_bbls_dock_net", 		data_type: "number", 		replace: "both" },
			measurement_loaded_gallons_net: 		{ key: "measurement_loaded_gallons_net", 		header: "Net Gallons", 						prop: "measurement_loaded_gallons_net", 		data_type: "number", 		replace: "main" },
			measurement_meter_factor: 				{ key: "measurement_meter_factor", 				header: "Meter Factor", 					prop: "measurement_meter_factor", 				data_type: "number", 		replace: "main" },
			measurement_obs_grav: 					{ key: "measurement_obs_grav", 					header: "Observed Grav", 					prop: "measurement_obs_grav", 					data_type: "number", 		replace: "main" },
			measurement_obs_temp: 					{ key: "measurement_obs_temp", 					header: "Observed Temp", 					prop: "measurement_obs_temp", 					data_type: "number", 		replace: "main" },
			measurement_receiver_wait_time: 		{ key: "measurement_receiver_wait_time", 		header: "Receiver Wait Time", 				prop: "measurement_receiver_wait_time", 		data_type: "string", 		replace: "main" },
			measurement_seal_off: 					{ key: "measurement_seal_off", 					header: "Seal Off", 						prop: "measurement_seal_off", 					data_type: "number", 		replace: "main" },
			measurement_seal_on: 					{ key: "measurement_seal_on", 					header: "Seal On", 							prop: "measurement_seal_on", 					data_type: "number", 		replace: "main" },
			measurement_shipper_wait_time: 			{ key: "measurement_shipper_wait_time", 		header: "Shipper Wait Time", 				prop: "measurement_shipper_wait_time", 			data_type: "string", 		replace: "main" },
			measurement_start_mass: 				{ key: "measurement_start_mass", 				header: "Starting Mass", 					prop: "measurement_start_mass", 				data_type: "number", 		replace: "main" },
			measurement_start_meter: 				{ key: "measurement_start_meter", 				header: "Start Meter (Pickup)", 			prop: "measurement_start_meter", 				data_type: "number", 		replace: "main" },
			measurement_start_meter_offload: 		{ key: "measurement_start_meter_offload", 		header: "Start Meter (Dropoff)",			prop: "measurement_start_meter_offload", 		data_type: "number", 		replace: "main" },
			measurement_stop_meter: 				{ key: "measurement_stop_meter", 				header: "Stop Meter (Pickup)", 				prop: "measurement_stop_meter", 				data_type: "number", 		replace: "main" },
			measurement_stop_meter_offload: 		{ key: "measurement_stop_meter_offload", 		header: "Stop Meter (Dropoff)", 			prop: "measurement_stop_meter_offload", 		data_type: "number", 		replace: "main" },
			measurement_t_gauge: 					{ key: "measurement_t_gauge", 					header: "Starting Gauge", 					prop: "measurement_t_gauge", 					data_type: "number", 		replace: "main" },
			measurement_t_temp: 					{ key: "measurement_t_temp", 					header: "Starting Temp", 					prop: "measurement_t_temp", 					data_type: "number", 		replace: "main" },
			mileage_standard: 						{ key: "mileage_standard", 						header: "Standard Distance", 				prop: "mileage_standard", 						data_type: "number", 		replace: "main" },
			po_number: 								{ key: "po_number", 							header: "PO Number", 						prop: "po_number", 								data_type: "string",		replace: "main" },
		},
	}

	const missingItemCheck: TsInterface_UnspecifiedObject = {
		new_crude_run_tickets: (
			mappedDataItem: TsInterface_UnspecifiedObject,
		): TsInterface_UnspecifiedObject => {
			return new Promise((resolve, reject) => {
				let missingFieldCount = 0
				let missingFields: TsType_String[] = []
				for( let loopPropKey in requiredImportMapProperties["new_crude_run_tickets"] ){
					if(
						 importMapProperties["new_crude_run_tickets"][loopPropKey] != null &&
						 importMapProperties["new_crude_run_tickets"][loopPropKey]["prop"] != null &&
						 mappedDataItem[ importMapProperties["new_crude_run_tickets"][loopPropKey]["prop"] ] != null
					){
						// Found Mapped Fields - no warning
					} else if( mappedDataItem[ loopPropKey ] == null ){
						missingFieldCount++
						missingFields.push( importMapProperties["new_crude_run_tickets"][ loopPropKey ]["header"] )
					}
				}
				if( missingFieldCount === 0 ){
					resolve({ success: true })
				} else {
					reject({ success: false, warning_message: "Ticket " + mappedDataItem.id_number + " missing the following fields: " + missingFields.join(', ') })
				}
			})
		},
		update_crude_run_tickets: (
			mappedDataItem: TsInterface_UnspecifiedObject,
		): TsInterface_UnspecifiedObject => {
			return new Promise((resolve, reject) => {
				let missingFieldCount = 0
				let missingFields: TsType_String[] = []
				for( let loopPropKey in requiredImportMapProperties["update_crude_run_tickets"] ){
					if( mappedDataItem[ loopPropKey ] == null ){
						missingFieldCount++
						missingFields.push( importMapProperties["update_crude_run_tickets"][ loopPropKey ]["header"] )
					}
				}
				if( missingFieldCount === 0 ){
					resolve({ success: true })
				} else {
					reject({ success: false, warning_message: "Ticket " + mappedDataItem.id_number + " missing the following fields: " + missingFields.join(', ') })
				}
			})
		}
	}

	const duplicateItemCheck: TsInterface_UnspecifiedObject = {
		new_crude_run_tickets: (
			clientKey: TsType_String,
			mappedDataItem: TsInterface_UnspecifiedObject,
		): TsInterface_UnspecifiedObject => {
			return new Promise((resolve, reject) => {
				if(
					mappedDataItem != null &&
					mappedDataItem.id_number != null
				){
					DatabaseGetCollection( DatabaseRef_TicketIDNumberSearch_Query( clientKey, mappedDataItem.id_number ) ).then( ( res_DGC ) => {
						let nonDeletedTickets = objectToArray( res_DGC.data ).filter(( ticket: TsInterface_UnspecifiedObject ) => ticket.status !== "deleted" )
						if( nonDeletedTickets.length === 0 ){
							resolve({ success: true })
						} else {
							reject({ success: false, warning_message: "Ticket " + mappedDataItem.id_number + " already exists in the system" })
						}
					}).catch( ( rej_DGC ) => {
						reject( rej_DGC )
					})
				} else {
					reject({success: false})
				}
			})
		},
		update_crude_run_tickets: (
			clientKey: TsType_String,
			mappedDataItem: TsInterface_UnspecifiedObject,
		): TsInterface_UnspecifiedObject => {
			return new Promise((resolve, reject) => {
				resolve({ success: true })
			})
		}
	}

	const importSessionKeyGeneration: TsInterface_UnspecifiedObject = {
		new_crude_run_tickets: (
			clientKey: TsType_String,
			mappedDataItem: TsInterface_UnspecifiedObject,
		): TsInterface_UnspecifiedObject => {
			return new Promise((resolve, reject) => {
				resolve({ success: true, data: mappedDataItem, key: mappedDataItem.key })
			})
		},
		update_crude_run_tickets: (
			clientKey: TsType_String,
			mappedDataItem: TsInterface_UnspecifiedObject
		): TsInterface_UnspecifiedObject => {
			return new Promise((resolve, reject) => {
				if(
					mappedDataItem != null &&
					mappedDataItem.id_number != null
				){
					DatabaseGetCollection( DatabaseRef_TicketIDNumberSearch_Query( clientKey, mappedDataItem.id_number ) ).then( ( res_DGC ) => {
						let nonDeletedTickets = objectToArray( res_DGC.data ).filter(( ticket: TsInterface_UnspecifiedObject ) => ticket.status !== "deleted" )
						if(
							nonDeletedTickets.length === 1 &&
							nonDeletedTickets[0] != null &&
							nonDeletedTickets[0]["key"] != null
						){
							mappedDataItem.key = nonDeletedTickets[0]["key"]
							resolve({ success: true, data: mappedDataItem, key: mappedDataItem.key })
						} else {
							reject({success: false})
						}
					}).catch( ( rej_DGC ) => {
						reject( rej_DGC )
					})
				} else {
					reject({success: false})
				}
			})
		},
	}

	const previewMapProperties: TsInterface_UnspecifiedObject = {
		new_crude_run_tickets: {
			// key: 									{ key: "key",									header: "Key", 								prop: "key", 									data_type: "string", },
			associated_account_name: 				{ key: "associated_account_name",				header: "Account", 							prop: "associated_account_name", 				data_type: "string", 		association: "associated_account"},
			associated_cargo_name: 					{ key: "associated_cargo_name",					header: "Commodity", 						prop: "associated_cargo_name", 					data_type: "string", 		association: "associated_cargo"},
			associated_dock_name: 					{ key: "associated_dock_name",					header: "Dock", 							prop: "associated_dock_name", 					data_type: "string", 		association: "associated_dock"},
			associated_driver_name: 				{ key: "associated_driver_name",				header: "Driver", 							prop: "associated_driver_name", 				data_type: "string", 		association: "associated_driver"},
			associated_lease_name: 					{ key: "associated_lease_name",					header: "Lease", 							prop: "associated_lease_name", 					data_type: "string", 		association: "associated_lease"},
			associated_lease_property_number: 		{ key: "associated_lease_property_number",		header: "Property Lease Number", 			prop: "associated_lease_property_number", 		data_type: "string", },
			associated_offload_name: 				{ key: "associated_offload_name",				header: "Offload", 							prop: "associated_offload_name", 				data_type: "string", 		association: "associated_offload"},
			associated_producer_name: 				{ key: "associated_producer_name",				header: "Producer", 						prop: "associated_producer_name", 				data_type: "string", 		association: "associated_producer"},
			associated_purchaser_name: 				{ key: "associated_purchaser_name",				header: "Purchaser", 						prop: "associated_purchaser_name", 				data_type: "string", 		association: "associated_purchaser"},
			associated_purchaser_region_name: 		{ key: "associated_purchaser_region_name",		header: "Purchaser Region", 				prop: "associated_purchaser_region_name", 		data_type: "string", 		association: "associated_purchaser_region"},
			associated_region_name: 				{ key: "associated_region_name",				header: "Region", 							prop: "associated_region_name", 				data_type: "string", 		association: "associated_region"},
			associated_trailer_name: 				{ key: "associated_trailer_name",				header: "Trailer", 							prop: "associated_trailer_name", 				data_type: "string", 		association: "associated_trailer"},
			associated_truck_name: 					{ key: "associated_truck_name",					header: "Truck", 							prop: "associated_truck_name", 					data_type: "string", 		association: "associated_truck"},
			bol: 									{ key: "bol",									header: "BOL", 								prop: "bol", 									data_type: "number", },
			timestamp_completed: 					{ key: "timestamp_completed",					header: "Completed Date (MM/dd/yy)", 		prop: "timestamp_completed", 					data_type: "datetime", 			format: "MM/dd/yy", 	linked_time_prop: "completed_time" },
			// completed_date: 						{ key: "completed_date",						header: "Completed Date (MM/dd/yy)", 		prop: "timestamp_completed", 					data_type: "date", 			format: "MM/dd/yy", 	linked_time_prop: "completed_time" },
			// completed_time: 						{ key: "completed_time",						header: "Completed Time (HH:mm)", 			prop: "timestamp_completed", 					data_type: "time", 			format: "HH:mm", 		linked_date_prop: "completed_date" },
			timestamp_receiver_arrived: 				{ key: "timestamp_receiver_arrived",			header: "Unload Arrive Date (MM/dd/yy)", 	prop: "timestamp_receiver_arrived", 		data_type: "datetime", 			format: "MM/dd/yy", 	linked_time_prop: "dropoff_time_arrive" },
			// dropoff_date_arrive: 					{ key: "dropoff_date_arrive",					header: "Unload Arrive Date (MM/dd/yy)", 	prop: "timestamp_receiver_arrived", 			data_type: "date", 			format: "MM/dd/yy", 	linked_time_prop: "dropoff_time_arrive" },
			timestamp_receiver_departed: 				{ key: "timestamp_receiver_departed",			header: "Unload Depart Date (MM/dd/yy)", 	prop: "timestamp_receiver_departed", 		data_type: "datetime", 			format: "MM/dd/yy", 	linked_time_prop: "dropoff_time_depart" },
			// dropoff_date_depart: 					{ key: "dropoff_date_depart",					header: "Unload Depart Date (MM/dd/yy)", 	prop: "timestamp_receiver_departed", 			data_type: "date", 			format: "MM/dd/yy", 	linked_time_prop: "dropoff_time_depart" },
			// dropoff_time_arrive: 					{ key: "dropoff_time_arrive",					header: "Unload Arrive Time (HH:mm)", 		prop: "timestamp_receiver_arrived", 			data_type: "time", 			format: "HH:mm", 		linked_date_prop: "dropoff_date_arrive" },
			// dropoff_time_depart: 					{ key: "dropoff_time_depart",					header: "Unload Depart Time (HH:mm)", 		prop: "timestamp_receiver_departed", 			data_type: "time", 			format: "HH:mm", 		linked_date_prop: "dropoff_date_depart" },
			external_confirmation_number: 			{ key: "external_confirmation_number",			header: "LoadCall Confirmation Number", 	prop: "external_confirmation_number", 			data_type: "number", },
			id_number: 								{ key: "id_number",								header: "Ticket Number", 					prop: "id_number", 								data_type: "number", },
			measurement_average_line_temp: 			{ key: "measurement_average_line_temp", 		header: "Average Line Temp", 				prop: "measurement_average_line_temp", 			data_type: "number", },
			measurement_b_gauge: 					{ key: "measurement_b_gauge", 					header: "Ending Gauge", 					prop: "measurement_b_gauge", 					data_type: "number", },
			measurement_b_temp: 					{ key: "measurement_b_temp", 					header: "Ending Temp", 						prop: "measurement_b_temp", 					data_type: "number", },
			measurement_bsw: 						{ key: "measurement_bsw", 						header: "BS&W", 							prop: "measurement_bsw", 						data_type: "number", },
			measurement_courtesy_number_lease: 		{ key: "measurement_courtesy_number_lease", 	header: "Courtesy Number", 					prop: "measurement_courtesy_number_lease", 		data_type: "string", },
			measurement_courtesy_number_offload: 	{ key: "measurement_courtesy_number_offload", 	header: "Receiver Courtesy", 				prop: "measurement_courtesy_number_offload", 	data_type: "string", },
			measurement_delivered_bbls: 			{ key: "measurement_delivered_bbls", 			header: "Delivered BBLs", 					prop: "measurement_delivered_bbls", 			data_type: "number", },
			measurement_delivered_gallons: 			{ key: "measurement_delivered_gallons", 		header: "Delivered Net Gallons", 			prop: "measurement_delivered_gallons", 			data_type: "number", },
			measurement_driver_notes: 				{ key: "measurement_driver_notes", 				header: "Notes", 							prop: "measurement_driver_notes", 				data_type: "string", },
			measurement_end_mass: 					{ key: "measurement_end_mass", 					header: "Ending Mass", 						prop: "measurement_end_mass", 					data_type: "number", },
			measurement_loaded_bbls: 				{ key: "measurement_loaded_bbls", 				header: "BBLs", 							prop: "measurement_loaded_bbls", 				data_type: "number", },
			measurement_loaded_bbls_dock_net: 		{ key: "measurement_loaded_bbls_dock_net", 		header: "Net BBLs", 						prop: "measurement_loaded_bbls_dock_net", 		data_type: "number", },
			measurement_loaded_gallons_net: 		{ key: "measurement_loaded_gallons_net", 		header: "Net Gallons", 						prop: "measurement_loaded_gallons_net", 		data_type: "number", },
			measurement_meter_factor: 				{ key: "measurement_meter_factor", 				header: "Meter Factor", 					prop: "measurement_meter_factor", 				data_type: "number", },
			measurement_obs_grav: 					{ key: "measurement_obs_grav", 					header: "Observed Grav", 					prop: "measurement_obs_grav", 					data_type: "number", },
			measurement_obs_temp: 					{ key: "measurement_obs_temp", 					header: "Observed Temp", 					prop: "measurement_obs_temp", 					data_type: "number", },
			measurement_receiver_wait_time: 		{ key: "measurement_receiver_wait_time", 		header: "Receiver Wait Time", 				prop: "measurement_receiver_wait_time", 		data_type: "string", },
			measurement_seal_off: 					{ key: "measurement_seal_off", 					header: "Seal Off", 						prop: "measurement_seal_off", 					data_type: "number", },
			measurement_seal_on: 					{ key: "measurement_seal_on", 					header: "Seal On", 							prop: "measurement_seal_on", 					data_type: "number", },
			measurement_shipper_wait_time: 			{ key: "measurement_shipper_wait_time", 		header: "Shipper Wait Time", 				prop: "measurement_shipper_wait_time", 			data_type: "string", },
			measurement_start_mass: 				{ key: "measurement_start_mass", 				header: "Starting Mass", 					prop: "measurement_start_mass", 				data_type: "number", },
			measurement_start_meter: 				{ key: "measurement_start_meter", 				header: "Start Meter (Pickup)", 			prop: "measurement_start_meter", 				data_type: "number", },
			measurement_start_meter_offload: 		{ key: "measurement_start_meter_offload", 		header: "Start Meter (Dropoff)",			prop: "measurement_start_meter_offload", 		data_type: "number", },
			measurement_stop_meter: 				{ key: "measurement_stop_meter", 				header: "Stop Meter (Pickup)", 				prop: "measurement_stop_meter", 				data_type: "number", },
			measurement_stop_meter_offload: 		{ key: "measurement_stop_meter_offload", 		header: "Stop Meter (Dropoff)", 			prop: "measurement_stop_meter_offload", 		data_type: "number", },
			measurement_t_gauge: 					{ key: "measurement_t_gauge", 					header: "Starting Gauge", 					prop: "measurement_t_gauge", 					data_type: "number", },
			measurement_t_temp: 					{ key: "measurement_t_temp", 					header: "Starting Temp", 					prop: "measurement_t_temp", 					data_type: "number", },
			mileage_loaded: 						{ key: "mileage_loaded",						header: "Loaded Miles", 					prop: "mileage_loaded", 						data_type: "number", },
			mileage_rerouted: 						{ key: "mileage_rerouted",						header: "Re-routed Miles", 					prop: "mileage_rerouted", 						data_type: "number", },
			mileage_standard: 						{ key: "mileage_standard",						header: "Standard Distance", 				prop: "mileage_standard", 						data_type: "number", },
			timestamp_shipper_arrived: 				{ key: "timestamp_shipper_arrived",				header: "Pickup Arrive Date (MM/dd/yy)", 	prop: "timestamp_shipper_arrived", 				data_type: "datetime", 		format: "MM/dd/yy",		linked_time_prop: "pickup_time_arrive" },
			// pickup_date_arrive: 					{ key: "pickup_date_arrive",					header: "Pickup Arrive Date (MM/dd/yy)", 	prop: "timestamp_shipper_arrived", 				data_type: "date", 			format: "MM/dd/yy",		linked_time_prop: "pickup_time_arrive" },
			timestamp_shipper_departed: 			{ key: "timestamp_shipper_departed",			header: "Pickup Depart Date (MM/dd/yy)", 	prop: "timestamp_shipper_departed", 			data_type: "datetime", 		format: "MM/dd/yy",		linked_time_prop: "pickup_time_depart" },
			// pickup_date_depart: 					{ key: "pickup_date_depart",					header: "Pickup Depart Date (MM/dd/yy)", 	prop: "timestamp_shipper_departed", 			data_type: "date", 			format: "MM/dd/yy",		linked_time_prop: "pickup_time_depart" },
			// pickup_time_arrive: 					{ key: "pickup_time_arrive",					header: "Pickup Arrive Time (HH:mm)", 		prop: "timestamp_shipper_arrived", 				data_type: "time", 			format: "HH:mm",		linked_date_prop: "pickup_date_arrive" },
			// pickup_time_depart: 					{ key: "pickup_time_depart",					header: "Pickup Depart Time (HH:mm)", 		prop: "timestamp_shipper_departed", 			data_type: "time", 			format: "HH:mm",		linked_date_prop: "pickup_date_depart" },
			po_number: 								{ key: "po_number",								header: "PO Number", 						prop: "po_number", 								data_type: "string", },
			reject_status: 							{ key: "reject_status",							header: "Rejected", 						prop: "reject_status", 							data_type: "string", },
			status: 								{ key: "status",								header: "Status", 							prop: "status", 								data_type: "string", },
		},
		update_crude_run_tickets: {
			associated_dock_name: 					{ key: "associated_dock_name", 					header: "Dock (Name Only NOT Association)", prop: "associated_dock_name", 					data_type: "string", 		replace: "both" },
			external_confirmation_number: 			{ key: "external_confirmation_number", 			header: "LoadCall Confirmation Number", 	prop: "external_confirmation_number", 			data_type: "number",		replace: "main" },
			id_number: 								{ key: "id_number", 							header: "Ticket Number", 					prop: "id_number", 								data_type: "number", },
			measurement_average_line_temp: 			{ key: "measurement_average_line_temp", 		header: "Average Line Temp", 				prop: "measurement_average_line_temp", 			data_type: "number",		replace: "main" },
			measurement_b_gauge: 					{ key: "measurement_b_gauge", 					header: "Ending Gauge", 					prop: "measurement_b_gauge", 					data_type: "number", 		replace: "main" },
			measurement_b_temp: 					{ key: "measurement_b_temp", 					header: "Ending Temp", 						prop: "measurement_b_temp", 					data_type: "number", 		replace: "main" },
			measurement_bsw: 						{ key: "measurement_bsw", 						header: "BS&W", 							prop: "measurement_bsw", 						data_type: "number", 		replace: "main" },
			measurement_courtesy_number_lease: 		{ key: "measurement_courtesy_number_lease", 	header: "Courtesy Number", 					prop: "measurement_courtesy_number_lease", 		data_type: "string", 		replace: "main" },
			measurement_delivered_bbls: 			{ key: "measurement_delivered_bbls", 			header: "Delivered BBLs", 					prop: "measurement_delivered_bbls", 			data_type: "number", 		replace: "main" },
			measurement_delivered_gallons: 			{ key: "measurement_delivered_gallons", 		header: "Delivered Net Gallons", 			prop: "measurement_delivered_gallons", 			data_type: "number",		replace: "main" },
			measurement_end_mass: 					{ key: "measurement_end_mass", 					header: "Ending Mass", 						prop: "measurement_end_mass", 					data_type: "number",		replace: "main" },
			measurement_loaded_bbls: 				{ key: "measurement_loaded_bbls", 				header: "BBLs", 							prop: "measurement_loaded_bbls", 				data_type: "number", 		replace: "both" },
			measurement_loaded_bbls_dock_net: 		{ key: "measurement_loaded_bbls_dock_net", 		header: "Net BBLs", 						prop: "measurement_loaded_bbls_dock_net", 		data_type: "number", 		replace: "both" },
			measurement_loaded_gallons_net: 		{ key: "measurement_loaded_gallons_net", 		header: "Net Gallons", 						prop: "measurement_loaded_gallons_net", 		data_type: "number",		replace: "main" },
			measurement_meter_factor: 				{ key: "measurement_meter_factor", 				header: "Meter Factor", 					prop: "measurement_meter_factor", 				data_type: "number",		replace: "main" },
			measurement_obs_grav: 					{ key: "measurement_obs_grav", 					header: "Observed Grav", 					prop: "measurement_obs_grav", 					data_type: "number", 		replace: "main" },
			measurement_obs_temp: 					{ key: "measurement_obs_temp", 					header: "Observed Temp", 					prop: "measurement_obs_temp", 					data_type: "number", 		replace: "main" },
			measurement_receiver_wait_time: 		{ key: "measurement_receiver_wait_time", 		header: "Receiver Wait Time", 				prop: "measurement_receiver_wait_time", 		data_type: "string", 		replace: "main" },
			measurement_seal_off: 					{ key: "measurement_seal_off", 					header: "Seal Off", 						prop: "measurement_seal_off", 					data_type: "number", 		replace: "main" },
			measurement_seal_on: 					{ key: "measurement_seal_on", 					header: "Seal On", 							prop: "measurement_seal_on", 					data_type: "number", 		replace: "main" },
			measurement_shipper_wait_time: 			{ key: "measurement_shipper_wait_time", 		header: "Shipper Wait Time", 				prop: "measurement_shipper_wait_time", 			data_type: "string", 		replace: "main" },
			measurement_start_mass: 				{ key: "measurement_start_mass", 				header: "Starting Mass", 					prop: "measurement_start_mass", 				data_type: "number",		replace: "main" },
			measurement_start_meter: 				{ key: "measurement_start_meter", 				header: "Start Meter (Pickup)", 			prop: "measurement_start_meter", 				data_type: "number", 		replace: "main" },
			measurement_start_meter_offload: 		{ key: "measurement_start_meter_offload", 		header: "Start Meter (Dropoff)",			prop: "measurement_start_meter_offload", 		data_type: "number", 		replace: "main" },
			measurement_stop_meter: 				{ key: "measurement_stop_meter", 				header: "Stop Meter (Pickup)", 				prop: "measurement_stop_meter", 				data_type: "number", 		replace: "main" },
			measurement_stop_meter_offload: 		{ key: "measurement_stop_meter_offload", 		header: "Stop Meter (Dropoff)", 			prop: "measurement_stop_meter_offload", 		data_type: "number", 		replace: "main" },
			measurement_t_gauge: 					{ key: "measurement_t_gauge", 					header: "Starting Gauge", 					prop: "measurement_t_gauge", 					data_type: "number", 		replace: "main" },
			measurement_t_temp: 					{ key: "measurement_t_temp", 					header: "Starting Temp", 					prop: "measurement_t_temp", 					data_type: "number", 		replace: "main" },
			mileage_standard: 						{ key: "mileage_standard", 						header: "Standard Distance", 				prop: "mileage_standard", 						data_type: "number", 		replace: "main" },
			po_number: 								{ key: "po_number", 							header: "PO Number", 						prop: "po_number", 								data_type: "string",		replace: "main" },
		},
	}

	const crudeAutoMappingProperties: TsInterface_UnspecifiedObject = {
		"Ticket Number": 						"id_number",
		"Lease": 								"associated_lease_name",
		"Shipper": 								"associated_lease_name",
		"Offload": 								"associated_offload_name",
		"Destination": 							"associated_offload_name",
		"Property Lease Number": 				"associated_lease_property_number",
		"Property Lease #": 					"associated_lease_property_number",
		"Dock": 								"associated_dock_name",
		"Account": 								"associated_account_name",
		"BOL": 									"bol",
		"Ticket #": 							"id_number",
		"Courtesy #": 							"measurement_courtesy_number_lease",
		"Courtesy Number": 						"measurement_courtesy_number_lease",
		"PO Number": 							"po_number",
		"Producer": 							"associated_producer_name",
		"Operator Name": 						"associated_producer_name",
		"Purchaser": 							"associated_purchaser_name",
		"Biller": 								"associated_purchaser_name",
		// "Region": 								"associated_purchaser_region_name",
		"Purchaser Region": 					"associated_purchaser_region_name",
		"Receiver Courtesy": 					"measurement_courtesy_number_offload",
		// "Split Tickets": 						"split_tickets",
		"Driver": 								"associated_driver_name",
		"Region": 								"associated_region_name",
		"Trailer": 								"associated_trailer_name",
		"Truck": 								"associated_truck_name",
		"LoadCall Confirmation Number": 		"external_confirmation_number",
		"Pickup Arrive Date (MM/dd/yy)": 		"pickup_date_arrive",
		"Pickup Arrive Time (HH:mm)": 			"pickup_time_arrive",
		"Pickup Depart Date (MM/dd/yy)": 		"pickup_date_depart",
		"Pickup Depart Time (HH:mm)": 			"pickup_time_depart",
		"Unload Arrive Date (MM/dd/yy)": 		"dropoff_date_arrive",
		"Unload Arrive Time (HH:mm)": 			"dropoff_time_arrive",
		"Unload Depart Date (MM/dd/yy)": 		"dropoff_date_depart",
		"Unload Depart Time (HH:mm)": 			"dropoff_time_depart",
		"Completed Date (MM/dd/yy)": 			"completed_date",
		"Completed Time (HH:mm)": 				"completed_time",
		"Loaded Miles": 						"mileage_loaded",
		"Standard Distance": 					"mileage_standard",
		"Re-routed Miles": 						"mileage_rerouted",
		"Shipper Wait Time": 					"measurement_shipper_wait_time",
		"Receiver Wait Time": 					"measurement_receiver_wait_time",
		"Start Meter (Pickup)": 				"measurement_start_meter",
		"Stop Meter (Pickup)": 					"measurement_stop_meter",
		"Starting Gauge": 						"measurement_t_gauge",
		"Ending Gauge": 						"measurement_b_gauge",
		"Observed Temp": 						"measurement_obs_temp",
		"Starting Temp": 						"measurement_t_temp",
		"Ending Temp": 							"measurement_b_temp",
		"Average Line Temp": 					"measurement_average_line_temp",
		"Meter Factor": 						"measurement_meter_factor",
		"Observed Grav": 						"measurement_obs_grav",
		"BS&W": 								"measurement_bsw",
		"BBLs": 								"measurement_loaded_bbls",
		"Net BBLs": 							"measurement_loaded_bbls_dock_net",
		"Delivered BBLs": 						"measurement_delivered_bbls",
		"Starting Mass":						"measurement_start_mass",
		"Ending Mass":							"measurement_end_mass",
		"Net Gallons":							"measurement_loaded_gallons_net",
		"Delivered Net Gallons":				"measurement_delivered_gallons",
		"Seal On": 								"measurement_seal_on",
		"Seal Off": 							"measurement_seal_off",
		"Start Meter (Dropoff)": 				"measurement_start_meter_offload",
		"Stop Meter (Dropoff)": 				"measurement_stop_meter_offload",
		"Status": 								"status",
		"Rejected": 							"reject_status",
		"Commodity": 							"associated_cargo_name",
		"Notes": 								"measurement_driver_notes",
	}

	const autoMappingProperties: TsInterface_UnspecifiedObject = {
		new_crude_run_tickets: cloneObjectWithoutReference( crudeAutoMappingProperties ),
		update_crude_run_tickets: cloneObjectWithoutReference( crudeAutoMappingProperties ),
	}

	const crudePrimaryAssociations: TsInterface_UnspecifiedObject = {
		associated_cargo_name: {
			searchIndexKey: "cargo",
			keyProp: "associated_cargo",
			nameProp: "associated_cargo_name",
			name: "Cargo",
			associationSearchEndpoint:( clientKey: TsType_String, name: TsType_String ) => { return DatabaseRef_CargoNameSearch_Query( clientKey, name ) },
			subassociationName: null,
			subassociationKeyProp: null,
			subassociationNameProp: null,
			subassociationEndpoint: null,
			linkedAssociationName: null,
			linkedAssociationKeyProp: null,
			linkedAssociationNameProp: null,
		},
		associated_lease_name: {
			searchIndexKey: "leases",
			keyProp: "associated_lease",
			nameProp: "associated_lease_name",
			name: "Leases",
			associationSearchEndpoint:( clientKey: TsType_String, name: TsType_String ) => { return DatabaseRef_LeaseNameSearch_Query( clientKey, name ) },
			subassociationName: "Docks",
			subassociationKeyProp: "associated_dock",
			subassociationNameProp: "associated_dock_name",
			subassociationEndpoint: ( clientKey: TsType_String, itemKey: TsType_String ) => { return DatabaseRef_Leases_Docks_Collection( clientKey, itemKey ) },
			linkedAssociationName: null,
			linkedAssociationKeyProp: null,
			linkedAssociationNameProp: null,
		},
		associated_offload_name: {
			searchIndexKey: "offloads",
			keyProp: "associated_offload",
			nameProp: "associated_offload_name",
			associationSearchEndpoint:( clientKey: TsType_String, name: TsType_String ) => { return DatabaseRef_OffloadNameSearch_Query( clientKey, name ) },
			name: "Offloads",
			subassociationName: "Accounts",
			subassociationKeyProp: "associated_account",
			subassociationNameProp: "associated_account_name",
			subassociationEndpoint: ( clientKey: TsType_String, itemKey: TsType_String ) => { return DatabaseRef_Offload_Accounts_Collection( clientKey, itemKey ) },
			linkedAssociationName: null,
			linkedAssociationKeyProp: null,
			linkedAssociationNameProp: null,
		},
		associated_producer_name: {
			searchIndexKey: "producers",
			keyProp: "associated_producer",
			nameProp: "associated_producer_name",
			associationSearchEndpoint:( clientKey: TsType_String, name: TsType_String ) => { return DatabaseRef_ProducerNameSearch_Query( clientKey, name ) },
			name: "Producers",
			subassociationName: null,
			subassociationKeyProp: null,
			subassociationNameProp: null,
			subassociationEndpoint: null,
			linkedAssociationName: null,
			linkedAssociationKeyProp: null,
			linkedAssociationNameProp: null,
		},
		associated_purchaser_name: {
			searchIndexKey: "purchasers",
			keyProp: "associated_purchaser",
			nameProp: "associated_purchaser_name",
			associationSearchEndpoint:( clientKey: TsType_String, name: TsType_String ) => { return DatabaseRef_PurchaserNameSearch_Query( clientKey, name ) },
			name: "Purchasers",
			subassociationName: "Purchaser Region",
			subassociationKeyProp: "associated_purchaser_region",
			subassociationNameProp: "associated_purchaser_region_name",
			subassociationEndpoint: ( clientKey: TsType_String, itemKey: TsType_String ) => { return DatabaseRef_Purchasers_Regions_Collection( clientKey, itemKey ) },
			linkedAssociationName: null,
			linkedAssociationKeyProp: null,
			linkedAssociationNameProp: null,
		},
		associated_driver_name: {
			searchIndexKey: "users",
			keyProp: "associated_driver",
			nameProp: "associated_driver_name",
			associationSearchEndpoint:( clientKey: TsType_String, name: TsType_String ) => { return DatabaseRef_UserNameSearch_Query( clientKey, name ) },
			name: "Drivers",
			subassociationName: null,
			subassociationKeyProp: null,
			subassociationNameProp: null,
			subassociationEndpoint: null,
			linkedAssociationName: "Carrier",
			linkedAssociationKeyProp: "associated_carrier",
			linkedAssociationNameProp: "associated_carrier_name",
		},
		associated_region_name: {
			searchIndexKey: "regions",
			keyProp: "associated_region",
			nameProp: "associated_region_name",
			associationSearchEndpoint:( clientKey: TsType_String, name: TsType_String ) => { return DatabaseRef_RegionNameSearch_Query( clientKey, name ) },
			name: "Region",
			namePlurals: "Regions",
			subassociationName: null,
			subassociationKeyProp: null,
			subassociationNameProp: null,
			subassociationEndpoint: null,
			linkedAssociationName: null,
			linkedAssociationKeyProp: null,
			linkedAssociationNameProp: null,
		},
		associated_trailer_name: {
			searchIndexKey: "trailers",
			keyProp: "associated_trailer",
			nameProp: "associated_trailer_name",
			associationSearchEndpoint:( clientKey: TsType_String, name: TsType_String ) => { return DatabaseRef_TrailerNameSearch_Query( clientKey, name ) },
			name: "Trailer",
			namePlurals: "Trailers",
			subassociationName: null,
			subassociationKeyProp: null,
			subassociationNameProp: null,
			subassociationEndpoint: null,
			linkedAssociationName: null,
			linkedAssociationKeyProp: null,
			linkedAssociationNameProp: null,
		},
		associated_truck_name: {
			searchIndexKey: "trucks",
			keyProp: "associated_truck",
			nameProp: "associated_truck_name",
			associationSearchEndpoint:( clientKey: TsType_String, name: TsType_String ) => { return DatabaseRef_TruckNameSearch_Query( clientKey, name ) },
			name: "Trucks",
			subassociationName: null,
			subassociationKeyProp: null,
			subassociationNameProp: null,
			subassociationEndpoint: null,
			linkedAssociationName: null,
			linkedAssociationKeyProp: null,
			linkedAssociationNameProp: null,
		},
	}

	const primaryAssociations: TsInterface_UnspecifiedObject = {
		new_crude_run_tickets: cloneObjectWithoutReference( crudePrimaryAssociations ),
		update_crude_run_tickets: cloneObjectWithoutReference( crudePrimaryAssociations ),
	}

	const importProcedures: TsInterface_UnspecifiedObject = {
		new_crude_run_tickets: (
			importSessionKey: TsType_String,
			clientKey: TsType_String,
			us_rootImportSession: TsInterface_UnspecifiedObject,
			us_importSessionMappedData: TsInterface_UnspecifiedObject,
			us_importSessionAssociationsData: TsInterface_UnspecifiedObject,
			uc_RootData_ClientUser: TsInterface_UnspecifiedObject,
		): TsType_UnknownPromise => {
			return new Promise((resolve, reject) => {
				// Instantiate Update Arrays
				let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
				let miniUpdateArray: TsInterface_DatabaseBatchUpdatesArray = []
				let logUpdateArray: TsInterface_DatabaseBatchUpdatesArray = []
				let sessionUpdateArray: TsInterface_DatabaseBatchUpdatesArray = []
				// Loop through tickets
				for( let loopTicketKey in us_importSessionMappedData ){
					let loopTicket = us_importSessionMappedData[ loopTicketKey ]
					if( loopTicket["id_number"] != null ){
						let miniUpdateObject: TsInterface_UnspecifiedObject = {}
						loopTicket["status_finished"] = true
						if( loopTicket["status"] !== "incomplete" ){
							loopTicket["status"] = "completed"
						}
						// Mini Related Fields
						let miniFields: TsInterface_UnspecifiedObject = {
							associated_cargo: true,
							associated_cargo_name: true,
							associated_carrier: true,
							associated_carrier_name: true,
							associated_driver: true,
							associated_driver_name: true,
							associated_invoice: true,
							associated_invoice_name: true,
							associated_lease: true,
							associated_lease_name: true,
							associated_offload: true,
							associated_offload_name: true,
							associated_producer: true,
							associated_producer_name: true,
							associated_purchaser: true,
							associated_purchaser_name: true,
							associated_region: true,
							associated_region_name: true,
							associated_trailer: true,
							associated_trailer_name: true,
							associated_truck: true,
							associated_truck_name: true,
							id_number: true,
							invoice_total_adjusted: true,
							key: true,
							measurement_loaded_bbls: true,
							measurement_loaded_bbls_net: true,
							measurement_loaded_gallons_gross: true,
							mileage_loaded: true,
							settlement_total: true,
							status: true,
							timestamp_receiver_departed: true,
							timestamp_shipper_departed: true,
						}
						// Loop through props and handle associations
						for( let propKey in loopTicket ){
							let propValue = loopTicket[ propKey ]
							if( propValue === undefined ){
								loopTicket[ propKey ] = null
							}
							if( primaryAssociations[ us_rootImportSession.import_session_type ][ propKey ] != null ){
								let primaryAssociation = primaryAssociations[ us_rootImportSession.import_session_type ][ propKey ]
								if(
									primaryAssociation != null &&
									primaryAssociation[ "keyProp" ] != null &&
									primaryAssociation[ "nameProp" ] != null &&
									us_importSessionAssociationsData != null &&
									us_importSessionAssociationsData[ primaryAssociation[ "keyProp" ] ] != null &&
									us_importSessionAssociationsData[ primaryAssociation[ "keyProp" ] ][ propValue ] != null
								){
									let primaryLinkedAssociation = us_importSessionAssociationsData[ primaryAssociation[ "keyProp" ] ][ propValue ]
									// Handle Primary Association
									loopTicket[ primaryAssociation[ "keyProp" ] ] = getProp(primaryLinkedAssociation, "key", null )
									loopTicket[ primaryAssociation[ "nameProp" ] ] = getProp(primaryLinkedAssociation, "name", null )
									// Handle Subassociations
									if(
										primaryAssociation[ "subassociationKeyProp" ] != null &&
										primaryAssociation[ "subassociationNameProp" ] != null &&
										loopTicket[ primaryAssociation[ "subassociationNameProp" ] ] != null &&
										primaryLinkedAssociation != null &&
										primaryLinkedAssociation["subassociations"] != null &&
										primaryLinkedAssociation["subassociations"][ loopTicket[ primaryAssociation[ "subassociationNameProp" ] ] ]
									){
										let linkedSubassociation = primaryLinkedAssociation["subassociations"][ loopTicket[ primaryAssociation[ "subassociationNameProp" ] ] ]
										loopTicket[ primaryAssociation[ "subassociationKeyProp" ] ] = linkedSubassociation["key"]
										loopTicket[ primaryAssociation[ "subassociationNameProp" ] ] = linkedSubassociation["name"]
									}
									// Handle Linked Associations
									if(
										primaryAssociation[ "linkedAssociationKeyProp" ] != null &&
										primaryAssociation[ "linkedAssociationNameProp" ] != null &&
										primaryLinkedAssociation[ primaryAssociation[ "linkedAssociationKeyProp" ] ] != null &&
										primaryLinkedAssociation[ primaryAssociation[ "linkedAssociationNameProp" ] ] != null
									){
										loopTicket[ primaryAssociation[ "linkedAssociationKeyProp" ] ] = primaryLinkedAssociation[ primaryAssociation[ "linkedAssociationKeyProp" ] ]
										loopTicket[ primaryAssociation[ "linkedAssociationNameProp" ] ] = primaryLinkedAssociation[ primaryAssociation[ "linkedAssociationNameProp" ] ]
									}
								}
							}
						}
						// Handle Mini
						for( let propKey in loopTicket ){
							let propValue = loopTicket[ propKey ]
							if( miniFields[ propKey ] === true ){
								miniUpdateObject[ propKey ] = propValue
							}
						}
						updateArray.push({
							type: "setMerge",
							ref: DatabaseRef_Ticket_Document( clientKey, loopTicketKey ),
							data: loopTicket
						})
						miniUpdateArray.push({
							type: "setMerge",
							ref: DatabaseRef_TicketMini_Document( clientKey, loopTicketKey ),
							data: miniUpdateObject
						})
						let logTimestamp = new Date().getTime()
						logUpdateArray.push({
							type: "setMerge",
							ref: DatabaseRef_TicketLog_Document( clientKey, loopTicketKey, logTimestamp.toString() ),
							data: {
								text: "Ticket Imported",
								timestamp: logTimestamp,
							}
						})
					}
				}
				sessionUpdateArray.push({
					type: "setMerge",
					ref: DatabaseRef_TicketImports_Document( clientKey, importSessionKey ),
					data: {
						substatus: "imported",
						status: "archived",
						timestamp_substatus_imported: new Date(),
						associated_importer_name_key: getProp(uc_RootData_ClientUser, "key", null),
						associated_importer_name: getProp(uc_RootData_ClientUser, "name", null),
					}
				})
				// Perform Batch Updates
				DatabaseBatchUpdate( updateArray, {} ).then((res_DBU) => {
					DatabaseBatchUpdate( miniUpdateArray, {} ).then((res_DBU) => {
						DatabaseBatchUpdate( logUpdateArray, {} ).then((res_DBU) => {
							DatabaseBatchUpdate( sessionUpdateArray, {} ).then((res_DBU) => {
							resolve( res_DBU )
							}).catch((rej_DSMD) => {
								reject( rej_DSMD )
							})
						}).catch((rej_DSMD) => {
							reject( rej_DSMD )
						})
					}).catch((rej_DSMD) => {
						reject( rej_DSMD )
					})
				}).catch((rej_DSMD) => {
					reject( rej_DSMD )
				})
			})
		},
		update_crude_run_tickets: (
			importSessionKey: TsType_String,
			clientKey: TsType_String,
			us_rootImportSession: TsInterface_UnspecifiedObject,
			us_importSessionMappedData: TsInterface_UnspecifiedObject,
			us_importSessionAssociationsData: TsInterface_UnspecifiedObject,
			uc_RootData_ClientUser: TsInterface_UnspecifiedObject,
		): TsType_UnknownPromise => {
			return new Promise((resolve, reject) => {
				// Instantiate Update Arrays
				let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
				let miniUpdateArray: TsInterface_DatabaseBatchUpdatesArray = []
				let logUpdateArray: TsInterface_DatabaseBatchUpdatesArray = []
				let sessionUpdateArray: TsInterface_DatabaseBatchUpdatesArray = []
				// Loop through tickets
				for( let loopTicketKey in us_importSessionMappedData ){
					let loopTicket = us_importSessionMappedData[ loopTicketKey ]
					if( loopTicket["id_number"] != null ){
						let miniUpdateObject: TsInterface_UnspecifiedObject = {}
						loopTicket["status_finished"] = true
						if( loopTicket["status"] !== "incomplete" ){
							loopTicket["status"] = "completed"
						}
						// Mini Related Fields
						let miniFields: TsInterface_UnspecifiedObject = {
							associated_cargo: true,
							associated_cargo_name: true,
							associated_carrier: true,
							associated_carrier_name: true,
							associated_driver: true,
							associated_driver_name: true,
							associated_invoice: true,
							associated_invoice_name: true,
							associated_lease: true,
							associated_lease_name: true,
							associated_offload: true,
							associated_offload_name: true,
							associated_producer: true,
							associated_producer_name: true,
							associated_purchaser: true,
							associated_purchaser_name: true,
							associated_region: true,
							associated_region_name: true,
							associated_trailer: true,
							associated_trailer_name: true,
							associated_truck: true,
							associated_truck_name: true,
							id_number: true,
							invoice_total_adjusted: true,
							key: true,
							measurement_loaded_bbls: true,
							measurement_loaded_bbls_net: true,
							measurement_loaded_gallons_gross: true,
							mileage_loaded: true,
							settlement_total: true,
							status: true,
							timestamp_receiver_departed: true,
							timestamp_shipper_departed: true,
						}
						// Loop through props and handle associations
						for( let propKey in loopTicket ){
							let propValue = loopTicket[ propKey ]
							if( propValue === undefined ){
								loopTicket[ propKey ] = null
							}
							if( primaryAssociations[ us_rootImportSession.import_session_type ][ propKey ] != null ){
								let primaryAssociation = primaryAssociations[ us_rootImportSession.import_session_type ][ propKey ]
								if(
									primaryAssociation != null &&
									primaryAssociation[ "keyProp" ] != null &&
									primaryAssociation[ "nameProp" ] != null &&
									us_importSessionAssociationsData != null &&
									us_importSessionAssociationsData[ primaryAssociation[ "keyProp" ] ] != null &&
									us_importSessionAssociationsData[ primaryAssociation[ "keyProp" ] ][ propValue ] != null
								){
									let primaryLinkedAssociation = us_importSessionAssociationsData[ primaryAssociation[ "keyProp" ] ][ propValue ]
									// Handle Primary Association
									loopTicket[ primaryAssociation[ "keyProp" ] ] = getProp(primaryLinkedAssociation, "key", null )
									loopTicket[ primaryAssociation[ "nameProp" ] ] = getProp(primaryLinkedAssociation, "name", null )
									// Handle Subassociations
									if(
										primaryAssociation[ "subassociationKeyProp" ] != null &&
										primaryAssociation[ "subassociationNameProp" ] != null &&
										loopTicket[ primaryAssociation[ "subassociationNameProp" ] ] != null &&
										primaryLinkedAssociation != null &&
										primaryLinkedAssociation["subassociations"] != null &&
										primaryLinkedAssociation["subassociations"][ loopTicket[ primaryAssociation[ "subassociationNameProp" ] ] ]
									){
										let linkedSubassociation = primaryLinkedAssociation["subassociations"][ loopTicket[ primaryAssociation[ "subassociationNameProp" ] ] ]
										loopTicket[ primaryAssociation[ "subassociationKeyProp" ] ] = linkedSubassociation["key"]
										loopTicket[ primaryAssociation[ "subassociationNameProp" ] ] = linkedSubassociation["name"]
									}
									// Handle Linked Associations
									if(
										primaryAssociation[ "linkedAssociationKeyProp" ] != null &&
										primaryAssociation[ "linkedAssociationNameProp" ] != null &&
										primaryLinkedAssociation[ primaryAssociation[ "linkedAssociationKeyProp" ] ] != null &&
										primaryLinkedAssociation[ primaryAssociation[ "linkedAssociationNameProp" ] ] != null
									){
										loopTicket[ primaryAssociation[ "linkedAssociationKeyProp" ] ] = primaryLinkedAssociation[ primaryAssociation[ "linkedAssociationKeyProp" ] ]
										loopTicket[ primaryAssociation[ "linkedAssociationNameProp" ] ] = primaryLinkedAssociation[ primaryAssociation[ "linkedAssociationNameProp" ] ]
									}
								}
							}
						}
						// Handle Mini
						for( let propKey in loopTicket ){
							let propValue = loopTicket[ propKey ]
							if( miniFields[ propKey ] === true ){
								miniUpdateObject[ propKey ] = propValue
							}
						}
						updateArray.push({
							type: "setMerge",
							ref: DatabaseRef_Ticket_Document( clientKey, loopTicketKey ),
							data: loopTicket
						})
						miniUpdateArray.push({
							type: "setMerge",
							ref: DatabaseRef_TicketMini_Document( clientKey, loopTicketKey ),
							data: miniUpdateObject
						})
						let logTimestamp = new Date().getTime()
						logUpdateArray.push({
							type: "setMerge",
							ref: DatabaseRef_TicketLog_Document( clientKey, loopTicketKey, logTimestamp.toString() ),
							data: {
								text: "Ticket corrections made via import",
								timestamp: logTimestamp,
							}
						})
					}
				}
				sessionUpdateArray.push({
					type: "setMerge",
					ref: DatabaseRef_TicketImports_Document( clientKey, importSessionKey ),
					data: {
						substatus: "imported",
						status: "archived",
						timestamp_substatus_imported: new Date(),
						associated_importer_name_key: getProp(uc_RootData_ClientUser, "key", null),
						associated_importer_name: getProp(uc_RootData_ClientUser, "name", null),
					}
				})
				// Perform Batch Updates
				DatabaseBatchUpdate( updateArray, {} ).then((res_DBU) => {
					DatabaseBatchUpdate( miniUpdateArray, {} ).then((res_DBU) => {
						DatabaseBatchUpdate( logUpdateArray, {} ).then((res_DBU) => {
							DatabaseBatchUpdate( sessionUpdateArray, {} ).then((res_DBU) => {
							resolve( res_DBU )
							}).catch((rej_DSMD) => {
								reject( rej_DSMD )
							})
						}).catch((rej_DSMD) => {
							reject( rej_DSMD )
						})
					}).catch((rej_DSMD) => {
						reject( rej_DSMD )
					})
				}).catch((rej_DSMD) => {
					reject( rej_DSMD )
				})
			})
		},
	}

	const tableSettings_ReviewImport: TsInterface_TableSettings = {
		paginated: true,
		pagination_rows_per_page_default: 100,
		pagination_rows_per_page_options: [ 10, 25, 50, 100 ],
		show_header: true,
		size: "small",
		sortable: false,
		sort_direction: "desc",
		sort_property_default: "id_number",
	}

///////////////////////////////
// Functions
///////////////////////////////

const SubassociationCustomDialog: React.FC<TsInterface_SubassociationCustomDialog> = ({ associationObject, associationItem, importSessionKey }): TsType_JSX => {

	// Props

	// Hooks - useContext, useState, useReducer, other
	// { sort-start } - hooks
	const [ us_singleAssociationDatabaseObject, us_setSingleAssociationDatabaseObject ] = 	useState<TsInterface_UnspecifiedObject>({})
	const [ us_subAssociationDatabaseData, us_setSubAssociationDatabaseData ] = 			useState<TsInterface_UnspecifiedObject>({})
	const [ us_subAssociationItems, us_setSubAssociationItems ] = 							useState<TsInterface_UnspecifiedObject>({})
	const ur_forceRerender = 																useReducer(() => ({}), {})[1] as () => TsType_Void
	const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 							useContext( Context_RootData_ClientKey )
	const { uc_setUserInterface_CustomDialogDisplay } = 									useContext( Context_UserInterface_CustomDialog )
	const { uc_setUserInterface_ErrorDialogDisplay } = 										useContext( Context_UserInterface_ErrorDialog )
	// { sort-end } - hooks

	// Hooks - useEffect
	useEffect(() => {
		let unsubscribeLiveData: TsType_VoidFunction
		const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
			us_setSingleAssociationDatabaseObject( newData )
			ur_forceRerender()
		}
		if(
			associationObject != null &&
			associationObject.key != null
		){
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveDocument(
					DatabaseRef_TicketImports_Associations_Document(
						res_GCK.clientKey,
						importSessionKey,
						associationObject.key
					), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
		}


		return () => {
			if (typeof unsubscribeLiveData === 'function'){
				unsubscribeLiveData()
			}
		}
	}, [associationObject, importSessionKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

	useEffect(() => {
		let unsubscribeLiveData: TsType_VoidFunction
		const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
			us_setSubAssociationItems( newData )
			ur_forceRerender()
		}
		if(
			associationObject != null &&
			associationObject.key != null &&
			crudePrimaryAssociations != null &&
			crudePrimaryAssociations[ associationObject.key + "_name" ] != null &&
			crudePrimaryAssociations[ associationObject.key + "_name" ]["subassociationEndpoint"] != null &&
			us_singleAssociationDatabaseObject != null &&
			associationItem != null &&
			associationItem.name != null &&
			us_singleAssociationDatabaseObject[ associationItem.name ] != null &&
			us_singleAssociationDatabaseObject[ associationItem.name ]["key"] != null
		){
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveCollection(
					crudePrimaryAssociations[ associationObject.key + "_name" ]["subassociationEndpoint"](
						res_GCK.clientKey,
						us_singleAssociationDatabaseObject[ associationItem.name ]["key"]
					), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
		}
		return () => {
			if (typeof unsubscribeLiveData === 'function'){
				unsubscribeLiveData()
			}
		}
	}, [associationItem, associationObject, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_singleAssociationDatabaseObject])

	useEffect(() => {
		if(
			associationObject != null &&
			associationObject.key != null &&
			us_singleAssociationDatabaseObject != null &&
			associationItem != null &&
			associationItem.name != null &&
			us_singleAssociationDatabaseObject[ associationItem.name ] != null &&
			us_singleAssociationDatabaseObject[ associationItem.name ]["subassociations"] != null
		){
			us_setSubAssociationDatabaseData( us_singleAssociationDatabaseObject[ associationItem.name ]["subassociations"] )
		}
		return () => { }
	}, [associationItem, associationObject, us_singleAssociationDatabaseObject])

	// Other Variables

	// Functions
	const saveSecondaryAssociationMapping = (
		associationKey: TsType_String,
		associationImportValue: TsType_String,
		subassociationImportValue: TsType_String,
		selectedSubassociationKey: TsType_String,
		selectedSubassociationValue: TsType_String,
	): TsType_UnknownPromise => {
		return new Promise((resolve, reject) => {
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				let updateObject = {
					[ associationImportValue ]: {
						subassociations: {
							[ subassociationImportValue ]: { key: selectedSubassociationKey, name: selectedSubassociationValue }
						}
					}
				}
				DatabaseSetMergeDocument( DatabaseRef_TicketImports_Associations_Document( res_GCK.clientKey, importSessionKey, associationKey ), updateObject, {} ).then( ( res_DSMD ) => {
					resolve( res_DSMD )
				}).catch( ( rej_DSMD ) => {
					uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
					console.error( rej_DSMD )
					reject( rej_DSMD )
				})
			}).catch(( rej_GCK ) => {
				uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
				console.error( rej_GCK )
				reject( rej_GCK )
			})
		})
	}

	const deleteSecondaryAssociationMapping = (
		associationKey: TsType_String,
		associationImportValue: TsType_String,
		subassociationImportValue: TsType_String,
	): TsType_UnknownPromise => {
		return new Promise((resolve, reject) => {
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				let updateObject = {
					[ associationImportValue ]: {
						subassociations: {
							[ subassociationImportValue ]: null
						}
					}
				}
				DatabaseSetMergeDocument( DatabaseRef_TicketImports_Associations_Document( res_GCK.clientKey, importSessionKey, associationKey ), updateObject, {} ).then( ( res_DSMD ) => {
					resolve( res_DSMD )
				}).catch( ( rej_DSMD ) => {
					uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
					console.error( rej_DSMD )
					reject( rej_DSMD )
				})
			}).catch(( rej_GCK ) => {
				uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
				console.error( rej_GCK )
				reject( rej_GCK )
			})
		})
	}

	// JSX Generation
	const returnJSX_UnlinkSubAssociationButton = (
		associationObject: TsInterface_UnspecifiedObject,
		associationItem: TsInterface_UnspecifiedObject,
		subassociationItem: TsInterface_UnspecifiedObject,
	): TsType_JSX => {
		let buttonJSX = <></>
		if(
			subassociationItem != null &&
			subassociationItem.name != null &&
			us_subAssociationDatabaseData != null &&
			us_subAssociationDatabaseData[ subassociationItem.name ] != null
		){
			buttonJSX =
			<Button
				color="error"
				variant="contained"
				onClick={ () => {
					if(
						associationObject != null &&
						associationObject.key != null &&
						associationItem != null &&
						associationItem.name != null &&
						subassociationItem != null &&
						subassociationItem.name != null
					){
						deleteSecondaryAssociationMapping(
							associationObject.key,
							associationItem.name,
							subassociationItem.name,
						).finally( () => {
							ur_forceRerender()
						})
					}
				} }
			>
				<Icon icon="link-slash" />
			</Button>
		} else {
			buttonJSX =
			<Button
				color="error"
				variant="outlined"
				onClick={ () => {
					if(
						associationObject != null &&
						associationObject.key != null &&
						associationItem != null &&
						associationItem.name != null &&
						subassociationItem != null &&
						subassociationItem.name != null
					){
						deleteSecondaryAssociationMapping(
							associationObject.key,
							associationItem.name,
							subassociationItem.name,
						).finally( () => {
							ur_forceRerender()
						})
					}
				} }
			>
				<Icon icon="link-slash" />
			</Button>
		}
		return buttonJSX
	}

	const returnJSX_SubAssociationSelectButton = (
		associationObject: TsInterface_UnspecifiedObject,
		associationItem: TsInterface_UnspecifiedObject,
		subassociationItem: TsInterface_UnspecifiedObject,
		subassociationDatabaseItem: TsInterface_UnspecifiedObject,
	): TsType_JSX => {
		let buttonJSX = <></>
		let buttonVariant: "outlined" | "contained" = "outlined"
		let buttonIcon = <Icon icon="circle" type="regular" sx={{ marginRight: "8px" }} />
		if(
			subassociationItem != null &&
			subassociationItem.name != null &&
			us_subAssociationDatabaseData != null &&
			us_subAssociationDatabaseData[ subassociationItem.name ] != null &&
			us_subAssociationDatabaseData[ subassociationItem.name ]["key"] === subassociationDatabaseItem.key
		){
			buttonVariant = "contained"
			buttonIcon = <Icon icon="circle-dot" sx={{ marginRight: "8px" }} />
		}
		buttonJSX =
		<Button
			variant={ buttonVariant }
			color="info"
			sx={{ marginBottom: "4px" }}
			onClick={ () => {
				saveSecondaryAssociationMapping(
					associationObject.key,
					associationItem.name,
					subassociationItem.name,
					subassociationDatabaseItem.key,
					us_subAssociationItems[ subassociationDatabaseItem.key ]["name"]
				).finally( () => {
					ur_forceRerender()
				})
			}}
		>
			{ buttonIcon }
			{
				returnJSX_HighlightedSearchString(
					subassociationItem.name,
					subassociationDatabaseItem.name
				)
			}
		</Button>
		return buttonJSX
	}

	const returnJSX_Dialog = (): TsType_JSX => {
		let dialogJSX =
		<Box>
			<Dialog
				className="bp_dialog_lg_width"
				keepMounted
				onClose={ () => {
					uc_setUserInterface_CustomDialogDisplay( UserInterface_Default_CustomDialogDisplayState )
				} }
				open={ true }
			>
				<DialogContent>
					<Typography variant="body1" sx={{ fontSize: "18px", fontWeight: "bold" }}>{ associationItem.name }</Typography>
					<Divider />
					<TableContainer>
						<Table size="small">
							<TableBody>
								<TableRow>
									<TableCell className="tw-align-inherit">
										<Typography variant="body1" sx={{ fontWeight: "bold", color: themeVariables.info_main }}>{ s_IMPORT_VALUE }</Typography>
									</TableCell>
									<TableCell className="tw-align-inherit">
										<Typography variant="body1" sx={{ fontWeight: "bold", color: themeVariables.info_main }}>{ s_MAPPING }</Typography>
									</TableCell>
									<TableCell className="tw-align-inherit">
									<Typography variant="body1" sx={{ fontWeight: "bold", color: themeVariables.info_main }}>{ s_UNLINK }</Typography>
									</TableCell>
								</TableRow>
								{ objectToArray( getProp( associationItem, "subassociations_to_build", [] ) ).sort( dynamicSort("name", "asc") ).map(( subassociationItem: TsInterface_UnspecifiedObject, subassociationIndex: TsType_Number ) => (
									<TableRow key={ subassociationIndex }>
										<TableCell className="tw-align-inherit" sx={{ verticalAlign: "top" }}>
											<Typography variant="body1" sx={{ display: "inline-block", }}>{ subassociationItem.name as TsType_String }</Typography>
											<Typography variant="body1" sx={{ display: "inline-block", opacity: ".3", fontStyle: "italic", marginLeft: "2px"}}>({ subassociationItem.count as TsType_String })</Typography>
										</TableCell>
										<TableCell className="tw-align-inherit" sx={{ verticalAlign: "top" }}>
											{ objectToArray( us_subAssociationItems ).sort( dynamicSort("name", "asc") ).map(( subassociationDatabaseItem: TsInterface_UnspecifiedObject, subassociationDatabaseIndex: TsType_Number ) => (
												<Box key={subassociationDatabaseIndex}>
													{ returnJSX_SubAssociationSelectButton(
														associationObject,
														associationItem,
														subassociationItem,
														subassociationDatabaseItem
													) }
												</Box>
											))}
										</TableCell>
										<TableCell className="tw-align-inherit" sx={{ verticalAlign: "top" }}>
											{ returnJSX_UnlinkSubAssociationButton(
												associationObject,
												associationItem,
												subassociationItem
											) }
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
			</Dialog>
		</Box>
		return dialogJSX
	}

	return <>{returnJSX_Dialog()}</>

}

///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		const params = useParams()
		const importSessionKey: TsType_String = params.id as TsType_String

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_autobuilidngAssociations, us_setAutobuilidngAssociations ] = 					useState<TsType_Boolean>(false)
		const [ us_confirmingImport, us_setConfirmingImport ] = 									useState<TsType_Boolean>(false)
		const [ us_importSessionAssociationFramework, us_setImportSessionAssociationFramework ] = 	useState<TsInterface_UnspecifiedObject>({})
		const [ us_importSessionAssociationsData, us_setImportSessionAssociationsData ] = 			useState<TsInterface_UnspecifiedObject>({})
		const [ us_importSessionMappedData, us_setImportSessionMappedData ] = 						useState<TsInterface_UnspecifiedObject>({})
		const [ us_importSessionRawData, us_setImportSessionRawData ] = 							useState<TsInterface_UnspecifiedObject>({})
		const [ us_importSessionRawDataHeaders, us_setImportSessionRawDataHeaders ] = 				useState<TsInterface_UnspecifiedObject>({})
		const [ us_importSessionRawDataHeadersLoaded, us_setImportSessionRawDataHeadersLoaded ] = 	useState<TsType_Boolean>(false)
		const [ us_mappedPropertyCounts, us_setMappedPropertyCounts ] =								useState<TsInterface_UnspecifiedObject>({})
		const [ us_mappingIssues, us_setMappingIssues ] = 											useState<TsInterface_UnspecifiedObject>({})
		const [ us_rootImportSession, us_setRootImportSession ] = 									useState<TsInterface_UnspecifiedObject>({})
		const [ us_savingMappedData, us_setSavingMappedData ] = 									useState<TsType_Boolean>(false)
		const [ us_uniqueValuesByHeader, us_setUniqueValuesByHeader ] = 							useState<TsInterface_UnspecifiedObject>({})
		const [ us_uploadingRawData, us_setUploadingRawData ] = 									useState<TsType_Boolean>(false)
		const un_routerNaviation = 																	useNavigate()
		const ur_forceRerender = 																	useReducer(() => ({}), {})[1] as () => TsType_Void
		const { CSVReader } = 																		useCSVReader()
		const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 								useContext( Context_RootData_ClientKey )
		const { uc_RootData_ClientUser } = 															useContext( Context_RootData_ClientUser )
		const { uc_setUserInterface_CustomDialogDisplay } = 										useContext( Context_UserInterface_CustomDialog )
		const { uc_setUserInterface_ErrorDialogDisplay } = 											useContext( Context_UserInterface_ErrorDialog )
		const { uc_setUserInterface_SnackbarDisplay } = 											useContext( Context_UserInterface_Snackbar )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			document.title = se_TICKET_IMPORT_SESSION
		}, [ ])

		useEffect(() => {
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey )
			return () => { }
		}, [ uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender ])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setRootImportSession( newData )
				ur_forceRerender()
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_TicketImports_Document( res_GCK.clientKey, importSessionKey ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [ uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, importSessionKey ])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setImportSessionRawData( newData )
				ur_forceRerender()
			}
			if(
				us_rootImportSession != null &&
				us_rootImportSession.substatus === "file_selected"
			){
				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
					unsubscribeLiveData = DatabaseGetLiveCollection( DatabaseRef_TicketImports_RawData_Collection( res_GCK.clientKey, importSessionKey ), updateLiveData )
				}).catch(( rej_GCK ) => {
					console.error( rej_GCK )
				})
			}
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, importSessionKey, us_rootImportSession])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setImportSessionRawDataHeaders( newData )
				us_setImportSessionRawDataHeadersLoaded( true )
				ur_forceRerender()
			}
			if(
				us_rootImportSession != null &&
				us_rootImportSession.substatus === "file_selected"
			){
				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
					unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_TicketImports_RawDataHeaders_Document( res_GCK.clientKey, importSessionKey ), updateLiveData )
				}).catch(( rej_GCK ) => {
					console.error( rej_GCK )
				})
			}
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, importSessionKey, us_rootImportSession])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setImportSessionMappedData( newData )
				ur_forceRerender()
			}
			if(
				us_rootImportSession != null &&
				(
					us_rootImportSession.substatus === "columns_mapped" ||
					us_rootImportSession.substatus === "associations_built" ||
					us_rootImportSession.substatus === "imported"
				)
			){
				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
					unsubscribeLiveData = DatabaseGetLiveCollection( DatabaseRef_TicketImports_MappedData_Collection( res_GCK.clientKey, importSessionKey ), updateLiveData )
				}).catch(( rej_GCK ) => {
					console.error( rej_GCK )
				})
			}
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, importSessionKey, us_rootImportSession])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setImportSessionAssociationsData( newData )
				ur_forceRerender()
			}
			if(
				us_rootImportSession != null &&
				(
					us_rootImportSession.substatus === "columns_mapped" ||
					us_rootImportSession.substatus === "associations_built" ||
					us_rootImportSession.substatus === "imported"
				)
			){
				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
					unsubscribeLiveData = DatabaseGetLiveCollection( DatabaseRef_TicketImports_Associations_Collection( res_GCK.clientKey, importSessionKey ), updateLiveData )
				}).catch(( rej_GCK ) => {
					console.error( rej_GCK )
				})
			}
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, importSessionKey, us_rootImportSession])

		useEffect(() => {
			if(
				us_rootImportSession != null &&
				us_rootImportSession.substatus === "file_selected" &&
				us_importSessionRawDataHeaders != null &&
				us_importSessionRawDataHeaders["headers"] != null &&
				us_importSessionRawDataHeaders["auto_mapping_complete"] !== true
			){
				let autoUpdateObject: TsInterface_UnspecifiedObject = {
					auto_mapping_complete: true,
					mapped_headers: {},
					automapped_headers: {},
				}
				for( let loopHeaderIndex in us_importSessionRawDataHeaders["headers"] ){
					let loopHeader = us_importSessionRawDataHeaders["headers"][ loopHeaderIndex ]
					if(
						us_importSessionRawDataHeaders["mapped_headers"] == null ||
						us_importSessionRawDataHeaders["mapped_headers"][ loopHeader ] == null
					){
						if(
							autoMappingProperties != null &&
							us_rootImportSession != null &&
							us_rootImportSession.import_session_type != null &&
							autoMappingProperties[ us_rootImportSession.import_session_type ] != null &&
							autoMappingProperties[ us_rootImportSession.import_session_type ][ loopHeader ] != null
						){
							autoUpdateObject["mapped_headers"][ loopHeader ] = autoMappingProperties[ us_rootImportSession.import_session_type ][ loopHeader ]
							autoUpdateObject["automapped_headers"][ loopHeader ] = autoMappingProperties[ us_rootImportSession.import_session_type ][ loopHeader ]
						}
					}
				}
				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
					DatabaseSetMergeDocument( DatabaseRef_TicketImports_RawDataHeaders_Document( res_GCK.clientKey, importSessionKey ), autoUpdateObject, {} ).then((res_DSM) => {
						ur_forceRerender()
					}).catch((rej_DSM) => {
						console.error( rej_DSM )
					})
				})
			}
		}, [importSessionKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_importSessionRawDataHeaders, us_rootImportSession])

		useEffect(() => {
			if(
				us_rootImportSession != null &&
				us_rootImportSession.substatus === "file_selected" &&
				us_importSessionRawData != null
			){
				let uniqueValuesByHeader: TsInterface_UnspecifiedObject = {}
				for( let loopRawDataKey in us_importSessionRawData ){
					let loopRawDataItem = us_importSessionRawData[ loopRawDataKey ]
					for( let loopHeaderString in loopRawDataItem ){
						let loopHeader = loopRawDataItem[ loopHeaderString ]
						if( uniqueValuesByHeader[ loopHeaderString ] == null ){
							uniqueValuesByHeader[ loopHeaderString ] = {}
						}
						uniqueValuesByHeader[ loopHeaderString ][ loopHeader ] = loopHeader
					}
				}
				us_setUniqueValuesByHeader( uniqueValuesByHeader )
			}
		}, [us_importSessionRawData, us_rootImportSession])

		useEffect(() => {
			if(
				us_rootImportSession != null &&
				us_rootImportSession.substatus === "file_selected" &&
				us_importSessionRawDataHeaders != null &&
				us_importSessionRawDataHeaders["mapped_headers"] != null
			){
				let mappedPropertyCounts: TsInterface_UnspecifiedObject = {}
				for( let loopHeaderValue in us_importSessionRawDataHeaders["mapped_headers"] ){
					let loopHeaderKey = us_importSessionRawDataHeaders["mapped_headers"][ loopHeaderValue ]
					if( mappedPropertyCounts[ loopHeaderKey ] == null ){
						mappedPropertyCounts[ loopHeaderKey ] = 0
					}
					mappedPropertyCounts[ loopHeaderKey ]++
				}
				us_setMappedPropertyCounts( mappedPropertyCounts )
			}
		}, [us_importSessionRawDataHeaders, us_rootImportSession])

		useEffect(() => {
			if(
				us_rootImportSession != null &&
				us_rootImportSession.substatus === "file_selected" &&
				us_importSessionRawDataHeadersLoaded === true
			){
				let mappingIssues: TsInterface_UnspecifiedObject = {
					missing_required: {},
					duplicate_mapped: {},
					missing_import_session_type: false
				}
				// Session Type
				if(
					us_rootImportSession != null &&
					us_rootImportSession.import_session_type != null &&
					requiredImportMapProperties != null &&
					requiredImportMapProperties[ us_rootImportSession.import_session_type ] != null &&
					importMapProperties != null &&
					importMapProperties[ us_rootImportSession.import_session_type ] != null
				){
					// Required
					if(
						us_rootImportSession != null &&
						us_rootImportSession.import_session_type != null &&
						requiredImportMapProperties != null &&
						requiredImportMapProperties[ us_rootImportSession.import_session_type ] != null
					){
						let invertedFileHeaders: TsInterface_UnspecifiedObject = {}
						for( let loopHeaderValue in us_importSessionRawDataHeaders["mapped_headers"] ){
							let loopHeaderKey = us_importSessionRawDataHeaders["mapped_headers"][ loopHeaderValue ]
							invertedFileHeaders[ loopHeaderKey ] = loopHeaderValue
						}
						for( let loopRequiredFieldKey in requiredImportMapProperties[ us_rootImportSession.import_session_type ] ){
							if( invertedFileHeaders[ loopRequiredFieldKey ] == null ){
								mappingIssues["missing_required"][ loopRequiredFieldKey ] = getProp( importMapProperties[ us_rootImportSession.import_session_type ], loopRequiredFieldKey + ".header", "" )
							}
						}
					}
					// Duplicate Mapped
					for( let loopMappedPropertyKey in us_mappedPropertyCounts ){
						let loopMappedPropertyCount = us_mappedPropertyCounts[ loopMappedPropertyKey ]
						if( loopMappedPropertyCount > 1 ){
							mappingIssues["duplicate_mapped"][ loopMappedPropertyKey ] = getProp( importMapProperties[ us_rootImportSession.import_session_type ], loopMappedPropertyKey + ".header", "" )
						}
					}
				} else {
					mappingIssues["missing_import_session_type"] = true
				}
				us_setMappingIssues( mappingIssues )
			}
		}, [us_importSessionRawDataHeaders, us_mappedPropertyCounts, us_rootImportSession, us_importSessionRawDataHeadersLoaded])

		useEffect(() => {
			if(
				us_rootImportSession != null &&
				us_rootImportSession.substatus === "columns_mapped" &&
				us_rootImportSession.import_session_type != null &&
				primaryAssociations != null &&
				primaryAssociations[ us_rootImportSession.import_session_type ] != null &&
				us_importSessionMappedData != null &&
				objectToArray( us_importSessionMappedData ).length > 0
			){
				// Generate Associations to Map
				let associationsList = primaryAssociations[ us_rootImportSession.import_session_type ]
				let associationsDataObject: TsInterface_UnspecifiedObject = {}
				for( let loopAssociationKey in associationsList ){
					let loopAssociation = associationsList[ loopAssociationKey ]
					associationsDataObject[ loopAssociationKey ] = {
						key: loopAssociation.keyProp,
						name: loopAssociation.name,
						searchIndexKey: loopAssociation.searchIndexKey,
						items_to_map: {},
					}
				}
				// Loop through items to get counts of strings that need associations
				for( let loopMappedDataKey in us_importSessionMappedData ){
					let loopMappedData = us_importSessionMappedData[ loopMappedDataKey ]
					for( let loopMappedPropKey in loopMappedData ){
						// Prop is an association
						if( associationsList[ loopMappedPropKey ] != null ){
							let loopMappedPropValue = loopMappedData[ loopMappedPropKey ]
							if( associationsDataObject[ loopMappedPropKey ]["items_to_map"][ loopMappedPropValue ] == null ){
								associationsDataObject[ loopMappedPropKey ]["items_to_map"][ loopMappedPropValue ] = {
									name: loopMappedPropValue,
									count: 0,
									subassociations_to_build: {}
								}
							}
							associationsDataObject[ loopMappedPropKey ]["items_to_map"][ loopMappedPropValue ]["count"]++
							// Handle Subassociations
							if(
								associationsList[ loopMappedPropKey ]["subassociationKeyProp"] != null &&
								loopMappedData[ associationsList[ loopMappedPropKey ]["subassociationKeyProp"] + "_name" ] != null
							){
								let subassociationName = loopMappedData[ associationsList[ loopMappedPropKey ]["subassociationKeyProp"] + "_name" ]
								if( associationsDataObject[ loopMappedPropKey ]["items_to_map"][ loopMappedPropValue ]["subassociations_to_build"][ subassociationName ] == null ){
									associationsDataObject[ loopMappedPropKey ]["items_to_map"][ loopMappedPropValue ]["subassociations_to_build"][ subassociationName ] = {
										name: subassociationName,
										count: 0,
									}
								}
								associationsDataObject[ loopMappedPropKey ]["items_to_map"][ loopMappedPropValue ]["subassociations_to_build"][ subassociationName ]["count"]++
							}
						}
					}
				}
				us_setImportSessionAssociationFramework( associationsDataObject )
			}
		}, [us_importSessionMappedData, us_rootImportSession])

		// Other Variables

		// Functions

		// PHASE: Upload CSV
		const downloadExampleFile = (): TsType_Void => {
			if(
				us_rootImportSession != null &&
				us_rootImportSession.import_session_type != null &&
				importMapProperties != null &&
				importMapProperties[ us_rootImportSession.import_session_type ] != null
			){
				let downloadTopRow: TsType_String[] = []
				for( let loopPropKey in importMapProperties[ us_rootImportSession.import_session_type ] ){
					let loopProp = importMapProperties[ us_rootImportSession.import_session_type ][ loopPropKey ]
					downloadTopRow.push( loopProp.header )
				}
				downloadCSV( "Import Template", [ downloadTopRow ] ).then(( res_DCSV ) => {
					uc_setUserInterface_SnackbarDisplay({
						display: true,
						snackbar: {
							autoHideDuration: 3000,
							message: "Template Downloaded",
							alertType: "info",
							verticalAlignment: "top",
							horizontalAlignment: "left"
						}
					})
				}).catch(( rej_DCSV ) => {
					uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DCSV.error })
				})
			}
		}

		const importRawData = ( csvData: TsType_Any ): TsType_Void => {
			// Instantiate variables
			let formattedData: TsInterface_UnspecifiedObject[] = []
			let uploadedFileColumns: TsType_String[] = []
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				if( csvData.length <= 501 ){
					us_setUploadingRawData( true )
					for ( let cellIndex = 0; cellIndex < csvData[0].length; cellIndex++ ) {
						uploadedFileColumns[ cellIndex ] = csvData[0][ cellIndex ]
					}
					for ( let rowIndex = 1; rowIndex < csvData.length; rowIndex++ ) {
						if(
							csvData != null &&
							csvData[ rowIndex ] != null
						){
							let loopRow = csvData[ rowIndex ]
							let formattedDataRow: TsInterface_UnspecifiedObject = {}
							for ( let loopCellIndex = 0; loopCellIndex < loopRow.length; loopCellIndex++ ) {
								formattedDataRow[ uploadedFileColumns[ loopCellIndex ] ] = loopRow[ loopCellIndex ]
							}
							formattedData.push( formattedDataRow )
						}
					}
					// Save Raw Data to Database
					let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
					for( let loopIndex in formattedData ){
						let loopItem = cloneObjectWithoutReference( formattedData[ loopIndex ] )
						let loopItemCharacterCount = 0
						for( let loopPropKey in loopItem ){
							let loopPropValue = loopItem[ loopPropKey ]
							if( loopPropKey !== "key" ){
								loopItemCharacterCount += loopPropValue.toString().length
							}
						}
						if( loopItemCharacterCount > 0 ){
							let rawDataKey = importSessionKey + "_" + loopIndex.toString()
							loopItem.key = rawDataKey
							updateArray.push(
								{
									type: "setMerge",
									ref: DatabaseRef_TicketImports_RawData_Document( res_GCK.clientKey, importSessionKey, rawDataKey ),
									data: loopItem
								},
							)
						}
					}
					DatabaseBatchUpdate( updateArray, {} ).then((res_DBU) => {
						// Update Root
						let updateArray2: TsInterface_DatabaseBatchUpdatesArray = [
							{
								type: "setMerge",
								ref: DatabaseRef_TicketImports_Document( res_GCK.clientKey, importSessionKey ),
								data: {
									substatus: "file_selected",
									raw_data_count: formattedData.length,
									timestamp_substatus_file_selected: new Date(),
									associated_file_uploader_key: getProp(uc_RootData_ClientUser, "key", null),
									associated_file_uploader_name: getProp(uc_RootData_ClientUser, "name", null),
								}
							},
							{
								type: "setMerge",
								ref: DatabaseRef_TicketImports_RawDataHeaders_Document( res_GCK.clientKey, importSessionKey ),
								data: { headers: uploadedFileColumns }
							},
						]
						DatabaseBatchUpdate( updateArray2, {} ).then((res_DBU) => {
							us_setUploadingRawData( false )
							ur_forceRerender()
						}).catch((rej_DSMD) => {
							uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
							us_setUploadingRawData( false )
						})
					}).catch((rej_DBU) => {
						uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
						us_setUploadingRawData( false )
					})
				} else {
					// Limit to 500 rows per import
					uc_setUserInterface_ErrorDialogDisplay({ display: true, error: {
						message: s_FAILED_TO_IMPORT_CSV_FILE,
						details: s_YOU_CAN_ONLY_IMPORT_A_MAXIMUM_OF_500_TICKETS_AT_A_TIME,
						code: "ER-D-TIV-IRD-01"
					}})
					us_setUploadingRawData( false )
				}
			}).catch(( rej_GCK ) => {
				uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
				us_setUploadingRawData( false )
				console.error( rej_GCK )
			})
		}

		// PHASE: Map Columns
		const attemptAssociationAutoMapping = (): TsType_UnknownPromise => {
			return new Promise((resolve, reject) => {
				us_setAutobuilidngAssociations( true )
				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
					// Attempt Build of primary associations
					let promiseArray1: TsType_UnknownPromise[] = []
					let updateObjects: TsInterface_UnspecifiedObject = {}
					for( let loopAssociationTypeKey in us_importSessionAssociationFramework ){
						if(
							loopAssociationTypeKey != null &&
							crudePrimaryAssociations != null &&
							crudePrimaryAssociations[ loopAssociationTypeKey ] != null &&
							crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] != null &&
							crudePrimaryAssociations[ loopAssociationTypeKey ]["associationSearchEndpoint"] != null
						){
							updateObjects[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ] = {}
							let loopAssociationType = us_importSessionAssociationFramework[ loopAssociationTypeKey ]
							for( let loopAssociationItemNameKey in loopAssociationType["items_to_map"] ){
								if(
									us_importSessionAssociationsData == null ||
									us_importSessionAssociationsData[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ] == null ||
									us_importSessionAssociationsData[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ][ loopAssociationItemNameKey ] == null
								){
									let loopAssociationItem = loopAssociationType["items_to_map"][ loopAssociationItemNameKey ]
									promiseArray1.push( DatabaseGetCollection( crudePrimaryAssociations[ loopAssociationTypeKey ]["associationSearchEndpoint"]( res_GCK.clientKey, loopAssociationItem.name ) ).then(( res_DGC ) => {
										if(
											res_DGC.data != null &&
											objectToArray( res_DGC.data ).length === 1
										){
											let singleMatch = objectToArray( res_DGC.data )[0]
											updateObjects[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ][ loopAssociationItemNameKey ] = {
												key: singleMatch.key,
												name: singleMatch.name,
											}
											if(
												crudePrimaryAssociations != null &&
												crudePrimaryAssociations[loopAssociationTypeKey ] != null &&
												crudePrimaryAssociations[loopAssociationTypeKey ]["linkedAssociationKeyProp"] != null &&
												singleMatch != null &&
												singleMatch[ crudePrimaryAssociations[loopAssociationTypeKey ]["linkedAssociationKeyProp"] ] != null
											){
												updateObjects[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ][ loopAssociationItemNameKey ][ crudePrimaryAssociations[loopAssociationTypeKey ]["linkedAssociationKeyProp"] ] = singleMatch[ crudePrimaryAssociations[loopAssociationTypeKey ]["linkedAssociationKeyProp"] ]
											}
											if(
												crudePrimaryAssociations != null &&
												crudePrimaryAssociations[loopAssociationTypeKey ] != null &&
												crudePrimaryAssociations[loopAssociationTypeKey ]["linkedAssociationNameProp"] != null &&
												singleMatch != null &&
												singleMatch[ crudePrimaryAssociations[loopAssociationTypeKey ]["linkedAssociationNameProp"] ] != null
											){
												updateObjects[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ][ loopAssociationItemNameKey ][ crudePrimaryAssociations[loopAssociationTypeKey ]["linkedAssociationNameProp"] ] = singleMatch[ crudePrimaryAssociations[loopAssociationTypeKey ]["linkedAssociationNameProp"] ]
											}
										}
									}).catch( ( rej_DGC ) => {
										console.error( rej_DGC )
									}))
								}
							}
						}
					}
					Promise.all( promiseArray1 ).finally(() => {
						// Secondary Associations
						// for( let loopAssociationTypeKey in us_importSessionAssociationFramework ){
						// 	if(
						// 		loopAssociationTypeKey != null &&
						// 		crudePrimaryAssociations != null &&
						// 		crudePrimaryAssociations[ loopAssociationTypeKey ] != null &&
						// 		crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] != null &&
						// 		crudePrimaryAssociations[ loopAssociationTypeKey ]["subassociationEndpoint"] != null &&
						// 		crudePrimaryAssociations[ loopAssociationTypeKey ]["subassociationKeyProp"] != null &&
						// 		crudePrimaryAssociations[ loopAssociationTypeKey ]["subassociationNameProp"] != null
						// 	){
						// 		let loopAssociationType = us_importSessionAssociationFramework[ loopAssociationTypeKey ]
						// 		for( let loopAssociationItemNameKey in loopAssociationType["items_to_map"] ){
						// 			if(
						// 				us_importSessionAssociationsData != null &&
						// 				us_importSessionAssociationsData[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ] != null &&
						// 				us_importSessionAssociationsData[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ][ loopAssociationItemNameKey ] != null &&
						// 				us_importSessionAssociationsData[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ][ loopAssociationItemNameKey ]["subassociations"] != null
						// 			){
						// 				if( updateObjects[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ] == null ){
						// 					updateObjects[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ] = {}
						// 				}
						// 				if( updateObjects[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ][ loopAssociationItemNameKey ] == null ){
						// 					updateObjects[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ][ loopAssociationItemNameKey ] = {
						// 						subassociations: us_importSessionAssociationsData[ crudePrimaryAssociations[ loopAssociationTypeKey ]["keyProp"] ][ loopAssociationItemNameKey ]["subassociations"]
						// 					}
						// 				}
						// 				// Build Subassociations


						// 				for( let loopSubassociationNameKey in loopAssociationType["items_to_map"][ loopAssociationItemNameKey ]["subassociations_to_build"] ){

						// 				}


						// 			}
						// 		}
						// 	}
						// }
						let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
						for( let loopAssociationTypeKey in updateObjects ){
							updateArray.push({
								type: "setMerge",
								ref: DatabaseRef_TicketImports_Associations_Document( res_GCK.clientKey, importSessionKey, loopAssociationTypeKey ),
								data: updateObjects[ loopAssociationTypeKey ]
							})
						}
						DatabaseBatchUpdate( updateArray, {} ).then((res_DBU) => {
							us_setAutobuilidngAssociations( false )
						}).catch((rej_DBU) => {
							uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
							us_setAutobuilidngAssociations( false )
							console.error( rej_DBU )
						})
					})
				}).catch(( rej_GCK ) => {
					uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
					us_setAutobuilidngAssociations( false )
					console.error( rej_GCK )
				})
			})
		}

		const saveMappedData = (): TsType_UnknownPromise => {
			return new Promise((resolve, reject) => {
				if(
					us_rootImportSession != null &&
					us_rootImportSession.import_session_type != null &&
					importSessionKeyGeneration != null &&
					importSessionKeyGeneration[ us_rootImportSession.import_session_type ] != null &&
					missingItemCheck != null &&
					missingItemCheck[ us_rootImportSession.import_session_type ] != null &&
					duplicateItemCheck != null &&
					duplicateItemCheck[ us_rootImportSession.import_session_type ] != null
				){
					let errorCount = 0
					let warningCount = 0
					let warningList: TsType_String[] = []
					us_setSavingMappedData( true )
					let promiseArray: TsType_UnknownPromise[] = []
					getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
						let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
						let mappedHeaders = getProp( us_importSessionRawDataHeaders, "mapped_headers", {} )
						let invertedFileHeaders: TsInterface_UnspecifiedObject = {}
						for( let loopHeaderValue in mappedHeaders ){
							let loopHeaderKey = mappedHeaders[ loopHeaderValue ]
							invertedFileHeaders[ loopHeaderKey ] = loopHeaderValue
						}
						// Errors and Warnings
						for( let loopItemKey in us_importSessionRawData ){
							let mappedItem: TsInterface_UnspecifiedObject = {
								key: loopItemKey,
								associated_import_session_key: importSessionKey,
							}
							let loopItem = us_importSessionRawData[ loopItemKey ]
							for( let loopHeaderString in loopItem ){
								let loopPropValue = loopItem[ loopHeaderString ]
								if(
									mappedHeaders[ loopHeaderString ] != null &&
									mappedHeaders[ loopHeaderString ] !== "" &&
									us_rootImportSession != null &&
									us_rootImportSession.import_session_type != null &&
									importMapProperties != null &&
									importMapProperties[ us_rootImportSession.import_session_type ] != null &&
									importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ] != null &&
									importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["prop"] != null &&
									(
										importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["linked_time_prop"] != null ||
										importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["linked_date_prop"] != null
									)
								){
									let dateTimeMilliseconds = null
									if(
										loopPropValue != null &&
										importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["linked_time_prop"] != null &&
										invertedFileHeaders[ importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["linked_time_prop"] ] != null &&
										loopItem[ invertedFileHeaders[ importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["linked_time_prop"] ] ] != null
									){
										let dateValue = loopPropValue
										let timeValue = loopItem[ invertedFileHeaders[ importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["linked_time_prop"] ] ]
										let dateTime = new Date( dateValue + " " + timeValue )
										dateTimeMilliseconds = dateTime.getTime()
									} else if(
										loopPropValue != null &&
										importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["linked_date_prop"] != null &&
										invertedFileHeaders[ importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["linked_date_prop"] ] != null &&
										loopItem[ invertedFileHeaders[ importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["linked_date_prop"] ] ] != null
									){
										let dateValue = loopItem[ invertedFileHeaders[ importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["linked_date_prop"] ] ]
										let timeValue = loopPropValue
										let dateTime = new Date( dateValue + " " + timeValue )
										dateTimeMilliseconds = dateTime.getTime()
									}
									if(
										dateTimeMilliseconds != null
									){
										mappedItem[ importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["prop"] ] = dateTimeMilliseconds
									}
								} else if(
									mappedHeaders[ loopHeaderString ] != null &&
									mappedHeaders[ loopHeaderString ] !== "" &&
									us_rootImportSession != null &&
									us_rootImportSession.import_session_type != null &&
									importMapProperties != null &&
									importMapProperties[ us_rootImportSession.import_session_type ] != null &&
									importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ] != null &&
									importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["data_type"] != null &&
									loopPropValue != null &&
									loopPropValue !== ""
								) {
									let dataType = importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ loopHeaderString ] ]["data_type"]
									switch( dataType ){
										case "string":
											mappedItem[ mappedHeaders[ loopHeaderString ] ] = loopPropValue
											break
										case "number":
											mappedItem[ mappedHeaders[ loopHeaderString ] ] = parseFloat( loopPropValue )
											break
										case "boolean":
											if(
												mappedItem[ mappedHeaders[ loopHeaderString ] ].toLowerCase() === "true" ||
												mappedItem[ mappedHeaders[ loopHeaderString ] ] === true
											){
												mappedItem[ mappedHeaders[ loopHeaderString ] ] = true
											} else if(
												mappedItem[ mappedHeaders[ loopHeaderString ] ].toLowerCase() === "false" ||
												mappedItem[ mappedHeaders[ loopHeaderString ] ] === false
											){
												mappedItem[ mappedHeaders[ loopHeaderString ] ] = false
											}
											break
										case "date":

											// TODO - might just be supported in the above

											break
										case "time":

											// TODO - might just be supported in the above

											break
										case "datetime":
											mappedItem[ mappedHeaders[ loopHeaderString ] ] = new Date( loopPropValue ).getTime()
											break
										default:
											mappedItem[ mappedHeaders[ loopHeaderString ] ] = loopPropValue
											break
									}
								} else {
									// console.log( "3<><><>" )
									// console.log( loopHeaderString )
								}
							}
							// Missing Fields Warning
							promiseArray.push(
								missingItemCheck[ us_rootImportSession.import_session_type ](
									mappedItem
								).then(( res_DIC: TsType_Any ) => {
									// Nothing
								// eslint-disable-next-line no-loop-func
								}).catch( ( rej_DIC: TsType_Any ) => {
									warningCount ++
									warningList.push( rej_DIC.warning_message )
								})
							)
							// Duplicate Items Warning
							promiseArray.push(
								duplicateItemCheck[ us_rootImportSession.import_session_type ](
									res_GCK.clientKey,
									mappedItem
								).then(( res_DIC: TsType_Any ) => {
									// Nothing
								// eslint-disable-next-line no-loop-func
								}).catch( ( rej_DIC: TsType_Any ) => {
									warningCount++
									warningList.push( rej_DIC.warning_message )
								})
							)
							// Generate Key
							promiseArray.push(
								importSessionKeyGeneration[ us_rootImportSession.import_session_type ](
									res_GCK.clientKey,
									mappedItem
								).then( ( res_ISKG: TsType_Any ) => {
									updateArray.push({
										type: "setMerge",
										ref: DatabaseRef_TicketImports_MappedData_Document( res_GCK.clientKey, importSessionKey, res_ISKG.key ),
										data: res_ISKG.data
									})
								// eslint-disable-next-line no-loop-func
								}).catch( ( rej_ISKG: TsType_Any ) => {
									errorCount++
								})
							)
						}
						Promise.all( promiseArray ).finally(() => {
							if( errorCount === 0 ){
								if( warningCount === 0 ){
									saveMappedDataProper(
										res_GCK.clientKey,
										updateArray
									).then((res_SMDP) => {
										resolve(res_SMDP)
										us_setSavingMappedData( false )
									}).catch((rej_SMDP) => {
										reject(rej_SMDP)
										us_setSavingMappedData( false )
									})
								} else {
									uc_setUserInterface_CustomDialogDisplay({
										display: true,
										dialog: {
											dialog_jsx:
											<Box>
												<Dialog
													className="bp_dialog_lg_width"
													keepMounted
													onClose={ () => {
														uc_setUserInterface_CustomDialogDisplay( UserInterface_Default_CustomDialogDisplayState )
														us_setSavingMappedData( false )
													} }
													open={ true }
												>
													{/* @ts-expect-error */}
													<AppBar position="static" color="error">
														<Toolbar>
															<IconButton
																disabled
																size="large"
																edge="start"
																color="error"
																aria-label="menu"
																sx={{ mr: 2, color: "#fff !important" }}
															>
																<Icon icon="triangle-exclamation" />
															</IconButton>
															<Typography component={ 'span' } variant={ 'h6' } sx={{ flexGrow: 1 }}>{ warningCount } { warningCount !== 1 ? s_IMPORT_WARNINGS : s_IMPORT_WARNING }</Typography>
														</Toolbar>
													</AppBar>
													<DialogContent>
														<Box>
															{ objectToArray( warningList ).map(( errorValue: TsType_String, errorIndex: TsType_Number ) => (
																<Box key={ errorIndex }>
																	{/* <Icon icon="triangle-exclamation" className="tw-mr-2" sx={{ color: themeVariables.error_main }} /> */}
																	<Box component="span" sx={{ color: themeVariables.error_main, marginBottom: "12px", marginRight: "4px", fontWeight: 700 }}>
																		{ errorIndex + 1 })
																	</Box>
																	<Box component="span">
																		{ errorValue }
																	</Box>
																</Box>
															))}
														</Box>
														<Box sx={{ textAlign: "center", marginTop: "16px" }}>
															<Button
																className="tw-m-auto"
																variant='contained'
																color='warning'
																onClick={ () => {
																	saveMappedDataProper(
																		res_GCK.clientKey,
																		updateArray
																	).then((res_SMDP) => {
																		resolve(res_SMDP)
																		us_setSavingMappedData( false )
																		uc_setUserInterface_CustomDialogDisplay( UserInterface_Default_CustomDialogDisplayState )
																	}).catch((rej_SMDP) => {
																		reject(rej_SMDP)
																		uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SMDP.error })
																		us_setSavingMappedData( false )
																		uc_setUserInterface_CustomDialogDisplay( UserInterface_Default_CustomDialogDisplayState )
																	})
																}}
															>
																{ s_PROCEED_ANYWAY }
															</Button>
														</Box>
													</DialogContent>
												</Dialog>
											</Box>,
											settings: {
												max_width: "md"
											}
										},
									})
								}
							} else {
								reject({error: {
									message: s_FAILED_TO_SAVE_MAPPED_DATA,
									details: <>{ s_FAILED_TO_DETERMINE_REPLACEMENT_TARGETS_FOR } { errorCount } { s_ITEMS }</>,
									code: "ER-D-TIV-SMD-01"
								}})
							}
						})
					}).catch(( rej_GCK ) => {
						uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
						us_setSavingMappedData( false )
						console.error( rej_GCK )
						reject( rej_GCK )
					})
				} else {
					reject({error: {
						message: s_FAILED_TO_SAVE_MAPPED_DATA,
						details: s_MISSING_REQUIRED_CONFIGURATION_KEYS,
						code: "ER-D-TIV-SMD-02"
					}})
				}
			})
		}

		const saveMappedDataProper = (
			clientKey: TsType_String,
			updateArray: TsInterface_DatabaseBatchUpdatesArray
		): TsType_UnknownPromise => {
			return new Promise((resolve, reject) => {
				DatabaseBatchUpdate( updateArray, {} ).then((res_DBU) => {
					// Update Root
					let updateArray2: TsInterface_DatabaseBatchUpdatesArray = [
						{
							type: "setMerge",
							ref: DatabaseRef_TicketImports_Document( clientKey, importSessionKey ),
							data: {
								substatus: "columns_mapped",
								timestamp_substatus_columns_mapped: new Date(),
								associated_column_mapper_key: getProp(uc_RootData_ClientUser, "key", null),
								associated_column_mapper_name: getProp(uc_RootData_ClientUser, "name", null),
							}
						},
					]
					DatabaseBatchUpdate( updateArray2, {} ).then((res_DBU) => {
						us_setSavingMappedData( false )
						ur_forceRerender()
						resolve({success: true})
					}).catch((rej_DSMD) => {
						uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
						us_setSavingMappedData( false )
						reject(rej_DSMD)
					})
				}).catch((rej_DBU) => {
					uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
					us_setSavingMappedData( false )
					reject(rej_DBU)
				})
			})
		}

		// PHASE: Build Associations
		const savePrimaryAssociationMapping = (
			associationKey: TsType_String,
			associationImportValue: TsType_String,
			selectedAssociationKey: TsType_String,
			selectedAssociationValue: TsType_String,
			option: TsInterface_UnspecifiedObject
		): TsType_UnknownPromise => {
			return new Promise((resolve, reject) => {
				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
					let updateObject: TsInterface_UnspecifiedObject = {
						[ associationImportValue ]: { key: selectedAssociationKey, name: selectedAssociationValue }
					}
					if(
						crudePrimaryAssociations != null &&
						crudePrimaryAssociations[ associationKey + "_name" ] != null &&
						crudePrimaryAssociations[ associationKey + "_name" ]["linkedAssociationKeyProp"] != null &&
						option != null &&
						option[ crudePrimaryAssociations[ associationKey + "_name" ]["linkedAssociationKeyProp"] ] != null
					){
						updateObject[ associationImportValue ][ crudePrimaryAssociations[ associationKey + "_name" ]["linkedAssociationKeyProp"] ] = option[ crudePrimaryAssociations[ associationKey + "_name" ]["linkedAssociationKeyProp"] ]
					}
					if(
						crudePrimaryAssociations != null &&
						crudePrimaryAssociations[ associationKey + "_name" ] != null &&
						crudePrimaryAssociations[ associationKey + "_name" ]["linkedAssociationNameProp"] != null &&
						option != null &&
						option[ crudePrimaryAssociations[ associationKey + "_name" ]["linkedAssociationNameProp"] ] != null
					){
						updateObject[ associationImportValue ][ crudePrimaryAssociations[ associationKey + "_name" ]["linkedAssociationNameProp"] ] = option[ crudePrimaryAssociations[ associationKey + "_name" ]["linkedAssociationNameProp"] ]
					}
					DatabaseSetMergeDocument( DatabaseRef_TicketImports_Associations_Document( res_GCK.clientKey, importSessionKey, associationKey ), updateObject, {} ).then( ( res_DSMD ) => {
						resolve( res_DSMD )
					}).catch( ( rej_DSMD ) => {
						uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
						console.error( rej_DSMD )
						reject( rej_DSMD )
					})
				}).catch(( rej_GCK ) => {
					uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
					console.error( rej_GCK )
					reject( rej_GCK )
				})
			})
		}

		const deletePrimaryAssociationMapping = (
			associationKey: TsType_String,
			associationImportValue: TsType_String,
		): TsType_UnknownPromise => {
			return new Promise((resolve, reject) => {
				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
					let updateObject = { [ associationImportValue ]: null }
					DatabaseSetMergeDocument( DatabaseRef_TicketImports_Associations_Document( res_GCK.clientKey, importSessionKey, associationKey ), updateObject, {} ).then( ( res_DSMD ) => {
						resolve( res_DSMD )
					}).catch( ( rej_DSMD ) => {
						uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
						console.error( rej_DSMD )
						reject( rej_DSMD )
					})
				}).catch(( rej_GCK ) => {
					uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
					console.error( rej_GCK )
					reject( rej_GCK )
				})
			})
		}

		const returnUnmappedAssociationCount = (
			associationKey: TsType_String
		): TsType_Number => {
			// Determine the total number of fields that need to be mapped
			let unmappedCount = 0
			if(
				us_importSessionAssociationFramework != null &&
				us_importSessionAssociationFramework[ associationKey + "_name" ] != null &&
				us_importSessionAssociationFramework[ associationKey + "_name" ]["items_to_map"] != null
			){
				// Primary association
				unmappedCount = objectToArray( us_importSessionAssociationFramework[ associationKey + "_name" ]["items_to_map"] ).length
				// Subassociations
				if(
					us_rootImportSession != null &&
					us_rootImportSession.import_session_type != null &&
					primaryAssociations != null &&
					primaryAssociations[ us_rootImportSession.import_session_type ] != null &&
					associationKey != null &&
					primaryAssociations[ us_rootImportSession.import_session_type ][ associationKey + "_name" ] != null &&
					primaryAssociations[ us_rootImportSession.import_session_type ][ associationKey + "_name" ]["subassociationKeyProp"] != null
				){
					for( let loopImportItemKey in us_importSessionAssociationFramework[ associationKey + "_name" ]["items_to_map"] ){
						let loopImportItem = us_importSessionAssociationFramework[ associationKey + "_name" ]["items_to_map"][ loopImportItemKey ]
						if(
							loopImportItem != null &&
							loopImportItem.subassociations_to_build != null
						){
							unmappedCount += objectToArray( loopImportItem.subassociations_to_build ).length
						}
					}
				}
			}
			// Determine the total number of fields that have been sucessfully mapped
			let mappedCount = 0
			if(
				us_importSessionAssociationsData != null &&
				us_importSessionAssociationsData[ associationKey ] != null
			){
				// Primary association
				mappedCount = objectToArray( us_importSessionAssociationsData[ associationKey ] ).length
				// Subassociations
				if(
					us_rootImportSession != null &&
					us_rootImportSession.import_session_type != null &&
					primaryAssociations != null &&
					primaryAssociations[ us_rootImportSession.import_session_type ] != null &&
					associationKey != null &&
					primaryAssociations[ us_rootImportSession.import_session_type ][ associationKey + "_name" ] != null &&
					primaryAssociations[ us_rootImportSession.import_session_type ][ associationKey + "_name" ]["subassociationKeyProp"] != null
				){
					for( let loopImportItemKey in us_importSessionAssociationsData[ associationKey ] ){
						let loopImportItem = us_importSessionAssociationsData[ associationKey ][ loopImportItemKey ]
						if(
							loopImportItem != null &&
							loopImportItem.subassociations != null
						){
							mappedCount += objectToArray( loopImportItem.subassociations ).length
						}
					}
				}
			}
			// Return the difference
			let actualUnmappedCount = unmappedCount - mappedCount
			if( actualUnmappedCount < 0 ){
				actualUnmappedCount = 0
			}
			return actualUnmappedCount
		}

		const returnTotalUnmapedAssociationCount = (): TsType_Number => {
			let unmappedCount = 0
			if(
				us_rootImportSession != null &&
				us_rootImportSession.import_session_type != null &&
				primaryAssociations != null &&
				primaryAssociations[ us_rootImportSession.import_session_type ] != null
			){
				for( let loopAssociationKey in primaryAssociations[ us_rootImportSession.import_session_type ] ){
					let loopAssociation = primaryAssociations[ us_rootImportSession.import_session_type ][ loopAssociationKey ]
					unmappedCount += returnUnmappedAssociationCount( loopAssociation.keyProp )
				}
			}
			return unmappedCount
		}

		const confirmAssociationMapping = (): TsType_UnknownPromise => {
			return new Promise((resolve, reject) => {
				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
					let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
						{
							type: "setMerge",
							ref: DatabaseRef_TicketImports_Document( res_GCK.clientKey, importSessionKey ),
							data: {
								substatus: "associations_built",
								timestamp_substatus_associations_built: new Date(),
								associated_association_builder_key: getProp(uc_RootData_ClientUser, "key", null),
								associated_association_builder_name: getProp(uc_RootData_ClientUser, "name", null),
							}
						},
					]
					DatabaseBatchUpdate( updateArray, {} ).then((res_DBU) => {
						resolve( res_DBU )
						us_setUploadingRawData( false )
						ur_forceRerender()
					}).catch((rej_DSMD) => {
						reject( rej_DSMD )
						uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
						us_setUploadingRawData( false )
					})
				}).catch(( rej_GCK ) => {
					reject( rej_GCK )
					uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
					us_setUploadingRawData( false )
					console.error( rej_GCK )
				})
			})
		}

		// PHASE: Review and Import
		const confirmImport = (): TsType_UnknownPromise => {
			return new Promise((resolve, reject) => {
				us_setConfirmingImport( true )
				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
					if(
						us_rootImportSession != null &&
						us_rootImportSession.import_session_type != null &&
						primaryAssociations != null &&
						primaryAssociations[ us_rootImportSession.import_session_type ] != null &&
						importProcedures != null &&
						importProcedures[ us_rootImportSession.import_session_type ] != null
					){
						importProcedures[ us_rootImportSession.import_session_type ](
							importSessionKey,
							res_GCK.clientKey,
							us_rootImportSession,
							us_importSessionMappedData,
							us_importSessionAssociationsData,
							uc_RootData_ClientUser,
						).then((res_IP: TsType_Any) => {
							us_setConfirmingImport( false )
							resolve( res_IP )
						}).catch((rej_IP: TsType_Any) => {
							us_setConfirmingImport( false )
							uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_IP.error })
							reject( rej_IP )
						})
					} else {
						us_setConfirmingImport( false )
						uc_setUserInterface_ErrorDialogDisplay({
							display: true,
							error: {
								message: s_FAILED_TO_CONFIRM_IMPORT,
								details: s_MISSING_REQUIRED_CONFIGURATION_KEYS,
								code: "ER-D-TIV-CI-01"
							}
						})
						reject({ success: false })
					}
				}).catch(( rej_GCK ) => {
					us_setConfirmingImport( false )
					uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
					console.error( rej_GCK )
					reject( rej_GCK )
				})
			})
		}

		// Call Functions

		// JSX Generation

		// JSX Generation - Base
		const returnJSX_BackButton = (): TsType_JSX => {
			let buttonJSX =
			<Button
				color="inherit"
				variant="outlined"
				onClick={ () => { un_routerNaviation( ApplicationPages.ticketsImport.url() ) } }
				disableElevation
				startIcon={ <Icon icon="chevron-left" /> }
				className="tw-mr-2"
			>
				{s_BACK_TO_TICKET_IMPORTS}
			</Button>
			return buttonJSX
		}

		const returnJSX_ImportSessionStepper = (): TsType_JSX => {
			let stepperJSX = <></>
			let activeStep = 0
			switch( us_rootImportSession.substatus ){
				case "new": 				activeStep = 0; break;
				case "file_selected": 		activeStep = 1; break;
				case "columns_mapped": 		activeStep = 2; break;
				case "associations_built": 	activeStep = 3; break;
				case "imported": 			activeStep = 4; break;
			}
			stepperJSX =
			<Box className="tw-w=full">
				<Stepper activeStep={ activeStep } alternativeLabel>
					<Step>
						<StepLabel>{s_UPLOAD_CSV}</StepLabel>
					</Step>
					<Step>
						<StepLabel>{s_MAP_COLUMNS}</StepLabel>
					</Step>
					<Step>
						<StepLabel>{s_BUILD_ASSOCIATIONS}</StepLabel>
					</Step>
					<Step>
						<StepLabel>{s_REVIEW_AND_IMPORT}</StepLabel>
					</Step>
				</Stepper>
			</Box>
			return stepperJSX
		}

		const returnJSX_ActiveStep = (): TsType_JSX => {
			let stepJSX = <></>
			switch( us_rootImportSession.substatus ){
				case "new": 				stepJSX = returnJSX_SelectFileStep(); break;
				case "file_selected": 		stepJSX = returnJSX_MapColumnsStep(); break;
				case "columns_mapped": 		stepJSX = returnJSX_BuildAssociationsStep(); break;
				case "associations_built": 	stepJSX = returnJSX_ConfirmImportStep(); break;
				case "imported": 			stepJSX = returnJSX_ImportCompleteStep(); break;
			}
			return stepJSX
		}

		// JSX Generation - PHASE: Upload CSV
		const returnJSX_SelectFileStep = (): TsType_JSX => {
			let stepJSX = <></>
			stepJSX =
			<Box className="tw-mt-8">
				<Box className="tw-text-center">
					{ returnJSX_DownloadTemplateButton() }
					{ returnJSX_CSVUploadButton() }
				</Box>
			</Box>
			return stepJSX
		}

		const returnJSX_DownloadTemplateButton = (): TsType_JSX => {
			let buttonJSX =
			<Button
				color="info"
				variant="outlined"
				className="tw-mr-2"
				disableElevation
				disabled={ us_uploadingRawData === true }
				onClick={ () => {
					downloadExampleFile()
				}}
			>
				<Icon icon="cloud-arrow-down" className="tw-mr-2" />
				{ s_DOWNLOAD_EXAMPLE }
			</Button>
			return buttonJSX
		}

		const returnJSX_CSVUploadButton = (): TsType_JSX => {
			let iconJSX = <Icon icon="cloud-arrow-up" className="tw-mr-2" />
			if( us_savingMappedData === true ){
				iconJSX = <Icon icon="arrows-rotate" className="bp_spin tw-mr-2"/>
			}
			let buttonJSX =
			<CSVReader
				onUploadAccepted={(results: TsType_Any) => { importRawData(results.data) }}
				noDrag
			>
				{({
					getRootProps,
					acceptedFile,
					ProgressBar,
					getRemoveFileProps,
				}: TsType_Any) => (
					<Button
						color="info"
						variant="contained"
						disableElevation
						disabled={ us_uploadingRawData === true }
						{...getRootProps()}
					>
						{ iconJSX }
						{ s_UPLOAD_A_CSV_FILE }
					</Button>
				)}
			</CSVReader>
			return buttonJSX
		}

		// JSX Generation - PHASE: Map Columns
		const returnJSX_MapColumnsStep = (): TsType_JSX => {
			let stepJSX = <></>
			stepJSX =
			<Box className="tw-m-auto">
				{ returnJSX_MappingIssues() }
				<Card className="tw-mt-2 tw-m-auto" >
					<TableContainer>
						<Table size="small">
							<TableBody>
								{ objectToArray( getProp( us_importSessionRawDataHeaders, "headers", [] ) ).map(( headerValue: TsType_String, headerIndex: TsType_Number ) => (
									<TableRow key={ headerIndex } className="tw-cursor-pointer">
										<TableCell className="tw-align-inherit">
											{ returnJSX_MappingRowIcon( headerValue ) }
										</TableCell>
										<TableCell className="tw-align-inherit">
											<Typography variant="subtitle1">{ headerValue as TsType_String }</Typography>
										</TableCell>
										<TableCell className="tw-align-inherit">
											{ returnJSX_HeaderMappingSelect( headerValue ) }
										</TableCell>
										<TableCell className="tw-align-inherit">
											{ returnJSX_DataTypeIcon( headerValue ) }
										</TableCell>
										<TableCell>
											{ objectToArray( getProp( us_uniqueValuesByHeader, headerValue, {} ) ).length } { s_VALUES }
											<Icon
												icon="magnifying-glass"
												className="tw-ml-2"
												sx={{
													color: themeVariables.gray_500,
													fontSize: "20px",
													"&:hover": {
														color: themeVariables.info_main,
													}
												}}
												tooltip={
													<Box>
														{ objectToArray( getProp( us_uniqueValuesByHeader, headerValue, {} ) ).map(( exampleHeaderValue: TsType_String, exampleHeaderIndex: TsType_Number ) => (
															<Box key={ exampleHeaderIndex }>{ exampleHeaderValue }</Box>
														))}
													</Box>
												}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Card>
			</Box>
			return stepJSX
		}

		const returnJSX_HeaderMappingSelect = (
			headerStringKey: TsType_String,
		): TsType_JSX => {
			let selectJSX = <></>
			let mappedHeaders = getProp( us_importSessionRawDataHeaders, "mapped_headers", {} )
			let mappingOptions: TsInterface_UnspecifiedObject = {}
			if(
				us_rootImportSession != null &&
				us_rootImportSession.import_session_type != null &&
				importMapProperties != null &&
				importMapProperties[ us_rootImportSession.import_session_type ] != null
			){
				mappingOptions = importMapProperties[ us_rootImportSession.import_session_type ]
			}
			// Style
			let sx: TsInterface_UnspecifiedObject = {}
			if(
				mappedHeaders[ headerStringKey ] == null ||
				mappedHeaders[ headerStringKey ] === ""
			){
				sx = { background: themeVariables.warning_main }
			} else if(
				us_mappedPropertyCounts[ mappedHeaders[ headerStringKey ] ] > 1
			){
				sx = { background: themeVariables.error_main, color: themeVariables.white }
			}
			// Required Icon
			const returnJSX_RequiredIcon = ( optionKey: TsType_String ): TsType_JSX => {
				let iconJSX = <></>
				if(
					us_rootImportSession != null &&
					us_rootImportSession.import_session_type != null &&
					requiredImportMapProperties != null &&
					requiredImportMapProperties[ us_rootImportSession.import_session_type ] != null &&
					requiredImportMapProperties[ us_rootImportSession.import_session_type ][ optionKey ] === true
				){
					iconJSX =
					<Icon icon="asterisk" className="tw-pl-2" sx={{ color: themeVariables.error_main }} />
				}
				return iconJSX
			}
			// JSX
			selectJSX =
			<Box>
				<FormControl>
					<Select
						className="bp_thin_select_input"
						value={ mappedHeaders[ headerStringKey ] || "" }
						sx={ sx }
						onChange={ ( event ) => {
							if ( event != null && event.target != null && event.target.value != null ){
								getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
									let updateObject: TsInterface_UnspecifiedObject = {
										mapped_headers: {
											[ headerStringKey ]: event.target.value
										}
									}
									DatabaseSetMergeDocument( DatabaseRef_TicketImports_RawDataHeaders_Document( res_GCK.clientKey, importSessionKey ), updateObject, {} ).then((res_DSM) => {
										ur_forceRerender()
									}).catch((rej_DSM) => {
										console.error( rej_DSM )
									})
								})
							}
						} }
					>
						{objectToArray( mappingOptions ).sort( dynamicSort( "header", "asc" ) ).map(( option, optionIndex ) => (
							<MenuItem value={ option.key } key={ optionIndex }>

								{returnJSX_HighlightedSearchString( headerStringKey, option.header )}

								{/* { option.header } */}
								{ returnJSX_RequiredIcon( option.key ) }
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Icon
					icon="square-xmark"
					className="tw-ml-2 tw-mt-1.5 tw-cursor-pointer"
					sx={{
						color: themeVariables.gray_500,
						fontSize: "20px",
						"&:hover": {
							color: themeVariables.error_main,
						}
					}}
					tooltip={ s_REMOVE_MAPPING }
					onClick={ () => {
						getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
							let updateObject: TsInterface_UnspecifiedObject = {
								mapped_headers: getProp( us_importSessionRawDataHeaders, "mapped_headers", {} )
							}
							updateObject.mapped_headers[ headerStringKey ] = DatabaseFieldDelete
							DatabaseSetMergeDocument( DatabaseRef_TicketImports_RawDataHeaders_Document( res_GCK.clientKey, importSessionKey ), updateObject, {} ).then((res_DSM) => {
								ur_forceRerender()
							}).catch((rej_DSM) => {
								console.error( rej_DSM )
							})
						})
					}}
				/>
			</Box>
			return selectJSX
		}

		const returnJSX_MappingRowIcon = (
			headerValue: TsType_String,
		): TsType_JSX => {
			let iconJSX = <></>
			let mappedHeaders = getProp( us_importSessionRawDataHeaders, "mapped_headers", {} )
			if(
				mappedHeaders[ headerValue ] == null ||
				mappedHeaders[ headerValue ] === ""
			){
				iconJSX =
				<Icon
					icon="circle-question"
					tooltip={ s_NOT_MAPPED }
					tooltipPlacement='right'
					sx={{ color: themeVariables.warning_main, fontSize: "20px" }}
				/>
			} else if(
				us_mappedPropertyCounts != null &&
				us_mappedPropertyCounts[ mappedHeaders[ headerValue ] ] > 1
			){
				iconJSX =
				<Icon
					icon="triangle-exclamation"
					tooltip={ s_DUPLICATE_MAPPING }
					tooltipPlacement='right'
					sx={{ color: themeVariables.error_main, fontSize: "20px" }}
				/>
			} else {
				iconJSX =
				<Icon
					icon="circle-check"
					tooltip={ s_MAPPED }
					tooltipPlacement='right'
					sx={{ color: themeVariables.success_main, fontSize: "20px" }}
				/>
			}
			return iconJSX
		}

		const returnJSX_DataTypeIcon = (
			headerStringKey: TsType_String,
		): TsType_JSX => {
			let iconJSX = <Icon icon="circle-question" sx={{ fontSize: "18px", color: themeVariables.gray_400 }} tooltip={ s_NOT_SELECTED } />
			let mappedHeaders = getProp( us_importSessionRawDataHeaders, "mapped_headers", {} )
			if(
				mappedHeaders[ headerStringKey ] != null &&
				mappedHeaders[ headerStringKey ] !== "" &&
				us_rootImportSession != null &&
				us_rootImportSession.import_session_type != null &&
				importMapProperties != null &&
				importMapProperties[ us_rootImportSession.import_session_type ] != null &&
				importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ headerStringKey ] ] != null &&
				importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ headerStringKey ] ]["data_type"] != null
			){
				let dataType = importMapProperties[ us_rootImportSession.import_session_type ][ mappedHeaders[ headerStringKey ] ]["data_type"]
				switch( dataType ){
					case "string":
						iconJSX = <Icon icon="pencil" sx={{ fontSize: "18px", color: themeVariables.error_main }} tooltip={ s_STRING } />
						break
					case "number":
						iconJSX = <Icon icon="calculator-simple" sx={{ fontSize: "18px", color: themeVariables.error_main }} tooltip={ s_NUMBER } />
						break
					case "boolean":
						iconJSX = <Icon icon="toggle-on" sx={{ fontSize: "18px", color: themeVariables.error_main }} tooltip={ s_BOOLEAN } />
						break
					case "date":
						iconJSX = <Icon icon="calendar" sx={{ fontSize: "18px", color: themeVariables.error_main }} tooltip={ s_DATE } />
						break
					case "time":
						iconJSX = <Icon icon="clock" sx={{ fontSize: "18px", color: themeVariables.error_main }} tooltip={ s_TIME } />
						break
					case "datetime":
						iconJSX = <Icon icon="calendar-clock" sx={{ fontSize: "18px", color: themeVariables.error_main }} tooltip={ s_DATETIME } />
						break
					default:
						iconJSX = <Icon icon="circle-question" sx={{ fontSize: "18px", color: themeVariables.gray_400 }} tooltip={ s_NOT_SELECTED } />
						break
				}
			}
			return iconJSX
		}

		const returnJSX_MappingIssues = (): TsType_JSX => {
			// Missing Session Type
			let sessionTypeErrorJSX = <></>
			let missingImportSessionType: TsType_Boolean = getProp( us_mappingIssues, "missing_import_session_type", false )
			if( missingImportSessionType === true ){
				sessionTypeErrorJSX =
				<Box sx={{ color: themeVariables.error_main }}>
					<Icon icon="triangle-exclamation" className="tw-mr-2" />
					{ s_INVALID_IMPORT_SESSION_TYPE }
				</Box>
			}
			// Missing Required
			let missingRequiredJSX = <></>
			let missingRequired: TsInterface_UnspecifiedObject = getProp( us_mappingIssues, "missing_required", {} )
			if( objectToArray( missingRequired ).length > 0 ){
				missingRequiredJSX =
				<Box>
					<Box className="tw-inline-block tw-mr-1" sx={{ color: themeVariables.error_main }}>
						<Icon icon="triangle-exclamation" className="tw-mr-2" />
						{ s_MISSING_REQUIRED_FIELDS }:
					</Box>
					<Box className="tw-inline-block">
						{ objectToArray( missingRequired ).map(( errorValue: TsType_String, errorIndex: TsType_Number ) => (
							<Box component="span" key={ errorValue }>
								{ errorValue }
								{ errorIndex < objectToArray( missingRequired ).length - 1 ? ", " : "" }
							</Box>
						))}
					</Box>
				</Box>
			}
			// Duplicates
			let duplicateMappedJSX = <></>
			let duplicateMapped: TsInterface_UnspecifiedObject = getProp( us_mappingIssues, "duplicate_mapped", {} )
			if( objectToArray( duplicateMapped ).length > 0 ){
				duplicateMappedJSX =
				<Box>
					<Box className="tw-inline-block tw-mr-1" sx={{ color: themeVariables.error_main }}>
						<Icon icon="triangle-exclamation" className="tw-mr-2" />
						{ s_DUPLICATE_MAPPED_FIELDS }:
					</Box>
					<Box className="tw-inline-block">
						{ objectToArray( duplicateMapped ).map(( duplicateValue: TsType_String, duplicateIndex: TsType_Number ) => (
							<Box component="span" key={ duplicateValue }>
								{ duplicateValue }
								{ duplicateIndex < objectToArray( duplicateMapped ).length - 1 ? ", " : "" }
							</Box>
						))}
					</Box>
				</Box>
			}
			// Full JSX
			let issuesJSX = <></>
			if( us_importSessionRawDataHeadersLoaded === true ){
				if(
					missingImportSessionType === true ||
					objectToArray( missingRequired ).length > 0 ||
					objectToArray( duplicateMapped ).length > 0
				){
					issuesJSX =
					<Card className="tw-p-2 tw-mt-2" sx={{ background: themeVariables.warning_main }}>
						{ sessionTypeErrorJSX }
						{ missingRequiredJSX }
						{ duplicateMappedJSX }
					</Card>
				} else {
					let iconJSX = <Icon icon="check-to-slot" className="tw-mr-2" />
					if( us_savingMappedData === true ){
						iconJSX = <Icon icon="arrows-rotate" className="bp_spin tw-mr-2"/>
					}
					issuesJSX =
					<Box className="tw-mt-2 tw-mb-3">
						<Stack direction="row" spacing={2} className="tw-justify-between">
							<Button
								className=""
								variant="contained"
								color="success"
								onClick={ () => { saveMappedData() } }
								disabled={ us_savingMappedData === true }
							>
								{ iconJSX }
								{ s_CONFIRM_FIELD_MAPPING }
							</Button>
							<Box className="tw-pt-3 tw-font-bold">
								{ s_TIMEZONE_TO_BE_USED }: { returnTimezoneAbbreviation( new Date() ) }
							</Box>
						</Stack>
					</Box>
				}
			}
			return issuesJSX
		}

		// JSX Generation - PHASE: Build Associations
		const returnJSX_BuildAssociationsStep = (): TsType_JSX => {
			let stepJSX = <></>
			stepJSX =
			<Box>
				{ returnJSX_BuildAssociationTabs() }
			</Box>
			return stepJSX
		}

		const returnJSX_BuildAssociationTabs = (): TsType_JSX => {
			let tabsJSX = <></>
			let tabsArray: TsInterface_TabContentArray = []
			for( let loopAssociationKey in us_importSessionAssociationFramework ){
				let loopAssociation = us_importSessionAssociationFramework[ loopAssociationKey ]
				tabsArray.push({
					tabHeader: returnJSX_AssociationTabHeader( loopAssociation ),
					tabContent: returnJSX_PrimaryAssociationTab( loopAssociation )
				})
			}
			// Tabs
			tabsJSX =
			<Box>
				<Box className="tw-mt-2">
					{ returnJSX_AttemptAutoBuildAssociationsButton() }
					{ returnJSX_ConfirmAssociationsButton() }
				</Box>
				<TabsBasic tabsSettings={ {} } tabs={ tabsArray } />
			</Box>
			return tabsJSX
		}

		const returnJSX_ConfirmAssociationsButton = (): TsType_JSX => {
			let buttonJSX = <></>
			let disabledButton = false
			if( returnTotalUnmapedAssociationCount( ) > 0 ){
				disabledButton = true
			}
			buttonJSX =
				<Button
					className=""
					variant="contained"
					color="success"
					disabled={ disabledButton }
					onClick={ () => {
						confirmAssociationMapping()
					}}
				>
					<Icon icon="square-check" className="tw-mr-2"/>
					{ s_CONFIRM_ASSOCIATIONS }
				</Button>
			return buttonJSX
		}

		const returnJSX_AttemptAutoBuildAssociationsButton = (): TsType_JSX => {
			let buttonJSX = <></>
			let iconJSX = <Icon icon="robot" className="tw-mr-2" />
			if( us_autobuilidngAssociations === true ){
				iconJSX = <Icon icon="arrows-rotate" className="bp_spin tw-mr-2"/>
			}
			buttonJSX =
				<Button
					className="tw-mr-2"
					variant="contained"
					color="info"
					disabled={ us_autobuilidngAssociations === true }
					onClick={ () => {
						attemptAssociationAutoMapping()
					}}
				>
					{ iconJSX }
					{ s_ATTEMPT_AUTO_ASSOCIATION_BUILD }
				</Button>
			return buttonJSX
		}

		const returnJSX_PrimaryAssociationTab = (
			associationObject: TsInterface_UnspecifiedObject
		): TsType_JSX => {
			let tabJSX = <></>
			tabJSX =
			<Box>
				<Card className="tw-m-auto" >
					<TableContainer>
						<Table size="small">
							<TableBody>
								{ objectToArray( getProp( associationObject, "items_to_map", [] ) ).sort( dynamicSort("count", "desc") ).map(( associationItem: TsInterface_UnspecifiedObject, associationIndex: TsType_Number ) => (
									<TableRow key={ associationIndex }>
										<TableCell className="tw-align-inherit">
											{ returnJSX_AssociationMappingIcon( associationObject.key, associationItem.name ) }
										</TableCell>
										<TableCell className="tw-align-inherit">
											<Typography variant="subtitle1" className="tw-inline-block">{ associationItem.name as TsType_String }</Typography>
											<Typography variant="subtitle1" className="tw-inline-block tw-ml-1 tw-italic tw-opacity-30">({ associationItem.count as TsType_String })</Typography>
										</TableCell>
										<TableCell className="tw-align-inherit">
											{ returnJSX_AssociationMappingSelect( associationObject.key, associationItem.name, associationObject.searchIndexKey ) }
										</TableCell>
										<TableCell className="tw-align-inherit">
											{ returnJSX_SubAssociationMappingCell( associationObject, associationItem ) }
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Card>
			</Box>
			return tabJSX
		}

		const returnJSX_AssociationTabHeader = (
			associationObject: TsInterface_UnspecifiedObject
		): TsType_JSX => {
			let headerJSX = <></>
			let countSX = { color: themeVariables.success_light }
			if( returnUnmappedAssociationCount( associationObject.key ) > 0 ){
				countSX = { color: themeVariables.error_main }
			}
			headerJSX =
			<Box>
				<Box className="tw-inline-block">{ associationObject.name }</Box>
				<Box className="tw-inline-block tw-ml-1" sx={ countSX }>({ returnUnmappedAssociationCount( associationObject.key ) })</Box>
			</Box>
			return headerJSX
		}

		const returnJSX_AssociationMappingSelect = (
			associationKey: TsType_String,
			associationItemName: TsType_String,
			searchIndexKey: TsType_String,
		): TsType_JSX => {
			let mappingSelectJSX = <></>
			if( uc_RootData_ClientKey != null ){
				if(
					us_importSessionAssociationsData != null &&
					us_importSessionAssociationsData[ associationKey ] != null &&
					us_importSessionAssociationsData[ associationKey ][ associationItemName ] != null
				){
					mappingSelectJSX =
					<Box>
						<Typography variant="subtitle1" className="tw-inline-block tw-mr-1 tw-opacity-30">
							{ s_MAPPED_AS }:
						</Typography>
						<Typography variant="subtitle1" className="tw-inline-block">
							{ us_importSessionAssociationsData[ associationKey ][ associationItemName ].name }
						</Typography>
						<Icon
							icon="square-xmark"
							className="tw-mt-2 tw-ml-2 tw-cursor-pointer"
							sx={{ color: themeVariables.gray_500, fontSize: "20px", "&:hover": {
								color: themeVariables.error_main,
							} }}
							tooltip={ s_REMOVE_MAPPING }
							onClick={ () => {
								deletePrimaryAssociationMapping(
									associationKey,
									associationItemName,
								)
							}}
						/>
					</Box>
				} else {
					mappingSelectJSX =
					<SearchInput
						clientKey={ uc_RootData_ClientKey }
						searchIndexKey={ searchIndexKey }
						searchFilters={ [] }
						searchResultRenderer={ returnJSX_SearchResult }
						additionalSearchData={ { associationKey: associationKey, associationItemName: associationItemName } }
						defaultSearchValue={ associationItemName }
					/>
				}
			}
			return mappingSelectJSX
		}

		const returnJSX_AssociationMappingIcon = (
			associationKey: TsType_String,
			associationItemName: TsType_String,
		): TsType_JSX => {
			let iconJSX = <></>
			if(
				us_importSessionAssociationsData != null &&
				us_importSessionAssociationsData[ associationKey ] != null &&
				us_importSessionAssociationsData[ associationKey ][ associationItemName ] != null
			){
				iconJSX = <Icon icon="circle-check" sx={{ fontSize: "18px", color: themeVariables.success_main }} tooltip={ s_LINKED } />
			} else {
				iconJSX = <Icon icon="link-horizontal-slash" sx={{ fontSize: "18px", color: themeVariables.error_main }} tooltip={ s_NOT_LINKED } />
			}
			return iconJSX
		}

		const returnJSX_SubAssociationMappingCell = (
			associationObject: TsInterface_UnspecifiedObject,
			associationItem: TsInterface_UnspecifiedObject,
		): TsType_JSX => {
			let cellJSX = <></>
			if(
				associationItem != null &&
				associationItem.subassociations_to_build != null &&
				objectToArray( associationItem.subassociations_to_build ).length > 0
			){
				cellJSX =
				<Stack direction="row" spacing={2}>
					<Box>
						{ objectToArray( getProp( associationItem, "subassociations_to_build", [] ) ).sort( dynamicSort("count", "desc") ).map(( subassociationItem: TsInterface_UnspecifiedObject, subassociationIndex: TsType_Number ) => (
							<Box key={ subassociationIndex }>
								{ returnJSX_SubAssociationIcon( associationObject.key, associationItem.name, subassociationItem.name ) }
								{ subassociationItem.name }
							</Box>
						))}
					</Box>
					<Box>
						{ returnJSX_SubAssociationEditIcon( associationObject, associationItem ) }
					</Box>
				</Stack>
			}
			return cellJSX
		}

		const returnJSX_SearchResult = (
			option: TsInterface_UnspecifiedObject,
			searchInputValue: TsType_String | TsType_Null,
			inputHooks: TsInterface_InputHooksObject,
			additionalSearchData: TsInterface_UnspecifiedObject,
		): TsType_JSX => {
			let searchResultJSX =
			<Box
				sx={{ marginLeft: "8px",  marginRight: "8px" }}
				onClick={ () => {
					savePrimaryAssociationMapping(
						additionalSearchData.associationKey,
						additionalSearchData.associationItemName,
						option.id,
						option.name,
						option
					)
				}}
			>
				<Typography className="tw-cursor-pointer">{ returnJSX_HighlightedSearchString(
					searchInputValue,
					option.name
				) } </Typography>
			</Box>
			return searchResultJSX
		}

		const returnJSX_SubAssociationIcon = (
			associationKey: TsType_String,
			associationItemName: TsType_String,
			subassociationItemName: TsType_String
		): TsType_JSX => {
			let iconJSX = <></>
			if(
				us_importSessionAssociationsData != null &&
				us_importSessionAssociationsData[ associationKey ] != null &&
				us_importSessionAssociationsData[ associationKey ][ associationItemName ] != null &&
				us_importSessionAssociationsData[ associationKey ][ associationItemName ]["subassociations"] != null &&
				us_importSessionAssociationsData[ associationKey ][ associationItemName ]["subassociations"][ subassociationItemName ] != null
			){
				iconJSX = <Icon icon="circle-check tw-mr-2" sx={{ color: themeVariables.success_main }} tooltip={ s_LINKED } />
			} else {
				iconJSX = <Icon icon="link-horizontal-slash tw-mr-2" sx={{ color: themeVariables.error_main }} tooltip={ s_NOT_LINKED } />
			}
			return iconJSX
		}

		const returnJSX_SubAssociationEditIcon = (
			associationObject: TsInterface_UnspecifiedObject,
			associationItem: TsInterface_UnspecifiedObject,
		): TsType_JSX => {
			let iconJSX = <></>
			if(
				us_importSessionAssociationsData != null &&
				us_importSessionAssociationsData[ associationObject.key ] != null &&
				us_importSessionAssociationsData[ associationObject.key ][ associationItem.name ] != null
			){
				iconJSX =
				<Button
					className=""
					variant="outlined"
					color="info"
					onClick={ () => {
						uc_setUserInterface_CustomDialogDisplay({
							display: true,
							dialog: {
								dialog_jsx:
								<Box>
									<SubassociationCustomDialog
										associationObject={ associationObject }
										associationItem={ associationItem }
										importSessionKey={ importSessionKey }
									/>
								</Box>,
								settings: {
									max_width: "md"
								}
							},
						})
					}}
				>
					<Icon icon="pen-to-square" />
				</Button>
			} else {
				iconJSX =
				<Button
					className=""
					variant="outlined"
					color="info"
					disabled={ true }
				>
					<Icon
						icon="pen-to-square"
						className=""
					/>
				</Button>
			}
			return iconJSX
		}

		// JSX Generation - PHASE: Review and Import
		const returnJSX_ConfirmImportStep = (): TsType_JSX => {
			let stepJSX = <></>
			stepJSX =
			<Box>
				<Box className="tw-mt-2">
					{ returnJSX_ConfirmImportButton() }
				</Box>
				{ returnJSX_ReviewImportDataTable() }
			</Box>
			return stepJSX
		}

		const returnJSX_ConfirmImportButton = (): TsType_JSX => {
			let iconJSX = <Icon icon="square-check" className="tw-mr-2" />
			if( us_confirmingImport === true ){
				iconJSX = <Icon icon="arrows-rotate" className="bp_spin tw-mr-2"/>
			}
			let buttonJSX = <></>
			buttonJSX =
			<Button
				className=""
				variant="contained"
				color="success"
				disabled={ us_confirmingImport === true }
				onClick={ () => {
					confirmImport()
				}}
			>
				{ iconJSX }
				{ s_CONFIRM_IMPORT }
			</Button>
			return buttonJSX
		}

		const returnJSX_ReviewImportDataTable = (): TsType_JSX => {
			let tableJSX = <></>
			let tableColumns_ReviewImport: TsInterface_TableColumns = {}
			if(
				us_rootImportSession != null &&
				us_rootImportSession.import_session_type != null &&
				previewMapProperties != null &&
				previewMapProperties[ us_rootImportSession.import_session_type ] != null
			){
				for( let loopImportMapPropertyKey in previewMapProperties[ us_rootImportSession.import_session_type ] ){
					let loopProp = previewMapProperties[ us_rootImportSession.import_session_type ][ loopImportMapPropertyKey ]
					switch( loopProp["data_type"] ){
						case "date":
							tableColumns_ReviewImport[ loopImportMapPropertyKey ] = TableCellTimestamp( loopImportMapPropertyKey, loopProp.header, loopImportMapPropertyKey, "D MMM YY ", false )
							break
						case "time":
							tableColumns_ReviewImport[ loopImportMapPropertyKey ] = TableCellTimestamp( loopImportMapPropertyKey, loopProp.header, loopImportMapPropertyKey, "h:mm a", true )
							break
						case "datetime":
							tableColumns_ReviewImport[ loopImportMapPropertyKey ] = TableCellTimestamp( loopImportMapPropertyKey, loopProp.header, loopImportMapPropertyKey, "D MMM YY h:mm a", true )
							break
						default:
							tableColumns_ReviewImport[ loopImportMapPropertyKey ] = TableCellBasic( loopImportMapPropertyKey, loopProp.header, loopImportMapPropertyKey )
							break
					}
				}
			}
			// JSX
			tableJSX =
			<Box>
				<Card className="tw-mt-2">
					<TableBasic
						tableAdditionalData={ {} }
						tableColumns={ tableColumns_ReviewImport }
						tableData={ objectToArray( us_importSessionMappedData ) }
						tableSettings={ tableSettings_ReviewImport }
					/>
				</Card>
			</Box>
			return tableJSX
		}

		// PHASE: Import Complete
		const returnJSX_ImportCompleteStep = (): TsType_JSX => {
			let stepJSX =
			<Box>
				<TabsBasic tabsSettings={ {} } tabs={[
					{
						tabHeader: s_IMPORT_SUMMARY,
						tabContent:
						<Box className="tw-text-center">
							{ returnJSX_SummaryTimeline() }
						</Box>
					},
					{
						tabHeader: <>{ s_IMPORTED_TICKETS } ({ objectToArray( us_importSessionMappedData ).length })</>,
						tabContent:
						<Box>
							{ returnJSX_ReviewImportDataTable() }
						</Box>
					},
				]} />
			</Box>
			return stepJSX
		}

		const returnJSX_SummaryTimeline = (): TsType_JSX => {
			let timelineView =
			<Card className="tw-p-2">
				<Timeline>
					<TimelineItem>
						<TimelineOppositeContent>
							<Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
								{ s_IMPORT_SESSION_CREATED }
							</Typography>
							<Typography variant="body1" className="tw-opacity-50">
							{ us_rootImportSession.associated_creator_name }
							</Typography>
						</TimelineOppositeContent>
						<TimelineSeparator sx={{ height: "100px" }}>
							<TimelineDot color="info" variant="outlined" sx={{ width: "44px", height: "44px" }}>
								<Box sx={{ verticalAlign: "middle", textAlign: "center", display: "block", margin: "auto", paddingTop: "2px" }}>
									<Icon icon="pen-to-square" type="regular" sx={{ fontSize: "24px", color: themeVariables.info_main }} />
								</Box>
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent>
							<Typography className="tw-mt-4 tw-opacity-70" sx={{ color: themeVariables.info_dark }} variant="body2">
								{ returnFormattedDate( us_rootImportSession.timestamp_created, "D MMM YYYY h:mm a" ) }
							</Typography>
						</TimelineContent>
					</TimelineItem>
					<TimelineItem>
						<TimelineOppositeContent>
							<Typography className="tw-mt-4 tw-opacity-70" sx={{ color: themeVariables.info_dark }} variant="body2">
								{ returnFormattedDate( us_rootImportSession.timestamp_substatus_file_selected, "D MMM YYYY h:mm a" ) }
							</Typography>
						</TimelineOppositeContent>
						<TimelineSeparator sx={{ height: "100px" }}>
							<TimelineDot color="info" variant="outlined" sx={{ width: "44px", height: "44px" }}>
								<Box sx={{ verticalAlign: "middle", textAlign: "center", display: "block", margin: "auto", paddingTop: "2px" }}>
									<Icon icon="file-csv" type="regular" sx={{ fontSize: "24px", color: themeVariables.info_main }} />
								</Box>
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent>
							<Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
								{ s_CSV_FILE_UPLOADED }
							</Typography>
							<Typography variant="body1" className="tw-opacity-50">
								{ us_rootImportSession.associated_file_uploader_name }
							</Typography>
						</TimelineContent>
					</TimelineItem>
					<TimelineItem>
						<TimelineOppositeContent>
							<Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
								{ s_COLUMNS_MAPPED }
							</Typography>
							<Typography variant="body1" className="tw-opacity-50">
								{ us_rootImportSession.associated_column_mapper_name }
							</Typography>
						</TimelineOppositeContent>
						<TimelineSeparator sx={{ height: "100px" }}>
							<TimelineDot color="info" variant="outlined" sx={{ width: "44px", height: "44px" }}>
								<Box sx={{ verticalAlign: "middle", textAlign: "center", display: "block", margin: "auto", paddingTop: "2px" }}>
									<Icon icon="map" type="regular" sx={{ fontSize: "24px", color: themeVariables.info_main }} />
								</Box>
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent>
							<Typography className="tw-mt-4 tw-opacity-70" sx={{ color: themeVariables.info_dark }} variant="body2">
								{ returnFormattedDate( us_rootImportSession.timestamp_substatus_columns_mapped, "D MMM YYYY h:mm a" ) }
							</Typography>
						</TimelineContent>
					</TimelineItem>
					<TimelineItem>
						<TimelineOppositeContent>
							<Typography className="tw-mt-4 tw-opacity-70" sx={{ color: themeVariables.info_dark }} variant="body2">
								{ returnFormattedDate( us_rootImportSession.timestamp_substatus_associations_built, "D MMM YYYY h:mm a" ) }
							</Typography>
						</TimelineOppositeContent>
						<TimelineSeparator sx={{ height: "100px" }}>
							<TimelineDot color="info" variant="outlined" sx={{ width: "44px", height: "44px" }}>
								<Box sx={{ verticalAlign: "middle", textAlign: "center", display: "block", margin: "auto", paddingTop: "2px" }}>
									<Icon icon="link" type="regular" sx={{ fontSize: "24px", color: themeVariables.info_main }} />
								</Box>
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent>
							<Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
								{ s_ASSOCIATIONS_BUILT }
							</Typography>
							<Typography variant="body1" className="tw-opacity-50">
								{ us_rootImportSession.associated_association_builder_name }
							</Typography>
						</TimelineContent>
					</TimelineItem>
					<TimelineItem>
						<TimelineOppositeContent>
							<Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
								{ s_IMPORT_COMPLETED }
							</Typography>
							<Typography variant="body1" className="tw-opacity-50">
								{ us_rootImportSession.associated_importer_name }
							</Typography>
						</TimelineOppositeContent>
						<TimelineSeparator sx={{ height: "100px" }}>
							<TimelineDot color="info" variant="outlined" sx={{ width: "44px", height: "44px" }}>
								<Box sx={{ verticalAlign: "middle", textAlign: "center", display: "block", margin: "auto", paddingTop: "2px" }}>
									<Icon icon="cloud-arrow-up" type="regular" sx={{ fontSize: "24px", color: themeVariables.info_main }} />
								</Box>
							</TimelineDot>
						</TimelineSeparator>
						<TimelineContent>
							<Typography className="tw-mt-4 tw-opacity-70" sx={{ color: themeVariables.info_dark }} variant="body2">
								{ returnFormattedDate( us_rootImportSession.timestamp_substatus_imported, "D MMM YYYY h:mm a" ) }
							</Typography>
						</TimelineContent>
					</TimelineItem>
				</Timeline>
			</Card>
			return timelineView
		}

		// PAGE
		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			<AuthenticatedContainer pageHeader={s_TICKET_IMPORT_SESSION} pageKey={pageKey} content={
				<Box>
					<Box>
						<Stack direction="row" spacing={2}>
							{ returnJSX_BackButton() }
						</Stack>
					</Box>
					<Box className="tw-mt-2">
						{ returnJSX_ImportSessionStepper() }
						{ returnJSX_ActiveStep() }
					</Box>
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>

	}