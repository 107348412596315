//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Table that pulls paginated data from specified database endpoint

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Query
} from 'firebase/firestore'
import React, {
	useContext,
	useEffect,
	useReducer,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useNavigate
} from 'react-router-dom'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	evaluateConditionLogic
} from 'rfbp_core/components/logic'
import {
	SearchInput
} from 'rfbp_core/components/search'
import {
	TsInterface_TableAdditionalData,
	TsInterface_TableColumn,
	TsInterface_TableColumns,
	TsInterface_TableData,
	TsInterface_TableDatabaseEndpointQueryObject,
	TsInterface_TableDatabaseSettings,
	TsInterface_TableDataRow,
	TsInterface_TableHooks,
	TsType_SortDirection,
	TsType_TableDatabaseEndpoint,
	TsType_TableSize
} from 'rfbp_core/components/table'
import {
	Context_RootData_AuthenticatedUser,
	Context_RootData_ClientKey,
	Context_RootData_ClientPermissions,
	Context_RootData_ClientUser,
	Context_RootData_GlobalUser,
	Context_RootData_UserPermissions,
	Context_UserInterface_AlertDialog,
	Context_UserInterface_ConfirmDialog,
	Context_UserInterface_CustomDialog,
	Context_UserInterface_ErrorDialog,
	Context_UserInterface_FormDialog,
	Context_UserInterface_PromptDialog
} from 'rfbp_core/services/context'
import {
	DatabaseGetCollection,
	DatabaseGetLiveCollection
} from 'rfbp_core/services/database_management'
import {
	dynamicSort,
	getProp,
	objectToArray
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_MuiButtonVariants,
	TsType_Number,
	TsType_String,
	TsType_Void,
	TsType_VoidFunction
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button,
	Divider,
	FormControl,
	InputAdornment,
	LinearProgress,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
	Tooltip,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		tableAdditionalData: TsInterface_TableAdditionalData,
		tableColumns: TsInterface_TableColumns,
		tableDatabaseEndpoint: TsType_TableDatabaseEndpoint,
		tableSettings: TsInterface_TableDatabaseSettings,
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_MANY = 					<Trans>many</Trans>
	const s_MINIMIZE = 				<Trans>Minimize</Trans>
	const s_OF = 					<Trans>of</Trans>
	const s_RESULTS = 				<Trans>Results</Trans>
	const s_SHOWING_ALL = 			<Trans>Showing All</Trans>
	const s_SHOWING_FIRST = 		<Trans>Showing First</Trans>
	// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const TableDatabase = ( props: TsInterface_ComponentProps ): TsType_JSX => {
	// export const TableDatabase = forwardRef( (props: TsInterface_ComponentProps, ref: React.ForwardedRef<unknown>): TsType_JSX => {

		// Props
		let pr_tableAdditionalData: TsInterface_TableAdditionalData = 					getProp( props, "tableAdditionalData", {} )
		let pr_tableColumns: TsInterface_TableColumns = 								getProp( props, "tableColumns", {} )
		let pr_tableDatabaseEndpoint: TsType_TableDatabaseEndpoint = 					getProp( props, "tableDatabaseEndpoint", () => {})
		let pr_tableSettings: TsInterface_TableDatabaseSettings = 						getProp( props, "tableSettings", {} )
		let pr_sortProperty: TsType_String = 											getProp( pr_tableSettings, "sort_property", "key" )
		let pr_sortDirection: TsType_SortDirection = 									getProp( pr_tableSettings, "sort_direction", "desc" )
		let pr_rowsPerPage: TsType_Number = 											getProp( pr_tableSettings, "rows_per_page", 20 )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_collapsedColumns, us_setCollapsedColumns ] = 						useState< TsInterface_UnspecifiedObject >( {} )
		const [ us_dataLoaded, us_setTableLoaded ] = 									useState< TsType_Boolean >( false )
		const [ us_filterView, us_setFilterView ] = 									useState< TsType_Boolean >( false )
		const [ us_pageChangeDirection, us_setPageChangeDirection ] = 					useState( "next" )
		const [ us_paginationQueryCursors, us_setPaginationQueryCursors ] = 			useState< TsInterface_UnspecifiedObject >( {} )
		const [ us_rowCount, us_setRowCount ] = 										useState< TsType_Number >( -1 )
		const [ us_searchProperty, us_setSearchProperty ] = 							useState< TsType_String >( "" )
		const [ us_searchValue, us_setSearchValue ] = 									useState< TsType_String >( "" )
		const [ us_searchView, us_setSearchView ] = 									useState< TsType_Boolean >( false )
		const [ us_searchingData, us_setSearchingData ] = 								useState< TsType_Boolean >( false )
		const [ us_selectedPage, us_setSelectedPage ] = 								useState( 0 )
		const [ us_startAfter, us_setStartAfter ] = 									useState( null )
		const [ us_tableData, us_setTableData ] = 										useState< TsInterface_TableData >( [] )
		const [ us_tableSearchData, us_setTableSearchData ] = 							useState< TsInterface_TableData >( [] )
		const un_routerNaviation = 														useNavigate()
		const ur_forceRerender = 														useReducer( () => ( {} ), {} )[1] as () => TsType_Void
		const { uc_RootData_AuthenticatedUser } = 										useContext( Context_RootData_AuthenticatedUser )
		const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 					useContext( Context_RootData_ClientKey )
		const { uc_RootData_ClientPermissions } = 										useContext( Context_RootData_ClientPermissions )
		const { uc_RootData_ClientUser } = 												useContext( Context_RootData_ClientUser )
		const { uc_RootData_GlobalUser } = 												useContext( Context_RootData_GlobalUser )
		const { uc_RootData_UserPermissions } = 										useContext( Context_RootData_UserPermissions )
		const { uc_setUserInterface_AlertDialogDisplay } = 								useContext( Context_UserInterface_AlertDialog )
		const { uc_setUserInterface_ConfirmDialogDisplay } = 							useContext( Context_UserInterface_ConfirmDialog )
		const { uc_setUserInterface_CustomDialogDisplay } = 							useContext( Context_UserInterface_CustomDialog )
		const { uc_setUserInterface_ErrorDialogDisplay } = 								useContext( Context_UserInterface_ErrorDialog )
		const { uc_setUserInterface_FormDialogDisplay } = 								useContext( Context_UserInterface_FormDialog )
		const { uc_setUserInterface_PromptDialogDisplay } = 							useContext( Context_UserInterface_PromptDialog )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			// TODO - TEMP
			us_setFilterView(false)
		}, [ us_setFilterView ])

		useEffect(() => {
			// Instantiate Variables
			let unsubscribeLiveData: TsType_VoidFunction
			// Update Live Data
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				// New Table Data
				let newTableData = objectToArray( newData )
				us_setTableData( newTableData )
				us_setTableLoaded( true )
				if ( newTableData.length === 0 ){
					let endRowCount = ( us_selectedPage * pr_rowsPerPage)
					us_setRowCount( endRowCount )
					// No Data
				} else if ( newTableData.length < pr_rowsPerPage ){
					let endRowCount = ( us_selectedPage * pr_rowsPerPage) + newTableData.length
					us_setRowCount( endRowCount )
				} else {
					us_setRowCount( -1 )
				}
				ur_forceRerender()
			}
			// Generate Database Query
			let queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject = {}
			if ( pr_tableSettings != null && pr_tableSettings.rows_per_page != null ){
				queryGenerationData["limit"] = pr_tableSettings.rows_per_page
			}
			if (us_pageChangeDirection === "next"){
				queryGenerationData["startAfter"] = us_startAfter
			}
			if (us_pageChangeDirection === "back"){
				queryGenerationData["startAt"] = us_paginationQueryCursors[us_selectedPage]["firstItem"][ pr_sortProperty ]
			}
			if (pr_tableDatabaseEndpoint != null){
				let databaseQueryRef: Query = pr_tableDatabaseEndpoint( queryGenerationData, pr_tableAdditionalData )
				if ( pr_tableSettings != null && pr_tableSettings["use_live_data"] === false ){
					DatabaseGetCollection( databaseQueryRef ).then(( res_DGC ) => {
						updateLiveData( res_DGC.data )
					})
				} else {
					unsubscribeLiveData = DatabaseGetLiveCollection( databaseQueryRef, updateLiveData )
				}
			}
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [
			ur_forceRerender,
			pr_rowsPerPage,
			pr_tableDatabaseEndpoint,
			pr_tableSettings,
			us_startAfter,
			us_selectedPage,
			us_pageChangeDirection,
			pr_sortProperty,
			us_paginationQueryCursors,
			pr_tableAdditionalData
		])

		// Other Variables
		const tableHooks: TsInterface_TableHooks = {
			uc_RootData_AuthenticatedUser: uc_RootData_AuthenticatedUser,
			uc_RootData_ClientPermissions: uc_RootData_ClientPermissions,
			uc_RootData_UserPermissions: uc_RootData_UserPermissions,
			uc_RootData_ClientKey: uc_RootData_ClientKey,
			uc_RootData_ClientUser: uc_RootData_ClientUser,
			uc_RootData_GlobalUser: uc_RootData_GlobalUser,
			ur_forceRerender: ur_forceRerender,
			un_routerNaviation: un_routerNaviation,
			uc_setRootData_ClientKey: uc_setRootData_ClientKey,
			uc_setUserInterface_AlertDialogDisplay: uc_setUserInterface_AlertDialogDisplay,
			uc_setUserInterface_ConfirmDialogDisplay: uc_setUserInterface_ConfirmDialogDisplay,
			uc_setUserInterface_CustomDialogDisplay: uc_setUserInterface_CustomDialogDisplay,
			uc_setUserInterface_ErrorDialogDisplay: uc_setUserInterface_ErrorDialogDisplay,
			uc_setUserInterface_FormDialogDisplay: uc_setUserInterface_FormDialogDisplay,
			uc_setUserInterface_PromptDialogDisplay: uc_setUserInterface_PromptDialogDisplay,
		}

		// Functions
		const returnTableSize = ( size: TsType_TableSize ): TsType_TableSize => {
			if (size !== "small" && size !== "medium"){
				size = "small"
			}
			return size
		}

		const returnHeaderCSS = ( column: TsInterface_TableColumn, columnIndex: TsType_Number ): TsType_String => {
			let headerCSS = ""
			if ( column != null && column.header != null && column.header.header_css != null ){
				headerCSS = column.header.header_css( pr_tableAdditionalData )
			}
			if( us_collapsedColumns[ columnIndex ] === true ){
				headerCSS += " tw-px-1"
			}
			return headerCSS
		}

		const returnHeaderSX = ( column: TsInterface_TableColumn, columnIndex: TsType_Number ): TsInterface_UnspecifiedObject => {
			let headerSX: TsInterface_UnspecifiedObject = {}
			let headerCSS = ""
			if (column != null && column.header != null && column.header.header_css != null){
				headerCSS = column.header.header_css( pr_tableAdditionalData )
			}
			if(
				headerCSS === "" &&
				getProp( pr_tableSettings, "sticky_header", false ) === true
			){
				headerSX = { backgroundColor: themeVariables.background_paper }
			}
			return headerSX
		}

		const returnCellCSS = ( column: TsInterface_TableColumn, columnIndex: TsType_Number, rowData: TsInterface_TableDataRow ): TsType_String => {
			let cellClassName = ""
			if ( column != null && column.cell != null && column.cell.cell_css != null ){
				cellClassName = column.cell.cell_css( rowData, pr_tableAdditionalData )
			}
			if ( pr_tableSettings != null && pr_tableSettings.conditional_row_styles != null ){
				for ( let loopConditionIndex in pr_tableSettings.conditional_row_styles ) {
					let loopCondition = pr_tableSettings.conditional_row_styles[ loopConditionIndex ]
					if (evaluateConditionLogic( loopCondition.conditional_display, { rowData: rowData, tableAdditionalData: pr_tableAdditionalData } ) === true){
						cellClassName += " "
						cellClassName += loopCondition.className
					}
				}
			}
			if( us_collapsedColumns[ columnIndex ] === true ){
				cellClassName += " tw-px-1"
			}
			return cellClassName
		}

		const returnTableHeadCellSortDirection = ( column: TsInterface_TableColumn ) => {
			if (
				pr_sortProperty != null &&
				column != null &&
				column.header != null &&
				column.header.header_sort_by != null &&
				pr_sortProperty === column.header.header_sort_by
			){
				return pr_sortDirection
			} else {
				return undefined
			}
		}

		const updatePageChange = ( newPageNumber: TsType_Number ) => {
			let firstItem: TsInterface_UnspecifiedObject = {}
			let lastItem: TsInterface_UnspecifiedObject = {}
			if ( us_tableData.length === 0 ){
				// No Data
			} else if ( us_tableData.length < pr_rowsPerPage ){
				firstItem = us_tableData[ 0 ]
				lastItem = {}
			} else {
				firstItem = us_tableData[ 0 ]
				lastItem = us_tableData[ us_tableData.length - 1 ]
			}
			if ( newPageNumber > us_selectedPage ){
				us_setPageChangeDirection( "next" )
			} else {
				us_setPageChangeDirection( "back" )
			}
			if ( lastItem != null && lastItem[ pr_sortProperty ] ){
				us_setStartAfter( lastItem[ pr_sortProperty ] )
			} else {
				us_setStartAfter( null )
			}
			us_setSelectedPage( newPageNumber )
			us_setPaginationQueryCursors( prevState => ({
				...prevState,
				[ us_selectedPage ]: {
					firstItem: firstItem,
					lastItem: lastItem
				}
			}));
		}

		const updateSearchInput = ( newSearchValue: TsType_String ) => {
			let searchSettings = getProp( pr_tableSettings, "search_settings_database", {} )
			us_setSearchValue( newSearchValue )
			if(
				us_searchProperty != null &&
				us_searchProperty !== "" &&
				newSearchValue != null &&
				newSearchValue !== "" &&
				searchSettings.search_endpoint != null
			){
				us_setSearchingData( true )
				// Get Data
				DatabaseGetCollection( searchSettings.search_endpoint( us_searchProperty, newSearchValue ) ).then(( res_DGC ) => {
					us_setTableSearchData( objectToArray(res_DGC.data) )
					us_setSearchingData( false )
				}).catch(( rej_DGC ) => {
					us_setTableSearchData( [] )
					us_setSearchingData( false )
				})
			}
		}

		const returnSX_TableContainer = (): TsInterface_UnspecifiedObject => {
			let tableContainerSX: TsInterface_UnspecifiedObject = {}
			if( getProp( pr_tableSettings, "sticky_header", null ) != null ){
				tableContainerSX["maxHeight"] = getProp( pr_tableSettings, "sticky_table_height", "100%" )
			}
			return tableContainerSX
		}

		// JSX Generation
		const returnJSX_TablePagination = (): TsType_JSX => {
			let tablePaginationJSX = <></>
			if( pr_tableSettings.hide_pagination === true ){
				// let from = 1
				let to = us_tableData.length
				// let count = tableSettings.rows_per_page
				let limitTextJSX = <>{ s_SHOWING_ALL } { us_tableData.length } { s_RESULTS }</>
				if( to === pr_tableSettings.rows_per_page ){
					limitTextJSX = <>{ s_SHOWING_FIRST } { us_tableData.length } { s_RESULTS }</>
				}
				tablePaginationJSX =
				<Box>
					<Divider/>
					<Box className="tw-m-2">
						{ limitTextJSX }
					</Box>
				</Box>
			} else {
				tablePaginationJSX =
				<Box>
					<Divider/>
					<TablePagination
						component="div"
						count={ us_rowCount }
						labelDisplayedRows={({ from, to, count }) => {
							return `${from}-${to} ${s_OF.props.children} ${count !== -1 ? count : `${s_MANY.props.children}`}`
						}}
						onPageChange={
							(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPageNumber: TsType_Number) => {
								updatePageChange( newPageNumber )
							} }
						page={ us_selectedPage }
						rowsPerPage={ pr_rowsPerPage }
						rowsPerPageOptions={[ pr_rowsPerPage ]}
					/>
				</Box>
			}
			return tablePaginationJSX
		}

		const returnColumnMinimization = ( column: TsInterface_TableColumn, columnIndex: TsType_Number ): TsType_JSX => {
			let iconJSX = <></>
			if( pr_tableSettings != null && pr_tableSettings["collapsible_columns"] === true ){
				if( us_collapsedColumns[ columnIndex ] !== true ){
					iconJSX =
					<Tooltip title={ s_MINIMIZE } placement="top">
						<Box
							className="tw-inline-block tw-opacity-5 hover:tw-opacity-100 tw-cursor-pointer tw-align-top"
							sx={{ marginTop: "2px", marginLeft: "4px" }}
							onClick={ () => {
								if( us_collapsedColumns[columnIndex] ){
									us_setCollapsedColumns( { ...us_collapsedColumns, [columnIndex]: false } )
								} else {
									us_setCollapsedColumns( { ...us_collapsedColumns, [columnIndex]: true } )
								}
							}}
						>
							<Icon icon="square-minus" />
						</Box>
					</Tooltip>
				} else {
					iconJSX =
					<Tooltip title={ column.header.header_jsx( pr_tableAdditionalData ) } placement="top">
						<Box
							className="tw-inline-block tw-cursor-pointer tw-align-top"
							sx={{ marginTop: "2px", rotate: "270deg" }}
							onClick={ () => {
								if( us_collapsedColumns[columnIndex] ){
									us_setCollapsedColumns( { ...us_collapsedColumns, [columnIndex]: false } )
								} else {
									us_setCollapsedColumns( { ...us_collapsedColumns, [columnIndex]: true } )
								}
							}}
						>
							<Icon icon="square-caret-down"/>
						</Box>
					</Tooltip>
				}
			}
			return iconJSX
		}

		const returnJSX_TableHeadCell = ( column: TsInterface_TableColumn, columnIndex: TsType_Number ): TsType_JSX => {
			let tableHeadCellJSX = <></>
			if( us_collapsedColumns[ columnIndex ] !== true ){
				if ( pr_sortProperty === column.header.header_sort_by ){
					tableHeadCellJSX =
					<>
						<TableSortLabel
							active={ true }
							direction={ pr_sortDirection }
							disabled={ true }
							onClick={ () => {  } }
						>
							{ column.header.header_jsx( pr_tableAdditionalData ) }
						</TableSortLabel>
						{ returnColumnMinimization( column, columnIndex ) }
					</>
				} else {
					tableHeadCellJSX =
					<>
						{ column.header.header_jsx( pr_tableAdditionalData ) }
						{ returnColumnMinimization( column, columnIndex ) }
					</>
				}
			} else {
				tableHeadCellJSX = returnColumnMinimization( column, columnIndex )
			}
			return tableHeadCellJSX
		}

		const returnJSX_TableHead = (): TsType_JSX => {
			let tableHeadJSX = <TableHead></TableHead>
			if ( pr_tableSettings.show_header !== false ){
				tableHeadJSX =
				<TableHead>
					<TableRow>
						{objectToArray( pr_tableColumns ).map(( column: TsInterface_TableColumn, columnIndex: TsType_Number ) => (
							<TableCell
								key={ columnIndex }
								className={ returnHeaderCSS( column, columnIndex ) }
								sx={ returnHeaderSX( column, columnIndex ) }
								sortDirection={ returnTableHeadCellSortDirection( column ) }
							>
								{returnJSX_TableHeadCell( column, columnIndex )}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
			}
			return tableHeadJSX
		}

		const returnJSX_CellContent = ( row: TsInterface_TableDataRow, column: TsInterface_TableColumn, columnIndex: TsType_Number ): TsType_JSX => {
			let cellContentJSX = <></>
			if( us_collapsedColumns[ columnIndex ] !== true ){
				cellContentJSX = column.cell.cell_jsx( row, pr_tableAdditionalData, tableHooks )
			}
			return cellContentJSX
		}

		const returnJSX_TableBody = (): TsType_JSX => {
			let rowData: TsInterface_TableData = []
			let searchable = getProp( pr_tableSettings, "searchable", false )
			if(
				searchable === true &&
				us_searchView === true &&
				us_searchProperty != null &&
				us_searchProperty !== "" &&
				us_searchValue != null &&
				us_searchValue !== ""
			){
				rowData = us_tableSearchData.sort( dynamicSort( pr_sortProperty, pr_sortDirection ))
			} else {
				rowData = us_tableData.sort( dynamicSort( pr_sortProperty, pr_sortDirection ))
			}
			let tableBodyJSX =
			<TableBody>
				{rowData.map(( row: TsInterface_TableDataRow, rowIndex: TsType_Number ) => (
					<TableRow key={ rowIndex } sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						{objectToArray( pr_tableColumns ).map(( column: TsInterface_TableColumn, columnIndex: TsType_Number ) => (
							<TableCell
								onClick={ () => {
									if( column.cell.cell_onClick != null ){
										column.cell.cell_onClick( row, pr_tableAdditionalData, tableHooks )
									}
								}}
								key={ columnIndex }
								className={ returnCellCSS( column, columnIndex, row ) }
							>
								{ returnJSX_CellContent( row, column, columnIndex ) }
							</TableCell>
						))}
					</TableRow>
				))}
			</TableBody>
			return tableBodyJSX
		}

		// TODO - copy to basic table
		const returnJSX_SearchButton = (): TsType_JSX => {
			let searchSettings = getProp( pr_tableSettings, "search_settings_database", {} )
			let searchButtonColor = getProp( searchSettings, "search_button_color", "info" )
			let searchButtonIcon = getProp( searchSettings, "search_button_icon", <Icon icon="magnifying-glass" type="solid" /> )
			let buttonVariant: TsType_MuiButtonVariants = "outlined"
			if( us_searchView === true ){ buttonVariant = "contained" }
			let buttonDisabled = false
			if( us_filterView === true ){ buttonDisabled = true }
			// SX
			let buttonSX: TsInterface_UnspecifiedObject = { minHeight: "36.5px", width: "50px", minWidth: "50px", paddingLeft: "25px" }
			// JSX
			let buttonJSX =
			<Button
				sx={ buttonSX }
				variant={ buttonVariant }
				disabled={ buttonDisabled }
				color={ searchButtonColor }
				startIcon={searchButtonIcon }
				className="tw-mr-2"
				onClick={ () => {
					if( us_searchView === true ){
						us_setSearchValue("")
						us_setSearchProperty("")
					}
					us_setSearchView( !us_searchView )
				}}
			/>
			return buttonJSX
		}

		const returnJSX_SearchFirebasePropertyDropdown = (): TsType_JSX => {
			// Variables
			let searchSettings = getProp( pr_tableSettings, "search_settings_database", {} )
			let searchPropertyOptions = getProp( searchSettings, "search_property_options", [] )
			// JSX
			let dropdownJSX =
			<FormControl sx={{ marginTop: 0, marginBottom: 0 }} className="tw-mr-2 bp_thin_select_input">
				<Select
					color="primary"
					value={ us_searchProperty }
					onChange={ (event: TsType_Any) => {
						if ( event != null && event.target != null && event.target.value != null ){
							us_setSearchProperty( event.target.value )
						}
					} }
					// displayEmpty={ true }
					// renderValue={ () => {
					// 	let renderValue = <></>
					// 	if( searchProperty != null ){
					// 		renderValue = <>{ searchProperty }</>
					// 	} else {
					// 		renderValue = <Box>{ s_SEARCH_BY }</Box>
					// 	}
					// 	return renderValue
					// } }
					variant="outlined"
				>
					{ searchPropertyOptions.map(( option: TsInterface_UnspecifiedObject, index: TsType_Number ) => (
						<MenuItem
							key={ index }
							value={ option["key"] }
							disabled={ option["disabled"] === true }
						>
							{ option["value"] }
						</MenuItem>
					))}
				</Select>
			</FormControl>
			return dropdownJSX
		}

		const returnJSX_SearchFirebaseTextInput = (): TsType_JSX => {
			// Variables
			let searchSettings = getProp( pr_tableSettings, "search_settings_database", {} )
			let searchButtonText = getProp( searchSettings, "search_input_text", "Search" )
			let searchButtonIcon = getProp( searchSettings, "search_button_icon", <Icon icon="magnifying-glass" type="solid" /> )
			// JSX
			let inputJSX =
			<FormControl sx={{ marginTop: 0, marginBottom: 0 }}>
				<TextField
					disabled={ us_searchProperty === "" || us_searchProperty == null }
					className="bp_thin_text_input"
					sx={{ marginTop: 0, marginBottom: 0 }}
					color="primary"
					value={ us_searchValue }
					margin="normal"
					placeholder={ searchButtonText }
					onChange={ (event: TsType_Any) => {
						if ( event != null && event.target != null && event.target.value != null ){
							updateSearchInput( event.target.value )
						}
					} }
					onBlur={ (event: TsType_Any) => {
						// if ( event != null && event.target != null && event.target.value != null ){
						// 	updateSearchInput( event.target.value )
						// }
					} }
					variant="outlined"
					InputProps={{
						startAdornment:
						<InputAdornment position="start">
							{ searchButtonIcon }
						</InputAdornment>
					}}
				/>
			</FormControl>
			return inputJSX
		}

		const returnJSX_MeilisearchSearchInput = (): TsType_JSX => {
			let inputJSX = <></>
			let searchSettings = getProp( pr_tableSettings, "search_settings_database", {} )
			let searchClientKey = getProp( searchSettings, "search_client_key", null )
			let searchIndexKey = getProp( searchSettings, "search_index_key", null )
			let searchFilters = getProp( searchSettings, "search_filters", [] )
			let searchSearchResultRenderer = getProp( searchSettings, "search_result_renderer", null )
			if( searchClientKey != null && searchIndexKey != null ){
				inputJSX =
				<SearchInput
					clientKey={ searchClientKey }
					searchIndexKey={ searchIndexKey }
					searchFilters={ searchFilters }
					searchResultRenderer={ searchSearchResultRenderer }
					additionalSearchData={ {} }
				/>
			}
			return inputJSX
		}

		const returnJSX_SearchInputs = (): TsType_JSX => {
			let searchSettings = getProp( pr_tableSettings, "search_settings_database", {} )
			let searchType = getProp( searchSettings, "search_type", null )
			// JSX
			let searchInputsJSX = <></>
			if( searchType === "firebase" ){
				if( us_searchProperty == null || us_searchProperty === "" ){
					searchInputsJSX =
					<Box className="tw-inline-block">
						{ returnJSX_SearchFirebasePropertyDropdown() }
					</Box>
				} else {
					searchInputsJSX =
					<Box className="tw-inline-block">
						{ returnJSX_SearchFirebasePropertyDropdown() }
						{ returnJSX_SearchFirebaseTextInput() }
					</Box>
				}
			} else if( searchType === "meilisearch" ) {
				searchInputsJSX =
				<Box className="tw-inline-block">
					{ returnJSX_MeilisearchSearchInput() }
				</Box>
			}
			return searchInputsJSX
		}

		// const returnJSX_FilterButton = (): TsType_JSX => {
			// let filterSettings = getProp( tableSettings, "filter_settings", {} )
			// let filterButtonColor = getProp( filterSettings, "filter_button_color", "warning" )
			// let filterButtonIcon = getProp( filterSettings, "filter_button_icon", <Icon icon="filter" type="regular" /> )
			// let buttonVariant: TsType_MuiButtonVariants = "outlined"
			// if( filterView === true ){ buttonVariant = "contained" }
			// let buttonDisabled = false
			// if( searchView === true ){ buttonDisabled = true }
			// // SX
			// let buttonSX: TsInterface_UnspecifiedObject = { minHeight: "36.5px", width: "50px", minWidth: "50px", paddingLeft: "25px" }
			// // JSX
			// let buttonJSX =
			// <Button
			// 	sx={ buttonSX }
			// 	variant={ buttonVariant }
			// 	disabled={ buttonDisabled }
			// 	color={ filterButtonColor }
			// 	startIcon={ filterButtonIcon }
			// 	className="tw-mr-2"
			// 	onClick={ () => {
			// 		if( filterView === true ){

			// 			// TODO - wipe filter selections

			// 		}
			// 		setFilterView( !filterView )
			// 	}}
			// />
			// return buttonJSX
		// }

		// const returnJSX_FilterInputs = (): TsType_JSX => {
			// let filterInputsJSX = <></>

			// // TODO

			// return filterInputsJSX
		// }

		const returnJSX_SearchAndFilterButtons = (): TsType_JSX => {
			// Buttons
			let buttonsJSX = <></>
			let searchable = getProp( pr_tableSettings, "searchable", false )
			let filterable = getProp( pr_tableSettings, "filterable", false )
			let hasButtonsOrInputs = false
			if( searchable === true && filterable === true ){
				buttonsJSX =
				<Box className="tw-inline-block">
					{ returnJSX_SearchButton() }
					{/* { returnJSX_FilterButton() } */}
				</Box>
				hasButtonsOrInputs = true
			} else if( searchable === true && filterable === false ){
				buttonsJSX =
				<Box className="tw-inline-block">
					{ returnJSX_SearchButton() }
				</Box>
				hasButtonsOrInputs = true
			} else if( searchable === false && filterable === true ) {
				buttonsJSX =
				<Box className="tw-inline-block">
					{/* { returnJSX_FilterButton() } */}
				</Box>
				hasButtonsOrInputs = true
			}
			// Inputs
			let inputsJSX = <></>
			if( us_searchView === true ){
				inputsJSX =
				<Box className="tw-inline-block tw-align-top">
					{ returnJSX_SearchInputs() }
				</Box>
				hasButtonsOrInputs = true
			}
			if( us_filterView === true ){
				inputsJSX =
				<Box className="tw-inline-block">
					{/* { returnJSX_FilterInputs() } */}
				</Box>
				hasButtonsOrInputs = true
			}
			// Combined
			let searchAndFilterJSX = <></>
			if( hasButtonsOrInputs === true ){
				searchAndFilterJSX =
				<Box className="tw-p-2">
					{ buttonsJSX }
					{ inputsJSX }
				</Box>
			}
			return searchAndFilterJSX
		}

		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX = <></>
			let searchable = getProp( pr_tableSettings, "searchable", false )
			if( us_dataLoaded === true ){
				if(
					us_selectedPage === 0 &&
					objectToArray( us_tableData ).length === 0 &&
					pr_tableSettings.no_data_message != null
				){
					// No Data for Default Query
					componentJSX =
					<Box className="tw-p-4 tw-text-center">
						<Typography variant='h5'>{ pr_tableSettings.no_data_message }</Typography>
					</Box>
				} else if(
					us_searchingData === false &&
					us_searchProperty != null &&
					us_searchProperty !== "" &&
					us_searchValue != null &&
					us_searchValue !== "" &&
					us_tableSearchData.length === 0 &&
					pr_tableSettings.search_settings_database != null &&
					pr_tableSettings.search_settings_database.search_no_data_message != null
				){
					// Using Search and No Data for Search
					componentJSX =
					<Box>
						{ returnJSX_SearchAndFilterButtons() }
						<Box className="tw-p-4 tw-text-center">
							<Typography variant='h5'>{ pr_tableSettings.search_settings_database.search_no_data_message }</Typography>
						</Box>
					</Box>
				} else {
					if( us_searchingData === true ){
						// Currently Searching
						componentJSX =
						<Box>
							{ returnJSX_SearchAndFilterButtons() }
							<Box className="tw-text-center">
								<LinearProgress color="inherit" />
							</Box>
						</Box>
					} else if(
						searchable === true &&
						us_searchView === true &&
						us_searchValue !== ""
					){
						// Search Results - no pagination
						componentJSX =
						<Box>
							{ returnJSX_SearchAndFilterButtons() }
							<TableContainer
								className="bp_show_horizontal_scroll"
								sx={ returnSX_TableContainer() }
							>
								<Table
									stickyHeader={ getProp( pr_tableSettings, "sticky_header", false ) }
									aria-label="table"
									size={returnTableSize( pr_tableSettings.size )}
									sx={{ width: "max-content", minWidth: "100%" }}
								>
									{ returnJSX_TableHead() }
									{ returnJSX_TableBody() }
								</Table>
							</TableContainer>
						</Box>
					} else if(
						pr_tableSettings != null &&
						pr_tableSettings.search_settings_database != null &&
						pr_tableSettings.search_settings_database.search_only_table === true
					){
						// Search Only Table Variant
						componentJSX =
						<Box>
							{ returnJSX_SearchAndFilterButtons() }
							<TableContainer
								className="bp_show_horizontal_scroll"
								sx={ returnSX_TableContainer() }
							>
								<Table
									stickyHeader={ getProp( pr_tableSettings, "sticky_header", false ) }
									aria-label="table"
									size={returnTableSize( pr_tableSettings.size )}
									sx={{ width: "max-content", minWidth: "100%" }}
								>
									{ returnJSX_TableHead() }
								</Table>
							</TableContainer>
						</Box>
					} else {
						// Not Searching - has pagination
						componentJSX =
						<Box>
							{ returnJSX_SearchAndFilterButtons() }
							<TableContainer
								className="bp_show_horizontal_scroll"
								sx={ returnSX_TableContainer() }
							>
								<Table
									stickyHeader={ getProp( pr_tableSettings, "sticky_header", false ) }
									aria-label="table"
									size={returnTableSize( pr_tableSettings.size )}
									sx={{ width: "max-content", minWidth: "100%" }}
								>
									{ returnJSX_TableHead() }
									{ returnJSX_TableBody() }
								</Table>
							</TableContainer>
							{ returnJSX_TablePagination() }
						</Box>
					}
				}
			} else {
				componentJSX = <Box></Box>
			}
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	// } )
	}