///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Services contain business logic that is completely abstracted from user interfaces

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_Null,
	TsType_Number,
	TsType_String
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComparisonReferenceDataObjects {
		[ sourceKey: TsType_String ]: {
			[ propKey: TsType_String ]: TsType_String | TsType_Number | TsType_Boolean | TsType_Null | TsInterface_UnspecifiedObject | TsType_Any
		}
	}

	export interface TsInterface_ConditionalObject {
		active: TsType_Boolean
		logic_type: "comparison" | "and" | "or" | ""
		source: TsType_String | TsType_Null
		prop: TsType_String | TsType_Null
		comparator: "==" | "!=" | ">" | ">=" | "<" | "<=" | "===" | "!==" | TsType_Null
		value: TsType_String | TsType_Number | TsType_Boolean | TsType_Null
		conditions: TsType_Any[] // TODO - clean this up
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

	export const evaluateConditionLogic = ( conditionalObject: TsInterface_ConditionalObject, comparisonReferenceDataObjects: TsInterface_ComparisonReferenceDataObjects ): TsType_Boolean | TsType_Null => {
		let conditionResult = null
		let dataSource = getProp( conditionalObject, "source", null )
		let conditionCheckValue = getProp( conditionalObject, "value", null )
		if ( conditionalObject != null ){
			if ( conditionalObject["logic_type"] === "comparison" ){
				conditionResult = false
				if ( conditionalObject["prop"] != null && conditionalObject["comparator"] != null ){
					let conditionActualValue = null
					if ( dataSource != null ){
						conditionActualValue = getProp( comparisonReferenceDataObjects[dataSource], conditionalObject["prop"], null )
					}
					switch( conditionalObject["comparator"] ){
						case	"==": 	if ( conditionActualValue 		=== 	conditionCheckValue ){ conditionResult = true } break
						case	"!=": 	if ( conditionActualValue 		!== 	conditionCheckValue ){ conditionResult = true } break
						// case	"===": 	if ( conditionActualValue 		=== 	conditionCheckValue ){ conditionResult = true } break
						// case	"!==": 	if ( conditionActualValue 		!== 	conditionCheckValue ){ conditionResult = true } break
						case	">": 	if ( conditionActualValue		> 		conditionCheckValue && conditionActualValue != null ){ conditionResult = true } break
						case	">=": 	if ( conditionActualValue		>= 		conditionCheckValue && conditionActualValue != null ){ conditionResult = true } break
						case	"<": 	if ( conditionActualValue		<		conditionCheckValue && conditionActualValue != null ){ conditionResult = true } break
						case	"<=": 	if ( conditionActualValue		<= 		conditionCheckValue && conditionActualValue != null ){ conditionResult = true } break
						default: 		break
					}
				}
			} else if ( conditionalObject["logic_type"] === "and" ) {
				let failedCondition = false
				for ( let conditionIndex in conditionalObject.conditions ){
					let loopConditionObject = conditionalObject.conditions[ conditionIndex ]
					let evaluatedConditionResult = evaluateConditionLogic( loopConditionObject, comparisonReferenceDataObjects )
					if ( evaluatedConditionResult === false ){
						failedCondition = true
					}
				}
				if (failedCondition === true){
					conditionResult = false
				} else {
					conditionResult = true
				}
			} else if (conditionalObject["logic_type"] === "or") {
				conditionResult = false
				for (let conditionIndex in conditionalObject.conditions){
					let loopConditionObject = conditionalObject.conditions[ conditionIndex ]
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					let evaluatedConditionResult = evaluateConditionLogic( loopConditionObject, comparisonReferenceDataObjects )
					if ( evaluatedConditionResult === true ){
						conditionResult = true
					}
				}
			}
		}
		return conditionResult
	}