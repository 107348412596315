//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Used to generate Confirm Dialogs

		TODO:
			[ ] Typescript - 2 instances of TsType_Any - function copied from MUI Docs
			[ ] Typescript - 1 instance of TsType_Unknown
	*/


///////////////////////////////
// Imports
///////////////////////////////

import React from 'react'
import {
	Trans
} from 'react-i18next'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TsInterface_ConfirmDialogObject,
	TsInterface_UserInterface_ConfirmDialogInternalState,
	TsType_UserInterface_AllDialogsDismissCallback
} from 'rfbp_core/services/context'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_MuiAppBarColors,
	TsType_Unknown,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	AppBar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Slide,
	Stack,
	Toolbar,
	Typography
} from '@mui/material/'
import {
	TransitionProps
} from '@mui/material/transitions'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_ARE_YOU_SURE_YOU_WANT_TO_CONFIRM: TsType_JSX = 		<Trans>Are you sure you want to confirm</Trans>
	const s_CONFIRM: TsType_JSX = 								<Trans>Confirm</Trans>
	const s_DISMISS: TsType_JSX = 								<Trans>Dismiss</Trans>
	// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

	const Transition = React.forwardRef(function Transition(
		props: TransitionProps & {
			children: React.ReactElement< TsType_Any, TsType_Any >;
		},
		ref: React.Ref< TsType_Unknown >,
	) {
		return <Slide direction="up" ref={ ref } { ...props } />;
	})


///////////////////////////////
// Component
///////////////////////////////

	export const ConfirmDialog = (
		confirm: TsInterface_ConfirmDialogObject,
		dismissDialog: TsType_UserInterface_AllDialogsDismissCallback,
		ConfirmDialogState: TsInterface_UserInterface_ConfirmDialogInternalState,
		setConfirmDialogState: React.Dispatch< React.SetStateAction< TsInterface_UserInterface_ConfirmDialogInternalState >>
	): TsType_JSX => {

		// Props
		let pr_confirmColor: TsInterface_ConfirmDialogObject["color"] = 					getProp( confirm, "color", "success" )
		let pr_confirmHeader: TsInterface_ConfirmDialogObject["header"] = 					getProp( confirm, "header", s_CONFIRM )
		let pr_confirmIcon: TsInterface_ConfirmDialogObject["icon"] = 						getProp( confirm, "icon", <Icon icon="square-check"/> )
		let pr_confirmSubmit: TsInterface_ConfirmDialogObject["submit_text"] = 				getProp( confirm, "submit_text", s_CONFIRM )
		let pr_confirmText: TsInterface_ConfirmDialogObject["text"] = 						getProp( confirm, "text", s_ARE_YOU_SURE_YOU_WANT_TO_CONFIRM )
		let pr_submitCallback: TsInterface_ConfirmDialogObject["submit_callback"] = 		getProp( confirm, "submit_callback", () => {
			return new Promise( ( resolve, reject ) => {
				resolve( { success: true } )
			})
		} )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks

		// { sort-end } - hooks

		// Hooks - useEffect

		// Other Variables
		let open: TsType_Boolean = true

		// Functions
		const handleClose = (): TsType_Void => {
			dismissDialog()
		}

		const handleSubmit = (): TsType_Void => {
			setConfirmDialogState({ confirming: true })
			pr_submitCallback!().then(() => {
				setConfirmDialogState({ confirming: false })
				dismissDialog()
			}).catch(() => {
				setConfirmDialogState({ confirming: false })
				dismissDialog()
			})
		}



		// JSX Generation
		const returnJSX_DialogActions = (): TsType_JSX => {
			let dialogActionsJSX
			if (ConfirmDialogState != null && ConfirmDialogState["confirming"] === true){
				dialogActionsJSX =
				<Stack spacing={ 1 } direction="row">
					<Button color={ pr_confirmColor } disabled onClick={ handleClose } variant="outlined">{ s_DISMISS }</Button>
					<Button color={ pr_confirmColor } disabled onClick={ handleSubmit } variant="contained" startIcon={<Icon icon="arrows-rotate" className="bp_spin"/>}>{ pr_confirmSubmit }</Button>
				</Stack>
			} else {
				dialogActionsJSX =
				<Stack spacing={ 1 } direction="row">
					<Button color={ pr_confirmColor } onClick={ handleClose } variant="outlined">{ s_DISMISS }</Button>
					<Button color={ pr_confirmColor } onClick={ handleSubmit } variant="contained">{ pr_confirmSubmit }</Button>
				</Stack>
			}
			return dialogActionsJSX
		}

		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box>
				<Dialog
					open={ open }
					TransitionComponent={ Transition }
					keepMounted
					onClose={ handleClose }
					aria-labelledby="confirm-dialog-title"
					aria-describedby="confirm-dialog-description"
					className="bp_dialog_md_width"
				>
					<AppBar position="static" color={ pr_confirmColor as TsType_MuiAppBarColors }>
						<Toolbar>
							<IconButton
								disabled
								size="large"
								edge="start"
								color="inherit"
								aria-label="menu"
								sx={{ mr: 2, color: "#fff !important" }}
							>
								{ pr_confirmIcon }
							</IconButton>
							<Typography component={'span'} variant={ 'h6' } sx={{ flexGrow: 1 }}>{ pr_confirmHeader }</Typography>
						</Toolbar>
					</AppBar>
					<DialogContent>
						<Box>
							<Typography variant="subtitle1" display="block">{ pr_confirmText }</Typography>
						</Box>
					</DialogContent>
					<DialogActions>
						{ returnJSX_DialogActions() }
					</DialogActions>
				</Dialog>
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}